 @import url("https://fonts.googleapis.com/css2?family=Assistant:wght@200..800&family=Heebo:wght@100..900&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap"); 
 

* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}

body {
  background: #ebecf1;
}

.UmobileMenuTop {
  position: fixed;
  top: 0px;
  right: -100%;
  transition: 0.U4s;
  width: 100%;
  bottom: 0px;
  z-index: 99999999;
  background: rgba(53, 53, 53, 0.U9);
}
.UmobileMenuTop .Uinner {
  position: relative;
  max-width: 364px;
  width: 90%;
  bottom: 0px;
  height: 100vh;
  background: #097a8f;
  padding: 22px;
  overflow-y: auto;
}
.UmobileMenuTop .Uinner .UheaderText {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 42px;
}
.UmobileMenuTop .Uinner .UheaderText .Ulogo {
  cursor: pointer;
}

.UmobileMenuTop .Uinner .UheaderText .UswitchBtn {
  width: 187px;
  height: 44px;
  border-radius: 40px;
  background: #2599af;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.UmobileMenuTop .Uinner .UheaderText .UswitchBtn a {
  width: 99px;
  height: 44px;
  border-radius: 40px;
  opacity: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Heebo", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 46px;
  text-align: center;
  color: #ffffff;
  text-decoration: none;
}
.UmobileMenuTop .Uinner .UheaderText .UswitchBtn a.Uactive {
  background: #02343d;
}

.UmobileMenuTop .Uinner ul {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.UmobileMenuTop .Uinner ul li {
  margin: 0px 51px 7px 0px;
  list-style-type: none;
  width: 100%;
}

.UmobileMenuTop .Uinner ul li a {
  font-family: "Heebo", sans-serif;
  font-size: 24px;
  font-weight: 400;
  width: 100%;
  display: block;
  line-height: 46px;
  text-align: right;
  text-decoration: none;
  color: #fff;
}

.UmobileMenuTop .Uinner ul .Uupgrade a {
  width: 179px;
  height: 48px;
  gap: 0px;
  opacity: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Assistant", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 18.U31px;
  text-align: center;
  background: #097185;
  border-radius: 35px;
  color: white;
}

.Uheader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 5% 0px 0%;
  background: #097185;
}

.Uheader .Uleft {
  display: flex;
  align-items: center;
}

.Uheader .Uleft .UmobileMenu {
  display: none;
}

.Uheader .Uleft .Ulogo {
  margin-left: 39px;
}

.Uheader .Uleft ul {
  display: flex;
  align-items: center;
}

.Uheader .Uleft ul li {
  margin-right: 39px;
  list-style-type: none;
}

.Uheader .Uleft ul li a {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 33px;
  text-align: left;
  text-decoration: none;
  color: #fff;
}

.Uheader .Uleft ul li:nth-last-child(1) a {
  width: 103.U09px;
  height: 35px;
  gap: 0px;
  opacity: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Assistant", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 18.U31px;
  text-align: center;
  background: #fff;
  border-radius: 35px;
  color: #0a7f95;
}

.Uheader .Uright {
  display: flex;
  align-items: center;
}
.Uheader .Uright .UlogoInst {
  width: 79px;
  height: 66px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Uheader .Uright .Ulang {
  width: 97px;
  height: 32px;
  gap: 0px;
  border-radius: 5px;
  margin-right: auto;
  border: 1px 0px 0px 0px;
  opacity: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #fff;
  padding: 0px 15px;
  cursor: pointer;
  position: relative;
  margin-left: 29px;
}

.Uheader .Uright .Uicons:nth-last-child(2) {
  margin-right: 14px;
}
.Uheader .Uright .Ulang p {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 33px;
  color: #fff;
}
.tablinks {
  background-color: #ffffff;
  border: none;
  margin-right: 20px;
  color: #333;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  padding: 7px 10px;
}
.Uheader .Uright .Ulang .Udropdown {
  position: absolute;
  bottom: -115%;
  border-radius: 7px;
  border: 1px 0px 0px 0px;
  opacity: 0px;
  right: 0px;
  background: #097185;
  border: 1px solid #fff;
  width: 100%;
  display: none;
}

.Uheader .Uright .Ulang .Ushow {
  display: block;
  z-index: 9999;
}

.Uheader .Uright .Ulang .Udropdown a {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 33px;
  color: #fff;
  text-decoration: none;
  display: flex;
  align-items: center;
  width: 97px;
  height: 32px;
  justify-content: center;
}

.Uheader .Uright .Uicons:nth-last-child(2) {
  margin-right: 14px;
  margin-left: 14px;
}

.UmainContent {
  max-width: 1140px;
  width: 90%;
  margin: auto;
}

.UmainContent .UtopHeader {
  width: 100%;
  padding: 25px 18px;
  box-shadow: 0px 0px 30px 0px #0000001f;
  background: #fff;
  border-radius: 0px 0px 10px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  position: relative;
}


.UmainContent .UtopHeader .UbackPage {
  position: absolute;
  right: -70px;
  bottom: 15px;
  width: 51px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #1d9da2;
  background: #ffffff;
  cursor: pointer;
  border-radius: 40px;
}

.UmainContent .UtopHeader .Uleft h2 {
  font-family: "Heebo", sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  color: #0f4152;
}

.UmainContent .UtopHeader .Uleft p {
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #0f4152;
  margin-bottom: 0px !important;
}

.UmainContent .UtopHeader .Uright {
  display: flex;
  align-items: center;
}

.UmainContent .UtopHeader .Uright .Utext {
  margin-left: 13px;
}

.UmainContent .UtopHeader .Uright .Utext h2 {
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  color: #0f4152;
}

.UmainContent .UtopHeader .Uright .Utext p {
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  color: #0f4152;
}

.UmainContent .UtopHeader .Uright button {
  width: 182px;
  height: 44px;
  gap: 0px;
  opacity: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #097285;
  border-radius: 35px;

  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 23.U5px;
  text-align: center;
  color: #fff;
  border: none;
  outline: none;
  box-shadow: none;
}
.UmainContent .UprogressChapter .UcontentTab .Utabcontent .Urow .UbtnsList.focus-visible{
  border: 1px solid #131212;
}
.UbtnsList.focus-visible {
  border-bottom: 2px solid#1a0707; /* Full red border for keyboard focus */
}

.UmainContent .UprogressChapter .UcontentTab .Utabcontent .Urow .Uleft {
  display: flex;
  align-items: center;
}
.UmainContent .UprogressChapter .UcontentTab .Utabcontent .Urow .UbtnsList .Uview{
  width: 82px;
  margin: 3px;
  height: 31px;
  cursor: pointer;
  border-radius: 35px;
  border: 1px solid #b6b6b6;
  background: transparent;
  /* outline: none; */
  box-shadow: none;
  font-family: "Heebo", sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.U09px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #909090;
}

.UbtnsList .Uview.blue:focus-visible {
  outline: 2px solid#1a0707; /* Adjust color and thickness as needed */
  /* outline-offset: 2px; */
}

  .UmainContent
  .UprogressChapter
  .UcontentTab
  .Utabcontent
  .UcompletedBookProgress
  .UbtnsList
  .Uview {
  background: #0a7a8f !important;
  color: #fff !important;
  border: none;
  outline: none;
  cursor: pointer;
  box-shadow: none;
}

.UmainContent .UtopContent {
  width: 100%;

  box-shadow: 0px 0px 30px 0px #0000001f;
  background: #fff;
  border-radius: 10px 10px 0px 0px;
  padding-bottom: 50px;
}

.UmainContent .UtopContent .Usearch,
.UmainContent .UprogressChapter .Usearch {
  padding: 34px 18px 21px 18px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.UmainContent .UtopContent .Usearch h2,
.UmainContent .UprogressChapter .Usearch h2 {
  font-family: "Heebo", sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  color: #0f4152;
}
.roleTabs {
  display: flex;
  justify-content: left;
  margin-bottom: 20px;
}
.freezeBackground {
  background-color: rgba(102, 102, 104, 0.356);
  color: black; /* Ensure text is readable against the blue background */
}


.tablinks {
  background-color: #ffffff;
  /* border: none; */
  border: 2px solid transparent; /* Default border (transparent) */
  transition: border 0.3s ease, background-color 0.3s ease;
  margin-left: 20px;
  color: #333;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  padding: 7px 10px;
}

.tablinks:hover {
  background-color: #dddddd00;
  color: #000;
}

.tablinks.active {
  /* background-color: #4CAF50; */
  border-bottom: #000 2px solid;
  /* color: white; */
}
.tablinks.focus-visible {
  border-bottom: 2px solid #1a0707; /* Full red border for keyboard focus */
}
/* Custom styles for the select dropdown */
.UmainContent .UtopContent .Usearch .Ucustom-select {
  position: relative;
  max-width: 162px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.UeditBookModal .UinnerContent .UbodyModal form .Urow .Ucustom-select {
  position: relative;
  width: calc(50% - 6px);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.UmainContent .UtopContent .Usearch .Ucustom-select .Uselect-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 13px;
}

.UeditBookModal .UinnerContent .UbodyModal form .Urow .Ucustom-select .Uselect-icon {
  position: absolute;
  left: 14px;
}

.UmainContent .UtopContent .Usearch .Ucustom-select select,
.UeditBookModal .UinnerContent .UbodyModal form .Urow .Ucustom-select select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-right: 21px;
  height: 42px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #b6b6b6;
  padding: 0px 16px;
  outline: none;
  box-shadow: none;
  border-radius: 35px;
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 33px;
  color: #0f4152;
}

.UeditBookModal .UinnerContent .UbodyModal form .Urow .Ucustom-select select {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #626262;
}
.UmainContent
  .UprogressChapter
  .UcontentTab
  .Utabcontent
  .Urow
  .UprogressBar
  .UprogressTopLine {
  position: relative;
  width: 133px;
  height: 5px;
  background: #d8d8d8;
  border-radius: 35px;
  margin-left: 48px;
  overflow: hidden;
}

.UmainContent
  .UprogressChapter
  .UcontentTab
  .Utabcontent
  .Urow
  .UprogressBar
  .UprogressTopLine
  .UprogressInnerLine {
  background: #0a7a8f;
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 35px;
  bottom: 0px;
}

.UeditBookModal .UinnerContent .UbodyModal form .Urow .Ucustom-select select {
  border-radius: 5px !important;
  height: 50px !important;
  background: #ffffff;
  border: 1px 0px 0px 0px;
  border: 1px solid #cdcfd6;
  border-radius: 5px;
  padding: 0px 23px;
  outline: none;
  box-shadow: none;
}

.UmainContent .UtopContent .Usearch .Uselect-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 100%;
}

.UmainContent .UtopContent .Usearch .UsearchInput,
.UmainContent .UprogressChapter .Usearch .UsearchInput {
  max-width: 452px;
  width: 70%;
  height: 42px;
  border: 1px solid #b6b6b6;
  border-radius: 35px;
  overflow: hidden;
  display: flex;
  align-items: center;
}
.UmainContent .UprogressChapter .Usearch .UsearchInput {
  max-width: 174px;
  width: 70%;
}
.UmainContent .UtopContent .Usearch .UsearchInput input,
.UmainContent .UprogressChapter .Usearch .UsearchInput input {
  width: calc(100% - 35px);
  height: 100%;
  border-radius: 35px;
  outline: none;
  box-shadow: none;
  border: none;
  padding: 0px 14px;
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20.U56px;
  color: #0f4152;
}

.UmainContent .UtopContent .Usearch .UsearchInput input::placeholder,
.UmainContent .UtopContent .Usearch .UsearchInput input option[disabled],
.UmainContent .UprogressChapter .Usearch .UsearchInput input::placeholder {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20.U56px;
  color: #0f4152;
}

.UmainContent .UtopContent .Usearch .UsearchInput img {
  cursor: pointer;
}

.UmainContent .UtopContent .Usearch button {
  width: 119px;
  height: 44px;
  background: #59a0ad;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 23.U5px;
  text-align: center;
  color: #fff;
  border-radius: 35px;
  border: none;
  outline: none;
  box-shadow: none;
}

.UmainContent .UtopContent .Usearch button.Uactive {
  background: #097285;
}

.UmainContent .Uline {
  width: calc(100% - 36px);
  margin: auto;
  display: block;
  border: 1px dotted #cdcfd6;
  margin-bottom: 20px;
}

.UmainContent .Ubooks {
  width: 100%;
}

.UmainContent .Ubooks h1 {
  font-family: "Heebo", sans-serif;
  font-size: 28px;
  font-weight: 700;
  line-height: 41.U13px;
  text-align: left;
  color: #0f4152;
  margin-left: 18px;
  margin-bottom: 15px;
}

.UmainContent .Ubooks .UheaderBooksList {
  margin-left: 18px;
}

.UmainContent .Ubooks .UheaderBooksList button {
  background: none;
  border: none;
  outline: none;
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-right: 20px;
  color: #0f4152;
  padding-bottom: 3px;
  cursor: pointer;
}

.UmainContent .Ubooks .UheaderBooksList button.Uactive::after {
  content: "";
  display: block;
  width: 58px;
  margin-bottom: -3px;
  margin-top: 3px;
  height: 4px;
  border-radius: 35px;
  background: #0f4152;
}

.UmainContent .Ubooks .UheaderBooksList img:nth-last-child(2) {
  margin-left: 39px;
  margin-right: 20px;
}

.UmainContent .Ubooks .UcontentTab {
  width: 100%;
  margin-bottom: 80px;
}

.UmainContent .Ubooks .UcontentTab .Utabcontent {
  display: none;
}

.UmainContent .Ubooks .UcontentTab .Utabcontent .Urow:nth-child(1) {
  border-top: 1px solid #cdcfd6;
}

.UmainContent .Ubooks .UcontentTab .Utabcontent .Urow {
  width: 100%;
  padding: 13px 18px;
  border-bottom: 1px solid #cdcfd6;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.UmainContent .Ubooks .UcontentTab .Utabcontent .Urow .Uleft {
  max-width: 234px;
  width: 25%;
  display: flex;
  align-items: center;
}

.UmainContent .Ubooks .UcontentTab .Utabcontent .Urow .Uleft img {
  margin-left: 20px;
  width: 72px;
  height: 97px;
  object-fit: cover;
}


.UmainContent .Ubooks .UcontentTab .Utabcontent .Urow .Uleft .Uspan h2 {
  font-family: "Heebo", sans-serif;
  font-size: 22px;
  font-weight: 700;
  line-height: 32.U31px;
  color: #0f4152;
  border-bottom: 1px dotted #0A7F94;
  margin-bottom: 5px;
}

.UmainContent .Ubooks .UcontentTab .Utabcontent .Urow .Uleft .Uspan p {
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.U2px;
  text-align: right;
  color: #3d3f45;
}

.UmainContent .Ubooks .UcontentTab .Utabcontent .Urow .Ucenter {
  width: calc(100% - 530px);
  display: flex;
  align-items: center;
}

.UmainContent .Ubooks .UcontentTab .Utabcontent .Urow .Ucenter p {
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 23.U5px;
  color: #2d2d2d;
  margin-left: 12px;
}

.UmainContent .Ubooks .UcontentTab .Utabcontent .Urow .Uright {
  max-width: 235px;
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.UmainContent .Ubooks .UcontentTab .Utabcontent .Urow .Uright .Uread {
  width: 119px;
  height: 44px;
  border: 1px solid #097285;
  border-radius: 35px;
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  background: transparent;
  color: #000;
  font-weight: 700;
  line-height: 23.U5px;
  text-align: center;
  color: #0b7a8e;
  margin-left: 9px;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;
}

.UmainContent .Ubooks .UcontentTab .Utabcontent .Urow .Uright .Uread img {
  margin-right: 16px;
}

.UmainContent .Ubooks .UcontentTab .Utabcontent .Urow .Uright .UcontinueReading {
  width: 182px;
  height: 44px;
  background: #097285;
  color: #fff;
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 23.U5px;
  text-align: center;
  border-radius: 35px;
  outline: none;
  margin-left: 9px;
  box-shadow: none;
  border: none;
  cursor: pointer;
}

.UmainContent .Ubooks .UcontentTab .Utabcontent .Urow .Uright .Ucircle,
.UmainContent .UprogressChapter .UcontentTab .Utabcontent .Urow .Ucircle {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  border: 1px solid #0b7a8e;
  color: #097285;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Heebo", sans-serif;
  font-size: 18px;
  font-weight: 500;
  position: relative;
  cursor: pointer;
}

.UmainContent .Ubooks .UcontentTab .Utabcontent .Urow .Uright .Ucircle .UdropDownTable,
.UmainContent
  .UprogressChapter
  .UcontentTab
  .Utabcontent
  .Urow
  .Ucircle
  .UdropDownTable {
  position: absolute;
  left: -7px;
  z-index: 9999;
  box-shadow: 0px 2px 52px -9px #203fb233;
  background: #fff;
  top: 140%;
  border-radius: 7px;
  display: none;
  padding-top: 7px;
  padding-bottom: 7px;
}

.UmainContent
  .Ubooks
  .UcontentTab
  .Utabcontent
  .Urow
  .Uright
  .Ucircle
  .UdropDownTable
  .Uinner,
.UmainContent
  .UprogressChapter
  .UcontentTab
  .Utabcontent
  .Urow
  .Ucircle
  .UdropDownTable
  .Uinner {
  position: relative;
  height: auto;
  width: 244px;
}

.UmainContent
  .Ubooks
  .UcontentTab
  .Utabcontent
  .Urow
  .Uright
  .Ucircle
  .UdropDownTable
  .Uinner
  .Usquare,
.UmainContent
  .UprogressChapter
  .UcontentTab
  .Utabcontent
  .Urow
  .Ucircle
  .UdropDownTable
  .Uinner
  .Usquare {
  position: absolute;
  left: 20px;
  top: -10%;
  z-index: 1;
  width: 17px;
  height: 17px;
  transform: rotate(45deg);
  background: #fff;
}

.UmainContent
  .Ubooks
  .UcontentTab
  .Utabcontent
  .Urow
  .Uright
  .Ucircle
  .UdropDownTable
  .Uinner
  button,
.UmainContent
  .UprogressChapter
  .UcontentTab
  .Utabcontent
  .Urow
  .Ucircle
  .UdropDownTable
  .Uinner
  button {
  width: 100%;
  height: 51px;
  background: transparent;
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  cursor: pointer;
  font-weight: 400;
  position: relative;
  border: none;
  z-index: 999;
  outline: none;
  box-shadow: none;
  line-height: 33px;
  padding: 0px 20px;
  text-align: right;
  color: #0f4152;
}

.UmainContent
  .Ubooks
  .UcontentTab
  .Utabcontent
  .Urow
  .Uright
  .Ucircle
  .UdropDownTable
  .Uinner
  button:hover,
.UmainContent
  .UprogressChapter
  .UcontentTab
  .Utabcontent
  .Urow
  .Ucircle
  .UdropDownTable
  .Uinner
  button:hover {
  background: #eaebf1;
  font-size: 14px;
  font-weight: 700;
  color: #0f4152;
}

/* all modal css here =========== */
.UeditBookModal,
.UdeleteBookModal,
.UeditChapterModal,
.UpaymentModal,
.UsettingModal {
  position: fixed;
  top: 0px;
  right: 0px;
  width: 100%;
  left: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.U8);
  z-index: 9999999;
  overflow-y: auto;
  display: none;
}

.UpaymentModal .UinnerContent {
  max-width: 782px;
  width: 90%;
  height: auto;
  border-radius: 10px;
  opacity: 0px;
  background: #ffffff;
  border-radius: 7px;
  margin: 79px auto;
  position: relative;
  padding: 62px 33px;
  padding-bottom: 80px;
}

.UpaymentModal .UinnerContent .UcrossPurchase {
  position: absolute;
  cursor: pointer;
  left: 33px;
  top: 33px;
}

.UpaymentModal .UinnerContent h1 {
  color: #023842;
  font-family: "Heebo", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  margin-bottom: 9px;
}

.UpaymentModal .UinnerContent p {
  font-family: "Heebo", sans-serif;
  max-width: 605px;
  width: 100%;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  margin: auto;
  text-align: center;
  color: #023842;
  margin-bottom: 42px;
}
.UpaymentModal .UinnerContent .UuserCards {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #b9b9b9;
  height: 106px;
  border-radius: 7px;
  padding: 0px 20px;
  margin-bottom: 42px;
  display: flex;
}
.UpaymentModal .UinnerContent .UuserCards .Ucard {
  width: 25%;
  position: relative;
}
.UpaymentModal .UinnerContent .UuserCards .Ucard p {
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #2d2d2d;
  margin-bottom: 6px;
}
.UpaymentModal .UinnerContent .UuserCards .Ucard h6 {
  font-family: "Heebo", sans-serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #44c6cb;
}
.UpaymentModal .UinnerContent .UuserCards .Ucard img {
  position: absolute;
  left: 0px;
  top: 5px;
}
.UpaymentModal .UinnerContent form {
  display: flex;
  width: 100%;
  margin-bottom: 25px;
}
.UpaymentModal .UinnerContent form .Uleft {
  width: 167px;
  margin-left: 10px;
}
.UpaymentModal .UinnerContent form .Uleft p {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 33px;
  color: #626262;
  margin-bottom: 0px;
  text-align: right;
}
.Unumber-input-container {
  position: relative;
  width: 167px;
}

.Unumber-input-container input[type="number"] {
  width: 100%;
  height: 50px;
  text-align: right;
  border: 1px solid #cdcfd6;
  outline: none;
  box-shadow: none;
  background: #fff;
  border-radius: 7px;
  font-size: 16px;
  padding-left: 10px; 
  padding-right: 40px; 
  padding-right: 13px;
  -moz-appearance: textfield; 
}

.Unumber-input-container input[type="number"]::-webkit-outer-spin-button,
.Unumber-input-container input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; 
}

.Unumber-input-container .Ucustom-buttons {
  position: absolute;
  left: 5px;
  top: 10px;
  display: flex;
  flex-direction: column;
  height: 50px;
}

.Unumber-input-container button {
  width: 20px;
  height: 15px;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  background: transparent;
  line-height: 0;
}
.UpaymentModal .UinnerContent form .Uright {
  width: 167px;
}
.UpaymentModal .UinnerContent form .Uright p {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 33px;
  color: #626262;
  margin-bottom: 0px;
  text-align: right;
}

.UpaymentModal .UinnerContent form .Uright .Ucustom-select {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 13px;
}

.UpaymentModal .UinnerContent form .Uright .Ucustom-select .Uselect-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 14px;
  height: 50px;
}

.UpaymentModal .UinnerContent form .Uright .Ucustom-select .Uselect-icon img {
  position: absolute;
  left: 14px;
  top: 17px;
}

.UpaymentModal .UinnerContent form .Uright .Ucustom-select select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #626262;
  width: 100%;
  padding: 0px 14px;
  background: #ffffff;
  border-radius: 5px;
  height: 50px;
  border: 1px solid #cdcfd6;
  outline: none;
  box-shadow: none;
}

.UpaymentModal .UinnerContent form .Uright .Ucustom-select .Uselect-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 100%;
}

.UpaymentModal .UinnerContent .Utotal {
  width: 100%;
  height: 51px;
  background: #e9e9e9;
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  outline: none;
  box-shadow: none;
  border-radius: 48px;
  padding: 0px 22px;
  margin-bottom: 25px;
}
.UpaymentModal .UinnerContent .UpurchaseBtn {
  width: 168px;
  height: 50px;
  border-radius: 48px;
  background: #0a7a8f;
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 500;
  display: block;
  line-height: 23.U5px;
  text-align: center;
  color: #ffffff;
  border: none;
  float: left;
  cursor: pointer;
  outline: none;
  box-shadow: none;
}
.UpaymentModal .UinnerContent .Utotal .Urightt h3,
.UpaymentModal .UinnerContent .Utotal .Uleftt h3 {
  font-family: "Heebo", sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 26.U44px;
  color: #0a7a8f;
  width: auto;
}
.UpaymentModal .UinnerContent .Utotal .Uleftt .Usummar {
  margin-left: 31px;
}
.UpaymentModal .UinnerContent .Utotal .Uleftt {
  display: flex;
  align-items: center;
}
.UpaymentModal .UinnerContent .Utotal .Uleftt .Utop {
  display: flex;
  align-items: center;
}
.UpaymentModal .UinnerContent .Utotal .Uleftt h6 {
  max-width: auto !important;
  position: relative;
  width: auto !important;
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 33px;
  color: #626262;
}
.UpaymentModal .UinnerContent .Utotal .Uleftt img {
  margin-left: 35px;
  margin-right: 31px;
}
.UeditBookModal .UinnerContent {
  max-width: 682px;
  width: 90%;
  margin: 79px auto;
  background: #ffffff;
  border-radius: 10px;
  overflow: hidden;
}

.UeditBookModal .UinnerContent .UheaderModal {
  width: 100%;
  height: 87px;
  background: #2ca3b9;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.UeditBookModal .UinnerContent .UheaderModal .Ucancel,
.UdeleteBookModal .UinnerContent .UheaderModal .Ucancel,
.UsettingModal .UinnerContent .UheaderModal .Ucancel {
  position: absolute;
  left: 41px;
  cursor: pointer;
}

.UeditBookModal .UinnerContent .UheaderModal h1,
.UdeleteBookModal .UinnerContent .UheaderModal h1,
.UsettingModal .UinnerContent .UheaderModal h1 {
  font-family: "Heebo", sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #fff;
}

.UeditBookModal .UinnerContent .UbodyModal form {
  width: 100%;
  padding: 51px 71px;
}

.UeditBookModal .UinnerContent .UbodyModal form form {
  width: 100%;
}

.UeditBookModal .UinnerContent .UbodyModal form .Urow {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}

.UeditBookModal .UinnerContent .UbodyModal form .Urow input {
  width: calc(50% - 6px);
  height: 50px;
  background: #ffffff;
  border: 1px 0px 0px 0px;
  border: 1px solid #cdcfd6;
  border-radius: 5px;
  padding: 0px 23px;
  outline: none;
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #626262;
  box-shadow: none;
}

.UeditBookModal .UinnerContent .UbodyModal form textarea {
  width: 100%;
  resize: none;
  border-radius: 5px;
  height: 129px;
  border: 1px 0px 0px 0px;
  padding: 14px 16px;
  background: #ffffff;
  border: 1px solid #cdcfd6;
  outline: none;
  box-shadow: none;
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #626262;
  margin-bottom: 12px;
}

.UeditBookModal .UinnerContent .UbodyModal form textarea::placeholder {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #626262;
}

.Uregister .Uinner form input::placeholder,
.Uregister .Uinner form input option[disabled] {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #626262;
}

.UeditBookModal .UinnerContent .UbodyModal form .UdeleteEditBook {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}

.UeditBookModal .UinnerContent .UbodyModal form .UdeleteEditBook img {
  width: 33px;
  height: 45px;
  margin-left: 19px;
}

.UeditBookModal .UinnerContent .UbodyModal form .UdeleteEditBook a {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: #c60000;
}

.UeditBookModal .UinnerContent .UbodyModal form .Ucontinue {
  width: 168px;
  height: 50px;
  margin-left: auto;
  float: left;
  margin-bottom: 28px;
  cursor: pointer;
  border-radius: 35px;
  background: #097083;
  border: none;
  outline: none;
  box-shadow: none;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.U96px;
  text-align: center;
  color: #fff;
}

.UdeleteBookModal .UinnerContent {
  max-width: 499px;
  width: 90%;
  border-radius: 10px;
  overflow: hidden;
  background: #ffffff;
  margin: 79px auto;
}

.UdeleteBookModal .UinnerContent .UheaderModal {
  width: 100%;
  height: 87px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 10px 10px 0px 0px;
  background: #b92c78;
}

.UdeleteBookModal .UinnerContent .UbodyModal {
  width: 100%;
  padding: 34px;
}

.UdeleteBookModal .UinnerContent .UbodyModal p {
  font-family: "Heebo", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #626262;
}

.UdeleteBookModal .UinnerContent .UbodyModal .Ubtns {
  display: flex;
  align-items: center;
  justify-content: center;
}

.UdeleteBookModal .UinnerContent .UbodyModal .Ubtns .UdeleteBtn {
  width: 168px;
  height: 50px;
  gap: 0px;
  opacity: 0px;
  border-radius: 35px;
  background: #b92c78;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.U96px;
  text-align: center;
  color: #fff;
  border: none;
  outline: none;
  box-shadow: none;
  margin-left: 6px;
  cursor: pointer;
}

.UdeleteBookModal .UinnerContent .UbodyModal .Ubtns .UcancelBtn {
  width: 168px;
  height: 50px;
  background: transparent;
  border-radius: 35px;
  border: 1px solid #000000;
  cursor: pointer;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.U96px;
  text-align: center;
  color: #343434;
}

.UeditChapterModal .UinnerContent {
  max-width: 411px;
  width: 90%;
  margin: 79px auto;
  overflow: hidden;
  background: #fff;
  border-radius: 10px;
}

.UeditChapterModal .UinnerContent .UheaderModal {
  width: 100%;
  background: #2ca3b9;
  height: 87px;
  display: flex;
  padding: 0px 27px;
  align-items: center;
  justify-content: space-between;
}

.UeditChapterModal .UinnerContent .UheaderModal img {
  cursor: pointer;
}

.UeditChapterModal .UinnerContent .UheaderModal h1 {
  font-family: "Heebo", sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 24px;
  color: #fff;
}

.UeditChapterModal .UinnerContent .UbodyModal {
  padding: 27px;
}

.UeditChapterModal .UinnerContent .UbodyModal .Urow {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 29px;
}

.UeditChapterModal .UinnerContent .UbodyModal .Urow h3 {
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: #097083;
}

.UeditChapterModal .UinnerContent .UbodyModal .Urow img {
  cursor: pointer;
}

.UeditChapterModal .UinnerContent .UbodyModal .UaddedChapter {
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #023842;
}

.UeditChapterModal .UinnerContent .UbodyModal .UsureToCancel {
  margin-top: 160px;
  width: 100%;
}

.UeditChapterModal .UinnerContent .UbodyModal .UsureToCancel p {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  color: #b92c78;
  margin-bottom: 5px;
}

.UeditChapterModal .UinnerContent .UbodyModal .UsureToCancel .UbtnsCancel {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 42px;
}

.UeditChapterModal .UinnerContent .UbodyModal .UsureToCancel .UbtnsCancel .UyesBtn {
  width: 69px;
  height: 35px;
  border-radius: 35px;
  background: #b92c78;
  border: none;
  outline: none;
  box-shadow: none;
  margin-right: 8px;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.U96px;
  text-align: center;
  color: #fff;
  cursor: pointer;
}

.UeditChapterModal
  .UinnerContent
  .UbodyModal
  .UsureToCancel
  .UbtnsCancel
  .UcancelBtn {
  width: 105px;
  height: 35px;
  border-radius: 35px;
  border: 1px 0px 0px 0px;
  border: 1px solid #000000;
  cursor: pointer;
  font-family: "Rubik", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.U59px;
  text-align: center;
  background: transparent;
  color: #383838;
}

.UeditChapterModal .UinnerContent .UbodyModal .Ufinish {
  width: 168px;
  height: 50px;
  background: #097083;
  border: none;
  outline: none;
  box-shadow: none;
  border-radius: 35px;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.U96px;
  text-align: center;
  color: #fff;
  float: left;
  margin-bottom: 23px;
}

/* Modal Style */
.UaddBookModal,
.UaddLibraryModalTop,
.UeditLibraryModalTop,
.UdeleteLibraryModalTop {
  position: fixed;
  top: 0px;
  right: 0px;
  width: 100%;
  left: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.U8);
  z-index: 9999999;
  overflow-y: auto;
  display: none;
}

/* Modal Content */
.UaddBookModal .Umodal-content,
.UaddLibraryModalTop .Umodal-content,
.UeditLibraryModalTop .Umodal-content,
.UdeleteLibraryModalTop .Umodal-content {
  max-width: 682px;
  background-color: #fff;
  margin: 79px auto;
  width: 90%;
  border-radius: 7px;
  overflow: hidden;
  position: relative;
}
.UaddLibraryModalTop .Umodal-content,
.UeditLibraryModalTop .Umodal-content,
.UdeleteLibraryModalTop .Umodal-content {
  max-width: 499px;
}

/* Modal Header */
.UaddBookModal .Umodal-content .Umodal-header,
.UaddLibraryModalTop .Umodal-content .Umodal-header,
.UeditLibraryModalTop .Umodal-content .Umodal-header,
.UdeleteLibraryModalTop .Umodal-content .Umodal-header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2ca3b9;
  width: 100%;
  height: 87px;
}
.UdeleteLibraryModalTop .Umodal-content .Umodal-header {
  background: #b92c78 !important;
}
.UaddBookModal .Umodal-content .Umodal-header .Uclose,
.UaddLibraryModalTop .Umodal-content .Umodal-header .Uclose,
.UeditLibraryModalTop .Umodal-content .Umodal-header .Uclose,
.UdeleteLibraryModalTop .Umodal-content .Umodal-header .Uclose {
  position: absolute;
  left: 41px;
  cursor: pointer;
}

.UaddBookModal .Umodal-content .Umodal-header h2,
.UaddLibraryModalTop .Umodal-content .Umodal-header h2,
.UeditLibraryModalTop .Umodal-content .Umodal-header h2,
.UdeleteLibraryModalTop .Umodal-content .Umodal-header h2 {
  font-family: "Heebo", sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #fff;
}

.UaddBookModal .Umodal-content .Umodal-body,
.UaddLibraryModalTop .Umodal-content .Umodal-body,
.UeditLibraryModalTop .Umodal-content .Umodal-body,
.UdeleteLibraryModalTop .Umodal-content .Umodal-body {
  padding: 35px;
  position: relative;
}

.UaddBookModal .Umodal-content .Umodal-body img {
  margin-bottom: -40px;
}
.UaddBookModal .Umodal-content .Umodal-body .UredCross {
  margin-right: 26px;
}
.UaddBookModal .Umodal-content .Umodal-body p,
.UaddLibraryModalTop .Umodal-content .Umodal-body p,
.UeditLibraryModalTop .Umodal-content .Umodal-body p,
.UdeleteLibraryModalTop .Umodal-content .Umodal-body p {
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  color: #023842;
}
.UaddLibraryModalTop .Umodal-content .Umodal-body p,
.UeditLibraryModalTop .Umodal-content .Umodal-body p {
  text-align: center;
  margin-bottom: 25px;
}
.UdeleteLibraryModalTop .Umodal-content .Umodal-body p {
  text-align: center;
  margin-bottom: 25px;
}
.UdeleteLibraryModalTop .Umodal-content .Umodal-body p span {
  font-family: "Heebo", sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #626262;
}
.UdeleteLibraryModalTop .Umodal-content .Umodal-body h6 {
  font-family: "Heebo", sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #e00561;
  margin-bottom: 35px;
}
.UdeleteLibraryModalTop .Umodal-content .Umodal-body .UbtnsDeleteLib {
  display: flex;
  align-items: center;
  justify-content: center;
}

.UdeleteLibraryModalTop .Umodal-content .Umodal-body .UbtnsDeleteLib .Ucancel{
  max-width: 168px;
  width: 49%;
  height: 50px;
  border-radius: 40px;
  background: transparent;
  border: 1px solid #000000;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.U96px;
  text-align: center;
  color: #343434;
  margin-left: 3px;
}
.UdeleteLibraryModalTop .Umodal-content .Umodal-body .UbtnsDeleteLib .Udelete {
  max-width: 168px;
  width: 49%;
  height: 50px;
  border-radius: 40px;
  background: #b92c78;
  border: none;
  outline: none;
  margin-right: 3px;
  box-shadow: none;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.U96px;
  text-align: center;
  color: #ffffff;
}
.UaddLibraryModalTop .Umodal-content .Umodal-body form,
.UeditLibraryModalTop .Umodal-content .Umodal-body form {
  max-width: 383px;
  width: 100%;
  margin: auto;
}
.UaddLibraryModalTop .Umodal-content .Umodal-body form input,
.UeditLibraryModalTop .Umodal-content .Umodal-body form input {
  width: 100%;
  height: 50px;
  margin-bottom: 13px;
  background: #ffffff;
  border: 1px solid #cdcfd6;
  border-radius: 7px;
  padding: 0px 25px;
  outline: none;
  box-shadow: none;
}

.UaddLibraryModalTop .Umodal-content .Umodal-body form .Ucustom-select,
.UeditLibraryModalTop .Umodal-content .Umodal-body form .Ucustom-select {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 13px;
}

.UaddLibraryModalTop .Umodal-content .Umodal-body form .Ucustom-select .Uselect-icon,
.UeditLibraryModalTop
  .Umodal-content
  .Umodal-body
  form
  .Ucustom-select
  .Uselect-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 14px;
  height: 50px;
}

.UaddLibraryModalTop
  .Umodal-content
  .Umodal-body
  form
  .Ucustom-select
  .Uselect-icon
  img,
.UeditLibraryModalTop
  .Umodal-content
  .Umodal-body
  form
  .Ucustom-select
  .Uselect-icon
  img {
  position: absolute;
  left: 14px;
  top: 17px;
}

.UaddLibraryModalTop .Umodal-content .Umodal-body form .Ucustom-select select,
.UeditLibraryModalTop .Umodal-content .Umodal-body form .Ucustom-select select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #626262;
  width: 100%;
  padding: 0px 14px;
  background: #ffffff;
  border-radius: 5px;
  height: 50px;
  border: 1px solid #cdcfd6;
  outline: none;
  box-shadow: none;
}

.UaddLibraryModalTop .Umodal-content .Umodal-body form .Ucustom-select .Uselect-icon,
.UeditLibraryModalTop
  .Umodal-content
  .Umodal-body
  form
  .Ucustom-select
  .Uselect-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 100%;
}

.UaddLibraryModalTop .Umodal-content .Umodal-body form button,
 .UeditLibraryModalTop .Umodal-content .Umodal-body form button{
  width: 168px;
  height: 50px;
  border-radius: 40px;
  background: #097083;
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.U96px;
  text-align: center;
  float: left;
  outline: none;
  box-shadow: none;
  border: none;
  margin-bottom: 40px;
  color: #ffffff;
}

/* Modal Footer */
.UaddBookModal .Umodal-content .Umodal-footer{
  text-align: center;
}

.UaddBookModal .Umodal-content .Umodal-body .Ustep1Btn {
  position: absolute;
  left: 35px;
  bottom: 35px;
  width: 168px;
  height: 50px;
  border-radius: 35px;
  background: #097083;
  border: none;
  outline: none;
  box-shadow: none;
  cursor: pointer;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.U96px;
  text-align: center;
  color: #fff;
}

.UaddBookModal .Umodal-content .Umodal-body .UheaderTitle {
  font-family: "Heebo", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #023842;
  margin-bottom: 11px;
}

.UaddBookModal .Umodal-content .Umodal-body .UheaderPara {
  text-align: center;
  margin-bottom: 19px;
}

.UaddBookModal .Umodal-content .Umodal-body form {
  width: 100%;
  padding: 0px 40px;
}

.UaddBookModal .Umodal-content .Umodal-body form form {
  width: 100%;
}

.UaddBookModal .Umodal-content .Umodal-body form .Urow {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}

.UaddBookModal .Umodal-content .Umodal-body form .Urow input {
  width: calc(50% - 6px);
  height: 50px;
  background: #ffffff;
  border: 1px 0px 0px 0px;
  border: 1px solid #cdcfd6;
  border-radius: 5px;
  padding: 0px 16px;
  outline: none;
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #626262;
  box-shadow: none;
}

.UaddBookModal .Umodal-content .Umodal-body form textarea {
  width: 100%;
  resize: none;
  border-radius: 5px;
  height: 129px;
  border: 1px 0px 0px 0px;
  padding: 14px 16px;
  background: #ffffff;
  border: 1px solid #cdcfd6;
  outline: none;
  box-shadow: none;
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #626262;
  margin-bottom: 19px;
}

.UaddBookModal .Umodal-content .Umodal-body form textarea::placeholder {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #626262;
}

.UaddBookModal .Umodal-content .Umodal-body form .Urow .Ucustom-select {
  position: relative;
  width: calc(50% - 6px);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.UaddBookModal .Umodal-content .Umodal-body form .Ucustom-select .Uselect-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 14px;
  height: 50px;
}

.UaddBookModal .Umodal-content .Umodal-body form .Ucustom-select .Uselect-icon img {
  position: absolute;
  left: 14px;
  top: 18px;
}

.UaddBookModal .Umodal-content .Umodal-body form .Urow .Ucustom-select select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #626262;
  width: 100%;
  padding: 0px 14px;
  background: #ffffff;
  border-radius: 5px;
  height: 50px;
  border: 1px solid #cdcfd6;
  outline: none;
  box-shadow: none;
}

.UaddBookModal .Umodal-content .Umodal-body form .Urow .Ucustom-select .Uselect-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 100%;
}

.UaddBookModal .Umodal-content .Umodal-body .UaddPara {
  text-align: center;
  font-weight: 500;
  color: #023842;
  margin-bottom: 153px;
}

.UfileUploadCancel {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 13px;
}

.UfileUploadCancel .UredCross {
  cursor: pointer;
}

/* Custom Upload Button Style */
.Ucustom-upload {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  margin-bottom: 9px;
}

.Ucustom-upload .Ufile-name {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: #626262;
  margin-left: 18px;
}

.Ucustom-upload input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
  height: 100%;
  width: 100%;
}
.Ucustom-upload button {
  width: 139px;
  height: 38px;
  border-radius: 35px;
  border: 1px solid #2ca3b9;
  cursor: pointer;
  color: #2ca3b9;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.U96px;
  text-align: center;
}

.Ucustom-upload button img {
  margin-right: 9px;
  margin-bottom: 0px !important;
}

/* File Name Display */
.Ufile-name {
  display: inline-block;
  margin-right: 10px;
}

.UaddBookModal .Umodal-content .Umodal-body form .Umodal-footer .Uback,
.UaddBookModal .Umodal-content .Umodal-body .Uback {
  width: 168px;
  height: 50px;
  border-radius: 35px;
  border: 1px 0px 0px 0px;
  opacity: 0px;
  border: 1px solid #097083;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.U96px;
  text-align: center;
  color: #097083;
  cursor: pointer;
  background: transparent;
  margin-right: 9px;
}

.UaddBookModal .Umodal-content .Umodal-body .UsuccessUpload {
  display: block;
  margin: auto;
}

.UaddBookModal .Umodal-content .Umodal-body form .Umodal-footer .UcontinueBtn,
.UaddBookModal .Umodal-content .Umodal-body .UcontinueBtn {
  width: 168px;
  height: 50px;
  border-radius: 35px;
  background: #097083;
  border: none;
  cursor: pointer;
  outline: none;
  box-shadow: none;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.U96px;
  text-align: center;
  color: #fff;
}

/* Close Button */
.Uclose {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.Uclose:hover,
.Uclose:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

/* my account css here ===== */
.UmainContent .UcontentArea {
  width: 100%;
  box-shadow: 0px 0px 30px 0px #0000001f;
  background: #fff;
  border-radius: 0px;
  min-height: 92vh;
}

.UmainContent .UcontentArea .UheaderTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 46px;
}

.UmainContent .UcontentArea .UheaderTop h2 {
  font-family: "Heebo", sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 24px;
  color: #0f4152;
}

.UmainContent .UcontentArea .UheaderTop .UtimeStamp {
  padding: 0px 26px;
  height: 45px;
  border-radius: 59px;
  background: #f1f1f1;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.UmainContent .UcontentArea .UheaderTop .UtimeStamp h3 {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 20.U56px;
  text-align: left;
  color: #2d2d2d;
  margin-left: 9px;
}

.UmainContent .UcontentArea .UheaderTop .UtimeStamp h3 span {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20.U56px;
  text-align: left;
  color: #2d2d2d;
}

.UmainContent .UcontentArea .Uprogress {
  width: 100%;
}

.UmainContent .UcontentArea .Uprogress .UmyAccountList {
  width: 100%;
  border-bottom: 1px solid #acacac;
}

.UmainContent .UcontentArea .Uprogress .UmyAccountList button {
  margin-right: 22px;
  padding-bottom: 4px;
  background: transparent;
  outline: none;
  box-shadow: none;
  border: none;
  width: 108px;
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #5f5f5f;
  cursor: pointer;
}

.UmainContent .UcontentArea .Uprogress .UmyAccountList button.Uactive {
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: #0a7a8f;
}






.UmainContent .UcontentArea .Uprogress .UmyAccountList button.Uactive::after {
  content: "";
  display: block;
  width: 108px;
  height: 4px;
  margin-bottom: -4px;
  margin-top: 3px;
  border-radius: 35px;
  background: #0a7a8f;
}

.UmainContent .UcontentArea .Uprogress .UmyAccountList button:nth-last-child(1) {
  margin-left: 5px !important;
}

.UmainContent .UcontentArea .Uprogress .UtabContentTop {
  width: 100%;
  padding: 45px;
}

.UmainContent .UcontentArea .Uprogress .UtabContentTop .Utabcontent {
  display: none;
}

.UmainContent .UcontentArea .Uprogress .UtabContentTop .Utabcontent .Urow {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.UmainContent .UcontentArea .Uprogress .UtabContentTop .Utabcontent .Urow .Ucard {
  max-width: 349px;
  width: 32%;
  height: 383px;
  overflow: hidden;
  background: #f1f1f1;
  border-radius: 35px;
  padding: 24px 24px 0px 24px;
  position: relative;
}

.UmainContent .UcontentArea .Uprogress .UtabContentTop .Utabcontent .Urow .Ucard p {
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  text-align: center;
  color: #0f4152;
  margin-bottom: 6px;
}

.UmainContent .UcontentArea .Uprogress .UtabContentTop .Utabcontent .Urow .Ucard h2 {
  font-family: "Heebo", sans-serif;
  font-size: 26px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #0a7a8f;
}

.UmainContent
  .UcontentArea
  .Uprogress
  .UtabContentTop
  .Utabcontent
  .Urow
  .Ucard
  .UbottomImg {
  position: absolute;
  bottom: -58px;
  left: 0px;
  right: 0px;
  margin: auto;
}

.UmainContent
  .UcontentArea
  .Uprogress
  .UtabContentTop
  .Utabcontent
  .Urow
  .Ucard
  .UrowTick {
  display: flex;
  align-items: center;
  justify-content: center;
}

.UmainContent
  .UcontentArea
  .Uprogress
  .UtabContentTop
  .Utabcontent
  .Urow
  .Ucard
  .UrowTick
  img {
  margin: 0px 2px;
}

.UmainContent .UcontentArea .Uprogress .UtabContentTop .Utabcontent form {
  max-width: 344px;
  width: 100%;
  flex-wrap: wrap;
}

.UmainContent .UcontentArea .Uprogress .UtabContentTop .Utabcontent form h2 {
  font-family: "Heebo", sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  color: #0f4152;
  margin-bottom: 18px;
}

.UmainContent .UcontentArea .Uprogress .UtabContentTop .Utabcontent form input {
  width: 100%;
  height: 50px;
  outline: none;
  box-shadow: none;
  border-radius: 7px;
  background: #ffffff;
  border: 1px solid #cdcfd6;
  margin-bottom: 7px;
  padding: 0px 23px;
}

.UmainContent
  .UcontentArea
  .Uprogress
  .UtabContentTop
  .Utabcontent
  form
  input::placeholder {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #626262;
}

.UmainContent .UcontentArea .Uprogress .UtabContentTop .Utabcontent form .UformBtn {
  text-align: left;
}

.UmainContent
  .UcontentArea
  .Uprogress
  .UtabContentTop
  .Utabcontent
  form
  .UformBtn
  button {
  width: 168px;
  height: 50px;
  border-radius: 35px;
  background: #0a7a8f;
  border: none;
  outline: none;
  box-shadow: none;
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 23.U5px;
  text-align: center;
  color: #ffffff;
}

.UmainContent .UindividualHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.UmainContent .UindividualHeader .Uleft h2 {
  font-family: "Heebo", sans-serif;
  font-size: 28px;
  font-weight: 700;
  line-height: 24px;
  color: #0f4152;
  margin-left: 35px;
}

.UmainContent .UindividualHeader .Uleft h2 span {
  font-family: "Heebo", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  color: #0f4152;
}

.UmainContent .UindividualHeader .Uright {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.UmainContent .UindividualHeader .UtotalUser {
  height: 59px;
  border-radius: 40px;
  background: #f0f0f0;
  padding: 0px 31px;
  border-radius: 59px;
  opacity: 0px;
}

.UmainContent .UindividualHeader .UtotalUser h4 {
  font-family: "Heebo", sans-serif;
  font-size: 22px;
  font-weight: 700;
  line-height: 24px;
  color: #0f4152;
  margin: 0 22px 0 22px;
}
.UmainContent .UindividualHeader .UtotalUser p {
  font-family: "Heebo", sans-serif;
  font-size: 18px;
  font-weight: 300;
  line-height: 24px;
  color: #0f4152;
}
.UmainContent .UindividualHeader .UtotalUser p span {
  font-family: "Heebo", sans-serif;
  font-size: 26px;
  font-weight: 700;
  line-height: 24px;
  color: #0a7a8f;
}

.UmainContent .UindividualHeader .Uright .UprogressLine {
  margin-left: 41px;
}
.UmainContent .UaddLibTop .UcontentTab .Utabcontent .Urow {
  padding: 5px 18px;
  height: 53px;
}
.UmainContent .UprogressChapter .UcontentTab .Utabcontent .Urow .Ucircle {
  width: 31px;
  height: 31px;
  font-size: 14px;
}
.UmainContent .UindividualHeader .Uright .UprogressLine p {
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 23.U5px;
  text-align: center;
  color: #2d2d2d;
  margin-bottom: 3px;
}

.UmainContent .UindividualHeader .Uright .UprogressLine .UgrayLine {
  width: 178px;
  height: 4px;
  border-radius: 35px;

  background: #d8d8d8;
  position: relative;
  overflow: hidden;
}

.UmainContent .UindividualHeader .Uright .UprogressLine .UgrayLine .UinsideLine {
  background: #0a7a8f;
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
  border-radius: 35px;
}

.UmainContent .UindividualHeader .Uright .Ubtn {
  display: flex;
  align-items: center;
}

.UmainContent .UindividualHeader .Uright .Ubtn h2 {
  font-family: "Heebo", sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 26.U44px;
  color: #0f4152;
  margin-left: 9px;
}

.UmainContent .UindividualHeader .Uright .Ubtn button {
  width: 157px;
  height: 44px;
  border-radius: 35px;
  background: #097285;
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 23.U5px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}

.UmainContent .UprogressChapter {
  width: 100%;
  box-shadow: 0px 0px 30px 0px #0000001f;
  background: #fff;
  border-radius: 10px 10px 0px 0px;
  padding-bottom: 50px;
}

.UmainContent .UprogressChapter .UchapterList {
  border-bottom: 1px solid #acacac;
}

.UmainContent .UprogressChapter .UchapterList button {
  margin-left: 18px;
  border: none;
  outline: none;
  box-shadow: none;
  background: transparent;
  padding-bottom: 3px;
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  width: 120px;
  font-weight: 400;
  line-height: 24px;
  position: relative;
  color: #5f5f5f;
  cursor: pointer;
}

.UmainContent .UprogressChapter .UchapterList {
  width: 100%;
}

.UmainContent .UprogressChapter .UchapterList button.Uactive::after {
  content: "";
  display: block;
  width: 120px;
  height: 4px;
  margin-bottom: -4px;
  margin-left: 0px;
  margin-top: 3px;
  border-radius: 35px;
  background: #0a7a8f;
}

.UmainContent .UprogressChapter .UchapterList button.Uactive {
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #0a7a8f;
}

.UmainContent .UprogressChapter .UcontentTab {
  width: 100%;
}

.UmainContent .UprogressChapter .UcontentTab .Utabcontent .Urow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 18px;
  border-bottom: 1px solid #cdcfd6;
}
/* .UmainContent .UprogressChapter .UcontentTab .Utabcontent .Urow .UprogressBar .Ucompleted */
.UmainContent .UprogressChapter .UcontentTab .Utabcontent .Urow h2{
  font-family: "Heebo", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  width: 120px;
 
}
.UmainContent .UprogressChapter .UcontentTab .Utabcontent .Urow .UreadAgainText{
  color: #b8b8b8;
}
.UmainContent .UprogressChapter .UcontentTab .Utabcontent .Urow .UprogressBar {
  display: flex;
  align-items: center;
  margin-left: 35px;
}
.UmainContent
  .UprogressChapter
  .UcontentTab
  .Utabcontent
  .Urow
  .UprogressBar
  p:nth-child(1) {
  width: 130px;
}
.UmainContent
  .UprogressChapter
  .UcontentTab
  .Utabcontent
  .Urow
  .UprogressBar
  .UprogressTopLine {
  position: relative;
  width: 133px;
  height: 5px;
  background: #d8d8d8;
  border-radius: 35px;
  margin-left: 48px;
  overflow: hidden;
}

.UmainContent
  .UprogressChapter
  .UcontentTab
  .Utabcontent
  .Urow
  .UprogressBar
  .UprogressTopLine
  .UprogressInnerLine {
  background: #0a7a8f;
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 35px;
  bottom: 0px;
}

.UmainContent
  .UprogressChapter
  .UcontentTab
  .Utabcontent
  .Urow
  .UprogressBar
  .Ucompleted {
  /* width: 120px; */
  font-family: "Heebo", sans-serif;
  font-size: 14px;

  font-weight: 400;
  line-height: 24px;
  color: #626262;
}

.UmainContent
  .UprogressChapter
  .UcontentTab
  .Utabcontent
  .Urow
  .UprogressBar
  .Ucompleted {
  width: 160px;
}
.UmainContent .UprogressChapter .UcontentTab .Utabcontent .Urow .Udescription {
  width: 450px;
}

.UmainContent .UprogressChapter .UcontentTab .Utabcontent .Urow .Udescription p {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #c1c1c1;
}

.UmainContent .UprogressChapter .UcontentTab .Utabcontent .Urow .UbtnsList {
  /* width: 228px; */
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.UmainContent
  .UprogressChapter
  .UcontentTab
  .Utabcontent
  .Urow
  .UbtnsList
  .UkeepReading {
  width: 118px;
  height: 31px;
  border-radius: 35px;
  border: none;
  outline: none;
  box-shadow: none;
  background: #0a7a8f;
  color: #fff;
  font-family: "Heebo", sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 19.U09px;
  text-align: center;
}

.UmainContent .UprogressChapter .UcontentTab .Utabcontent .Urow .UbtnsList .UreadLock {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 86px;
  height: 31px;
  border-radius: 35px;
  border: 1px solid #097285;
  background: transparent;
  margin-left: 5px;
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20.U56px;
  text-align: center;
  color: #0b7a8e;
}

.UmainContent .UprogressChapter .UcontentTab .Utabcontent .Urow .UbtnsList .UreadLock img {
  width: 17px;
  height: 17px;
  margin-left: 11px;
}

.UmainContent .UprogressChapter .UcontentTab .Utabcontent .Urow .UbtnsList .Ucolored {
  background: #0a7a8f;
  color: #fff;
}

.UmainContent
  .UprogressChapter
  .UcontentTab
  .Utabcontent
  .Urow
  .UbtnsList
  .UreadAgain {
  width: 82px;
  height: 31px;
  border-radius: 35px;
  border: 1px solid #b6b6b6;
  background: transparent;
  outline: none;
  box-shadow: none;
  font-family: "Heebo", sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.U09px;
  text-align: center;
  color: #909090;
}

/* add this class to the completed book and all the color should appear  */
.UmainContent .UprogressChapter .UcontentTab .Utabcontent .UcompletedBookProgress {
  background: #f2f5fe !important;
}

.UmainContent
  .UprogressChapter
  .UcontentTab
  .Utabcontent
  .UcompletedBookProgress
  h2 {
  color: #0a7a8f;
  font-weight: 700;
}

.UmainContent
  .UprogressChapter
  .UcontentTab
  .Utabcontent
  .UcompletedBookProgress
  .UprogressBar
  .Ucompleted {
  font-weight: 700;
  color: #0a7a8f;
  max-width: 200px;
}

.UmainContent
  .UprogressChapter
  .UcontentTab
  .Utabcontent
  .UcompletedBookProgress
  .Udescription
  p {
  width: auto;
  color: #0a7a8f;
  font-weight: 700;
}

/* reading book css here === */
.UmainContent .UreadingBookTop {
  padding: 31px 61px;
  position: relative;
}
.UmainContent .UreadingBookTop .Uplayer .Ugroup{
  display: none;
}
.UbackPage {
  position: absolute;
  right: -70px;
  top: 51px;
  width: 51px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #1d9da2;
  background: #ffffff;
  cursor: pointer;
  border-radius: 40px;
}

.UmainContent .UreadingBookTop p {
  font-family: "Heebo", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #0f4152;
  margin-bottom: 23px;
}

.UmainContent .UreadingBookTop .Uplayer {
  width: 100%;
  padding: 15px 20px;
  border: 1px solid #9ebebf;
  height: 329px;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 201px;
}

.UmainContent .UreadingBookTop .Uplayer h1 {
  font-family: "Heebo", sans-serif;
  font-size: 35px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #0f4152;
}

.UmainContent .UreadingBookTop .Uplayer .UfooterPlayer {
  position: absolute;
  bottom: 15px;
  width: 100%;
  left: 0px;
  right: 0px;
  border-radius: 7px;
  padding: 0px 20px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.UmainContent .UreadingBookTop .Uplayer .UfooterPlayer .Uleft {
  display: flex;
  align-items: center;
  width: 140px;
}

.UmainContent .UreadingBookTop .Uplayer .UfooterPlayer .Uleft img {
  cursor: pointer;
}

.UmainContent .UreadingBookTop .Uplayer .UfooterPlayer .Uleft img:nth-last-child(1) {
  margin-left: 13px;
}

.UmainContent .UreadingBookTop .Uplayer .UfooterPlayer .Ucenter {
  display: flex;
  justify-content: center;
}

.UmainContent .UreadingBookTop .Uplayer .UfooterPlayer .Ucenter .Uprevious,
.UmainContent .UreadingBookTop .Uplayer .UfooterPlayer .Ucenter .Urepeat,
.UmainContent .UreadingBookTop .Uplayer .UfooterPlayer .Ucenter .Ustart,
.UmainContent .UreadingBookTop .Uplayer .UfooterPlayer .Ucenter .Unext {
  width: 74px;
  height: 63px;
  border-radius: 6px;
  border: 1px 0px 0px 0px;
  opacity: 0px;
  border: 1px solid #e4e4e4;
  margin: 0px 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.UmainContent .UreadingBookTop .Uplayer .UfooterPlayer .Ucenter .Uprevious:hover,
.UmainContent .UreadingBookTop .Uplayer .UfooterPlayer .Ucenter .Urepeat:hover,
.UmainContent .UreadingBookTop .Uplayer .UfooterPlayer .Ucenter .Ustart:hover,
.UmainContent .UreadingBookTop .Uplayer .UfooterPlayer .Ucenter .Unext:hover {
  background: #f1f1f1;
}

.UmainContent .UreadingBookTop .Uplayer .UfooterPlayer .Ucenter .Uprevious:hover p,
.UmainContent .UreadingBookTop .Uplayer .UfooterPlayer .Ucenter .Urepeat:hover p,
.UmainContent .UreadingBookTop .Uplayer .UfooterPlayer .Ucenter .Ustart:hover p,
.UmainContent .UreadingBookTop .Uplayer .UfooterPlayer .Ucenter .Unext:hover p {
  color: #0f4152;
}

.UmainContent .UreadingBookTop .Uplayer .UfooterPlayer .Ucenter .Uprevious .Uinner,
.UmainContent .UreadingBookTop .Uplayer .UfooterPlayer .Ucenter .Urepeat .Uinner,
.UmainContent .UreadingBookTop .Uplayer .UfooterPlayer .Ucenter .Ustart .Uinner,
.UmainContent .UreadingBookTop .Uplayer .UfooterPlayer .Ucenter .Unext .Uinner {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.UmainContent .UreadingBookTop .Uplayer .UfooterPlayer .Ucenter .Uprevious .Uinner img,
.UmainContent .UreadingBookTop .Uplayer .UfooterPlayer .Ucenter .Urepeat img,
.UmainContent .UreadingBookTop .Uplayer .UfooterPlayer .Ucenter .Ustart img,
.UmainContent .UreadingBookTop .Uplayer .UfooterPlayer .Ucenter .Unext img {
  margin-bottom: 4px;
  margin-top: 14px;
  width: 24px;
  height: 24px;
}

.UmainContent .UreadingBookTop .Uplayer .UfooterPlayer .Ucenter .Uprevious .Uinner p,
.UmainContent .UreadingBookTop .Uplayer .UfooterPlayer .Ucenter .Urepeat .Uinner p,
.UmainContent .UreadingBookTop .Uplayer .UfooterPlayer .Ucenter .Ustart .Uinner p,
.UmainContent .UreadingBookTop .Uplayer .UfooterPlayer .Ucenter .Unext .Uinner p {
  margin-bottom: 0px;
  font-family: "Heebo", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  text-align: center;
  color: #ababab;
}

.UmainContent .UreadingBookTop .Uplayer .UfooterPlayer .Uright {
  width: 140px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.UmainContent .UreadingBookTop .Uplayer .UfooterPlayer .Uright .UcustomSelect {
  position: relative;
  width: 81px;
  height: 34px;
  border-radius: 35px;
  border: 1px solid #1d9da2;
  background: #fff;
}

.UmainContent
  .UreadingBookTop
  .Uplayer
  .UfooterPlayer
  .Uright
  .UcustomSelect
  .UdropDownBtn {
  width: 100%;
  height: 100%;
  border-radius: 24px;
  background: transparent;
  border: none;
  outline: none;
  padding: 0px 10px;
  box-shadow: none;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 33px;
  color: #0a7a8f;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url("../../img/downSelectIcon.svg") no-repeat right 8px center;
  background-size: 12px;
  padding-right: 24px;
}


.UmainContent
  .UreadingBookTop
  .Uplayer
  .UfooterPlayer
  .Uright
  .UcustomSelect
  .UdropContent {
  display: none;
  position: absolute;
  background-color: white;
  border: 1px solid #1d9da2;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.U2);
  z-index: 1;
  width: 81px;
  bottom: -8px;
  height: auto;
  overflow: hidden;
  border-radius: 25px;
}

.UmainContent
  .UreadingBookTop
  .Uplayer
  .UfooterPlayer
  .Uright
  .UcustomSelect
  .UdropContent {
  display: none;
  position: absolute;
  background-color: white;
  border: 1px solid #1d9da2;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.U2);
  z-index: 1;
  width: 81px;

  bottom: -210px;
  height: auto;

  border-radius: 14px;
}
.UmainContent
  .UreadingBookTop
  .Uplayer
  .UfooterPlayer
  .Uright
  .UcustomSelect
  .UdropContent
  a {
    font-family: "Rubik", sans-serif;
    font-size: 16px;
    font-weight: 400;
    text-decoration: none;
    display: block;
padding: 0px 10px;
    line-height: 33px;
    text-align: left;
    color: #0A7A8F;

}

.UmainContent
  .UreadingBookTop
  .Uplayer
  .UfooterPlayer
  .Uright
  .UcustomSelect
  .UdropContent
  a:hover {
  background-color: #0d8e9b;
  color: #fff;
}


  
.UmainContent
.UreadingBookTop
.Uplayer
.UfooterPlayer
.Uright
.UcustomSelect
.UdropContent
a.UactiveBtn {
  background-color: #0d8e9b;
  color: #fff;

}

.UmainContent
  .UreadingBookTop
  .Uplayer
  .UfooterPlayer
  .Uright
  .UcustomSelect
  .UdropContent.Ushow {
  display: block;
  color: #fff;
}
.UmainContent .UreadingBookTop .Uplayer .UfooterPlayer .Uright .UcustomSelect img {
  position: absolute;
  right: 10px;
  top: 8px;
}


option {
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 33px;
  color: #0a7a8f;
}

.UmainContent .UreadingBookTop .UprogressPlayer {
  width: 271px;
  margin: auto;
  margin-bottom: 123px;
}

.UmainContent .UreadingBookTop .UprogressPlayer p {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #717171;
  margin-bottom: 11px;
}

.UmainContent .UreadingBookTop .UprogressPlayer .Uprogress {
  width: 100%;
  height: 7px;
  background: #d8d8d8;
  border-radius: 35px;
  overflow: hidden;
  position: relative;
}

.UmainContent .UreadingBookTop .UprogressPlayer .Uprogress .UinnerLine {
  position: absolute;
  left: 0px;
  height: 100%;
  top: 0px;
  bottom: 0px;
  border-radius: 35px;
  background: #67b6c5;
}

.UmainContent .UreadingBookTop .UpracticeEnd {
  display: flex;
  align-items: center;
  justify-content: center;
}

.UmainContent .UreadingBookTop .UpracticeEnd h4 {
  font-family: "Heebo", sans-serif;
  font-size: 22px;
  font-weight: 900;
  line-height: 33px;
  text-align: center;
  color: #e90000;
}

/* celebrate here  */
.UmainContent .UreadingBookTop .Ucelebrate {
  width: 100%;
  border: 1px solid #9ebebf;
  border-radius: 7px;
  height: 679px;
  overflow: hidden;
  margin-bottom: 100px;
}

.UmainContent .UreadingBookTop .Ucelebrate h2 {
  font-family: "Heebo", sans-serif;
  font-size: 35px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #0f4152;
  margin-top: 57px;
  margin-bottom: 33px;
}

.UmainContent .UreadingBookTop .Ucelebrate img {
  width: 100%;
  height: auto;
}

.UsettingModal .UinnerContent {
  max-width: 682px;
  width: 90%;
  margin: 79px auto;
  background: #ffffff;
  border-radius: 10px;
  overflow: hidden;
}

.UsettingModal .UinnerContent .UheaderModal {
  width: 100%;
  height: 87px;
  background: #2ca3b9;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.UsettingModal .UinnerContent .UsettingModal-body {
  padding: 27px 34px;
  display: flex;
  justify-content: space-between;
}

.UsettingModal .UinnerContent .UsettingModal-body .Uleft {
  max-width: 285px;
  width: 49%;
}

.UsettingModal .UinnerContent .UsettingModal-body .Uleft .UheadText {
  width: 100%;
  border-bottom: 1px solid #000000;
  padding-bottom: 4px;
  margin-bottom: 9px;
}

.UsettingModal .UinnerContent .UsettingModal-body .Uleft .UheadText h2 {
  font-family: "Heebo", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  color: #023842;
}

.UsettingModal .UinnerContent .UsettingModal-body .Uleft form {
  width: 100%;
}

.UsettingModal .UinnerContent .UsettingModal-body .Uright {
  max-width: 265px;
  width: 48%;
}

.UsettingModal
  .UinnerContent
  .UsettingModal-body
  .Uleft
  form
  [type="radio"]:checked,
.UsettingModal
  .UinnerContent
  .UsettingModal-body
  .Uleft
  form
  [type="radio"]:not(:checked) {
  position: absolute;
  right: -9999px;
}

.UsettingModal
  .UinnerContent
  .UsettingModal-body
  .Uleft
  form
  [type="radio"]:checked
  + label,
.UsettingModal
  .UinnerContent
  .UsettingModal-body
  .Uleft
  form
  [type="radio"]:not(:checked)
  + label {
  position: relative;
  padding-right: 28px;
  cursor: pointer;
  display: inline-block;
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: right;
  color: #023842;
}

.UsettingModal .UinnerContent .UsettingModal-body .Uleft form p {
  margin-bottom: 5px;
}

.UsettingModal .UinnerContent .UsettingModal-body .Uleft form .Uinner,
.UsettingModal .UinnerContent .UsettingModal-body .Uleft form .Uinner {
  display: flex;
  align-self: center;
  font-family: "Heebo", sans-serif;
  font-size: 12px;
  font-weight: 300;
  line-height: 24px;
  margin-right: 28px;
  color: #023842;
  margin-top: -10px !important;
}

.UsettingModal
  .UinnerContent
  .UsettingModal-body
  .Uleft
  form
  [type="radio"]:checked
  + label
  span,
.UsettingModal
  .UinnerContent
  .UsettingModal-body
  .Uleft
  form
  [type="radio"]:not(:checked)
  + label
  span {
  display: flex;
  align-self: center;
  font-family: "Heebo", sans-serif;
  font-size: 12px;
  font-weight: 300;
  line-height: 24px;
  text-align: right;
  color: #023842;
  margin-top: -10px !important;
}

.UsettingModal
  .UinnerContent
  .UsettingModal-body
  .Uleft
  form
  [type="radio"]:checked
  + label:before,
.UsettingModal
  .UinnerContent
  .UsettingModal-body
  .Uleft
  form
  [type="radio"]:not(:checked)
  + label:before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #000000;
  border-radius: 100%;
  background: #fff;
}

.UsettingModal
  .UinnerContent
  .UsettingModal-body
  .Uleft
  form
  [type="radio"]:checked
  + label:after,
.UsettingModal
  .UinnerContent
  .UsettingModal-body
  .Uleft
  form
  [type="radio"]:not(:checked)
  + label:after {
  content: "";
  width: 12px;
  height: 12px;
  background: #2ca3b9;
  position: absolute;
  top: 4px;
  right: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.U2s ease;
  transition: all 0.U2s ease;
}

.UsettingModal
  .UinnerContent
  .UsettingModal-body
  .Uleft
  form
  [type="radio"]:not(:checked)
  + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.UsettingModal
  .UinnerContent
  .UsettingModal-body
  .Uleft
  form
  [type="radio"]:checked
  + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.Ucustom-dropdown {
  position: relative;
  display: inline-block;
}

.Uselected-value {
  width: 27px;
  height: 15px;
  border: 1px solid #000000;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Heebo", sans-serif;
  font-size: 12px;
  font-weight: 300;
  margin: 0px 5px;
  line-height: 24px;
  color: #023842;
  margin-top: 4px !important;
}

.Udropdown-content{
  display: none;
  position: absolute;
  background-color: #fff;
  border-top: none;
  right: 0px;
  overflow: hidden;
  border-radius: 7px;
  width: 100%;
  width: 110px;
  z-index: 999;
  height: auto;
  box-shadow: 0px 4px 4px 0px #00000040;
}


.Ucustom-dropdown .UheaderTimer{
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  display: none;
  margin-bottom: 11px;
}
.Ucustom-dropdown .UheaderTimer h3 {
  font-family: "Heebo", sans-serif;
  font-size: 20px;
  display: inline;
  font-weight: 700;
  line-height: 24px;
  color: #023842;
}

.Ucustom-dropdown .Udropdown-content p{
  display: none;
}
.Ucustom-dropdown .UheaderTimer img{
  cursor: pointer;
}
.Udropdown-content .UparaG{
  font-family: "Heebo", sans-serif;
font-size: 16px;
font-weight: 300;
line-height: 24px;
color: #023842;
margin-bottom: 20px;
display: none;
} 
.Udropdown-content .Uoption {
  padding: 10px;
  height: 34px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  color: #023842;
}

.Udropdown-content .Uoption:hover {
  background: #eaebf1;
  color: #023842;
  font-weight: 700;
}

.UsettingModal .UinnerContent .UsettingModal-body .Uleft form .Umarker {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  width: 300px !important;
}

.UsettingModal .UinnerContent .UsettingModal-body .Uleft form .Umarker .Umark {
  width: 148px;
  height: 29px;
  border-radius: 35px;
  background: transparent;
  border: 1px solid #acacac;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Heebo", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  color: #023842;
}

.UsettingModal .UinnerContent .UsettingModal-body .Uleft form .Umarker .Umark .Uyellow,
.UsettingModal .UinnerContent .UsettingModal-body .Uleft form .Umarker .Umark .Ugray {
  background: yellow;
  margin-left: 5px;
  padding: 0px 3px;
}

.UsettingModal .UinnerContent .UsettingModal-body .Uleft form .Umarker .Umark .Ugray {
  background: #d5d5d5;
}

.UsettingModal
  .UinnerContent
  .UsettingModal-body
  .Uleft
  form
  .Umarker
  .Umark
  .Uunderline {
  border-bottom: 1px solid #023842;
  margin-left: 5px;
  padding: 0px 3px;
}

.UsettingModal .UinnerContent .UsettingModal-body .Uleft .UheadText {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.UsettingModal .UinnerContent .UsettingModal-body .Uleft .UheadText p {
  font-family: "Heebo", sans-serif;
  font-size: 12px;
  font-weight: 300;
  line-height: 24px;
  color: #023842;
}

.UsettingModal .UinnerContent .UbtnSave {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.UsettingModal .UinnerContent .UbtnSave .Usave {
  width: 168px;
  height: 50px;
  border-radius: 35px;
  background: #097083;
  border: none;
  outline: none;
  box-shadow: none;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  cursor: pointer;
  font-weight: 500;
  line-height: 18.U96px;
  text-align: center;
  color: #ffffff;
  margin-left: 35px;
  margin-bottom: 35px;
}

/* mobile design here ======== */
.UbookMobileColor .UtopContent .Ubooks .UforMobileSearch {
  width: 100%;
  display: none;
  margin-bottom: 24px;
}

.UbookMobileColor .UtopContent .Ubooks .UforMobileSearch .UdropDownSearch {
  position: relative;
  margin-bottom: 24px;
  z-index: 9999;
  box-shadow: 0px 0px 30px 0px #0000001f;
  background: #ffffff;
  border-radius: 7px;
}

.UbookMobileColor .UtopContent .Ubooks .UforMobileSearch .UdropDownSearch .UfindBook {
  height: 60px;

  padding: 0px 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.UbookMobileColor
  .UtopContent
  .Ubooks
  .UforMobileSearch
  .UdropDownSearch
  .UfindBook
  h3 {
  font-family: "Heebo", sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  color: #0f4152;
}

.UbookMobileColor .UtopContent .Ubooks .UforMobileSearch .UsearchBooksMobile {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.UbookMobileColor .UtopContent .Ubooks .UforMobileSearch .UsearchBooksMobile {
  width: 100%;
}

.UbookMobileColor .UtopContent .Ubooks .UforMobileSearch .UsearchBooksMobile h3 {
  font-family: "Heebo", sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 24px;
  color: #0f4152;
}

.UbookMobileColor
  .UtopContent
  .Ubooks
  .UforMobileSearch
  .UsearchBooksMobile
  .Ucustom-select {
  position: relative;
  max-width: 246px;
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.UbookMobileColor
  .UtopContent
  .Ubooks
  .UforMobileSearch
  .UsearchBooksMobile
  .Uselect-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 13px;
}

.UbookMobileColor
  .UtopContent
  .Ubooks
  .UforMobileSearch
  .UsearchBooksMobile
  .Ucustom-select
  .Uselect-icon {
  position: absolute;
  left: 14px;
}

.UbookMobileColor
  .UtopContent
  .Ubooks
  .UforMobileSearch
  .UsearchBooksMobile
  .Ucustom-select
  select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-right: 21px;
  height: 42px;
  width: 100%;
  background: transparent;
  border: 1px solid #097285;
  padding: 0px 16px;
  outline: none;
  box-shadow: none;
  border-radius: 35px;
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 33px;
  color: #5c5c5c;
}

.UbookMobileColor
  .UtopContent
  .Ubooks
  .UforMobileSearch
  .UdropDownSearch
  .UseachQuery {
  transition: 0.U4s;
  padding: 0px 18px 18px 18px;
  height: auto;
  display: none;
}
.UbookMobileColor
  .UtopContent
  .Ubooks
  .UforMobileSearch
  .UdropDownSearch
  .UseachQuery
  .Uinner {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.UbookMobileColor
  .UtopContent
  .Ubooks
  .UforMobileSearch
  .UdropDownSearch
  .UseachQuery
  .Uinner
  .Ucustom-select {
  position: relative;
  width: 48%;

  display: flex;
  align-items: center;
  justify-content: space-between;
}
.UbookMobileColor
  .UtopContent
  .Ubooks
  .UforMobileSearch
  .UdropDownSearch
  .UseachQuery
  .Uinner
  .Ucustom-select
  .Uselect-icon {
  position: absolute;
  top: 13px;
  left: 10px;
  width: 20px;
  height: 100%;
}
.UbookMobileColor
  .UtopContent
  .Ubooks
  .UforMobileSearch
  .UdropDownSearch
  .UseachQuery
  .Uinner
  .Ucustom-select
  select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-right: 21px;
  height: 42px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #b6b6b6;
  padding: 0px 16px;
  outline: none;
  box-shadow: none;
  border-radius: 35px;
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 33px;
  color: #0f4152;
}
.UbookMobileColor
  .UtopContent
  .Ubooks
  .UforMobileSearch
  .UdropDownSearch
  .UseachQuery
  .Uinner
  .UsearchInput {
  width: 100%;
  margin: 10px 0px;
  height: 42px;
  border: 1px solid #b6b6b6;
  border-radius: 35px;
  overflow: hidden;
  display: flex;
  align-items: center;
}
.UbookMobileColor
  .UtopContent
  .Ubooks
  .UforMobileSearch
  .UdropDownSearch
  .UseachQuery
  .Uinner
  .UsearchInput
  input {
  width: calc(100% - 35px);
  height: 100%;
  border-radius: 35px;
  outline: none;
  box-shadow: none;
  border: none;
  padding: 0px 14px;
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20.U56px;
  color: #0f4152;
}
.UbookMobileColor
  .UtopContent
  .Ubooks
  .UforMobileSearch
  .UdropDownSearch
  .UseachQuery
  .Uinner
  .UsearchInput
  input::placeholder {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20.U56px;
  color: #0f4152;
}
.UbookMobileColor
  .UtopContent
  .Ubooks
  .UforMobileSearch
  .UdropDownSearch
  .UseachQuery
  .Uinner
  img {
  cursor: pointer;
}

.UbookMobileColor
  .UtopContent
  .Ubooks
  .UforMobileSearch
  .UdropDownSearch
  .UseachQuery
  .Uinner
  button {
  width: 119px;
  height: 44px;
  background: #59a0ad;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 23.U5px;
  text-align: center;
  color: #fff;
  border-radius: 35px;
  border: none;
  outline: none;
  box-shadow: none;
}

.UbookMobileColor
  .UtopContent
  .Ubooks
  .UforMobileSearch
  .UdropDownSearch
  .UseachQuery
  .Uinner
  button.Uactive {
  background: #097285;
}
#chapterMobile {
  font-family: "Heebo", sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 23px;
  color: #0f4152;
  margin-bottom: 24px;
  margin-top: 20px;
  display: none;
}

.Ufooter {
  width: 100%;
  position: static;

  height: 66px;
  display: flex;
  align-items: center;
  background: #097185;
}
.Ufooter .Uinner {
  max-width: 1140px;
  width: 90%;
  margin: auto;
  display: flex;
  align-items: center;
}

.Ufooter .Uinner a {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 33px;
  width: 89px;
  text-align: left;
  color: #ffffff;
  text-decoration: none;
}

.UmainContent .UdetailAccountTop {
  padding: 58px 46px;
}
.UmainContent .UcontentArea .UdetailAccount {
  display: flex;
  align-items: center;
  padding: 0px !important;

  justify-content: space-between;
  margin-bottom: 18px;
}

.UmainContent .UcontentArea .UdetailAccount .UactiveUsers {
  width: auto;
  padding: 0px 39px;
  height: 45px;
  background: #f1f1f1;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.UmainContent .UcontentArea .UdetailAccount .UactiveUsers p {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20.U56px;
  color: #2d2d2d;
  display: flex;
  align-items: center;
}
.UmainContent .UcontentArea .UdetailAccount .UactiveUsers p:nth-child(1) {
  margin-left: 37px;
}
.UmainContent .UcontentArea .UdetailAccount .UactiveUsers p img {
  margin-right: 15px;
}
.UmainContent .UcontentArea .UuserRegistrationLink {
  width: 100%;
  margin: auto;
  border: 1px solid #cdcfd6;
  height: 83px;
  border-radius: 7px;
  display: flex;
  align-items: center;
  padding: 0px 24px;
  justify-content: space-between;
  margin-bottom: 28px;
}
.UmainContent .UcontentArea .UuserRegistrationLink .Uleft h3 {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  color: #0f4152;
}
.UmainContent .UcontentArea .UuserRegistrationLink .Uleft .UcopyTexting {
  display: flex;
  align-items: center;
}
.UmainContent .UcontentArea .UuserRegistrationLink .Uleft .UcopyTexting p,
.UmainContent .UcontentArea .UuserRegistrationLink .Uleft .Uhidden,
#copyNotification {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 24px;
  color: #44c6cb;
}
#copyNotification {
  color: rgb(128, 19, 0);
}
.UmainContent .UcontentArea .UuserRegistrationLink .Uleft .Uhidden {
  color: rgb(128, 15, 0);
  display: none;
}
.UmainContent .UcontentArea .UuserRegistrationLink .Uleft .UcopyTexting img {
  cursor: pointer;
  margin-right: 16px;
}

.UmainContent .UcontentArea .UuserRegistrationLink button {
  width: 265px;
  height: 43px;
  border-radius: 40px;
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20.U56px;
  text-align: center;
  color: #0a7a8f;
  border: 1px solid #979797;
  background: transparent;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;
  cursor: pointer;
}
.UmainContent .UcontentArea .UuserRegistrationLink button img {
  margin-right: 22px;
}

.UmainContent .UcontentArea form {
  width: 100%;
  display: flex;
}
.UmainContent .UcontentArea form .Uleft {
  max-width: 344px;
  width: 50%;
  margin-left: 55px;
}
.UmainContent .UcontentArea form .Uleft h4,
.UmainContent .UcontentArea form .Uright h4 {
  font-family: "Heebo", sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  color: #0f4152;
  margin-bottom: 18px;
}
.UmainContent .UcontentArea form .Uleft input,
.UmainContent .UcontentArea form .Uright input {
  border: 1px solid #cdcfd6;
  width: 100%;
  padding: 0px 23px;
  height: 50px;
  outline: none;
  box-shadow: none;
  background: transparent;
  border-radius: 7px;
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #626262;
  margin-bottom: 7px;
}
.UmainContent .UcontentArea form .Uright {
  max-width: 344px;
  width: 50%;
}
.UmainContent .UcontentArea form .Uleft .Ucontainer {
  display: flex;
  align-items: center;
  margin-bottom: 7px;
}
.UmainContent .UcontentArea form .Uleft .Ucontainer .Ucustom-file-upload {
  width: 50px;
  height: 50px;
  display: inline-block;
  border: 1px solid #cdcfd6;
  border-radius: 7px;
  cursor: pointer;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 36px; 
  background-color: transparent;
  position: relative;
}

.UmainContent .UcontentArea form .Uleft .Ucontainer .Ucustom-file-upload:hover {
  background-color: #e0e0e0;
}
.UmainContent .UcontentArea form .Uleft .Ucontainer .Ufile-name {
  margin-left: 18px;
  font-size: 16px;
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #626262;
}
.UmainContent .UcontentArea .UsaveBtn {
  margin-top: 15px;
  width: 168px;
  height: 50px;
  border-radius: 40px;
  background: #0a7a8f;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 23.U5px;
  color: #ffffff;
  border: none;
  outline: none;
  box-shadow: none;
  cursor: pointer;
}
@media (max-width: 1300px) {
  .backPage {
    left: 0px;
    top: 10px;
    z-index: 999999999;
  }
  .UmainContent .topHeader .backPage {
    top: 2px;
    left: 0px;
    height: 25px;
  }
}

@media (max-width: 1250px) {
  .UmainContent
    .UprogressChapter
    .UcontentTab
    .Utabcontent
    .Urow
    .UprogressBar
    .UprogressTopLine {
    margin-left: 20px;
  }
}

@media (max-width: 1200px) {
  .header .left .logo {
    margin-left: 9px;
  }
  .header .left ul li {
    margin-right: 19px;
  }
  .UmainContent
    .contentArea
    .progress
    .UtabcontentTop
    .Utabcontent
    .Urow
    .card
    .bottomImg {
    width: 250px;
    display: block;
    margin: auto;
  }

  .UmainContent .readingBookTop .celebrate {
    height: auto;
  }

  .UmainContent .UprogressChapter .UcontentTab .Utabcontent .Urow .description {
    width: 400px !important;
  }

  .UmainContent .topContent .search .searchInput {
    max-width: 250px;
  }
}

@media (max-width: 1100px) {
  /* .UmainContent .UprogressChapter .UcontentTab .Utabcontent .Urow .UprogressBar {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  } */
  .UmainContent .UprogressChapter .UcontentTab .Utabcontent .Urow .UprogressBar {
    /* flex-wrap: wrap; */
    /* flex-direction: column-reverse; */
    /* width: auto; */
  }
  .UmainContent
    .UprogressChapter
    .UcontentTab
    .Utabcontent
    .Urow
    .UprogressBar
    .completed {
    /* width: 100%; */
    margin-right: -14px;
    margin-left: 0px;
    text-align: center;
  }
}

@media (max-width: 992px) {
  .header {
    padding: 10px 5%;
  }
  .UmainContent .topHeader .backPage,
  .backPage {
    display: none;
  }
  .UmainContent .topContent .search .searchInput {
    max-width: 200px;
  }

  .header .right {
    display: none;
  }

  .header .left {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .header .left .mobileMenu {
    display: block;
    cursor: pointer;
  }

  .header .left .logo {
    margin-right: 0px;
  }

  .header .left .overLay {
    display: none;
  }

  .UmainContent .contentArea .progress .UtabcontentTop .Utabcontent .Urow .card h2 {
    font-size: 24px;
  }

  .UmainContent .contentArea .progress .UtabcontentTop .Utabcontent .Urow {
    flex-wrap: wrap;
  }

  .UmainContent .contentArea .progress .UtabcontentTop .Utabcontent .Urow .card {
    width: 49%;
    margin-bottom: 12px;
  }

  .UmainContent .contentArea .progress .myAccountList {
    border-bottom: none;
    display: flex;
    justify-content: center;
  }

  .UmainContentSuccess {
    width: 100% !important;
  }

  .UmainContent .readingBookTop .celebrate {
    background: #fff;
  }

  .UmainContent .readingBookTop .celebrate h2 {
    line-height: 40px;
  }
}

@media (max-width: 900px) {
  .UmainContent .individualHeader .totalUser {
    display: none;
  }
  .UmainContent .books .headerBooksList {
    display: none;
  }

  .UmainContent .books .UcontentTab .Utabcontent .Urow {
    background: #fff;
    border-radius: 7px;
    border: none;
    margin-bottom: 6px;
    box-shadow: 0px 0px 30px 0px #0000001f;
  }

  .UmainContent .books h1 {
    display: none;
  }

  .bookMobileColor .topContent {
    box-shadow: none !important;
    background: transparent !important;
  }

  .bookMobileColor .topContent .books .forMobileSearch {
    display: block;
  }

  .UmainContent .line {
    display: none;
  }

  .UmainContent .books .UcontentTab .Utabcontent .Urow .left,
  .UmainContent .books .UcontentTab .Utabcontent .Urow .center {
    max-width: 48%;
    width: 48%;
  }

  .UmainContent .books .UcontentTab .Utabcontent .Urow .right {
    max-width: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 24px;
  }

  .UmainContent .books .UcontentTab .Utabcontent .Urow {
    flex-wrap: wrap;
  }

  .UmainContent .topContent .search {
    display: none;
  }

  .readingTop {
    max-width: 100% !important;
    width: 100% !important;
  }

  .UmainContent .readingBookTop {
    background: transparent !important;
    padding: 15px 14px;
  }

  .UmainContent .readingBookTop .hideParaMobile {
    display: none;
  }

  .UmainContent .readingBookTop .player {
    background: #fff;
    height: 662px;
  }

  .UmainContent .readingBookTop .player h1 {
    line-height: 55px;
  }

  .UmainContent
    .readingBookTop
    .player
    .footerPlayer
    .center
    .previous
    .inner
    img,
  .UmainContent .readingBookTop .player .footerPlayer .center .repeat img,
  .UmainContent .readingBookTop .player .footerPlayer .center .start img,
  .UmainContent .readingBookTop .player .footerPlayer .center .next img {
    margin-top: 7px;
  }

  .UmainContent .readingBookTop .player {
    margin-bottom: 58px;
  }

  .UmainContent .readingBookTop .progressPlayer {
    margin-bottom: 38px;
  }

  .UmainContent .individualHeader .right {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    justify-content: flex-end;
  }

  .UmainContent .individualHeader .right .progressLine {
    padding-right: 0px;
    margin-right: 0px;
    margin-left: 0px;
  }

  .UmainContent .individualHeader .right .btn {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .UmainContent .individualHeader .right .btn button {
    margin-bottom: 5px;
  }

  .UmainContent .individualHeader .right .progressLine .grayLine {
    margin-right: 0px;
    width: 137px;
  }

  .UmainContent .UprogressChapter .UcontentTab .Utabcontent .Urow .description {
    width: 250px !important;
  }
}
@media (max-width: 896px) {
  .settingModal .innerContent .settingModal-body {
    padding: 20px;
  }

  .dropdown-content .option {
    display: none;
  }

  .custom-dropdown .headerTimer {
    display: block;
  }

  .custom-dropdown .headerTimer img {
    float: right;
    display: inline;
  }

  .UmainContent .readingBookTop .player {
    width: 90%;
    margin-left: 10%;
  }

  .UmainContent .readingBookTop .player .group {
    display: block;
    width: 5%;
    position: absolute;
    top: 10px;
    left: -70px;
  }

  .UmainContent .readingBookTop .player {
    height: 94vh;
    margin-bottom: 0px;
  }

  .UmainContent .readingBookTop .player h1 {
    margin-bottom: 100px;
  }

  .headerReader {
    display: none;
  }

  .footerReader {
    display: none;
  }

  .UmainContent .readingBookTop .progressPlayer {
    display: none;
  }

  .UmainContent .readingBookTop .player .footerPlayer .center {
    position: absolute;
    top: -80px !important;
    left: 0px;
    right: 0px;
    margin: auto;
  }

  .settingModal .innerContent .settingModal-body .left form p {
    display: block;
    margin-bottom: 20px;
  }

  .custom-dropdown .inner2 {
    position: fixed;
    z-index: 9999999;
    background: rgba(0, 0, 0, 0.7);
    top: 0px;
    right: 0px;
    bottom: 0px;
    overflow-y: auto;
    padding-bottom: 50px;
    left: 0px;
    display: none;
  }

  .custom-dropdown .inner2 .dropdown-content {
    position: static;
    width: 90%;
    margin: auto;
    padding: 35px;
    margin-top: 5%;
  }
}
@media (max-width: 800px) {
  .UmainContent .contentArea .userRegistrationLink .left .copyTexting p {
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .UmainContent .contentArea .userRegistrationLink button {
    margin-top: 10px;
  }
  .UmainContent .detailAccountTop .headerTop {
    flex-wrap: wrap !important;
  }
  .UmainContent .contentArea .detailAccount .activeUsers {
    flex-wrap: wrap;
    height: auto !important;
    padding: 5px 20px;
  }
  .UmainContent .contentArea .detailAccount h2 {
    margin-right: 20px;
    width: 100% !important;

    margin-bottom: 10px;
  }
  .UmainContent .contentArea .userRegistrationLink {
    flex-wrap: wrap;
    height: auto;
    padding: 10px;
    overflow: hidden;
  }
  .UmainContent .detailAccountTop {
    padding: 20px;
  }
}
@media (max-width: 779px) {
  .tablinks {
    font-size: 14px;
  }

  .UmainContent .UprogressChapter .UcontentTab .Utabcontent .Urow .UprogressBar {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .UmainContent .UprogressChapter .UcontentTab .Utabcontent .Urow .UbtnsList .Uview {
 margin: 2px;   
  }
  .UmainContent .UprogressChapter .UcontentTab .Utabcontent .Urow .UbtnsList{
    display: flex;
    flex-direction: column;
    align-items: center;
    
  }
  .UmainContent .UprogressChapter .UcontentTab .Utabcontent .Urow h2 {
    margin-left: 10px;
    width: 50%;
  }
  .UmainContent .UprogressChapter .UcontentTab .Utabcontent .Urow .UprogressBar {
    width: 50%;
  }
  .UmainContent
    .UprogressChapter
    .UcontentTab
    .Utabcontent
    .Urow
    .UprogressBar
    p:nth-child(1) {
    width: 160px;
  }

  .UmainContent
    .UprogressChapter
    .UcontentTab
    .Utabcontent
    .Urow
    .UprogressBar
    .UprogressTopLine {
    margin-right: 5px;
  }

  .UmainContent .contentArea .headerTop .timeStamp {
    height: auto;
    text-align: center;
    padding: 4px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .UmainContent .contentArea .headerTop .timeStamp img {
    display: none;
  }

  .UmainContent .contentArea .headerTop {
    padding: 23px 15px;
  }

  .UmainContent .contentArea .headerTop h2 {
    font-size: 18px;
    line-height: 24px;
  }

  .UmainContent .contentArea .headerTop .timeStamp h3 {
    text-align: center;
  }

  .UmainContent .contentArea .headerTop .timeStamp h3 .span1 {
    display: none;
  }

  .UmainContent .contentArea .headerTop .timeStamp h3 .span2 {
    display: block;
    text-align: center;
  }

  .UmainContent .contentArea .progress .UtabcontentTop .Utabcontent .Urow .card {
    width: 100%;
    margin: auto;
    margin-bottom: 12px;
  }

  .UmainContent .contentArea .progress .UtabcontentTop {
    padding: 23px 9px;
  }

  .UmainContent .readingBookTop .celebrate img {
    height: 405px;
    object-fit: cover;
  }

  .UmainContent .individualHeader {
    padding: 9px;
  }

  .UmainContent .individualHeader .right .btn h2 {
    display: none;
  }

  .UmainContent .individualHeader .left h2 {
    font-size: 18px;
    line-height: 24px;
    margin-right: 5px;
  }

  .UmainContent .UprogressChapter .chapterList button {
    margin-left: 0px;
    margin-right: 10px;
  }

  .UmainContent .topHeader .right {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }

  .UmainContent .topHeader .right button {
    margin-left: auto;
  }

  .UmainContent .topHeader .right .text h2 {
    display: none;
  }

  .UmainContent .topHeader .right .text {
    margin-right: 0px;
  }

  .UmainContent .topHeader .right .text p {
    width: 160px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    margin-top: 8px;
  }

  .UmainContent .topHeader {
    padding: 12px 9px;
  }
}

@media (max-width: 700px) {
 

  .custom-dropdown .dropdown-content label {
    font-family: "Heebo", sans-serif;
    font-size: 16px !important;
    font-weight: 300 !important;
    line-height: 24px;
    color: #023842;
  }
  .dropdown-content .paraG {
    display: block;
    margin-bottom: 20px!important;
  }
  
  .paymentModal .innerContent .userCards {
    flex-wrap: wrap;
    height: auto;
    padding: 10px 20px;
  }
  .paymentModal .innerContent .userCards .card {
    width: 50%;
  }
  .paymentModal .innerContent .userCards .card:nth-child(1),
  .paymentModal .innerContent .userCards .card:nth-child(2) {
    margin-bottom: 15px;
  }
  .paymentModal .innerContent .userCards .card:nth-child(2) img {
    display: none;
  }
  #chapterMobile {
    display: block;
  }

  .UmainContent .UprogressChapter .search,
  .UmainContent .UprogressChapter .chapterList {
    display: none;
  }

  .UmainContent .books .headerBooksList {
    display: none;
  }

  .UmainContent .books .UcontentTab .Utabcontent .Urow .left,
  .UmainContent .books .UcontentTab .Utabcontent .Urow .center {
    max-width: 100%;
    width: 100%;
  }

  .UmainContent .books .UcontentTab .Utabcontent .Urow .center img {
    position: absolute;
    top: 14px;
    left: 18px;
  }

  .UmainContent .books .UcontentTab .Utabcontent .Urow .center {
    margin-top: 10px;
  }

  .settingModal .innerContent .settingModal-body {
    flex-wrap: wrap;
  }





  .UmainContent .UprogressChapter .UcontentTab .Utabcontent .Urow .description {
    display: none;
  }

  .UmainContent .UprogressChapter .UcontentTab .Utabcontent .Urow .btnsList {
    width: auto;
  }

  .UmainContent .UprogressChapter .UcontentTab .Utabcontent .Urow .UprogressBar {
    width: auto;
    margin-left: 10px;
  }
  /* .UmainContent
    .UprogressChapter
    .UcontentTab
    .Utabcontent
    .Urow
    .UprogressBar
    .completed {
    margin-right: -34px;
    
  } */
  .UmainContent
    .UprogressChapter
    .UcontentTab
    .Utabcontent
    .Urow
    .UprogressBar
    .UprogressTopLine {
    width: 100px;
  }

  .UmainContent .UprogressChapter .UcontentTab .Utabcontent .Urow .UprogressBar {
    margin-right: 5px;
  }

  .UmainContent .UprogressChapter .UcontentTab .Utabcontent .Urow {
    padding: 12px 9px;
  }
}

@media (max-width: 679px) {
  .UmainContent .UprogressChapter .UcontentTab .Utabcontent .Urow .btnsList .keepReading{
    width: 80px;
  }
  
  .addBookModal .modal-content .modal-body img {
    margin-bottom: 20px;
  }
  .addBookModal .modal-content .modal-body .step1Btn {
    margin: auto;
    left: 0px;
    right: 0px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  .UmainContent .contentArea form {
    flex-wrap: wrap;
    max-width: 100%;
  }
  .UmainContent .contentArea form .left,
  .UmainContent .contentArea form .right {
    max-width: 100%;
    width: 100%;
    margin-left: 0px;
  }
  .addLibraryModalTop .modal-content .modal-header,
  .editLibraryModalTop .modal-content .modal-header,
  .deleteLibraryModalTop .modal-content .modal-header {
    justify-content: flex-start;
    padding-right: 25px;
  }
  .addLibraryModalTop .modal-content .modal-header h2,
  .editLibraryModalTop .modal-content .modal-header h2,
  .deleteLibraryModalTop .modal-content .modal-header h2 {
    font-size: 20px;
  }
  .UmainContent .UprogressChapter .UcontentTab .Utabcontent .Urow h2 {
    /* width: 80px; */
    text-align: start;
    margin-left: 10px;
  }
  .editChapterModal .innerContent,
  .editBookModal .innerContent {
    margin: 36px auto;
  }

  .editBookModal .innerContent .headerModal {
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-right: 24px;
  }

  .UmainContent .UprogressChapter .UcontentTab .Utabcontent .Urow .UprogressBar {
    width: auto;
    flex-direction:column ;
    text-align: start;
  }

  .addBookModal .modal-content,
  .paymentModal .innerContent,
  .settingModal .innerContent,
  .deleteBookModal .innerContent {
    margin: 27px auto;
    overflow-y: auto;
  }

  .editBookModal .innerContent .headerModal {
    text-align: left;
  }

  .deleteBookModal .innerContent .headerModal {
    display: flex;
    justify-content: flex-start;
    padding-right: 26px;
  }

  .settingModal .innerContent .headerModal .cancel,
  .editBookModal .innerContent .headerModal .cancel,
  .deleteBookModal .innerContent .headerModal .cancel {
    left: 26px;
    margin-left: auto;
    position: absolute;
  }

  .addBookModal .modal-content .modal-header .close {
    right: 26px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .addBookModal .modal-content .modal-header,
  .settingModal .innerContent .headerModal {
    justify-content: flex-start;
    padding-right: 26px;
    position: relative;
  }

  .addBookModal .modal-content .modal-header h2,
  .settingModal .innerContent .headerModal h1,
  .editBookModal .innerContent .headerModal h1,
  .deleteBookModal .innerContent .headerModal h1 {
    font-size: 20px;
  }

  .addBookModal .modal-content .modal-body {
    padding: 17px 18px;
  }

  .addBookModal .modal-content .modal-body .firstImg {
    margin-bottom: 68px !important;
    position: static;
    display: block;
    margin: auto;
    width: 80%;
    height: auto;
  }

  .addBookModal .modal-content .modal-body form .Urow {
    flex-wrap: wrap;
  }

  .addBookModal .modal-content .modal-body form .Urow input {
    width: 100%;
    margin-bottom: 12px;
  }

  .addBookModal .modal-content .modal-body form .Urow .custom-select {
    width: 100%;
  }

  .addBookModal .modal-content .modal-body form .Urow .forMobileMargin {
    margin-bottom: 12px;
  }

  .addBookModal .modal-content .modal-footer {
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
  }

  .addBookModal .modal-content .modal-footer .back1 {
    margin-right: 0px;
  }

  .addBookModal .modal-content .modal-footer button {
    margin-bottom: 10px;
  }

  .addBookModal .modal-content .modal-body form {
    padding: 0px 18px;
  }

  .addBookModal .modal-content .modal-body .successUpload {
    width: 80%;
  }

  .UmainContent .readingBookTop .player .footerPlayer .center {
    position: absolute;
    top: -80px !important;
    left: 0px;
    right: 0px;
    margin: auto;
  }
}

@media (max-width: 664px) {
    .settingModal .innerContent .settingModal-body .left {
    width: 100%;
  }
  .UmainContent .topHeader .left p {
    font-size: 14px;
  }
  .UmainContent .topHeader .right .text p {
    font-size: 12px;
  }
  .paymentModal .innerContent {
    padding: 62px 20px;
  }
  .paymentModal .innerContent .total .leftt {
    flex-wrap: wrap;
    width: 50%;
  }
  .paymentModal .innerContent .total .rightt h3,
  .paymentModal .innerContent .total .leftt h3 {
    font-size: 15px;
  }
  .paymentModal .innerContent .total .leftt p {
    width: 100%;
  }
  .paymentModal .innerContent .total {
    height: auto;
    padding: 10px;
    border-radius: 7px;
  }
  .paymentModal .innerContent .total .leftt {
    flex-wrap: wrap;
  }
  .paymentModal .innerContent .total .leftt .firstH3 {
    width: 90px !important;
  }
  .paymentModal .innerContent .total .leftt img {
    display: none;
  }
  .paymentModal .innerContent .userCards {
    padding: 10px;
  }
  .settingModal .innerContent .settingModal-body .leftForMobile {
    margin-top: 58px;
  }

  .editBookModal .innerContent .bodyModal form {
    padding: 28px;
  }
}

@media (max-width: 622px) {
  .paymentModal .innerContent form {
    flex-wrap: wrap;
  }
  .paymentModal .innerContent form .left,
  .number-input-container,
  .paymentModal .innerContent form .right {
    width: 100%;
    margin-left: 0px;
  }
  .paymentModal .innerContent .twoOffer .monthly,
  .paymentModal .innerContent .twoOffer .yearly {
    margin-right: 0px;
    width: 266px;
    margin-bottom: 25px;
  }

  .editBookModal .innerContent .bodyModal form .Urow {
    flex-wrap: wrap;
  }

  .editBookModal .innerContent .bodyModal form .Urow:nth-child(1) .custom-select,
  .editBookModal .innerContent .bodyModal form .Urow:nth-child(2) .custom-select,
  .editBookModal
    .innerContent
    .bodyModal
    form
    .Urow:nth-child(3)
    .custom-select {
    margin-bottom: 0px;
  }

  .editBookModal .innerContent .bodyModal form .Urow input,
  .editBookModal .innerContent .bodyModal form .Urow .custom-select {
    width: 100%;
    margin-bottom: 12px;
  }

  .deleteBookModal .innerContent .bodyModal .btns {
    flex-wrap: wrap;
  }

  .deleteBookModal .innerContent .bodyModal .btns button {
    margin-bottom: 6px;
  }
}
@media (max-width: 440px) {
  .UmainContent .readingBookTop .progressPlayer{
    display: block;
    margin-top: 58px;
  }
  .footerReader{
    display: block;
  }
  .headerReader {
    display: block;
  }
  .UmainContent .readingBookTop .player {
    width: 100%;
    margin-right: 0%;
  }
  .UmainContent .readingBookTop .player .group {
    display: none;
  }
}
/* Responsive Styles */
@media only screen and (max-width: 768px) {
  .UmainContent .UindividualHeader .UtotalUser {
    height: 59px;
    border-radius: 40px;
    background: #f0f0f0;
    padding: 0px 31px;
    border-radius: 59px;
    opacity: 0px;
  }
  
  .UmainContent .UindividualHeader .UtotalUser h4 {
    font-family: "Heebo", sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: #0f4152;
    margin: 0 22px 0 22px;
  }
  .UmainContent .UindividualHeader .UtotalUser p {
    font-family: "Heebo", sans-serif;
    font-size: 12px;
    font-weight: 300;
    line-height: 24px;
    color: #0f4152;
  }
  .UmainContent .UindividualHeader .UtotalUser p span {
    font-family: "Heebo", sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    color: #0a7a8f;
  }
  
  .UmainContent .UtopHeader {
    padding: 20px 15px;
    flex-direction: column;
    align-items: flex-start;
  }

  .UmainContent .UtopHeader .UbackPage {
    position: static;
    margin-top: 10px;
    display: none;
    width: 40px;
    height: 30px;
  }

  .UmainContent .UtopHeader .Uleft h2 {
    font-size: 16px;
    line-height: 22px;
  }

  .UmainContent .UtopHeader .Uleft p {
    font-size: 14px;
    line-height: 20px;
  }

  .UmainContent .UtopHeader .Uright {
    display: none;
    width: 100%;
    justify-content: space-between;
    margin-top: 15px;
    padding: 10px;
    height: auto;
  }

  .UmainContent .UtopHeader .Uright .Utext {
    margin-left: 0;
  }

  .UmainContent .UtopHeader .Uright button {
    width: 100%;
    height: 40px;
    font-size: 14px;
  }
}

@media only screen and (max-width: 480px) {
  .UmainContent .UtopHeader {
    padding: 15px 10px;
  }

  .UmainContent .UtopHeader .Uleft h2 {
    font-size: 18px;
  }

  .UmainContent .UtopHeader .Uleft p {
    font-size: 12px;
  }

  .UmainContent .UtopHeader .Uright button {
    height: 35px;
    font-size: 12px;
  }
}
