@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@100..900&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");
* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}

body {
  background: #ebecf1;
}
.top {
  width: 100%;
  height: 100vh;
  display: flex;
  background: #ebecf1;
}

.top .left {
  width: calc(100% - 510px);
  height: 100vh;
  background-image: url("../../img/login.png");
  background-position: 100% 100%;
  background-size: 100% 100%;
  object-fit: cover;
  background-repeat: no-repeat;
}
.top .right {
  width: 510px;
  position: relative;
  height: 100vh;
  padding: 43px 49px;
}
.top .right .lang,
.register .inner .lang {
  width: 97px;
  height: 32px;
  gap: 0px;
  border-radius: 7px;
  margin-right: auto;
  border: 1px 0px 0px 0px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #0f4152;
  padding: 0px 15px;
  cursor: pointer;
  position: relative;
  margin-bottom: 49px;
}
.register .inner .lang,
.register .inner .logoImg {
  margin-bottom: 12px !important;
}
.top .right .lang p,
.register .inner .lang p {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 33px;
  color: #0f4152;
}

.top .right .lang .dropdown,
.register .inner .lang .dropdown {
  position: absolute;
  bottom: -115%;
  border-radius: 7px;
  border: 1px 0px 0px 0px;

  right: 0px;
  border: 1px solid #0f4152;
  width: 100%;
  display: none;
}
.top .right .lang .show,
.register .inner .lang .show {
  display: block;
}
.top .right .lang .dropdown a,
.register .inner .lang .dropdown a {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 33px;
  color: #0f4152;
  text-decoration: none;
  display: flex;
  align-items: center;
  width: 97px;
  height: 32px;
  justify-content: center;
}

.top .right .logoImg,
.register .inner .logoImg {
  margin: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 55px;
}
.top .right .logoImg .logo,
.register .inner .lang {
  text-decoration: none;
}
.top .right h1 {
  font-family: "Heebo", sans-serif;
  font-size: 28px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #0f4152;
  margin-bottom: 5px;
}
.top .right h5 {
  font-family: "Heebo", sans-serif;
  font-size: 28px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #0f4152;
  margin-bottom: 27px;
}

.top .right form {
  width: 100%;
}
.top .right form .input {
  width: 100%;
  height: 50px;
  border: 1px 0px 0px 0px;
 
  border: 1px solid #cdcfd6;
  background: #ffffff !important;
  margin-bottom: 13px;
  border-radius: 7px;
  padding: 0px 23px;
  outline: none;
  box-shadow: none;
}

.top .right form .row,
.register .inner form .row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 10px;
}
.top .right form .row .custom-checkbox,
.register .inner form .row .custom-checkbox {
  position: relative;
  display: inline-block;
  cursor: pointer;
  font-size: 16px;
}



.top .right form .row .custom-checkbox input,
.register .inner form .row .custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.top .right form .row .custom-checkbox p,
.register .inner form .row .custom-checkbox p {
  margin-right: 35px;
  font-family: "Heebo", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #626262;
}
.register .inner form .row .custom-checkbox p a {
  font-family: "Heebo", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #626262;
}
.top .right form .row .custom-checkbox .checkmark,
.register .inner form .row .custom-checkbox .checkmark {
  position: absolute;
  top: 0;
  right: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: 1px solid #15151524;
  border-radius: 5px;
}

.top .right form .row .custom-checkbox:hover input ~ .checkmark,
.register .inner form .row .custom-checkbox:hover input ~ .checkmark {
  background-color: #0f4152;
}

.top .right form .custom-checkbox input:checked ~ .checkmark,
.register .inner form .row .custom-checkbox input:checked ~ .checkmark {
  background-color: #0f4152;
}

.top .right form .row .custom-checkbox .checkmark:after,
.register .inner form .row .custom-checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.top .right form .row .custom-checkbox input:checked ~ .checkmark:after,
.register .inner form .row .custom-checkbox input:checked ~ .checkmark:after {
  display: block;
}

.top .right form .row .custom-checkbox .checkmark:after,
.register .inner form .row .custom-checkbox .checkmark:after {
  left: 5px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
/* Focus-Visible State */
.top .right form .row .custom-checkbox input:focus-visible ~ .checkmark,
.register .inner form .row .custom-checkbox input:focus-visible ~ .checkmark {
  outline: 2px solid #0a7a8f; /* Add focus outline */
  outline-offset: 2px; /* Space between outline and element */
}
.top .right form .row button,
.register .inner form .row button {
  width: 168px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0px;
  background: #097083;
  text-decoration: none;
  border-radius: 35px;
  /* border: none;
  outline: none; */
  box-shadow: none;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.96px;
  text-align: center;
  color: #fff;
  cursor: pointer;
  border: none; /* Add this to remove the default border */
  outline: none; /* Ensure custom focus styles are visible */
}
.top .right form .row button.focus-visible,
.register .inner form .row button.focus-visible {
  outline: 2px solid #1d1b1b; /* White focus ring */
  outline-offset: 3px; /* Space between button and outline */
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.5); /* Optional shadow effect */
}

.top .right form .fPassword {
  font-family: "Heebo", sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.09px;
  text-align: center;
  width: 168px;
  float: right;
  color: #626262;
  margin-bottom: 27px;
}
.top .right form .line,
.register .inner form .line {
  display: block;
  width: 100%;
  height: 1px;
  margin-top: 27px;
  border: 1px 0px 0px 0px;
  margin-bottom: 22px;
  border: 1px solid #979797;
}

.top .right form .dontHaveAccount {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
}
.top .right form .dontHaveAccount p {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin: auto;
  color: #626262;
  text-align: center;
}
.top .right form .dontHaveAccount p a {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #0f4152;
}
/*.top .right  */
 .accessibilitystyle {
  position: absolute;
  bottom: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0px;
  right: 0px;
  margin-left: auto;
  margin-right: auto;
}
.top .right .accessibilitystyle p {
  display: flex;
  align-items: center;
  justify-content: center;
}

.top .right .accessibilitystyle p img {
  margin-right: 17px;
}
.top .right .accessibilitystyle p a {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 33px;
  text-align: left;
  color: #0f4152;
}

/* registertaion css here ====== */
.register {
  display: flex;
  min-height: 100vh;
  padding-top: 40px;
  padding-bottom: 40px;
  align-items: center;
  justify-content: center;
}
.register .inner {
  max-width: 644px;
  width: 90%;
  margin: 10px auto;
  height: auto;
  box-shadow: 0px 2px 52px 0px #d3d3d3;
  border-radius: 7px;
  background: #fff;
  padding: 22px;
}
.register .inner .para {
  font-family: "Heebo", sans-serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #0F4152;
  margin-bottom: 16px;
}
.register .inner .pUserTop{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
}
.register .inner .pUserTop .privateUser {
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 33px;
  text-align: center;
  color: #ff0000;

}
.register .inner h1 {
  font-family: "Heebo", sans-serif;
  font-size: 22px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #0f4152;
  margin-bottom: 21px;
}
.register .inner form {
  max-width: 369px;
  width: 100%;
  margin: auto;
}

/* Custom styles for the select dropdown */
.register .inner form .custom-select {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}

.register .inner form .custom-select .select-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 13px;
}

.register .inner form .custom-select select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-right: 20px;
  width: 100%;
  height: 50px;
  background: #ffffff;
  border: 1px solid #0f4152;
  padding: 0px 27px;
  outline: none;
  box-shadow: none;
  border-radius: 7px;

  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;

}
.register .inner form .select-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 100%;
  pointer-events: none; 
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.register .inner form .input {
  width: 100%;
  height: 50px;
  padding: 0px 27px;
  background: #ffffff;
  border: 1px solid #cdcfd6;
  border-radius: 7px;
  outline: none;
  box-shadow: none;
  margin-bottom: 12px;
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #626262;
}

.register .inner form input::placeholder,
.register .inner form input option[disabled] {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #626262;
}
.register .inner form .dontHaveAccount {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #626262;
}
.register .inner form .dontHaveAccount a {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #626262;
}
@media (max-width: 1100px) {
  .top .left {
    width: calc(100% - 410px);
  }
  .top .right {
    width: 410px;
  }
}

@media (max-width: 800px) {
  .top .left {
    display: none;
  }
  .top .right {
    width: 100%;
    padding: 30px 5%;
  }
  .top .right form .row .custom-checkbox p {
    margin-left: 29px;
  }
  .top .right form .row button,
  .top .right form .fPassword {
    width: 158px;
  }
}
