@import url("https://fonts.googleapis.com/css2?family=Assistant:wght@200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  padding: 0%;
  margin: 0%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-family: "Heebo", sans-serif;
}

html {
  width: 100%;
  font-size: 100%;
  overflow-x: hidden;
  scroll-behavior: auto !important;
}

@media screen and (max-width: 992px) {
  html {
    font-size: 100%;
  }
}

@media screen and (max-width: 668px) {
  html {
    font-size: 92%;
  }
}

@media screen and (max-width: 575px) {
  html {
    font-size: 88%;
  }
}

@media screen and (max-width: 475px) {
  html {
    font-size: 84%;
  }
}

@media screen and (max-width: 380px) {
  html {
    font-size: 80%;
  }
}

@media screen and (max-width: 280px) {
  html {
    font-size: 76%;
  }
}

body {
  background-color: #f0ecf4 !important;
}

.assistant {
  font-family: "Assistant", sans-serif;
}

.heebo {
  font-family: "Heebo", sans-serif;
}

.rubik {
  font-family: "Rubik", sans-serif;
}

.f8 {
  font-size: 0.5rem;
}

.f10 {
  font-size: 0.625rem;
}

.f12 {
  font-size: 0.75rem;
}

.f14 {
  font-size: 0.875rem;
}

.f15 {
  font-size: 0.938rem;
}

.f16 {
  font-size: 1rem;
}

.f18 {
  font-size: 1.125rem;
}

.f20 {
  font-size: 1.25rem;
}

.f22 {
  font-size: 1.375rem;
}

.f24 {
  font-size: 1.5rem;
}

.f28 {
  font-size: 1.75rem;
}

.f34 {
  font-size: 2.125rem;
}

.f30 {
  font-size: 1.875rem;
}

.f35 {
  font-size: 2.125rem;
  line-height: 57px;
}

.f50 {
  font-size: 3.125rem;
  line-height: 65px;
}

.f64 {
  font-size: 4rem;
  line-height: 71px;
}

.fw400 {
  font-weight: 400;
}

.fw500 {
  font-weight: 500;
}

.fw600 {
  font-weight: 600;
}

.fw700 {
  font-weight: 700;
}

.fw800 {
  font-weight: 800;
}

.fw900 {
  font-weight: 900;
}

.color1 {
  color: #0f4152 !important;
}

.bg_color1 {
  background: #0f4152 !important;
}

.color2 {
  color: #606060 !important;
}

.bg_color2 {
  background: #606060 !important;
}

.color3 {
  color: #f0ecf4 !important;
}

.bg_color3 {
  background: #f0ecf4 !important;
}

.color4 {
  color: #bababa !important;
}

.bg_color4 {
  background: #bababa !important;
}

.color5 {
  color: #2d2d2d !important;
}

.bg_color5 {
  background: #2d2d2d !important;
}

.color6 {
  color: #3d3f45 !important;
}

.bg_color6 {
  background: #3d3f45 !important;
}

.color7 {
  color: #f2f5fe !important;
}

.bg_color7 {
  background: #f2f5fe !important;
}

.color8 {
  color: #cdcfd6 !important;
}

.bg_color8 {
  background: #cdcfd6 !important;
}

.color9 {
  color: #eceef4 !important;
}

.bg_color9 {
  background: #eceef4 !important;
}

.color10 {
  color: #44c6cb !important;
}

.bg_color10 {
  background: #44c6cb !important;
}

.color11 {
  color: #626262 !important;
}

.bg_color11 {
  background: #626262 !important;
}

.rtl {
  direction: rtl;
}

.ltr {
  direction: ltr;
}

button:not([disabled]) {
  -webkit-transition: 1s;
  transition: 1s;
}

button:not([disabled]):focus {
  outline: none;
}

button:not([disabled]):hover {
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
}

.page_container {
  max-width: 1200px;
  width: 100%;
  padding: 0rem 1rem;
  margin-left: auto;
  margin-right: auto;
}

.pointer {
  cursor: pointer;
}

details.custom-select {
  position: relative;
  width: 120px;
 
}

details.custom-select summary {
  padding: 0.3rem 0.6rem;
  cursor: pointer;
  border-radius: 5px;
  border: 2px solid #0f4152;
  list-style: none;
  color: #0f4152;
}

details.custom-select summary::-webkit-details-marker {
  display: none;
}

details.custom-select summary:after {
  content: url("../../icons/downArrow.svg");
  display: inline-block;
  float: right;
}

details.custom-select summary.rtl:after {
  content: url("../../icons/downArrow.svg");
  display: inline-block;
  float: left;
}

details.custom-select summary:focus {
  outline: none;
}

details.custom-select ul {
  list-style: none;
  width: 100%;
  background: #0f4152;
  position: absolute;
  top: calc(100% + 0.5rem);
  left: 0;
  padding: 1rem;
  margin: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 5px;
  max-height: 200px;
  overflow-y: auto;
}

details.custom-select li {
  margin: 0;
  padding: 0.5rem 0;
  border-bottom: 1px solid #0f4152;
}

details.custom-select li:first-child {
  padding-top: 0;
}

details.custom-select li:last-child {
  padding-bottom: 0;
  border-bottom: none;
}

details.custom-select summary.radios {
  counter-reset: radios;
}

details.custom-select input[type="radio"] {
  counter-increment: radios;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  display: none;
}

details.custom-select input[type="radio"]:checked {
  display: inline;
}

details.custom-select input[type="radio"]:after {
  content: attr(title);
  display: inline;
  font-size: 1rem;
  color: #0f4152;
  margin-right: 0.5rem;
}

details.custom-select summary.rtl input[type="radio"]:after {
  margin-right: 0rem;
}

details.custom-select ul.list {
  counter-reset: labels;
}

details.custom-select label {
  width: 100%;
  display: block;
  cursor: pointer;
}

details.custom-select[open] summary:before {
  content: "";
  display: block;
  width: 100vw;
  height: 100vh;
  background: transparent;
  position: fixed;
  top: 0;
  left: 0;
}

details.custom-select[open] {
  z-index: 1;
}

details.custom-select.light {
  position: relative;
  width: 120px;
 
}

details.custom-select.light summary {
  padding: 0.3rem 0.6rem;
  cursor: pointer;
  border-radius: 5px;
  border: 2px solid #f0ecf4;
  list-style: none;
  color: #f0ecf4;
}

details.custom-select.light summary::-webkit-details-marker {
  display: none;
}

details.custom-select.light summary:after {
  content: url("../../icons/downArrow-light.svg");
  display: inline-block;
  float: right;
}

details.custom-select.light summary.rtl:after {
  content: url("../../icons/downArrow-light.svg");
  display: inline-block;
  float: left;
}

details.custom-select.light summary:focus {
  outline: none;
}

details.custom-select.light ul {
  list-style: none;
  width: 100%;
  background: #0f4152;
  position: absolute;
  top: calc(100% + 0.5rem);
  left: 0;
  padding: 1rem;
  margin: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 5px;
  max-height: 200px;
  overflow-y: auto;
}

details.custom-select.light li {
  margin: 0;
  padding: 0.5rem 0;
  border-bottom: 1px solid #0f4152;
}

details.custom-select.light li:first-child {
  padding-top: 0;
}

details.custom-select.light li:last-child {
  padding-bottom: 0;
  border-bottom: none;
}

details.custom-select.light summary.radios {
  counter-reset: radios;
}

details.custom-select.light input[type="radio"] {
  counter-increment: radios;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  display: none;
}

details.custom-select.light input[type="radio"]:checked {
  display: inline;
}

details.custom-select.light input[type="radio"]:after {
  content: attr(title);
  display: inline;
  font-size: 1rem;
  color: #f0ecf4;
  margin-right: 0.5rem;
}

details.custom-select.light summary.rtl input[type="radio"]:after {
  margin-right: 0rem;
}

details.custom-select.light ul.list {
  counter-reset: labels;
}

details.custom-select.light label {
  width: 100%;
  display: block;
  cursor: pointer;
}

details.custom-select.light[open] summary:before {
  content: "";
  display: block;
  width: 100vw;
  height: 100vh;
  background: transparent;
  position: fixed;
  top: 0;
  left: 0;
}

details.custom-select.light[open] {
  z-index: 1;
}

.header_container {
  background: #0f4152;
  height: 66px;
  position: relative;
}

.header_container .profile,
.header_container .access {
  position: absolute;
}

.header_container .profile {
  left: 15px;
}

.header_container .access {
  right: 15px;
}

.header_container .inner_header_container {
  position: relative;
}

.header_container .inner_header_container .logo {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.sidebar_container {
  background: #0f4152;
  height: 66px;
  position: absolute;
  width: 100%;
  left: 0%;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  top:-100%;
}

.search_container {
  position: relative;
  background: #ffffff;
  -webkit-box-shadow: 0px 2px 52px -9px rgba(32, 63, 178, 0.201513);
          box-shadow: 0px 2px 52px -9px rgba(32, 63, 178, 0.201513);
  border-radius: 10px;
  margin-top: 3rem;
  padding: 1.6rem 2.5rem;
}

.search_container .pay_card {
  border: 1px solid rgba(151, 151, 151, 0.5);
  background: #ffffff;
  -webkit-box-shadow: 0px 2px 52px -9px #d3d3d3;
          box-shadow: 0px 2px 52px -9px #d3d3d3;
  border-radius: 20px;
  position: relative;
  padding: 2.5rem 1rem 2rem 1rem;
}

.search_container .pay_card .bs_val {
  position: absolute;
  top: -1rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  background: #0f4152;
  max-width: 150px;
  color: #fff;
  text-align: center;
  border-radius: 8px;
  padding: 0.2rem 0.8rem;
}

.search_container .pay_card .bs_val.rtl {
  top: -0.8rem;
}

.search_container .pay_card.bs {
  border: 1px solid #0f4152;
}

.search_container .back {
  position: absolute;
  top: 0%;
  left: -5.5rem;
  width: 80px;
}
#tranzila{
  width:100%;
  height:500px;
}
#closeiframe{
  margin-right:2%;background-color: #FFBF31; margin-bottom: 14px; float: right;
}
.container-fluid
{
  margin-right:5%
}
.subscription_container {
  overflow-y: auto;
}

.subscription_container::-webkit-scrollbar {
  width: 7px;
}

.subscription_container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.subscription_container::-webkit-scrollbar-thumb {
  background: #0f4152;
  border-radius: 10px;
}

.subscription_container .cal_details {
  max-width: 800px;
}

.subscription_container .cal_details .inner_cal_details {
  background-color: #eceef4;
  position: relative;
}

.subscription_container .cal_details .inner_cal_details .drop_down {
  position: absolute;
  top: 50%;
  left: -5rem;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.subscription_container .cal_details .line_ver {
  border: 1px dashed #00000049;
}

.subscription_container .cal_details .form_sec input {
  height: 55px;
  padding: 0rem 1.2rem;
  background: #ffffff;
  border: 1px solid #cdcfd6;
  border-radius: 10px;
  color: #626262;
}

.subscription_container .cal_details .form_sec select {
  height: 55px;
  padding: 0rem 1.2rem;
  background: #ffffff;
  border: 1px solid #cdcfd6;
  border-radius: 10px;
  color: #626262;
}

.subscription_container .cal_details .form_sec .selectdiv {
  position: relative;
  width: 100%;
}

.subscription_container .cal_details .form_sec select::-ms-expand {
  display: none;
}

.subscription_container .cal_details .form_sec .selectdiv:after {
  content: ">";
  font: 22px "Consolas", monospace;
  color: #0f4152;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  right: 15px;
  top: 15px;
  position: absolute;
  pointer-events: none;
  font-weight: 600;
}

.subscription_container .cal_details .form_sec .selectdiv.rtl:after {
  left: 15px;
  right: auto;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.subscription_container .cal_details .form_sec .selectdiv select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  width: 100%;
  font-size: 16px;
  line-height: 1.75;
  background-color: #ffffff;
  background-image: none;
  -ms-word-break: normal;
  word-break: normal;
}

.subscription_container .cal_details .date_cal .abs_icon {
  position: absolute;
  right: 15px;
  top: 45%;
}

.subscription_container .cal_details .date_cal .abs_icon img {
  max-width: 22px;
}
.abs_icon
{
  float:left
}

.subscription_container .cal_details .date_cal input[type="date"]::-webkit-inner-spin-button,
.subscription_container .cal_details .date_cal input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.subscription_container .save_btn {
  margin-top: -2rem;
}

@media screen and (max-width: 768px) {
  .search_container {
    padding: 1rem;
    margin-top: 1rem;
  }
  .searched_list_container {
    margin-top: 1rem;
  }
  .searched_list_container .header {
    padding: 1.2rem 1rem 0rem 1rem;
  }
  .searched_list_container .tabs_container,
  .searched_list_container .searched_list_body_cont {
    width: 100%;
    overflow-x: auto;
  }
  .searched_list_container .tabs {
    min-width: 600px;
  }
  .searched_list_container .searched_list_body {
    min-width: 1000px;
    height: calc(100vh - 530px);
    overflow-y: auto;
  }
  .subscription_container {
    height: 100%;
  }
  .subscription_container .save_btn {
    margin-top: 1rem;
  }
}
