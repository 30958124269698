@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@100.R.R900&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");
* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}

body {
  background: #ebecf1;
}

.Rtry{
  color:red
}

 .Rleft {
  width: calc(100% - 510px);
  height: 100vh;
  background-image: url("../../img/login.png");
  background-position: 100% 100%;
  background-size: 100% 100%;
  object-fit: cover;
  background-repeat: no-repeat;
}
 .Rright {
  width: 510px;
  position: relative;
  height: 100vh;
  padding: 43px 49px;
}
 .Rright .Rlang,
.Rregister .Rinner .Rlang {
  width: 97px;
  height: 32px;
  gap: 0px;
  border-radius: 7px;
  border: 1px 0px 0px 0px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #0f4152;
  padding: 0px 15px;
  cursor: pointer;
  float: right;
  position: relative;
  margin-bottom: 49px;
}

.Rright .Rlang.focus-visible,
.Rregister .Rinner .Rlang.focus-visible {
  outline: 2px solid #1d1b1b; /* White focus ring */
  outline-offset: 3px; /* Space between button and outline */
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.5); /* Optional shadow effect */
}
 .Rright .show .Rdropdown {
  display: block;
}
 .Rright .Rlang p,
.Rregister .Rinner .Rlang p {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 33px;
  color: #0f4152;
}

 .Rright .Rlang .Rdropdown,
.Rregister .Rinner .Rlang .Rdropdown {
  position: absolute;
  bottom: -115%;
  border-radius: 7px;
  border: 1px 0px 0px 0px;
  background-color: white;
  right: 0px;
  border: 1px solid #0f4152;
  width: 100%;
  display: none;
}
 .Rright .Rlang .show,
.Rregister .Rinner .Rlang .show {
  display: block;
}
 .Rright .Rlang .Rdropdown a,
.Rregister .Rinner .Rlang .Rdropdown a {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 33px;
  color: #0f4152;
  text-decoration: none;
  display: flex;
  align-items: center;
  width: 97px;
  height: 32px;
  justify-content: center;
}
.Rregister .Rinner form .RdontHaveAccount {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #626262;
}
.Rregister .Rinner form .RdontHaveAccount a {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #626262;
}
 .Rright .RlogoImg,
.Rregister .Rinner .RlogoImg {
  margin: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 55px;
}
.Rregister .Rinner .RlogoImg,
.Rregister .Rinner .Rlang {
  margin-bottom: 12px !important;
}
 .Rright .RlogoImg .Rlogo,
.Rregister .Rinner .RlogoImg {
  text-decoration: none;
}
 .Rright h1 {
  font-family: "Heebo", sans-serif;
  font-size: 28px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #0f4152;
  margin-bottom: 5px;
}
 .Rright h5 {
  font-family: "Heebo", sans-serif;
  font-size: 28px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #0f4152;
  margin-bottom: 27px;
}

 .Rright form {
  width: 100%;
}
 .Rright form .Rinput {
  width: 100%;
  height: 50px;
  border: 1px 0px 0px 0px;

  border: 1px solid #cdcfd6;
  background: #ffffff;
  margin-bottom: 13px;
  border-radius: 7px;
  padding: 0px 23px;
  outline: none;
  box-shadow: none;
}

 .Rright form .Rrow,
.Rregister .Rinner form .Rrow {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  margin-bottom: 10px;
}
 .Rright form .Rrow .Rcustom-checkbox,
.Rregister .Rinner form .Rrow .Rcustom-checkbox {
  position: relative;
  display: inline-block;
  cursor: pointer;
  font-size: 16px;
}

 .Rright form .Rrow .Rcustom-checkbox input,
.Rregister .Rinner form .Rrow .Rcustom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
 .Rright form .Rrow .Rcustom-checkbox p,
.Rregister .Rinner form .Rrow .Rcustom-checkbox p {
  margin-left: 35px;
  font-family: "Heebo", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #626262;
}
.Rregister .Rinner form .Rrow .Rcustom-checkbox p a {
  font-family: "Heebo", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #626262;
}
 .Rright form .Rrow .Rcustom-checkbox .Rcheckmark,
.Rregister .Rinner form .Rrow .Rcustom-checkbox .Rcheckmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: 1px solid #15151524;
  border-radius: 5px;
}

 .Rright form .Rrow .Rcustom-checkbox:hover input ~ .Rcheckmark,
.Rregister .Rinner form .Rrow .Rcustom-checkbox:hover input ~ .Rcheckmark {
  background-color: #0f4152;
}

 .Rright form .Rcustom-checkbox input:checked ~ .Rcheckmark,
.Rregister .Rinner form .Rrow .Rcustom-checkbox input:checked ~ .Rcheckmark {
  background-color: #0f4152;
}

 .Rright form .Rrow .Rcustom-checkbox .Rcheckmark:after,
.Rregister .Rinner form .Rrow .Rcustom-checkbox .Rcheckmark:after {
  content: "";
  position: absolute;
  display: none;
}

 .Rright form .Rrow .Rcustom-checkbox input:checked ~ .Rcheckmark:after,
.Rregister .Rinner form .Rrow .Rcustom-checkbox input:checked ~ .Rcheckmark:after {
  display: block;
}

 .Rright form .Rrow .Rcustom-checkbox .Rcheckmark:after,
.Rregister .Rinner form .Rrow .Rcustom-checkbox .Rcheckmark:after {
  left: 5px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
 .Rright form .Rrow button,
.Rregister .Rinner form .Rrow button {
  width: 168px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0px;
  background: #097083;

  border-radius: 35px;
  border: none;
  outline: none;
  box-shadow: none;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.R96px;
  text-align: center;
  color: #fff;
  cursor: pointer;
}
.Rright form .Rrow button.focus-visible  ,
.Rregister .Rinner form .Rrow button.focus-visible {
  outline: 2px solid #1d1b1b; /* White focus ring */
  outline-offset: 3px; /* Space between button and outline */
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.5); /* Optional shadow effect */
}
 .Rright form .RfPassword {
  font-family: "Heebo", sans-serif;
  font-size: 13px;
  font-weight: 400;
  width: 168px;
  line-height: 19.R09px;
  text-align: center;
  float: right;
  color: #626262;
  margin-bottom: 27px;
}
 .Rright form .Rline,
.Rregister .Rinner form .Rline {
  display: block;
  width: 100%;
  height: 1px;
  margin-top: 27px;
  border: 1px 0px 0px 0px;
  margin-bottom: 22px;
  border: 1px solid #979797;
}

 .Rright form .RdontHaveAccount {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content:center;
  text-align: right;
}
 .Rright form .RdontHaveAccount p {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #626262;
  text-align: center;
}
 .Rright form .RdontHaveAccount p a {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #0f4152;
}

 .Rright .Raccessiblity {
  position: absolute;
  bottom: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0px;
  right: 0px;
  margin-left: auto;
  margin-right: auto;
}
 .Rright .Raccessiblity p {
  display: flex;
  align-items: center;
  justify-content: center;
}

 .Rright .Raccessiblity p img {
  margin-right: 17px;
}
 .Rright .Raccessiblity p a {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 33px;
  text-align: left;
  color: #0f4152;
}
.Rcheckmark:focus-visible {
  outline: 2px solid #0a7a8f;
  outline-offset: 2px;
  border-radius: 4px; /* Adjust if needed */
}
/* registertaion css here ====== */
.Rregister {
  display: flex;
  min-height: 100vh;
  padding-top: 40px;
  padding-bottom: 40px;
  align-items: center;
  justify-content: center;
}
.Rregister .Rinner {
  max-width: 644px;
  width: 90%;
  margin: 0px auto;
  height: auto;
  box-shadow: 0px 2px 52px 0px #d3d3d3;
  border-radius: 7px;
  background: #fff;
  padding: 22px;
}
.Rregister .Rinner .Rpara {
  font-family: "Heebo", sans-serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #0f4152;
  margin-bottom: 16px;
}
.Rregister .Rinner .RpUserTop{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
}
.Rregister .Rinner .RpUserTop .RprivateUser {
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 33px;
  text-align: center;
  color: #ff0000;

}
.Rregister .Rinner h1 {
  font-family: "Heebo", sans-serif;
  font-size: 22px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #0f4152;
  margin-bottom: 21px;
}
.Rregister .Rinner form {
  max-width: 369px;
  width: 100%;
  margin: auto;
}

/* Custom styles for the select dropdown */
.Rregister .Rinner form .Rcustom-select {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}
.Rregister .Rinner form .Rcustom-select .Rselect-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 13px;
}

.Rregister .Rinner form .Rcustom-select select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-right: 20px;
  width: 100%;
  height: 50px;
  background: #ffffff;
  border: 1px solid #0f4152;
  padding: 0px 27px;
  outline: none;
  box-shadow: none;
  border-radius: 7px;

  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
}
.Rregister .Rinner form .Rselect-icon {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 100%;
  pointer-events: none; 
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.Rregister .Rinner form .Rinput {
  width: 100%;
  height: 50px;
  padding: 0px 27px;
  background: #ffffff;
  border: 1px solid #cdcfd6;
  border-radius: 7px;
  outline: none;
  box-shadow: none;
  margin-bottom: 12px;
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #626262;
}

.Rregister .Rinner form input::placeholder,
.Rregister .Rinner form input option[disabled] {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #626262;
}
.Rregister .Rinner form .RdontHaveAccount {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;

  line-height: 24px;
  text-align: center;
  color: #626262;
}
.Rregister .Rinner form .RdontHaveAccount a {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  padding: 5px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #626262;
}
@media (max-width: 1100px) {
   .Rleft {
    width: calc(100% - 410px);
  }
   .Rright {
    width: 410px;
  }
   .Rright .Raccessiblity{
    position: static;
    margin-top: 100px;
    padding-bottom: 40px;
  }
}

@media (max-width: 800px) {
   .Rleft {
    display: none;
  }
   .Rright {
    width: 100%;
    padding: 30px 5%;
  }
   .Rright form .Rrow .Rcustom-checkbox p {
    margin-left: 29px;
  }
   .Rright form .Rrow button,
   .Rright form .RfPassword {
    width: 158px;
  }
}
