@import url("https://fonts.googleapis.com/css2?family=Assistant:wght@200..800&family=Heebo:wght@100..900&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");
.error-border {
  border: 1px solid rgba(255, 0, 0, 0.637)  !important ;
  }
* {
  padding: 0px;

  margin: 0px;
  box-sizing: border-box;
}

body {

  background: #ebecf1;
}
.nav-link {
  font-weight: bold !important;
}
.mobileMenuTop {
  position: fixed;
  top: 0px;
  left: -100%;
  transition: 0.4s;
  width: 100%;
  bottom: 0px;
  z-index: 99999999;
  background: rgba(53, 53, 53, 0.9);
}
.header .right .icons:nth-last-child(2) {
  margin-left: 14px;
}
.mobileMenuTop .inner {
  position: relative;
  max-width: 364px;
  width: 90%;
  bottom: 0px;
  height: 100vh;
  background: #097a8f;
  padding: 22px;
  overflow-y: auto;
}

.mobileMenuTop .inner .headerText {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 42px;
}

.mobileMenuTop .inner .headerText .logo {
  cursor: pointer;
}
.logo-button {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}
button{
  border: 0px;
  background: transparent;
}
button:hover{
  cursor: pointer;
  opacity: 0.9;
}

.logo-button:focus {
  outline: 2px solid blue; /* For focus indication */
}

.logo {
  display: block; /* Ensures the image behaves like an inline element */
}
.mobileMenuTop .inner .headerText .switchBtn {
  width: 187px;
  height: 44px;
  border-radius: 40px;
  background: #2599af;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mobileMenuTop .inner .headerText .switchBtn a {
  width: 99px;
  height: 44px;
  border-radius: 40px;

  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Heebo", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 46px;
  text-align: center;
  color: #ffffff;
  text-decoration: none;
}

.mobileMenuTop .inner .headerText .switchBtn a.active {
  background: #02343d;
}

.mobileMenuTop .inner ul {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.mobileMenuTop .inner ul li {
  margin: 0px 0px 7px 51px;
  list-style-type: none;
  width: 100%;
}

.mobileMenuTop .inner ul li a {
  font-family: "Heebo", sans-serif;
  font-size: 24px;
  font-weight: 400;
  width: 100%;
  display: block;
  line-height: 46px;
  text-align: left;
  text-decoration: none;
  color: #fff;
}

.mobileMenuTop .inner ul .upgrade a {
  width: 179px;
  height: 48px;
  gap: 0px;

  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Assistant", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 18.31px;
  text-align: center;
  background: #097185;
  border-radius: 35px;
  color: white;
}




.custom-pagination {
  display: flex;
  justify-content: center;
  padding: 1em 0;
  list-style: none;
}


.custom-pagination .MuiPaginationItem-root {
  margin: 0 0.25em;
  padding: 0.5em 0.75em;
  text-decoration: none;
  color: #2596be;
  border: 1px solid #dee2e6;

  transition: background-color 0.3s, color 0.3s;
}

.custom-pagination .Mui-selected {
  color: #fff !important;
  background-color: #2596be !important;
  border-color: #2596be !important;
}

.custom-pagination .MuiPaginationItem-page:hover {
  background-color: #e9ecef;
  color: #2596be;
  border-color: #dae0e5;
}

.custom-pagination .MuiPaginationItem-page.Mui-disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6;
}



.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 5%;
  background: #097185;
}
.header .Admin {
  padding: 0px 5% 0px 0%!important;
}
.header .left {
  display: flex;
  align-items: center;
}

.header .left .mobileMenu {
  display: none;
}

.header .left .logo {
  margin-right: 39px;
}

.header .left ul {
  display: flex;
  align-items: center;
}

.header .left ul li {
  margin-right: 39px;
  list-style-type: none;
}

.header .left ul li a {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 33px;
  cursor: pointer;
  text-align: left;
  text-decoration: none;
  color: #fff;
}

.header .left ul li:nth-last-child(1) a {
  width: 103.09px;
  height: 35px;
  gap: 0px;

  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Assistant", sans-serif;
  font-size: 14px;
  line-height: 18.31px;
  text-align: center;
  background: #0a7f95;
  border-radius: 35px;
  color: white;
}

.header .right {
  display: flex;
  align-items: center;
}

.header .right .logoInst {
  width: 79px;
  height: 66px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header .right .logoInst img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  display: block;
}
.header .right .lang {
  width: 97px;
  height: 32px;
  gap: 0px;
  border-radius: 5px;
  margin-right: auto;
  border: 1px 0px 0px 0px;
  display: flex;

  align-items: center;
  justify-content: space-between;
  border: 1px solid #fff;
  padding: 0px 15px;
  cursor: pointer;
  position: relative;
  margin-right: 29px;
}

.header .right .lang p {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 33px;
  color: #fff;
}

.header .right .lang .dropdown {
  position: absolute;
  bottom: -115%;
  border-radius: 7px;
  border: 1px 0px 0px 0px;

  right: 0px;
  background: #097185;
  border: 1px solid #fff;
  width: 100%;
  display: none;
}

.header .right .lang .show {
  display: block;
  z-index: 9999;
}

.header .right .lang .dropdown a {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 33px;
  color: #fff;
  text-decoration: none;
  display: flex;
  align-items: center;
  width: 97px;
  height: 32px;
  justify-content: center;
}

.header .right .icons:nth-last-child(2) {
  margin-right: 14px;
}


.top .right .accessiblity {
  position: absolute;
  bottom: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0px;
  right: 0px;
  margin-left: auto;
  margin-right: auto;
}
.top .right .accessiblity p {
  display: flex;
  align-items: center;
  justify-content: center;
}

.top .right .accessiblity p img {
  margin-right: 17px;
}
.top .right .accessiblity p a {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 33px;
  text-align: left;
  color: #0f4152;
}

.mainContent {
  max-width: 1140px;
  width: 90%;
  margin: auto;
  margin-bottom: 0px;
}

.mainContent .topHeader {
  width: 100%;
  padding: 25px 18px;
  box-shadow: 0px 0px 30px 0px #0000001f;
  background: #fff;
  border-radius: 0px 0px 10px 10px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.mainContent .topHeader .backPage  {
  position: absolute;
  right: -70px;
  bottom: 15px;
  width: 51px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #1d9da2;
  background: #ffffff;
  cursor: pointer;
  border-radius: 40px;
}

.mainContent .topHeader .left h2 {
  font-family: "Heebo", sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  color: #0f4152;
}

.mainContent .topHeader .left p {
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #0f4152;
  margin-bottom: 0px !important;
}

.mainContent .topHeader .right {
  display: flex;
  align-items: center;
}

.mainContent .topHeader .right .text {
  margin-right: 13px;
}

.mainContent .topHeader .right .text h2 {
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  color: #0f4152;
}

.mainContent .topHeader .right .text p {
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  color: #0f4152;
}

.mainContent .topHeader .right button {
  width: 182px;
  height: 44px;
  gap: 0px;

  display: flex;
  align-items: center;
  justify-content: center;
  background: #097285;
  border-radius: 35px;

  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 23.5px;
  text-align: center;
  color: #fff;
  /* border: none;
  outline: none; */
  box-shadow: none;
}
.mainContent .topHeader .right button.focus-visible{
  border: 2px solid #1a0707
}
.mainContent .topHeader .right .addLibrary button.focus-visible{
  border: 2px solid #1a0707 !important;
}

.mainContent .topContent {
  width: 100%;
  box-shadow: 0px 0px 30px 0px #0000001f;
  background: #fff;
  border-radius: 10px 10px 0px 0px;
  padding-bottom: 50px;
}

.mainContent .topContent .search,
.mainContent .progressChapter .search {
  padding: 34px 18px 21px 18px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.mainContent .topContent .search h2,
.mainContent .progressChapter .search h2 {
  font-family: "Heebo", sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  color: #0f4152;
}

.mainContent .topContent .search .custom-select {
  position: relative;
  max-width: 162px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mainContent .topContent .search .custom-select.focus-visible {
  border: 2px solid #1a0707; 
  /* outline: 2px solid blue;
  outline-offset: 2px; */
}

.editBookModal .innerContent .bodyModal form .row .custom-select {
  position: relative;
  width: calc(50% - 6px);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.editBookModal .innerContent .bodyModal form .row .custom-select.focus-visible {
  border: 0.5px solid #cdcfd6; }

.mainContent .topContent .search .custom-select .select-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 13px;
}

.editBookModal .innerContent .bodyModal form .row .custom-select .select-icon {
  position: absolute;
  right: 14px;
}

.mainContent .topContent .search .custom-select select,
.editBookModal .innerContent .bodyModal form .row .custom-select select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-right: 21px;
  height: 42px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #b6b6b6;
  padding: 0px 16px;
  /* outline: none; */
  box-shadow: none;
  border-radius: 35px;
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 33px;
  color: #0f4152;
}
.mainContent .topContent .search .custom-select select.focus-visible{
  border: 1px solid #1a0707;
  }
.editBookModal .innerContent .bodyModal form .row .custom-select select {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #626262;
}

.editBookModal .innerContent .bodyModal form .row .custom-select select {
  border-radius: 5px !important;
  height: 50px !important;
  background: #ffffff;
  border: 1px 0px 0px 0px;
  border: 1px solid #cdcfd6;
  border-radius: 5px;
  padding: 0px 23px;
  outline: none;
  box-shadow: none;
}

.mainContent .topContent .search .select-icon {
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 100%;
}

.mainContent .topContent .search .searchInput,
.mainContent .progressChapter .search .searchInput {
  max-width: 452px;
  width: 70%;
  height: 42px;
  cursor: pointer;
  border: 1px solid #b6b6b6;
  border-radius: 35px;
  overflow: hidden;
  display: flex;
  align-items: center;
}
.mainContent .progressChapter .search .searchInput {
  max-width: 174px;
  width: 70%;
}
.mainContent .topContent .search .searchInput input,
.mainContent .progressChapter .search .searchInput input {
  width: calc(100% - 35px);
  height: 100%;
  border-radius: 35px;
  outline: none;
  box-shadow: none;
  border: none;
  padding: 0px 14px;
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20.56px;
  color: #0f4152;
}

.mainContent .topContent .search .searchInput input::placeholder,
.mainContent .topContent .search .searchInput input option[disabled],
.mainContent .progressChapter .search .searchInput input::placeholder {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20.56px;
  color: #0f4152;
}

.mainContent .topContent .search .searchInput img {
  cursor: pointer;
}

.mainContent .topContent .search button {
  width: 119px;
  height: 44px;
  background: #59a0ad;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 23.5px;
  text-align: center;
  color: #fff;
  border-radius: 35px;
  /* border: none;
  outline: none; */
  cursor: pointer;
  box-shadow: none;
}
.mainContent .topContent .search button.focus-visible{
  border: 1px solid #1a0707;
  }

.mainContent .topContent .search button.active {
  background: #097285;
}

.mainContent .line {
  width: calc(100% - 36px);
  margin: auto;
  display: block;
  border: 1px dotted #cdcfd6;
  margin-bottom: 20px;
}

.mainContent .books {
  width: 100%;
}

.mainContent .books h1 {
  font-family: "Heebo", sans-serif;
  font-size: 28px;
  font-weight: 700;
  line-height: 41.13px;
  text-align: left;
  color: #0f4152;
  margin-left: 18px;
  margin-bottom: 15px;
}

.mainContent .books .headerBooksList {
  margin-left: 18px;
  text-align: left;
}

.mainContent .books .headerBooksList button {
  background: none;
  /* border: none; */
  /* Default border (transparent) */
  transition: border 0.3s ease, background-color 0.3s ease;
  /* outline: none; */
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-right: 20px;
  color: #0f4152;
  padding-bottom: 3px;
  cursor: pointer;
}
.mainContent .books .headerBooksList button .focus-visible {
  border-bottom: 2px solid#1a0707; 
}

/* .mainContent .books .headerBooksList button.active::after {
  content: "";
  display: block;
  width: 100%;
  margin-bottom: -3px;
  margin-top: 3px;
  height: 4px;
  border-radius: 35px;
  background: #0f4152;
} */

.mainContent .books .headerBooksList  input:nth-last-child(2) {
  margin-left: 39px;
  margin-right: 20px;
}

.mainContent .books .contentTab {
  width: 100%;
  margin-bottom: 80px;
}

.mainContent .books .contentTab .tabcontent {
}

.mainContent .books .contentTab .tabcontent .row:nth-child(1) {
  border-top: 1px solid #cdcfd6;
}

.mainContent .books .contentTab .tabcontent .row {
  width: 100%;
  padding: 13px 18px;
  border-bottom: 1px solid #cdcfd6;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.mainContent .books .contentTab .tabcontent .row .left {
  max-width: 234px;
  width: 25%;
  display: flex;
  align-items: center;
}

.mainContent .books .contentTab .tabcontent .row .left img {
  margin-left: 20px;
  width: 100%;
  margin: 10px;
  max-width: 90px; 
  height: auto; 
  object-fit: cover; 
}

.mainContent .books .contentTab .tabcontent .row .left .span h2 {
  font-family: "Heebo", sans-serif;
  font-size: 22px;
  text-align: left;
  font-weight: 700;
  line-height: 32.31px;
  color: #0f4152;
  border-bottom: 1px dotted #0a7f94;
  margin-bottom: 5px;
}



.mainContent .books .contentTab .tabcontent .row .left .span p {
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;

  color: #3d3f45;
}

.mainContent .books .contentTab .tabcontent .row .center {
  text-align: left;
  cursor: pointer;
  width: calc(100% - 530px);
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.mainContent .books .contentTab .tabcontent .row .center p {
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  line-height: 23.5px;
  color: #2d2d2d;
  margin-right: 12px;
}

.mainContent .books .contentTab .tabcontent .row .right {
  max-width: 235px;
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.mainContent .books .contentTab .tabcontent .row .right .read {
  width: 182px;
  height: 44px;
  border: 1px solid #097285;
  border-radius: 35px;
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  background: transparent;
  color: #000;
  font-weight: 700;
  line-height: 23.5px;
  text-align: center;
  color: #0b7a8e;
  margin-right: 9px;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;
}
.mainContent .books .contentTab .tabcontent .row .right .read.focus-visible {
  border: 2px solid #1a0707; /* Example keyboard focus styling */
  outline: none; /* Removes default browser focus outline */

}

.mainContent .books .contentTab .tabcontent .row .right .read img {
  margin-right: 16px;
}
button:focus {
  outline: 2px solid #007BFF; /* Visible outline when focused */
  outline-offset: 2px;        /* Space between outline and element */
}
/* .continueReading:focus,
.read:focus {
  outline: 2px solid #FF5733; 
  outline-offset: 2px;
} */
.mainContent .books .contentTab .tabcontent .row .right .continueReading {
  width: 182px;
  height: 44px;
  background: #097285;
  color: #fff;
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 23.5px;
  text-align: center;
  border-radius: 35px;
  outline: none;
  margin-right: 9px;
  box-shadow: none;
  /* border: none; */
  cursor: pointer;
}


.mainContent .books .contentTab .tabcontent .row .right .continueReading.focus-visible {
  border: 2px solid#1a0707; /* Example keyboard focus styling */
  outline: none; /* Removes default browser focus outline */
}
.mainContent .books .contentTab .tabcontent .row .right .circle,
.mainContent .progressChapter .contentTab .tabcontent .row .circle {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  border: 1px solid #0b7a8e;
  color: #097285;
  display: flex;
  align-items: center;
  /* align-items: stretch; */
  justify-content: space-around;
  font-family: "Heebo", sans-serif;
  font-size: 18px;
  font-weight: 500;
  position: relative;
  cursor: pointer;
}
 .circle.focus-visible{
  border: 2px solid #1a0707; 
}


.mainContent .progressChapter .contentTab .tabcontent .row .circle {
  font-size: 14px;
}



.mainContent .books .contentTab .tabcontent .row .right .circle .dropDownTable,
.mainContent
  .progressChapter
  .contentTab
  .tabcontent
  .row
  .circle
  .dropDownTable {
  position: absolute;
  right: -7px;
  z-index: 9999;
  box-shadow: 0px 2px 52px -9px #203fb233;
  background: #fff;
  top: 140%;
  border-radius: 7px;
  display: none;
  padding-top: 7px;
  padding-bottom: 7px;
}

.mainContent
  .books
  .contentTab
  .tabcontent
  .row
  .right
  .circle
  .dropDownTable
  .inner,
.mainContent
  .progressChapter
  .contentTab
  .tabcontent
  .row
  .circle
  .dropDownTable
  .inner {
  position: absolute;
  height: auto;
  width: 244px;
  right: -7px;
  top: 10%;
  box-shadow: 0px 2px 52px -9px #203fb233;
  background: #fff;
  border-radius: 7px;
}

.mainContent
  .books
  .contentTab
  .tabcontent
  .row
  .right
  .circle
  .dropDownTable
  .inner
  .square,
.mainContent
  .progressChapter
  .contentTab
  .tabcontent
  .row
  .circle
  .dropDownTable
  .inner
  .square {
  position: absolute;
  right: 20px;
  top: -6%;
  z-index: 1;
  width: 17px;
  height: 17px;
  transform: rotate(45deg);
  background: #fff;
}

.mainContent
  .books
  .contentTab
  .tabcontent
  .row
  .right
  .circle
  .dropDownTable
  .inner
  button,
.mainContent
  .progressChapter
  .contentTab
  .tabcontent
  .row
  .circle
  .dropDownTable
  .inner
  button {
  width: 100%;
  height: 51px;
  background: transparent;
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  cursor: pointer;
  font-weight: 400;
  position: relative;
  border: none;
  z-index: 999;
  outline: none;
  box-shadow: none;
  line-height: 33px;
  padding: 0px 20px;
  text-align: left;
  color: #0f4152;
}
.mainContent
.books
.contentTab
.tabcontent
.row
.right
.circle
.dropDownTable
.inner
button.focus-visible,
.mainContent
  .progressChapter
  .contentTab
  .tabcontent
  .row
  .circle
  .dropDownTable
  .inner
  button.focus-visible{
    border: 2px solid #1a0707;
  }



.mainContent
  .books
  .contentTab
  .tabcontent
  .row
  .right
  .circle
  .dropDownTable
  .inner
  button:hover,
.mainContent
  .progressChapter
  .contentTab
  .tabcontent
  .row
  .circle
  .dropDownTable
  .inner
  button:hover {
  background: #eaebf1;
  font-size: 14px;
  font-weight: 700;
  color: #0f4152;
}

.editBookModal,
.deleteBookModal,
.editChapterModal,
.paymentModal,
.settingModal {
  position: fixed;
  top: 0px;
  right: 0px;
  width: 100%;
  left: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999999;
  overflow-y: auto;
  display: none;
}

.paymentModal .innerContent {
  max-width: 782px;
  width: 90%;
  height: auto;
  border-radius: 10px;

  background: #ffffff;
  border-radius: 7px;
  margin: 79px auto;
  position: relative;
  padding: 62px 33px;
  padding-bottom: 80px;
}

.paymentModal .innerContent .crossPurchase {
  position: absolute;
  cursor: pointer;
  left: 33px;
  top: 33px;
}

.paymentModal .innerContent h1 {
  color: #023842;
  font-family: "Heebo", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  margin-bottom: 9px;
}

.paymentModal .innerContent p {
  font-family: "Heebo", sans-serif;
  max-width: 605px;
  width: 100%;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  margin: auto;
  text-align: center;
  color: #023842;
  margin-bottom: 42px;
}
.paymentModal .innerContent .userCards {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #b9b9b9;
  height: 106px;
  border-radius: 7px;
  padding: 0px 20px;
  margin-bottom: 42px;
  display: flex;
}
.paymentModal .innerContent .userCards .card {
  width: 25%;
  position: relative;
}
.paymentModal .innerContent .userCards .card p {
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #2d2d2d;
  margin-bottom: 6px;
}
.paymentModal .innerContent .userCards .card h6 {
  font-family: "Heebo", sans-serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #44c6cb;
}
.paymentModal .innerContent .userCards .card img {
  position: absolute;
  right: -3px;
  top: 5px;
}
.paymentModal .innerContent form {
  display: flex;
  width: 100%;
  margin-bottom: 25px;
}
.paymentModal .innerContent form .left {
  width: 167px;
  margin-right: 10px;
}
.paymentModal .innerContent form .left p {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 33px;
  color: #626262;
  margin-bottom: 0px;
  text-align: left;
}
.number-input-container {
  position: relative;
  width: 167px;
}

.number-input-container input[type="number"] {
  width: 100%;
  height: 50px;
  text-align: left;
  border: 1px solid #cdcfd6;
  outline: none;
  box-shadow: none;
  background: #fff;
  border-radius: 7px;
  font-size: 16px;
  padding-left: 10px; 
  padding-right: 40px; 
  padding-right: 13px; 
  -moz-appearance: textfield; 
}

.number-input-container input[type="number"]::-webkit-outer-spin-button,
.number-input-container input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; 
}

.number-input-container .custom-buttons {
  position: absolute;
  right: 5px;
  top: 10px;
  display: flex;
  flex-direction: column;
  height: 50px; 
}

.number-input-container button {
  width: 20px;
  height: 15px;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  background: transparent;
  line-height: 0;
}
.paymentModal .innerContent form .right {
  width: 167px;
}
.paymentModal .innerContent form .right p {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 33px;
  color: #626262;
  margin-bottom: 0px;
  text-align: left;
}

.paymentModal .innerContent form .right .custom-select {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 13px;
}

.paymentModal .innerContent form .right .custom-select .select-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 14px;
  height: 50px;
}

.paymentModal .innerContent form .right .custom-select .select-icon img {
  position: absolute;
  right: 14px;
  top: 17px;
}

.paymentModal .innerContent form .right .custom-select select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #626262;
  width: 100%;
  padding: 0px 14px;
  background: #ffffff;
  border-radius: 5px;
  height: 50px;
  border: 1px solid #cdcfd6;
  outline: none;
  box-shadow: none;
}

.paymentModal .innerContent form .right .custom-select .select-icon {
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 100%;
}

.paymentModal .innerContent .total {
  width: 100%;
  height: 51px;
  background: #e9e9e9;
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  outline: none;
  box-shadow: none;
  border-radius: 48px;
  padding: 0px 22px;
  margin-bottom: 25px;
}
.paymentModal .innerContent .purchaseBtn {
  width: 168px;
  height: 50px;
  border-radius: 48px;
  background: #0a7a8f;
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 500;
  display: block;
  line-height: 23.5px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  border: none;
  float: right;
  outline: none;
  box-shadow: none;
}
.paymentModal .innerContent .purchaseBtn.focus-visible {
  outline: 2px solid #1a0707; /* White outline for contrast */
    outline-offset: 2px; /* Creates spacing around the button */
    box-shadow: 0 0 8px rgba(255, 255, 255, 0.6); /* Optional for a glow effect */

}

.paymentModal .innerContent .total .rightt h3,
.paymentModal .innerContent .total .leftt h3 {
  font-family: "Heebo", sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 26.44px;
  color: #0a7a8f;
  width: auto;
}
.paymentModal .innerContent .total .leftt {
  display: flex;
  align-items: center;
}
.paymentModal .innerContent .total .leftt h3 {
  width: 142px;
}

.paymentModal .innerContent .total .leftt h6 {
  max-width: auto !important;
  position: relative;
  width: auto !important;
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 33px;
  color: #626262;
}
.editBookModal .innerContent {
  max-width: 682px;
  width: 90%;
  margin: 79px auto;
  background: #ffffff;
  border-radius: 10px;
  overflow: hidden;
}

.editBookModal .innerContent .headerModal {
  width: 100%;
  height: 87px;
  background: #2ca3b9;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.editBookModal .innerContent .headerModal .cancel,
.deleteBookModal .innerContent .headerModal .cancel,
.settingModal .innerContent .headerModal .cancel {
  position: absolute;
  /* left: 41px; */
  cursor: pointer;
}

.editBookModal .innerContent .headerModal h1,
.deleteBookModal .innerContent .headerModal h1,
.settingModal .innerContent .headerModal h1 {
  font-family: "Heebo", sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #fff;
}

.editBookModal .innerContent .bodyModal form {
  width: 100%;
  padding: 51px 71px;
}

.editBookModal .innerContent .bodyModal form form {
  width: 100%;
}

.editBookModal .innerContent .bodyModal form .row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}

.editBookModal .innerContent .bodyModal form .row input {
  width: calc(50% - 6px);
  height: 50px;
  background: #ffffff;
  border: 1px 0px 0px 0px;
  border: 1px solid #cdcfd6;
  border-radius: 5px;
  padding: 0px 23px;
  outline: none;
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #626262;
  box-shadow: none;
}

.editBookModal .innerContent .bodyModal form textarea {
  width: 100%;
  resize: none;
  border-radius: 5px;
  height: 129px;
  border: 1px 0px 0px 0px;
  padding: 14px 16px;
  background: #ffffff;
  border: 1px solid #cdcfd6;
  outline: none;
  box-shadow: none;
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #626262;
  margin-bottom: 12px;
}

.editBookModal .innerContent .bodyModal form textarea::placeholder {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #626262;
}

.register .inner form input::placeholder,
.register .inner form input option[disabled] {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #626262;
}

.editBookModal .innerContent .bodyModal form .deleteEditBook {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}

.editBookModal .innerContent .bodyModal form .deleteEditBook img {
  width: 33px;
  height: 45px;
  margin-right: 19px;
}

.editBookModal .innerContent .bodyModal form .deleteEditBook a {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: #c60000;
}

.editBookModal .innerContent .bodyModal form .continue {
  width: 168px;
  height: 50px;
  margin-left: auto;
  float: right;
  margin-bottom: 28px;
  cursor: pointer;
  border-radius: 35px;
  background: #097083;
  border: none;
  outline: none;
  box-shadow: none;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.96px;
  text-align: center;
  color: #fff;
}

.deleteBookModal .innerContent {
  max-width: 499px;
  width: 90%;
  border-radius: 10px;
  overflow: hidden;
  background: #ffffff;
  margin: 79px auto;
}

.deleteBookModal .innerContent .headerModal {
  width: 100%;
  height: 87px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 10px 10px 0px 0px;
  background: #b92c78;
}

.deleteBookModal .innerContent .bodyModal {
  width: 100%;
  padding: 34px;
}

.deleteBookModal .innerContent .bodyModal p {
  font-family: "Heebo", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #626262;
}

.deleteBookModal .innerContent .bodyModal .btns {
  display: flex;
  align-items: center;
  justify-content: center;
}

.deleteBookModal .innerContent .bodyModal .btns .deleteBtn {
  width: 168px;
  height: 50px;
  gap: 0px;

  border-radius: 35px;
  background: #b92c78;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.96px;
  text-align: center;
  color: #fff;
  border: none;
  outline: none;
  box-shadow: none;
  margin-right: 6px;
  cursor: pointer;
}

.deleteBookModal .innerContent .bodyModal .btns .cancelBtn {
  width: 168px;
  height: 50px;
  background: transparent;
  border-radius: 35px;
  border: 1px solid #000000;
  cursor: pointer;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.96px;
  text-align: center;
  color: #343434;
}

.editChapterModal .innerContent {
  max-width: 411px;
  width: 90%;
  margin: 79px auto;
  overflow: hidden;
  background: #fff;
  border-radius: 10px;
}

.editChapterModal .innerContent .headerModal {
  width: 100%;
  background: #2ca3b9;
  height: 87px;
  display: flex;
  padding: 0px 27px;
  align-items: center;
  justify-content: space-between;
}

.editChapterModal .innerContent .headerModal img {
  cursor: pointer;
}

.editChapterModal .innerContent .headerModal h1 {
  font-family: "Heebo", sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 24px;
  color: #fff;
}

.editChapterModal .innerContent .bodyModal {
  padding: 27px;
}

.editChapterModal .innerContent .bodyModal .row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 29px;
}

.editChapterModal .innerContent .bodyModal .row h3 {
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: #097083;
}

.editChapterModal .innerContent .bodyModal .row img {
  cursor: pointer;
}

.editChapterModal .innerContent .bodyModal .addedChapter {
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #023842;
}

.editChapterModal .innerContent .bodyModal .sureToCancel {
  margin-top: 160px;
  width: 100%;
}

.editChapterModal .innerContent .bodyModal .sureToCancel p {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  color: #b92c78;
  margin-bottom: 5px;
}

.editChapterModal .innerContent .bodyModal .sureToCancel .btnsCancel {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 42px;
}

.editChapterModal .innerContent .bodyModal .sureToCancel .btnsCancel .yesBtn {
  width: 69px;
  height: 35px;
  border-radius: 35px;
  background: #b92c78;
  border: none;
  outline: none;
  box-shadow: none;
  margin-right: 8px;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.96px;
  text-align: center;
  color: #fff;
  cursor: pointer;
}

.editChapterModal
  .innerContent
  .bodyModal
  .sureToCancel
  .btnsCancel
  .cancelBtn {
  width: 105px;
  height: 35px;
  border-radius: 35px;
  border: 1px 0px 0px 0px;
  border: 1px solid #000000;
  cursor: pointer;
  font-family: "Rubik", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.59px;
  text-align: center;
  background: transparent;
  color: #383838;
}

.editChapterModal .innerContent .bodyModal .finish {
  width: 168px;
  height: 50px;
  background: #097083;
  border: none;
  outline: none;
  box-shadow: none;
  border-radius: 35px;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.96px;
  text-align: center;
  color: #fff;
  float: right;
  margin-bottom: 23px;
}

.addBookModal,
.addLibraryModalTop,
.editLibraryModalTop,
.deleteLibraryModalTop {
  position: fixed;
  top: 0px;
  right: 0px;
  width: 100%;
  left: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999999;
  overflow-y: auto;
  display: none;
}

.addBookModal .modal-content,
.addLibraryModalTop .modal-content,
.editLibraryModalTop .modal-content,
.deleteLibraryModalTop .modal-content {
  max-width: 682px;
  background-color: #fff;
  margin: 79px auto;
  width: 90%;
  border-radius: 7px;
  overflow: hidden;
  position: relative;
}

.addLibraryModalTop .modal-content,
.editLibraryModalTop .modal-content,
.deleteLibraryModalTop .modal-content {
  max-width: 499px;

}

.addBookModal .modal-content .modal-header,
.addLibraryModalTop .modal-content .modal-header,
.editLibraryModalTop .modal-content .modal-header,
.deleteLibraryModalTop .modal-content .modal-header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2ca3b9;
  width: 100%;
  height: 87px;
}

.deleteLibraryModalTop .modal-content .modal-header {
  background: #b92c78 !important;
}

.addBookModal .modal-content .modal-header .close,
.addLibraryModalTop .modal-content .modal-header .close,
.editLibraryModalTop .modal-content .modal-header .close,
.deleteLibraryModalTop .modal-content .modal-header .close {
  position: absolute;
  left: 41px;
  cursor: pointer;
}

.addBookModal .modal-content .modal-header h2,
.addLibraryModalTop .modal-content .modal-header h2,
.editLibraryModalTop .modal-content .modal-header h2,
.deleteLibraryModalTop .modal-content .modal-header h2 {
  font-family: "Heebo", sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #fff;
}

.addBookModal .modal-content .modal-body,
.addLibraryModalTop .modal-content .modal-body,
.editLibraryModalTop .modal-content .modal-body,
.deleteLibraryModalTop .modal-content .modal-body {
  padding: 35px;
  text-align: left;
  position: relative;
}

.addBookModal .modal-content .modal-body img {
  margin-bottom: -40px;
}

.addBookModal .modal-content .modal-body p,
.addLibraryModalTop .modal-content .modal-body p,
.editLibraryModalTop .modal-content .modal-body p,
.deleteLibraryModalTop .modal-content .modal-body p {
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  color: #023842;
}

.addLibraryModalTop .modal-content .modal-body p,
.editLibraryModalTop .modal-content .modal-body p {
  text-align: center;
  margin-bottom: 25px;
}

.deleteLibraryModalTop .modal-content .modal-body p {
  text-align: center;
  margin-bottom: 25px;
}

.deleteLibraryModalTop .modal-content .modal-body p span {
  font-family: "Heebo", sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #626262;
}

.deleteLibraryModalTop .modal-content .modal-body h6 {
  font-family: "Heebo", sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #e00561;
  margin-bottom: 35px;
}

.deleteLibraryModalTop .modal-content .modal-body .btnsDeleteLib {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-evenly;
}

.deleteLibraryModalTop .modal-content .modal-body .btnsDeleteLib .cancel {
  max-width: 168px;
  width: 49%;
  height: 50px;
  border-radius: 40px;
  cursor: pointer;
  background: transparent;
  border: 1px solid #000000;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.96px;
  text-align: center;
  color: #343434;
  margin-right: 3px;
}
.deleteLibraryModalTop .modal-content .modal-body .btnsDeleteLib .cancel.focus-visible{
  border: 2px solid #1a0707;
}
.deleteLibraryModalTop .modal-content .modal-body .btnsDeleteLib .delete {
  max-width: 168px;
  width: 49%;
  height: 50px;
  cursor: pointer;
  border-radius: 40px;
  background: #b92c78;
  border: none;
  outline: none;
  box-shadow: none;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.96px;
  text-align: center;
  color: #ffffff;
}

.deleteLibraryModalTop .modal-content .modal-body .btnsDeleteLib .delete.focus-visible {
  border: 2px solid #1a0707;
}
.addLibraryModalTop .modal-content .modal-body form,
.editLibraryModalTop .modal-content .modal-body form {
  max-width: 383px;
  width: 100%;
  margin: auto;
}

.addLibraryModalTop .modal-content .modal-body form input,
.editLibraryModalTop .modal-content .modal-body form input {
  width: 100%;
  height: 50px;
  margin-bottom: 13px;
  text-align: end;
  background: #ffffff;
  border: 1px solid #cdcfd6;
  border-radius: 7px;
  padding: 0px 25px;
  outline: none;
  box-shadow: none;
}
.addLibraryModalTop .modal-content .modal-body form input.focus-visible,
.editLibraryModalTop .modal-content .modal-body form input.focus-visible {
  outline: 0.5px solid #cdcfd6;
}
.addLibraryModalTop .modal-content .modal-body form .custom-select,
.editLibraryModalTop .modal-content .modal-body form .custom-select {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 13px;
}
.addLibraryModalTop .modal-content .modal-body form .custom-select.focus-visible,
.editLibraryModalTop .modal-content .modal-body form .custom-select.focus-visible {
  outline: 0.5px solid #cdcfd6;

}

.addLibraryModalTop .modal-content .modal-body form .custom-select .select-icon,
.editLibraryModalTop
  .modal-content
  .modal-body
  form
  .custom-select
  .select-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 14px;
  height: 50px;
}

.addLibraryModalTop
  .modal-content
  .modal-body
  form
  .custom-select
  .select-icon
  img,
.editLibraryModalTop
  .modal-content
  .modal-body
  form
  .custom-select
  .select-icon
  img {
  position: absolute;
  right: 14px;
  top: 17px;
}

.addLibraryModalTop .modal-content .modal-body form .custom-select select,
.editLibraryModalTop .modal-content .modal-body form .custom-select select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #626262;
  width: 100%;
  padding: 0px 14px;
  background: #ffffff;
  border-radius: 5px;
  height: 50px;
  border: 1px solid #cdcfd6;
  outline: none;
  text-align: end;
  box-shadow: none;
}

.addLibraryModalTop .modal-content .modal-body form .custom-select .select-icon,
.editLibraryModalTop
  .modal-content
  .modal-body
  form
  .custom-select
  .select-icon {
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 100%;
}

.addLibraryModalTop .modal-content .modal-body form button,
.editLibraryModalTop .modal-content .modal-body form button {
  width: 168px;
  height: 50px;
  border-radius: 40px;
  background: #097083;
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.96px;
  text-align: center;
  float: right;
  outline: none;
  box-shadow: none;
  border: none;
  margin-bottom: 40px;
  color: #ffffff;
}
.addLibraryModalTop .modal-content .modal-body form button.focus-visible,
.editLibraryModalTop .modal-content .modal-body form button.focus-visible {
  border: 2px solid #1a0707;
}

.addBookModal .modal-content .modal-footer {
  text-align: end;
}

.addBookModal .modal-content .modal-body .step1Btn {
  position: absolute;
  right: 35px;
  bottom: 35px;
  width: 168px;
  height: 50px;
  border-radius: 35px;
  background: #097083;
  border: none;
  outline: none;
  box-shadow: none;
  cursor: pointer;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.96px;
  text-align: center;
  color: #fff;
  
}
.addBookModal .modal-content .modal-body .step1Btn.focus-visible {
  outline: 2px solid#1a0707; /* White outline for contrast */
  outline-offset: 2px; /* Creates spacing around the button */
  box-shadow: 0 0 8px rgba(255, 255, 255, 0.6); /* Optional for a glow effect */
}

#bt2{
  margin-top: 550px;
}
.addBookModal .modal-content .modal-body .headerTitle {
  font-family: "Heebo", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #023842;
  margin-bottom: 11px;
}

.addBookModal .modal-content .modal-body .headerPara {
  text-align: center;
  margin-bottom: 19px;
}

.addBookModal .modal-content .modal-body form {
  width: 100%;
  padding: 0px 40px;
}

.addBookModal .modal-content .modal-body form form {
  width: 100%;
}

.addBookModal .modal-content .modal-body form .row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}

.addBookModal .modal-content .modal-body form .row input {
  width: calc(50% - 6px);
  height: 50px;
  background: #ffffff;
  border: 1px 0px 0px 0px;
  border: 1px solid #cdcfd6;
  border-radius: 5px;
  padding: 0px 16px;
  outline: none;
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #626262;
  box-shadow: none;
}
.addBookModal .modal-content .modal-body form .row input.focus-visible {
  outline: 0.5px solid #cdcfd6;
}

.addBookModal .modal-content .modal-body form textarea {
  width: 100%;
  resize: none;
  border-radius: 5px;
  height: 129px;
  border: 1px 0px 0px 0px;
  padding: 14px 16px;
  background: #ffffff;
  border: 1px solid #cdcfd6;
  outline: none;
  box-shadow: none;
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #626262;
  margin-bottom: 19px;
}
.addBookModal .modal-content .modal-body form textarea.focus-visible {
  outline: 0.5px solid #cdcfd6;

}
.addBookModal .modal-content .modal-body form textarea::placeholder {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #626262;
}

.addBookModal .modal-content .modal-body form .row .custom-select {
  position: relative;
  width: calc(50% - 6px);
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.addBookModal .modal-content .modal-body form .custom-select .select-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 14px;
  height: 50px;
}

.addBookModal .modal-content .modal-body form .custom-select .select-icon img {
  position: absolute;
  right: 14px;
  top: 17px;
}

.addBookModal .modal-content .modal-body form .row .custom-select select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #626262;
  width: 100%;
  padding: 0px 14px;
  background: #ffffff;
  border-radius: 5px;
  height: 50px;
  border: 1px solid #cdcfd6;
  outline: none;
  box-shadow: none;
}
.addBookModal .modal-content .modal-body form .row .custom-select select.focus-visible  {
  outline: 0.5px solid #cdcfd6;
}
.addBookModal .modal-content .modal-body form .row .custom-select .select-icon {
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 100%;
}

.addBookModal .modal-content .modal-body  .addPara {
  text-align: center;
  font-weight: 500;
  color: #023842;
  cursor: pointer;
  display: flex;
  margin-bottom: 153px;
  border: none;
  outline: none;
  align-items: center;
  flex-direction: column-reverse;
}
.addBookModal .modal-content .modal-body .addPara.focus-visible {
  outline: 2px solid #1a0707;
  outline-offset: 2px;
}



.fileUploadCancel {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 13px;
}

.fileUploadCancel .redCross {
  cursor: pointer;
}

.custom-upload button {
  position: relative;
  /* overflow: hidden; */
  display: flex;
  align-items: center;
  margin-bottom: 9px;
  width: 139px;
  height: 38px;
  border-radius: 35px;
  border: 1px solid #2ca3b9;
  cursor: pointer;
  color: #2ca3b9;
  background: transparent;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.96px;
  text-align: center;
  justify-content: center;
}
.custom-upload label {
  position: relative;
  /* overflow: hidden; */
  display: flex;
  align-items: center;
  margin-bottom: 9px;
  width: 139px;
  height: 38px;
  border-radius: 35px;
  border: 1px solid #2ca3b9;
  cursor: pointer;
  color: #2ca3b9;
  background: transparent;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.96px;
  text-align: center;
  justify-content: center;
}
.custom-upload button.focus-visible{
  border: 2px solid #0a0a0a;
}
.custom-upload label.focus-visible{
  border: 2px solid #0a0a0a;
}

.custom-upload .fileName {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: #626262;
  margin-right: 18px;
}

.custom-upload input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
  height: 100%;
  width: 100%;
}


.custom-upload  {
  display: flex;
}

.custom-upload  input[type="file"].focus-visible{
  border: 2px solid #0a0a0a;
}

.custom-upload button img {
  margin-right: 9px;
  margin-bottom: 0px !important;
}
.custom-upload label img {
  margin-right: 9px;
  margin-bottom: 0px !important;
}
.custom-upload img {
  margin-right: 9px;
  margin-bottom: 0px !important;
}
.custom-upload.focus-visible {
  outline: 2px solid #0a0a0a;
  outline-offset: 2px;
}
/* *.focus-visible {
  outline: 2px solid #1a0707; 
  outline-offset: 2px;
} */

/* Optional: Style the select file button specifically */
/* .select-file-button.focus-visible {
  background-color: #e0f7fa;
  border-color: #00796b;
} */

.fileName {
  display: inline-block;
  margin-right: 10px;
}

.addBookModal .modal-content .modal-body form .modal-footer .back,
.addBookModal .modal-content .modal-body .back {
  width: 168px;
  height: 50px;
  border-radius: 35px;
  border: 1px 0px 0px 0px;
  border: 1px solid #097083;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.96px;
  text-align: center;
  color: #097083;
  cursor: pointer;
  background: transparent;
  margin-right: 9px;
}
.addBookModal .modal-content .modal-body form .modal-footer .back.focus-visible,
.addBookModal .modal-content .modal-body .back:focus-visible {
  outline: 2px solid#1a0707;
}

.addBookModal .modal-content .modal-body .successUpload {
  display: block;
  margin: auto;
}

.addBookModal .modal-content .modal-body form .modal-footer .continueBtn,
.addBookModal .modal-content .modal-body .continueBtn {
  width: 168px;
  height: 50px;
  border-radius: 35px;
  background: #097083;
  border: none;
  cursor: pointer;
  outline: none;
  box-shadow: none;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.96px;
  text-align: center;
  color: #fff;
}
.addBookModal .modal-content .modal-body form .modal-footer .continueBtn.focus-visible,
.addBookModal .modal-content .modal-body .continueBtn.focus-visible {
  border: 2px solid #1a0707; /* White focus ring */
  outline-offset: 12px;  
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.5); 
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.mainContent .contentArea {
  width: 100%;
  box-shadow: 0px 0px 30px 0px #0000001f;
  background: #fff;
  border-radius: 0px;
  min-height: 92vh;
}

.mainContent .contentArea .headerTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 46px;
  border: none;
}

.mainContent .contentArea .headerTop h2 {
  font-family: "Heebo", sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 24px;
  color: #0f4152;
}

.mainContent .contentArea .headerTop .timeStamp {
  padding: 0px 26px;
  height: 45px;
  border-radius: 59px;
  background: #f1f1f1;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mainContent .contentArea .headerTop .timeStamp h3 {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 20.56px;
  text-align: left;
  color: #2d2d2d;
  margin-right: 9px;
}

.mainContent .contentArea .headerTop .timeStamp h3 span {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20.56px;
  text-align: left;
  color: #2d2d2d;
}

.mainContent .contentArea .progress {
  width: 100%;
}

.mainContent .contentArea .progress .myAccountList {
  width: 100%;
  text-align: left;
   border-bottom: 1px solid #acacac;
}

.mainContent .contentArea .progress .myAccountList button {
 
  margin-left: 22px;
  padding-bottom: 4px;
  background: transparent;
  outline: none;
  box-shadow: none;
  border: none;
  width: 108px;
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #5f5f5f;
  cursor: pointer;
}

.mainContent .contentArea .progress .myAccountList button.active {
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: #0a7a8f; 
  /* border-bottom: 2px solid #0a7a8f;  */

}
.mainContent .contentArea .progress .myAccountList button.focus-visible {
  border-bottom: 2px solid #0a7a8f; /* Keep active underline */
  outline: none; 
}

/* .mainContent .contentArea .progress .myAccountList button.active::after {
  content: "";
  display: block;
  width: 108px;
  height: 4px;
  margin-bottom: -4px;
  margin-top: 3px;
  border-radius: 35px;
  background: #0a7a8f;
} */
.mainContent .contentArea .progress .myAccountList button.active:not(.focus-visible)::after {
  content: "";
  display: block;
  width: 108px;
  height: 4px;
  margin-bottom: -4px;
  margin-top: 3px;
  border-radius: 35px;
  background: #0a7a8f;
}

.mainContent .contentArea .progress .myAccountList button:nth-last-child(1) {
  margin-left: 5px !important;
}

.mainContent .contentArea .progress .tabContentTop {
  width: 100%;
  padding: 45px;
}
 #progress {display: block;}
.mainContent .contentArea .progress .tabContentTop .tabcontent {
  display: none;
}

.mainContent .contentArea .progress .tabContentTop .tabcontent .row {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.mainContent .contentArea .progress .tabContentTop .tabcontent .row .card {
  max-width: 349px;
  width: 32%;
  height: 383px;
  overflow: hidden;
  background: #f1f1f1;
  border-radius: 35px;
  padding: 24px 24px 0px 24px;
  position: relative;
}

.mainContent .contentArea .progress .tabContentTop .tabcontent .row .card p {
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  text-align: center;
  color: #0f4152;
  margin-bottom: 6px;
}

.mainContent .contentArea .progress .tabContentTop .tabcontent .row .card h2 {
  font-family: "Heebo", sans-serif;
  font-size: 26px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #0a7a8f;
}

.mainContent
  .contentArea
  .progress
  .tabContentTop
  .tabcontent
  .row
  .card
  .bottomImg {
  position: absolute;
  bottom: -58px;
  left: 0px;
  right: 0px;
  margin: auto;
}

.mainContent
  .contentArea
  .progress
  .tabContentTop
  .tabcontent
  .row
  .card
  .rowTick {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mainContent
  .contentArea
  .progress
  .tabContentTop
  .tabcontent
  .row
  .card
  .rowTick
  img {
  margin: 0px 2px;
}

.mainContent .contentArea .progress .tabContentTop .tabcontent form {
  max-width: 344px;
  width: 100%;
  flex-wrap: wrap;
}

.mainContent .contentArea .progress .tabContentTop .tabcontent form h2 {
  font-family: "Heebo", sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  color: #0f4152;
  margin-bottom: 18px;
}

.mainContent .contentArea .progress .tabContentTop .tabcontent form input {
  width: 100%;
  height: 50px;
  outline: none;
  box-shadow: none;
  border-radius: 7px;
  background: #ffffff;
  border: 1px solid #cdcfd6;
  margin-bottom: 7px;
  padding: 0px 23px;
}

.mainContent
  .contentArea
  .progress
  .tabContentTop
  .tabcontent
  form
  input::placeholder {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #626262;
}

.mainContent .contentArea .progress .tabContentTop .tabcontent form .formBtn {
  text-align: right;
}

.mainContent
  .contentArea
  .progress
  .tabContentTop
  .tabcontent
  form
  .formBtn
  button {
  width: 168px;
  height: 50px;
  border-radius: 35px;
  background: #0a7a8f;
  border: none;
  outline: none;
  cursor: pointer;
  box-shadow: none;
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 23.5px;
  text-align: center;
  color: #ffffff;
}
.mainContent
  .contentArea
  .progress
  .tabContentTop
  .tabcontent
  form
  .formBtn
  button.focus-visible{
    outline: 2px solid #1a0707; /* White outline for contrast */
    outline-offset: 2px; /* Creates spacing around the button */
    box-shadow: 0 0 8px rgba(255, 255, 255, 0.6); /* Optional for a glow effect */

  }
.mainContent .individualHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mainContent .individualHeader .left h2 {
  font-family: "Heebo", sans-serif;
  font-size: 28px;
  font-weight: 700;
  line-height: 24px;
  color: #0f4152;
  margin-right: 35px;
}

.mainContent .individualHeader .left h2 span {
  font-family: "Heebo", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  color: #0f4152;
}

.mainContent .individualHeader .right {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mainContent .individualHeader .totalUser {
  height: 59px;
  border-radius: 40px;
  background: #f1f1f1;
  padding: 0px 31px;
  border-radius: 59px;

}

.mainContent .individualHeader .totalUser h4 {
  font-family: "Heebo", sans-serif;
  font-size: 22px;
  font-weight: 700;
  line-height: 24px;
  color: #0f4152;
  margin-right: 22px;
}

.mainContent .individualHeader .totalUser p {
  font-family: "Heebo", sans-serif;
  font-size: 18px;
  font-weight: 300;
  line-height: 24px;
  color: #0f4152;
}


.mainContent .individualHeader .totalUser p span {
  font-family: "Heebo", sans-serif;
  font-size: 26px;
  font-weight: 700;
  line-height: 24px;
  color: #0a7a8f;
}
.mainContent .individualHeader .right .progressLine{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
}
.mainContent .individualHeader .right .progressLine p {
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 23.5px;
  text-align: center;
  color: #2d2d2d;
  width: 178px;
  margin-bottom: 3px;
}

.mainContent .individualHeader .right .progressLine .grayLine {
  width: 100px;
  height: 4px;
  border-radius: 35px;
  margin-right: 41px;
  background: #d8d8d8;
  position: relative;
  overflow: hidden;
}

.mainContent .individualHeader .right .progressLine .grayLine .insideLine {
  background: #0a7a8f;
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
  border-radius: 35px;
}

.mainContent .individualHeader .right .btn {
  display: flex;
  align-items: center;
}

.mainContent .individualHeader .right .btn h2 {
  font-family: "Heebo", sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 26.44px;
  color: #0f4152;
  margin-right: 9px;
}

.mainContent .individualHeader .right .btn button {
  width: 157px;
  height: 44px;
  border-radius: 35px;
  background: #097285;
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 23.5px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}
.mainContent .individualHeader .right .btn button.focus-visible{
  border: 2px solid #1a0707;
}
.mainContent .progressChapter {
  width: 100%;
  box-shadow: 0px 0px 30px 0px #0000001f;
  background: #fff;
  border-radius: 10px 10px 0px 0px;
  padding-bottom: 50px;
}

.mainContent .progressChapter .chapterList {
  text-align: start;
  border-bottom: 1px solid #acacac;
}

.mainContent .progressChapter .chapterList button {
  margin-left: 18px;
  border: none;
  outline: none;
  box-shadow: none;
  background: transparent;
  padding-bottom: 3px;
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  width: 120px;
  font-weight: 400;
  line-height: 24px;
  position: relative;
  color: #5f5f5f;
  cursor: pointer;
}

.mainContent .progressChapter .chapterList {
  width: 100%;
}
.mainContent .progressChapter .chapterList button.focus-visible{
  border-bottom: 2px solid #1a0707;
}

.mainContent .progressChapter .chapterList button.active:not(.focus-visible)::after{
  content: "";
  display: block;
  width: 120px;
  height: 4px;
  margin-bottom: -4px;
  margin-left: 0px;
  margin-top: 3px;
  border-radius: 35px;
  background: #0a7a8f;
}

.mainContent .progressChapter .chapterList button.active {
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #0a7a8f;
}

.mainContent .progressChapter .contentTab {
  width: 100%;
  margin-bottom: 50px;
}

.mainContent .progressChapter .contentTab .tabcontent .row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 18px;
  border-bottom: 1px solid #cdcfd6;
}
.mainContent .addLibTop .contentTab .tabcontent .row {
  padding: 5px 18px;
  height: 53px;
}
.mainContent .progressChapter .contentTab .tabcontent .row .circle {
  width: 31px;
  height: 31px;
}
.completedBookProgress
  .mainContent
  .progressChapter
  .contentTab
  .tabcontent
  .row
  h2 {
  font-family: "Heebo", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  width: 120px;
  color: #b8b8b8;
}

.mainContent .progressChapter .contentTab .tabcontent .row .progressBar {
  display: flex;
  align-items: center;
  width: 284px;
  margin-right: 35px;
    
}

.mainContent
  .progressChapter
  .contentTab
  .tabcontent
  .row
  .progressBar
  p:nth-child(1) {
  width: 130px;
}

.mainContent
  .progressChapter
  .contentTab
  .tabcontent
  .row
  .progressBar
  .progressTopLine {
  position: relative;
  width: 133px;
  height: 5px;
  background: #d8d8d8;
  border-radius: 35px;
  margin-right: 48px;
  overflow: hidden;
  
}

.mainContent
  .progressChapter
  .contentTab
  .tabcontent
  .row
  .progressBar
  .progressTopLine
  .progressInnerLine {
  background: #0a7a8f;
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 35px;
  bottom: 0px;
  
}

.mainContent
  .progressChapter
  .contentTab
  .tabcontent
  .row
  .progressBar
  .completed {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  width: 100%;
  font-weight: 400;
  line-height: 24px;
  color: #626262;
  
}

.mainContent .progressChapter .contentTab .tabcontent .row .description {
  width: 450px;
  
}

.mainContent .progressChapter .contentTab .tabcontent .row .description p {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  line-height: 20px;
  color: #c1c1c1;
  
}

.mainContent .progressChapter .contentTab .tabcontent .row .btnsList{
  width: 118px;
  display: flex;
  
  align-items: center;
  justify-content: flex-end;
}

.mainContent
  .progressChapter
  .contentTab
  .tabcontent
  .row
  .btnsList
  .keepReading {
  width: 118px;
  
  height: 31px;
  border-radius: 35px;
  border: none;
  outline: none;
  box-shadow: none;
  background: #0a7a8f;
  color: #fff;
  font-family: "Heebo", sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 19.09px;
  text-align: center;
}

.mainContent
  .progressChapter
  .contentTab
  .tabcontent
  .row
  .btnsList
  .keepReading.focus-visible{
    border: 2px solid #1a0707;
  }

.mainContent .progressChapter .contentTab .tabcontent .row .btnsList .readLock {
  display: flex;
  align-items: center;
  justify-content: center;
  width:118px;
  height: 31px;
  border-radius: 35px;
  border: 1px solid #097285;
  background: transparent;
  margin-left: 5px;
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20.56px;
  text-align: center;
  color: #0b7a8e;
}
.mainContent .progressChapter .contentTab .tabcontent .row .btnsList .readLock.focus-visible{
  border: 2px solid #1a0707;
}

.mainContent
  .progressChapter
  .contentTab
  .tabcontent
  .row
  .btnsList
  .readLock
  img {
  width: 17px;
  height: 17px;
  margin-right: 11px;
}

.mainContent .progressChapter .contentTab .tabcontent .row .btnsList .colored {
  background: #0a7a8f;
  color: #fff;
}

.mainContent .progressChapter .contentTab .tabcontent .row .btnsList .view,
.mainContent
  .progressChapter
  .contentTab
  .tabcontent
  .row
  .btnsList
  .readAgain {
  width: 118px;
  height: 31px;
  border-radius: 35px;
  border: 1px solid #b6b6b6;
  background: transparent;
  outline: none;
  box-shadow: none;
  font-family: "Heebo", sans-serif;
  font-size: 13px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  line-height: 19.09px;
  text-align: center;
  color: #909090;
}

.mainContent
  .progressChapter
  .contentTab
  .tabcontent
  .row
  .btnsList
  .readAgain.focus-visible{
    border: 2px solid #1a0707;
  }

.mainContent
  .progressChapter
  .contentTab
  .tabcontent
  .row
  .btnsList
  .view.blue {
  border: 1px solid #0b7a8e;
  color: #0b7a8e;
}
.mainContent
  .progressChapter
  .contentTab
  .tabcontent
  .row
  .btnsList
  .view.black {
  border: 1px solid #000;
  color: #000;
}

.mainContent .progressChapter .contentTab .tabcontent .completedBookProgress {
  background: #f2f5fe !important;
}

.mainContent .progressChapter .contentTab .tabcontent .row .left {
  display: flex;
  align-items: center;
}

.mainContent .progressChapter .contentTab .tabcontent .row h2 {
  font-family: "Heebo", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  color: #2d2d2d;
  margin-right: 30px;
}
.mainContent .progressChapter .contentTab .tabcontent .row .readAgainText {
  color: #b8b8b8;
}
.mainContent .addLibTop .contentTab .tabcontent .row h2 {
  width: 150px;
}

.mainContent
  .progressChapter
  .contentTab
  .tabcontent
  .completedBookProgress
  .btnsList
  .view {
  background: #0a7a8f !important;
  color: #fff !important;
  border: none;
  outline: none;
  box-shadow: none;
}

.mainContent
  .progressChapter
  .contentTab
  .tabcontent
  .completedBookProgress
  h2 {
  color: #0a7a8f;
  font-weight: 700;
}

.mainContent
  .progressChapter
  .contentTab
  .tabcontent
  .completedBookProgress
  .progressBar
  .completed {
  font-weight: 700;
  color: #0a7a8f;
}

.mainContent
  .progressChapter
  .contentTab
  .tabcontent
  .completedBookProgress
  .description
  p {
  color: #0a7a8f;
  font-weight: 700;
  text-align: left;
}

.mainContent .readingBookTop {
  padding: 31px 61px;
  position: relative;
}
.mainContent .readingBookTop .player .group{
  display: none;
}

.backPage {
  position: absolute;
  right: -70px;
  top: 51px;
  width: 51px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #1d9da2;
  background: #ffffff;
  cursor: pointer;
  border-radius: 40px;
}

.mainContent .readingBookTop p {
  font-family: "Heebo", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #0f4152;
  margin-bottom: 23px;
}

.mainContent .readingBookTop .player {
  width: 100%;
  position: relative;
  padding: 15px 20px;
  border: 1px solid #9ebebf;
  height: 329px;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 201px;
}

.mainContent .readingBookTop .player h1 {
  font-family: "Heebo", sans-serif;
  font-size: 35px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #0f4152;
}

.mainContent .readingBookTop .player .footerPlayer {
  position: absolute;
  bottom: 15px;
  width: 100%;
  left: 0px;
  right: 0px;
  border-radius: 7px;
  padding: 0px 20px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.mainContent .readingBookTop .player .footerPlayer .left {
  display: flex;
  align-items: center;
  width: 140px;
}

.mainContent .readingBookTop .player .footerPlayer .left img {
  cursor: pointer;
}
.mainContent .readingBookTop .player .footerPlayer .left img:focus-visible {
  border: 2px solid #1a0707; /* Add the focus-visible styling here */
}

.mainContent .readingBookTop .player .footerPlayer .left img:nth-last-child(1) {
  margin-left: 13px;
}

.mainContent .readingBookTop .player .footerPlayer .center {
  display: flex;
  justify-content: center;
}

.mainContent .readingBookTop .player .footerPlayer .center .previous,
.mainContent .readingBookTop .player .footerPlayer .center .repeat,
.mainContent .readingBookTop .player .footerPlayer .center .start,
.mainContent .readingBookTop .player .footerPlayer .center .next {
  width: 74px;
  height: 63px;
  border-radius: 6px;
  border: 1px 0px 0px 0px;
  border: 1px solid #e4e4e4;
  margin: 0px 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.mainContent .readingBookTop .player .footerPlayer .center .previous:hover,
.mainContent .readingBookTop .player .footerPlayer .center .repeat:hover,
.mainContent .readingBookTop .player .footerPlayer .center .start:hover,
.mainContent .readingBookTop .player .footerPlayer .center .next:hover {
  background: #f1f1f1;
}

.mainContent .readingBookTop .player .footerPlayer .center .previous:hover p,
.mainContent .readingBookTop .player .footerPlayer .center .repeat:hover p,
.mainContent .readingBookTop .player .footerPlayer .center .start:hover p,
.mainContent .readingBookTop .player .footerPlayer .center .next:hover p {
  color: #0f4152;
}

.mainContent .readingBookTop .player .footerPlayer .center .previous .inner,
.mainContent .readingBookTop .player .footerPlayer .center .repeat .inner,
.mainContent .readingBookTop .player .footerPlayer .center .start .inner,
.mainContent .readingBookTop .player .footerPlayer .center .next .inner {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.mainContent .readingBookTop .player .footerPlayer .center .previous .inner img,
.mainContent .readingBookTop .player .footerPlayer .center .repeat img,
.mainContent .readingBookTop .player .footerPlayer .center .start img,
.mainContent .readingBookTop .player .footerPlayer .center .next img {
  margin-bottom: 4px;
  margin-top: 14px;
  width: 24px;
  height: 24px;
}

.mainContent .readingBookTop .player .footerPlayer .center .previous .inner p,
.mainContent .readingBookTop .player .footerPlayer .center .repeat .inner p,
.mainContent .readingBookTop .player .footerPlayer .center .start .inner p,
.mainContent .readingBookTop .player .footerPlayer .center .next .inner p {
  margin-bottom: 0px;
  font-family: "Heebo", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  text-align: center;
  color: #ababab;
}

.mainContent .readingBookTop .player .footerPlayer .right {
  width: 140px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.mainContent .readingBookTop .player .footerPlayer .right .customSelect {
  position: relative;
  width: 81px;
  height: 34px;
  border-radius: 35px;
  border: 1px solid #1d9da2;
  background: #fff;
}
.mainContent .readingBookTop .player .footerPlayer .right .customSelect.focus-visible{
  border: 2px solid #1a0707;
}

.mainContent
  .readingBookTop
  .player
  .footerPlayer
  .right
  .customSelect
  .dropDownBtn {
  width: 100%;
  height: 100%;
  border-radius: 24px;
  background: transparent;
  border: none;
  outline: none;
  padding: 0px 10px;
  box-shadow: none;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 33px;
  color: #0a7a8f;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url("../../img/downSelectIcon.svg") no-repeat right 8px center;
  background-size: 12px;
  padding-right: 24px;
}

.mainContent
  .readingBookTop
  .player
  .footerPlayer
  .right
  .customSelect
  .dropContent {
  display: none;
  position: absolute;
  background-color: white;
  border: 1px solid #1d9da2;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  width: 81px;
  bottom: -8px;
  height: auto;
  overflow: hidden;
  border-radius: 25px;
}

.mainContent
  .readingBookTop
  .player
  .footerPlayer
  .right
  .customSelect
  .dropContent {
  display: none;
  position: absolute;
  background-color: white;
  border: 1px solid #1d9da2;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  width: 81px;
  bottom: -210px;
  height: auto;
  border-radius: 14px;
}
.mainContent
  .readingBookTop
  .player
  .footerPlayer
  .right
  .customSelect
  .dropContent
  a {
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
  display: block;
  padding: 0px 10px;
  line-height: 33px;
  text-align: left;
  color: #0a7a8f;
}

.mainContent
  .readingBookTop
  .player
  .footerPlayer
  .right
  .customSelect
  .dropContent
  a:hover {
  background-color: #0d8e9b;
  color: #fff;
}

.mainContent
  .readingBookTop
  .player
  .footerPlayer
  .right
  .customSelect
  .dropContent
  a.activeBtn {
  background-color: #0d8e9b;
  color: #fff;
}

.mainContent
  .readingBookTop
  .player
  .footerPlayer
  .right
  .customSelect
  .dropContent.show {
  display: block;
  color: #fff;
}
.mainContent .readingBookTop .player .footerPlayer .right .customSelect img {
  position: absolute;
  right: 10px;
  top: 8px;
}

option {
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 33px;
  color: #0a7a8f;
}

.mainContent .readingBookTop .progressPlayer {
  width: 271px;
  margin: auto;
  margin-bottom: 123px;
}

.mainContent .readingBookTop .progressPlayer p {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #717171;
  margin-bottom: 11px;
}

.mainContent .readingBookTop .progressPlayer .progress {
  width: 100%;
  height: 7px;
  background: #d8d8d8;
  border-radius: 35px;
  overflow: hidden;
  position: relative;
}

.mainContent .readingBookTop .progressPlayer .progress .innerLine {
  position: absolute;
  left: 0px;
  height: 100%;
  top: 0px;
  bottom: 0px;
  border-radius: 35px;
  background: #67b6c5;
}

.mainContent .readingBookTop .practiceEnd {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mainContent .readingBookTop .practiceEnd h4 {
  font-family: "Heebo", sans-serif;
  font-size: 22px;
  font-weight: 900;
  line-height: 33px;
  text-align: center;
  color: #e90000;
}
.editBookModal,
.deleteBookModal,
.editChapterModal,
.paymentModal,
.settingModal {
  position: fixed;
  top: 0px;
  right: 0px;
  width: 100%;
  left: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999999;
  overflow-y: auto;
  display: none;
}

.paymentModal .innerContent {
  max-width: 682px;
  width: 90%;
  height: auto;
  border-radius: 10px;
  background: #ffffff;
  border-radius: 7px;
  margin: 79px auto;
  position: relative;
  padding: 62px 33px;
}

.paymentModal .innerContent .crossPurchase {
  position: absolute;
  cursor: pointer;
  left: 33px;
  top: 33px;
}

.paymentModal .innerContent h1 {
  color: #023842;
  font-family: "Heebo", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  margin-bottom: 9px;
}

.paymentModal .innerContent p {
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  text-align: center;
  color: #023842;
  margin-bottom: 42px;
}

.paymentModal .innerContent .twoOffer {
  display: flex;
  justify-content: center;
}

.paymentModal .innerContent .twoOffer .monthly {
  /* width: 236px; */
  height: 333px;
  border: 1px 0px 0px 0px;
  border: 1px solid #97979780;
  box-shadow: 0px 2px 52px -9px #d3d3d3;
  border-radius: 12px;
  padding: 39px 19px 24px 19px;
  margin-right: 22px;
}

.paymentModal .innerContent .twoOffer .monthly h3,
.paymentModal .innerContent .twoOffer .yearly h3 {
  font-family: "Heebo", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #000000;
  margin-bottom: 24px;
}

.paymentModal .innerContent .twoOffer .monthly h5,
.paymentModal .innerContent .twoOffer .yearly h5 {
  font-family: "Heebo", sans-serif;
  font-size: 28px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #0a7a8f;
  margin-bottom: 11px;
}

.paymentModal .innerContent .twoOffer .monthly p,
.paymentModal .innerContent .twoOffer .yearly p {
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #0f4152;
}

.paymentModal .innerContent .twoOffer .monthly .purchaseBtn,
.paymentModal .innerContent .twoOffer .yearly .purchaseBtn {
  text-align: center;
}

.paymentModal .innerContent .twoOffer .monthly .purchaseBtn button,
.paymentModal .innerContent .twoOffer .yearly .purchaseBtn button {
  width: 168px;
  height: 50px;
  cursor: pointer;
  background: #0a7a8f;
  border-radius: 35px;
  outline: none;
  box-shadow: none;
  border: none;
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 23.5px;
  text-align: center;
  color: #fff;
}
.paymentModal .innerContent .twoOffer .monthly .purchaseBtn button.focus-visible, 
.paymentModal .innerContent .twoOffer .yearly .purchaseBtn button .focus-visible{
  outline: 2px solid #1a0707; /* White outline for contrast */
  outline-offset: 2px; /* Creates spacing around the button */
  box-shadow: 0 0 8px rgba(255, 255, 255, 0.6); /* Optional for a glow effect */

}

.paymentModal .innerContent .twoOffer .yearly {
  /* width: 236px; */
  height: 333px;
  border-radius: 12px;
  border: 1px 0px 0px 0px;
  padding: 39px 15px 24px 15px;
  border: 1px solid #0a7a8f;
  box-shadow: 0px 2px 52px -9px #d3d3d3;
  position: relative;
}

.paymentModal .innerContent .twoOffer .yearly .bestValue {
  position: absolute;
  width: 123px;
  height: 28px;
  background: #0f4152;
  border-radius: 5px;
  top: -4%;
  left: 0px;
  right: 0px;
  margin: auto;
}

.paymentModal .innerContent .twoOffer .yearly .bestValue p {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  margin-top: 4px;
  line-height: 20.56px;
  color: #ffffff;
}

.editBookModal .innerContent {
  max-width: 682px;
  width: 90%;
  margin: 79px auto;
  background: #ffffff;
  border-radius: 10px;
  overflow: hidden;
}

.editBookModal .innerContent .headerModal {
  width: 100%;
  height: 87px;
  background: #2ca3b9;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.editBookModal .innerContent .headerModal .cancel,
.deleteBookModal .innerContent .headerModal .cancel,
.settingModal .innerContent .headerModal .cancel {
  position: absolute;
  right: 41px;
  cursor: pointer;
}

.editBookModal .innerContent .headerModal h1,
.deleteBookModal .innerContent .headerModal h1,
.settingModal .innerContent .headerModal h1 {
  font-family: "Heebo", sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #fff;
}

.editBookModal .innerContent .bodyModal form {
  width: 100%;
  padding: 51px 71px;
}

.editBookModal .innerContent .bodyModal form form {
  width: 100%;
}

.editBookModal .innerContent .bodyModal form .row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}

.editBookModal .innerContent .bodyModal form .row input {
  width: calc(50% - 6px);
  height: 50px;
  background: #ffffff;
  border: 1px 0px 0px 0px;
  border: 1px solid #cdcfd6;
  border-radius: 5px;
  padding: 0px 23px;
  outline: none;
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #626262;
  box-shadow: none;
}
.editBookModal .innerContent .bodyModal form .row input.focus-visible{
  outline: 0.5px solid #cdcfd6;
}

.editBookModal .innerContent .bodyModal form textarea {
  width: 100%;
  resize: none;
  border-radius: 5px;
  height: 129px;
  border: 1px 0px 0px 0px;
  padding: 14px 16px;
  background: #ffffff;
  border: 1px solid #cdcfd6;
  outline: none;
  box-shadow: none;
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #626262;
  margin-bottom: 12px;
}

.editBookModal .innerContent .bodyModal form textarea::placeholder {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #626262;
}

.register .inner form input::placeholder,
.register .inner form input option[disabled] {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #626262;
}

.editBookModal .innerContent .bodyModal form .deleteEditBook {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}

.editBookModal .innerContent .bodyModal form .deleteEditBook img {
  width: 33px;
  height: 45px;
  margin-right: 19px;
}

.editBookModal .innerContent .bodyModal form .deleteEditBook a {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: #c60000;
}

.editBookModal .innerContent .bodyModal form .continue {
  width: 168px;
  height: 50px;
  margin-left: auto;
  float: right;
  margin-bottom: 28px;
  cursor: pointer;
  border-radius: 35px;
  background: #097083;
  border: none;
  outline: none;
  box-shadow: none;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.96px;
  text-align: center;
  color: #fff;
}

.editBookModal .innerContent .bodyModal form .continue.focus-visible{
  border: 2px solid #1a0707; 
}
.deleteBookModal .innerContent {
  max-width: 499px;
  width: 90%;
  border-radius: 10px;
  overflow: hidden;
  background: #ffffff;
  margin: 79px auto;
}

.deleteBookModal .innerContent .headerModal {
  width: 100%;
  height: 87px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 10px 10px 0px 0px;
  background: #b92c78;
}

.deleteBookModal .innerContent .bodyModal {
  width: 100%;
  padding: 34px;
}

.deleteBookModal .innerContent .bodyModal p {
  font-family: "Heebo", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #626262;
}

.deleteBookModal .innerContent .bodyModal .btns {
  display: flex;
  align-items: center;
  justify-content: center;
}

.deleteBookModal .innerContent .bodyModal .btns .deleteBtn {
  width: 168px;
  height: 50px;
  gap: 0px;
  border-radius: 35px;
  background: #b92c78;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.96px;
  text-align: center;
  color: #fff;
  border: none;
  outline: none;
  box-shadow: none;
  margin-right: 6px;
  cursor: pointer;
}
.deleteBookModal .innerContent .bodyModal .btns .deleteBtn.focus-visible{
  border: 2px solid #1a0707;
}


.deleteBookModal .innerContent .bodyModal .btns .cancelBtn {
  width: 168px;
  height: 50px;
  background: transparent;
  border-radius: 35px;
  border: 1px solid #000000;
  cursor: pointer;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.96px;
  text-align: center;
  color: #343434;
}
.deleteBookModal .innerContent .bodyModal .btns .cancelBtn.focus-visible{
  border: 2px solid #1a0707;
}

.editChapterModal .innerContent {
  max-width: 411px;
  width: 90%;
  margin: 79px auto;
  overflow: hidden;
  background: #fff;
  border-radius: 10px;
}

.editChapterModal .innerContent .headerModal {
  width: 100%;
  background: #2ca3b9;
  height: 87px;
  display: flex;
  padding: 0px 27px;
  align-items: center;
  justify-content: space-between;
}

.editChapterModal .innerContent .headerModal img {
  cursor: pointer;
}

.editChapterModal .innerContent .headerModal h1 {
  font-family: "Heebo", sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 24px;
  color: #fff;
}

.editChapterModal .innerContent .bodyModal {
  padding: 27px;
}

.editChapterModal .innerContent .bodyModal .row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 29px;
}

.editChapterModal .innerContent .bodyModal .row h3 {
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: #097083;
}

.editChapterModal .innerContent .bodyModal .row img {
  cursor: pointer;
}

.editChapterModal .innerContent .bodyModal .addedChapter {
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #023842;
}

.editChapterModal .innerContent .bodyModal .sureToCancel {
  margin-top: 60px;
  width: 100%;
}

.editChapterModal .innerContent .bodyModal .sureToCancel p {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #b92c78;
  margin-bottom: 5px;
}

.editChapterModal .innerContent .bodyModal .sureToCancel .btnsCancel {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 42px;
}

.editChapterModal .innerContent .bodyModal .sureToCancel .btnsCancel .yesBtn{
  width: 105px;
  height: 35px;
  border-radius: 35px;
  background: #b92c78;
  border: none;
  outline: none;
  box-shadow: none;
  margin-right: 8px;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.96px;
  text-align: center;
  color: #fff;
  cursor: pointer;
}
.editChapterModal .innerContent .bodyModal .sureToCancel .btnsCancel .yesBtn.focus-visible{
  border: 2px solid #1a0707;
}


.editChapterModal
  .innerContent
  .bodyModal
  .sureToCancel
  .btnsCancel
  .cancelBtn {
  width: 105px;
  height: 35px;
  border-radius: 35px;
  border: 1px 0px 0px 0px;
  border: 1px solid #000000;
  cursor: pointer;
  font-family: "Rubik", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.59px;
  text-align: center;
  background: transparent;
  color: #383838;
}
.editChapterModal
  .innerContent
  .bodyModal
  .sureToCancel
  .btnsCancel
  .cancelBtn.focus-visible{
    border: 2px solid #1a0707;
  }

.editChapterModal .innerContent .bodyModal .finish {
  width: 168px;
  height: 50px;
  cursor: pointer;
  background: #097083;
  border: none;
  outline: none;
  box-shadow: none;
  border-radius: 35px;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.96px;
  text-align: center;
  color: #fff;
  float: right;
  margin-bottom: 23px;
}
.editChapterModal .innerContent .bodyModal .finish.focus-visible{
  border: 2px solid #1a0707;
}
.mainContent .readingBookTop .celebrate {
  width: 100%;
  border: 1px solid #9ebebf;
  border-radius: 7px;
  height: 679px;
  overflow: hidden;
  margin-bottom: 100px;
}

.mainContent .readingBookTop .celebrate h2 {
  font-family: "Heebo", sans-serif;
  font-size: 35px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #0f4152;
  margin-top: 57px;
  margin-bottom: 33px;
}

.mainContent .readingBookTop .celebrate img {
  width: 100%;
  height: auto;
}

.settingModal .innerContent {
  max-width: 682px;
  width: 90%;
  margin: 79px auto;
  background: #ffffff;
  border-radius: 10px;
  overflow: hidden;
}

.settingModal .innerContent .headerModal {
  width: 100%;
  height: 87px;
  background: #2ca3b9;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.settingModal .innerContent .settingModal-body {
  padding: 27px 34px;
  display: flex;
  justify-content: space-between;
}

.settingModal .innerContent .settingModal-body .left {
  max-width: 265px;
  width: 48%;
}

.settingModal .innerContent .settingModal-body .left .headText {
  width: 100%;
  border-bottom: 1px solid #000000;
  padding-bottom: 4px;
  margin-bottom: 9px;
}

.settingModal .innerContent .settingModal-body .left .headText h2 {
  font-family: "Heebo", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  color: #023842;
}

.settingModal .innerContent .settingModal-body .left form {
  width: 100%;
}

.settingModal .innerContent .settingModal-body .right {
  max-width: 265px;
  width: 48%;
}

.settingModal
  .innerContent
  .settingModal-body
  .left
  form
  [type="radio"]:checked,
.settingModal
  .innerContent
  .settingModal-body
  .left
  form
  [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
  opacity: 0; /*rg*/

}




.settingModal .innerContent .settingModal-body .left form div label {
  display: flex;
  align-items: center;
  gap: 10px; 
}
.settingModal .innerContent .settingModal-body .left form div label span {
  margin-left: 0;
}
.settingModal
  .innerContent
  .settingModal-body
  .left
  form
  [type="radio"]:checked
  + label,
.settingModal
  .innerContent
  .settingModal-body
  .left
  form
  [type="radio"]:not(:checked)
  + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  display: block;
  font-family: "Heebo", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #023842;
}

.settingModal .innerContent .settingModal-body .left form p {
  margin-bottom: 5px;
}

.settingModal .innerContent .settingModal-body .left form .inner,
.settingModal .innerContent .settingModal-body .left form .inner {
  display: flex;
  align-self: center;
  font-family: "Heebo", sans-serif;
  font-size: 12px;
  font-weight: 300;
  line-height: 24px;
  margin-left: 28px;
  color: #023842;
  margin-top: -10px !important;
}

.settingModal
  .innerContent
  .settingModal-body
  .left
  form
  [type="radio"]:checked
  + label
  span,
.settingModal
  .innerContent
  .settingModal-body
  .left
  form
  [type="radio"]:not(:checked)
  + label
  span {
  display: flex;
  align-self: center;
  font-family: "Heebo", sans-serif;
  font-size: 12px;
  font-weight: 300;
  line-height: 24px;
  text-align: left;
  color: #023842;
  margin-top: -10px !important;
}

.settingModal
  .innerContent
  .settingModal-body
  .left
  form
  [type="radio"]:checked
  + label:before,
.settingModal
  .innerContent
  .settingModal-body
  .left
  form
  [type="radio"]:not(:checked)
  + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #000000;
  border-radius: 100%;
  background: #ffffff;
}
.settingModal
  .innerContent
  .settingModal-body
  .left
  form
  [type="radio"]:not(:checked):focus
  + label:before {
  border: 2px solid red; /* Red border on focus for all radio buttons */
  outline: none; /* Remove default focus outline if needed */
}



.settingModal
  .innerContent
  .settingModal-body
  .left
  form
  [type="radio"].focus-visible{
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #000000;
  border-radius: 100%;
  background: #1bbb1b;
}





.settingModal
  .innerContent
  .settingModal-body
  .left
  form
  [type="radio"]:checked
  + label:after,
.settingModal
  .innerContent
  .settingModal-body
  .left
  form
  [type="radio"]
  :not(:checked)
  + label:after {
  content: "";
  width: 12px;
  height: 12px;
  background: #2ca3b9;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}


.settingModal
  .innerContent
  .settingModal-body
  .left
  form
  [type="radio"]:not(:checked)
  + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.settingModal
  .innerContent
  .settingModal-body
  .left
  form
  [type="radio"]:checked
  + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}


.custom-dropdown  {
  position: relative;
  display: inline-block;
}

.selected-value {
  width: 27px;
  height: 15px;
  border: 1px solid #000000;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Heebo", sans-serif;
  font-size: 12px;
  font-weight: 300;
  margin: 0px 5px;
  line-height: 24px;
  color: #023842;
  margin-top: 4px !important;
}


.dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  border-top: none;
  right: 0px;
  overflow: hidden;
  border-radius: 7px;
  width: 100%;
  width: 110px;
  z-index: 999;
  height: auto;
  box-shadow: 0px 4px 4px 0px #00000040;
}
.custom-dropdown .headerTimer{
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  display: none;
  margin-bottom: 11px;
}
.custom-dropdown .headerTimer h3 {
  font-family: "Heebo", sans-serif;
  font-size: 20px;
  display: inline;
  font-weight: 700;
  line-height: 24px;
 
  color: #023842;
}
.custom-dropdown .dropdown-content p{
  display: none;
}
.custom-dropdown .headerTimer img{
  cursor: pointer;
}
.dropdown-content .paraG{
  font-family: "Heebo", sans-serif;
font-size: 16px;
font-weight: 300;
line-height: 24px;
color: #023842;
margin-bottom: 20px;
display: none;
} 
.dropdown-content .option {
  padding: 10px;
  height: 34px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  color: #023842;
}

.dropdown-content .option:hover {
  background: #eaebf1;
  color: #023842;
  font-weight: 700;
}

.settingModal .innerContent .settingModal-body .left form .marker {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  /* width: 300px !important; */
}

.settingModal .innerContent .settingModal-body .left form .marker .mark {
  width: 148px;
  height: 29px;
  border-radius: 35px;
  background: transparent;
  border: 1px solid #acacac;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Heebo", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  color: #023842;
}
.markupbtn
{
  background-color: lightgrey;
}
.lighterthengrey
{
  background-color: lightgray !important;
}
.yellow{
  background-color: yellow;
}
.lightgrey
{
  background-color: lightgrey;

}
.grey{
  background-color: grey;
}

.yellowbtn{
  background-color: yellow;
    border-radius: 50%;
    padding: 10px;
}
.greybtn{
  background-color: grey;
    border-radius: 50%;
    padding: 10px;
}
.underline{
  text-decoration: underline;
}
.body_container .swiper .slide_box .swiper-button-prev {
  width: 20px;
}

.settingModal .innerContent .settingModal-body .left form .marker .mark .yellow,
.settingModal .innerContent .settingModal-body .left form .marker .mark .gray {
  background: yellow;
  margin-right: 5px;
  padding: 0px 3px;
}

.settingModal .innerContent .settingModal-body .left form .marker .mark .gray {
  background: #d5d5d5;
}

.settingModal
  .innerContent
  .settingModal-body
  .left
  form
  .marker
  .mark
  .underline {
  border-bottom: 1px solid #023842;
  margin-right: 5px;
  padding: 0px 3px;
}
.settingModal .innerContent .settingModal-body .left form .marker .mark .noMarker {
  margin-right: 5px;
}
.settingModal .innerContent .settingModal-body .left .headText {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.settingModal .innerContent .settingModal-body .left .headText p {
  font-family: "Heebo", sans-serif;
  font-size: 12px;
  font-weight: 300;
  line-height: 24px;
  color: #023842;
}

.settingModal .innerContent .btnSave {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.settingModal .innerContent .btnSave .save {
  width: 168px;
  height: 50px;
  border-radius: 35px;
  background: #097083;
  border: none;
  outline: none;
  box-shadow: none;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  cursor: pointer;
  font-weight: 500;
  line-height: 18.96px;
  text-align: center;
  color: #ffffff;
  margin-right: 35px;
  margin-bottom: 35px;
}

.settingModal .innerContent .btnSave .save.focus-visible{
  border:  2px solid #000000;
}
.bookMobileColor .topContent .books .forMobileSearch {
  width: 100%;
  display: none;
  margin-bottom: 24px;
}

.bookMobileColor .topContent .books .forMobileSearch .dropDownSearch {
  /* position: relative; */
  margin-bottom: 24px;
  z-index: 9999;
  box-shadow: 0px 0px 30px 0px #0000001f;
  background: #ffffff;
  border-radius: 7px;
}

.bookMobileColor .topContent .books .forMobileSearch .dropDownSearch .findBook {
  height: 60px;

  padding: 0px 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.bookMobileColor
  .topContent
  .books
  .forMobileSearch
  .dropDownSearch
  .findBook
  h3 {
  font-family: "Heebo", sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  color: #0f4152;
}

.bookMobileColor .topContent .books .forMobileSearch .searchBooksMobile {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bookMobileColor .topContent .books .forMobileSearch .searchBooksMobile {
  width: 100%;
}

.bookMobileColor .topContent .books .forMobileSearch .searchBooksMobile h3 {
  font-family: "Heebo", sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 24px;
  color: #0f4152;
}

.bookMobileColor
  .topContent
  .books
  .forMobileSearch
  .searchBooksMobile
  .custom-select {
  position: relative;
  max-width: 246px;
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.bookMobileColor
  .topContent
  .books
  .forMobileSearch
  .searchBooksMobile
  .custom-select.focus-visible{
    border: 2px solid #1a0707;
  }

.bookMobileColor
  .topContent
  .books
  .forMobileSearch
  .searchBooksMobile
  .select-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 13px;
}

.bookMobileColor
  .topContent
  .books
  .forMobileSearch
  .searchBooksMobile
  .custom-select
  .select-icon {
  position: absolute;
  right: 14px;
}

.bookMobileColor
  .topContent
  .books
  .forMobileSearch
  .searchBooksMobile
  .custom-select
  select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-right: 21px;
  height: 42px;
  width: 100%;
  background: transparent;
  border: 1px solid #097285;
  padding: 0px 16px;
  outline: none;
  box-shadow: none;
  border-radius: 35px;
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 33px;
  color: #5c5c5c;
}
.bookMobileColor
  .topContent
  .books
  .forMobileSearch
  .searchBooksMobile
  .custom-select
  select.focus-visible{
    border: 2px solid #1a0707;
  }

.bookMobileColor
  .topContent
  .books
  .forMobileSearch
  .dropDownSearch
  .searchQuery  {
  transition: 0.4s;
  padding: 0px 18px 18px 18px;
  height: auto;
  display: none;
}

.bookMobileColor
  .topContent
  .books
  .forMobileSearch
  .dropDownSearch
  .searchQuery 
  .inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.bookMobileColor
  .topContent
  .books
  .forMobileSearch
  .dropDownSearch
  .searchQuery 
  .inner
  .custom-select {
  position: relative;
  width: 48%;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bookMobileColor
  .topContent
  .books
  .forMobileSearch
  .dropDownSearch
  .searchQuery 
  .inner
  .custom-select
  .select-icon {
  position: absolute;
  top: 13px;
  right: 10px;
  width: 20px;
  height: 100%;
}

.bookMobileColor
  .topContent
  .books
  .forMobileSearch
  .dropDownSearch
  .searchQuery 
  .inner
  .custom-select
  select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-right: 21px;
  height: 42px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #b6b6b6;
  padding: 0px 16px;
  /* outline: none; */
  box-shadow: none;
  border-radius: 35px;
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 33px;
  color: #0f4152;
}
.bookMobileColor
  .topContent
  .books
  .forMobileSearch
  .dropDownSearch
  .searchQuery 
  .inner
  .custom-select
  select.focus-visible{
    border: 2px solid #1a0707;
  }
.bookMobileColor
  .topContent
  .books
  .forMobileSearch
  .dropDownSearch
  .searchQuery 
  .inner
  .searchInput {
  width: 100%;
  margin: 10px 0px;
  height: 42px;
  border: 1px solid #b6b6b6;
  border-radius: 35px;
  overflow: hidden;
  display: flex;
  align-items: center;
}
.bookMobileColor
  .topContent
  .books
  .forMobileSearch
  .dropDownSearch
  .searchQuery 
  .inner
  .searchInput.focus-visible{
    border: 2px solid #1a0707;
  }

.bookMobileColor
  .topContent
  .books
  .forMobileSearch
  .dropDownSearch
  .searchQuery 
  .inner
  .searchInput
  input {
  width: calc(100% - 35px);
  height: 100%;
  border-radius: 35px;
  outline: none;
  box-shadow: none;
  border: none;
  padding: 0px 14px;
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20.56px;
  color: #0f4152;
}

.bookMobileColor
  .topContent
  .books
  .forMobileSearch
  .dropDownSearch
  .searchQuery 
  .inner
  .searchInput
  input::placeholder {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20.56px;
  color: #0f4152;
}

.bookMobileColor
  .topContent
  .books
  .forMobileSearch
  .dropDownSearch
  .searchQuery 
  .inner
  img {
  cursor: pointer;
}

.bookMobileColor
  .topContent
  .books
  .forMobileSearch
  .dropDownSearch
  .searchQuery 
  .inner
  button {
  width: 119px;
  height: 44px;
  background: #59a0ad;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 23.5px;
  text-align: center;
  color: #fff;
  border-radius: 35px;
  border: none;
  outline: none;
  box-shadow: none;

}
.bookMobileColor
  .topContent
  .books
  .forMobileSearch
  .dropDownSearch
  .searchQuery 
  .inner
  button.focus-visible{
    border: 2px solid #1a0707;
  }

.bookMobileColor .topContent .books .forMobileSearch .dropDownSearch .searchQuery  .inner .custom-select .select-icon 
.bookMobileColor
  .topContent
  .books
  .forMobileSearch
  .dropDownSearch
  .searchQuery 
  .inner
  button.active {
  background: #097285;
}

#chapterMobile {
  font-family: "Heebo", sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 23px;
  color: #0f4152;
  margin-bottom: 24px;
  margin-top: 20px;
  display: none;
}

.footer {
  width: 100%;
  position: static;
  bottom: 0px;
  height: 66px;
  display: flex;
  align-items: center;
  background: #097185;
}

.footer .inner {
  max-width: 1140px;
  width: 90%;
  margin: auto;
  display: flex;
  align-items: center;
}

.footer .inner a {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 33px;
  width: 89px;
  text-align: right;
  color: #ffffff;
  text-decoration: none;
}
.mainContent .detailAccountTop {
  padding: 58px 46px;
}
.mainContent .contentArea .detailAccount {
  display: flex;
  align-items: center;
  padding: 0px !important;
  justify-content: space-between;
  margin-bottom: 18px;
}

.mainContent .contentArea .detailAccount .activeUsers {
  width: auto;
  padding: 0px 39px;
  height: 45px;
  background: #f1f1f1;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mainContent .contentArea .detailAccount .activeUsers p {
  font-family: "Heebo", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 20.56px;
  color: #2d2d2d;
  display: flex;
  align-items: center;
}
.mainContent .contentArea .detailAccount .activeUsers p:nth-child(1) {
  margin-right: 37px;
}
.mainContent .contentArea .detailAccount .activeUsers p img {
  margin-left: 15px;
}
.mainContent .contentArea .userRegistrationLink {
  width: 100%;
  margin: auto;
  border: 1px solid #cdcfd6;
  height: 123px;
  border-radius: 7px;
  display: flex;
  align-items: center;
  padding: 0px 24px;
  justify-content: space-between;
  margin-bottom: 28px;
}
.mainContent .contentArea .userRegistrationLink .left h3 {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  color: #0f4152;
}
.mainContent .contentArea .userRegistrationLink .left{
  display: flex;
}

.mainContent .contentArea .userRegistrationLink .left .copyTexting {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}
.mainContent .contentArea .userRegistrationLink .left .copyTexting p,
.mainContent .contentArea .userRegistrationLink .left .hidden,
#copyNotification {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 24px;
  color: #44c6cb;
}
#copyNotification {
  color: rgb(168, 45, 8);
}
.mainContent .contentArea .userRegistrationLink .left .hidden {
  color: rgb(128, 0, 0);
  display: none;
}
.mainContent .contentArea .userRegistrationLink .left .copyTexting img {
  cursor: pointer;
  margin-left: 16px;
}
.mainContent .contentArea .userRegistrationLink .EmailButton{
  display: flex;
flex-wrap: wrap;
align-content: flex-start;
flex-direction: row-reverse;

}

.mainContent .contentArea .userRegistrationLink button {
  width: 100%;
  /* height: 60px; */
 margin: 5px;
 padding: 5px;
  border-radius: 40px;
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20.56px;
  text-align: center;
  color: #0a7a8f;
  border: 1px solid #979797;
  background: transparent;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;
  cursor: pointer;
}
.mainContent .contentArea .userRegistrationLink button.focus-visible{
  border: 2px solid #1a0707;
}

.mainContent .contentArea .userRegistrationLink button img {
  margin-left: 22px;
}

.mainContent .contentArea form {
  width: 100%;
  display: flex;
}
.mainContent .contentArea form .left {
  max-width: 344px;
  width: 50%;
  margin-right: 55px;
}
.mainContent .contentArea form .left h4,
.mainContent .contentArea form .right h4 {
  font-family: "Heebo", sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  color: #0f4152;
  margin-bottom: 18px;
}
.mainContent .contentArea form .left input,
.mainContent .contentArea form .right input {
  border: 1px solid #cdcfd6;
  width: 100%;
  padding: 0px 23px;
  height: 50px;
  outline: none;
  box-shadow: none;
  background: transparent;
  border-radius: 7px;
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #626262;
  margin-bottom: 7px;
}
.mainContent .contentArea form .right {
  max-width: 344px;
  width: 50%;
}

.mainContent .contentArea form .left .container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 7px;
}
.custom-file-upload:focus-visible {
  outline: 1px solid #1a1b1b; /* Customize focus outline color *
  outline-offset: 4px; /* Add spacing between the element and the outline */
}

.mainContent .contentArea form .left .container .custom-file-upload {
  width: 50px;
  height: 50px;
  display: inline-block;
  border: 1px solid #cdcfd6;
  border-radius: 7px;
  cursor: pointer;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 36px; 
  background-color: transparent;
  position: relative; 
}

.mainContent .contentArea form .left .container .custom-file-upload:hover {
  background-color: #e0e0e0;
}
.mainContent .contentArea form .left .container .fileName {
  margin-left: 18px;
  font-size: 16px;
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #626262;
}
.mainContent .contentArea .saveBtn {
  margin-top: 15px;
  width: 168px;
  height: 50px;
  border-radius: 40px;
  background: #0a7a8f;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 23.5px;
  color: #ffffff;
  border: none;
  outline: none;
  box-shadow: none;
  cursor: pointer;
}
.mainContent .contentArea .saveBtn.focus-visible{
  border: 1px solid #17181a;
}

@media (max-width: 1300px) {
  .backPage {
    right: 0px;
    top: 10px;
    z-index: 999999999;
  }
  .mainContent .topHeader .backPage {
    top: 2px;
    right: 0px;
    height: 25px;
  }
}

@media (max-width: 1250px) {
  .mainContent
    .progressChapter
    .contentTab
    .tabcontent
    .row
    .progressBar
    .progressTopLine {
    margin-right: 20px;
  }
}

@media (max-width: 1200px) {
  .header .left .logo {
    margin-right: 9px;
  }
  .header .left ul li {
    margin-right: 19px;
  }
  .mainContent
    .contentArea
    .progress
    .tabContentTop
    .tabcontent
    .row
    .card
    .bottomImg {
    width: 250px;
    display: block;
    margin: auto;
  }
  .mainContent .readingBookTop .celebrate {
    height: auto;
  }

  .mainContent .progressChapter .contentTab .tabcontent .row .description {
    width: 400px !important;
    text-align: left;
  }

  .mainContent .topContent .search .searchInput {
    max-width: 250px;
  }
}
@media (max-width: 1100px) {
 
  .mainContent .progressChapter .contentTab .tabcontent .row .progressBar {
    flex-wrap: wrap;
    width: auto;
  }
  
}
@media (max-width: 992px) {
  .mainContent .topHeader .backPage,
  .backPage {
    display: none;
  }
  .mainContent .topContent .search .searchInput {
    max-width: 200px;
  }
  .header {
    padding: 10px 5%;
  }
  .header .Admin {
    padding: 10px 5%;
  }
  .header .right {
    display: none;
  }
  .header .left {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .header .left .mobileMenu {
    display: block;
    cursor: pointer;
  }
  .header .left .logo {
    margin-right: 0px;
  }
  .header .left .overLay {
    display: none;
  }
  .mainContent .contentArea .progress .tabContentTop .tabcontent .row .card h2 {
    font-size: 24px;
  }
  .mainContent .contentArea .progress .tabContentTop .tabcontent .row {
    flex-wrap: wrap;
  }
  .mainContent .contentArea .progress .tabContentTop .tabcontent .row .card {
    width: 49%;
    margin-bottom: 12px;
  }
  .mainContent .contentArea .progress .myAccountList {
    border-bottom: none;
    display: flex;
    justify-content: center;
  }
  .mainContentSuccess {
    width: 100% !important;
  }
  .mainContent .readingBookTop .celebrate {
    background: #fff;
  }
  .mainContent .readingBookTop .celebrate h2 {
    line-height: 40px;
  }
}
@media (max-width: 900px) {
  .mainContent .books .headerBooksList {
    display: none;
  }
  .mainContent .books .contentTab .tabcontent .row {
    background: #fff;
    border-radius: 7px;
    border: none;
    margin-bottom: 6px;
    box-shadow: 0px 0px 30px 0px #0000001f;
  }
  .mainContent .books h1 {
    display: none;
  }
  .bookMobileColor .topContent {
    box-shadow: none !important;
    background: transparent !important;
  }
  .bookMobileColor .topContent .books .forMobileSearch {
    display: block;
  }
  .mainContent .line {
    display: none;
  }
  .mainContent .books .contentTab .tabcontent .row .left,
  .mainContent .books .contentTab .tabcontent .row .center {
    max-width: 48%;
    width: 48%;
  }
  .mainContent .books .contentTab .tabcontent .row .right {
    max-width: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 24px;
  }
  .mainContent .books .contentTab .tabcontent .row {
    flex-wrap: wrap;
  }
  .mainContent .topContent .search {
    display: none;
  }
  .readingTop {
    max-width: 100% !important;
    width: 100% !important;
  }
  .mainContent .readingBookTop {
    background: transparent !important;
    padding: 15px 14px;
  }
  .mainContent .readingBookTop .hideParaMobile {
    display: none;
  }
  .mainContent .readingBookTop .player {
    background: #fff;
    height: 272px;
  }
  .mainContent .readingBookTop .player h1 {
    line-height: 55px;
  }
  .mainContent
    .readingBookTop
    .player
    .footerPlayer
    .center
    .previous
    .inner
    img,
  .mainContent .readingBookTop .player .footerPlayer .center .repeat img,
  .mainContent .readingBookTop .player .footerPlayer .center .start img,
  .mainContent .readingBookTop .player .footerPlayer .center .next img {
    margin-top: 7px;
  }
  .mainContent .readingBookTop .player {
    margin-bottom: 58px;
  }
  .mainContent .readingBookTop .progressPlayer {
    margin-bottom: 38px;
  }
  .mainContent .individualHeader .right {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    justify-content: flex-end;
  }
  .mainContent .individualHeader .totalUser {
    display: none;
  }
  .mainContent .individualHeader .right .progressLine {
    padding-right: 0px;
    margin-right: 0px;
  }

  .mainContent .individualHeader .right .btn {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .mainContent .individualHeader .right .btn button {
    margin-bottom: 5px;
  }
  .mainContent .individualHeader .right .progressLine .grayLine,
  .mainContent .individualHeader .right .progressLine p {
    margin-right: 0px;
    width: 100px;
  }
  .mainContent .progressChapter .contentTab .tabcontent .row .description {
    width: 250px !important;
  }
  .mainContent .contentArea .userRegistrationLink {
    flex-wrap: wrap;
    height: auto;
    padding: 10px;
    overflow: hidden;
  }
  .mainContent .detailAccountTop {
    padding: 20px;
  }
}
@media (max-width: 800px) {
  .mainContent .contentArea .userRegistrationLink .left .copyTexting p {
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .mainContent .contentArea .userRegistrationLink button {
    margin-top: 10px;
  }
  .mainContent .detailAccountTop .headerTop {
    flex-wrap: wrap !important;
  }
  .mainContent .contentArea .detailAccount .activeUsers {
    flex-wrap: wrap;
    height: auto !important;
    padding: 5px 20px;
  }
  .mainContent .contentArea .detailAccount h2 {
    margin-right: 20px;
    width: 100% !important;

    margin-bottom: 10px;
  }
  .paymentModal .innerContent .total .leftt h6 {
    font-size: 10px;
  }
}
@media (max-width: 779px) {
  .mainContent .progressChapter .contentTab .tabcontent .row h2 {
    margin-right: 20px;
    width: 100px;
  }
  .mainContent
    .progressChapter
    .contentTab
    .tabcontent
    .row
    .progressBar
    p:nth-child(1) {
    width: auto;
  }
  .mainContent .contentArea .headerTop .timeStamp {
    height: auto;
    text-align: center;
    padding: 4px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .mainContent .contentArea .headerTop .timeStamp img {
    display: none;
  }
  .mainContent .contentArea .headerTop {
    padding: 23px 15px;
  }
  .mainContent .contentArea .headerTop h2 {
    font-size: 18px;
    line-height: 24px;
  }
  .mainContent .contentArea .headerTop .timeStamp h3 {
    text-align: center;
  }
  .mainContent .contentArea .headerTop .timeStamp h3 .span1 {
    display: none;
  }
  .mainContent .contentArea .headerTop .timeStamp h3 .span2 {
    display: block;
    text-align: center;
  }
  .mainContent .contentArea .progress .tabContentTop .tabcontent .row .card {
    width: 100%;
    margin: auto;
    margin-bottom: 12px;
  }
  .mainContent .contentArea .progress .tabContentTop {
    padding: 23px 9px;
  }
  .mainContent .readingBookTop .celebrate img {
    height: 405px;
    object-fit: cover;
  }

  .mainContent .individualHeader {
    padding: 9px;
  }
  .mainContent .individualHeader .right .btn h2 {
    display: none;
  }
  .mainContent .individualHeader .left h2 {
    font-size: 18px;
    line-height: 24px;
    margin-right: 5px;
  }
  .mainContent .progressChapter .chapterList button {
    margin-left: 0px;
    margin-right: 10px;
  }
  .mainContent .topHeader .right {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .mainContent .topHeader .right button {
    margin-left: auto;
  }
  .mainContent .topHeader .right .text h2 {
    display: none;
  }
  .mainContent .topHeader .right .text {
    margin-right: 0px;
  }
  .mainContent .topHeader .right .text p {
    width: 160px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    margin-top: 8px;
  }
  .mainContent .topHeader {
    padding: 12px 9px;
  }
}

@media (max-width: 700px) {
  .custom-dropdown .headerTimer {
    display: block;
  }
  .custom-dropdown .headerTimer img {
    float: right;
    display: inline;
  }
  .headerReader{
    display: none;
  }.mainContent .readingBookTop .player{
    width: 90%;
    margin-left: 10%;
  }
  .mainContent .readingBookTop .player .group{
    display: block;
    width: 5%;
    position: absolute;
    top: 10px;
    left: -50px;
  }
  .custom-dropdown .option {
    display: none;
  }
  .custom-dropdown .dropdown-content p{
    display: block;
   }
  .custom-dropdown .dropdown-content label {
    font-family: "Heebo", sans-serif;
    font-size: 16px !important;
    font-weight: 300!important;
    line-height: 24px;
    color: #023842;
  }
  .dropdown-content .paraG {
    display: block;
    margin-bottom: 20px!important;
  }
  .custom-dropdown .inner2 {
    position: fixed;
    z-index: 9999999;
    background: rgba(0, 0, 0, 0.7);
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    display: none;
  }
  .custom-dropdown .inner2 .dropdown-content {
    position: static;
    width: 90%;
    margin: auto;
    padding: 35px;
    margin-top: 5%;
  }
  .paymentModal .innerContent .userCards {
    flex-wrap: wrap;
    height: auto;
    padding: 10px 20px;
  }
  .paymentModal .innerContent .userCards .card {
    width: 50%;
  }
  .paymentModal .innerContent .userCards .card:nth-child(1),
  .paymentModal .innerContent .userCards .card:nth-child(2) {
    margin-bottom: 15px;
  }
  .paymentModal .innerContent .userCards .card:nth-child(2) img {
    display: none;
  }
  #chapterMobile {
    display: block;
  }
  .mainContent .progressChapter .search,
  .mainContent .progressChapter .chapterList {
    /* display: none; */
  }
  .mainContent .books .headerBooksList {
    /* display: none; */
  }

  .mainContent .books .contentTab .tabcontent .row .left,
  .mainContent .books .contentTab .tabcontent .row .center {
    max-width: 100%;
    width: 100%;
  }

  .mainContent .books .contentTab .tabcontent .row .center img {
    position: absolute;
    top: 14px;
    right: 18px;
  }
  .mainContent .books .contentTab .tabcontent .row .center {
    margin-top: 10px;
  }
  .settingModal .innerContent .settingModal-body {
    flex-wrap: wrap;
  }
  .settingModal .innerContent .settingModal-body .left {
    width: 100%;
  }

  .mainContent .readingBookTop .player {
    height: 600px;
  }
  .mainContent .progressChapter .contentTab .tabcontent .row .description {
    display: none;
  }
  .mainContent .progressChapter .contentTab .tabcontent .row .btnsList {
    width: 118px;
  }
  .mainContent .progressChapter .contentTab .tabcontent .row .progressBar {
    width: auto;
  }
  .paymentModal .innerContent .userCards .card p {
    font-size: 12px;
  
  }
  .paymentModal .innerContent .userCards .card h6 {
font-size: 15px;
      }  
        .mainContent
    .progressChapter
    .contentTab
    .tabcontent
    .row
    .progressBar
    .progressTopLine {
    width: 100px;
  }
  .mainContent .progressChapter .contentTab .tabcontent .row .progressBar {
    margin-right: 0px;
    width: 50%;
  }
  .mainContent .progressChapter .contentTab .tabcontent .row {
    padding: 12px 9px;
  }
}
@media (max-width: 679px) {

  #bt2{
    margin-top: 20px;
  }
  .mainContent .contentArea .userRegistrationLink .left{
    display: flex;
    flex-direction: column-reverse;
  }
  .addBookModal .modal-content .modal-body img {
    margin-bottom: 20px;
  }
  .addBookModal .modal-content .modal-body .step1Btn {
    margin: auto;
    left: 0px;
    right: 0px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;

  }



  .mainContent .contentArea form {
    flex-wrap: wrap;
    max-width: 100%;
  }
  .mainContent .contentArea form .left,
  .mainContent .contentArea form .right {
    max-width: 100%;
    width: 100%;
    margin-right: 0px;
  }
  .addLibraryModalTop .modal-content .modal-header,
  .editLibraryModalTop .modal-content .modal-header,
  .deleteLibraryModalTop .modal-content .modal-header {
    /* justify-content: flex-end; */
    padding-right: 25px;
  }
  .addLibraryModalTop .modal-content .modal-header h2,
  .editLibraryModalTop .modal-content .modal-header h2,
  .deleteLibraryModalTop .modal-content .modal-header h2 {
    font-size: 20px;
  }
  .editChapterModal .innerContent,
  .editBookModal .innerContent {
    margin: 36px auto;
  }
  .editBookModal .innerContent .headerModal {
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 24px;
  }
  .mainContent .progressChapter .contentTab .tabcontent .row .progressBar {
    width: 120px;
  }
  .addBookModal .modal-content,
  .paymentModal .innerContent,
  .settingModal .innerContent,
  .deleteBookModal .innerContent {
    margin: 27px auto;
    overflow-y: auto;
  }
  .editBookModal .innerContent .headerModal {
    text-align: left;
  }
  .deleteBookModal .innerContent .headerModal {
    display: flex;
    justify-content: flex-start;
    padding-left: 26px;
  }
  .settingModal .innerContent .headerModal .cancel,
  .editBookModal .innerContent .headerModal .cancel,
  .deleteBookModal .innerContent .headerModal .cancel {
    right: 26px;
    margin-left: auto;
    position: absolute;
  }
  .addBookModal .modal-content .modal-header .close {
    right: 26px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .addBookModal .modal-content .modal-header,
  .settingModal .innerContent .headerModal {
    justify-content: flex-start;
    padding-left: 26px;
    position: relative;
  }
  .addBookModal .modal-content .modal-header h2,
  .settingModal .innerContent .headerModal h1,
  .editBookModal .innerContent .headerModal h1,
  .deleteBookModal .innerContent .headerModal h1 {
    font-size: 20px;
  }
  .addBookModal .modal-content .modal-body {
    padding: 17px 18px;
  }
  .addBookModal .modal-content .modal-body .firstImg {
    margin-bottom: 68px !important;
    position: static;
    display: block;
    margin: auto;
    width: 80%;
    height: auto;
  }
  .addBookModal .modal-content .modal-body form .row {
    flex-wrap: wrap;
  }
  .addBookModal .modal-content .modal-body form .row input {
    width: 100%;
    margin-bottom: 12px;
  }
  .addBookModal .modal-content .modal-body form .row .custom-select {
    width: 100%;
    margin-bottom: 12px;
  }
  .addBookModal .modal-content .modal-body form .row .forMobileMargin {
    margin-bottom: 12px;
  }
  .addBookModal .modal-content .modal-footer {
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
  }
  .addBookModal .modal-content .modal-footer .back1 {
    margin-right: 0px;
  }
  .addBookModal .modal-content .modal-footer button {
    margin-bottom: 10px;
  }
  .addBookModal .modal-content .modal-body form {
    padding: 0px 18px;
  }
  .addBookModal .modal-content .modal-body .successUpload {
    width: 80%;
  }

  .mainContent .readingBookTop .player .footerPlayer .center {
    position: absolute;
    top: -80px !important;
    left: 0px;
    right: 0px;
    margin: auto;
  }
}
@media (max-width: 664px) {
  .paymentModal .innerContent {
    padding: 62px 20px;
  }
  .paymentModal .innerContent .total .leftt {
    flex-wrap: wrap;
    width: 50%;
  }
  .paymentModal .innerContent .total .rightt h3,
  .paymentModal .innerContent .total .leftt h3 {
    font-size: 15px;
  }
  .paymentModal .innerContent .total .leftt p {
    width: 100%;
  }
  .paymentModal .innerContent .total {
    height: auto;
    padding: 10px;
    border-radius: 7px;
  }
  .paymentModal .innerContent .total .leftt {
    flex-wrap: wrap;
  }
  .paymentModal .innerContent .total .leftt .firstH3 {
    width: 90px !important;
  }
  .paymentModal .innerContent .total .leftt img {
    display: none;
  }
  .paymentModal .innerContent .userCards {
    padding: 10px;
  }
  .settingModal .innerContent .settingModal-body .leftForMobile {
    margin-top: 58px;
  }
  .editBookModal .innerContent .bodyModal form {
    padding: 28px;
  }
}
@media (max-width: 622px) {
  .paymentModal .innerContent form {
    flex-wrap: wrap;
  }
  .paymentModal .innerContent form .left,
  .number-input-container,
  .paymentModal .innerContent form .right {
    width: 100%;
    margin-right: 0px;
  }
  
  .paymentModal .innerContent .twoOffer {
    flex-direction: column-reverse;
    align-items: center;
  }
  .paymentModal .innerContent .twoOffer .monthly,
  .paymentModal .innerContent .twoOffer .yearly {
    margin-right: 0px;
    width: 266px;
    margin-bottom: 25px;
  }
  .editBookModal .innerContent .bodyModal form .row {
    flex-wrap: wrap;
  }
  .editBookModal .innerContent .bodyModal form .row:nth-child(1) .custom-select,
  .editBookModal .innerContent .bodyModal form .row:nth-child(2) .custom-select,
  .editBookModal
    .innerContent
    .bodyModal
    form
    .row:nth-child(3)
    .custom-select {
    margin-bottom: 12px;
  }
  .editBookModal .innerContent .bodyModal form .row input,
  .editBookModal .innerContent .bodyModal form .row .custom-select {
    width: 100%;
    margin-bottom: 12px;
  }
  .deleteBookModal .innerContent .bodyModal .btns {
    flex-wrap: wrap;
  }
  .deleteBookModal .innerContent .bodyModal .btns button {
    margin-bottom: 6px;
  }
}
@media (max-width:440px){
.headerReader{
  display: block;
}

.mainContent .readingBookTop .player {
  height: 629px;
}

  .mainContent .readingBookTop .player{
    width: 100%;
    margin-left: 0%;
  }
  .mainContent .readingBookTop .player .group{
    display: none;
  }
}



/* Modal backdrop styling (if not using a specific class for the backdrop) */
.modal.fade {
  display: none; /* Hidden by default */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 1050; /* Ensure it's above other elements */
}

/* Modal dialog styling */
.modal-dialog {
  margin: 5% auto; /* Centered vertically and horizontally */
  max-width: 600px; /* Set a max width */
  position: relative;
}

/* Specific styling for subs_modal */
.subs_modal {
  width: 100%;
}

/* Modal content styling */
.modal-content {
  position: relative;
  background-color: #fff;
  border-radius: 0.3rem;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2); /* Box shadow for depth */
  overflow: hidden;
  /* padding: 20px; */
  margin-top: 20%; /* Align with inline style */
}

/* RTL support for the container */
.container-fluid.rtl {
  direction: rtl;
}

/* Full-width for w-100 */
.w-100 {
  width: 100%;
}

/* Iframe styling */
iframe {
  width: 100%;
  height: 400px; /* Adjust as needed */
  border: none;
  display: block;
}

/* Button styling */
#closeiframe {
  display: block; /* Make sure button behaves like a block element */
  width: auto;
  padding: 10px 20px;
  color: #fff; /* Text color */
  background-color: var(--bg-color1, #007bff); /* Background color */
  border: none; /* No border */
  border-radius: 3px; /* Rounded corners */
  cursor: pointer;
  margin-top: 20px; /* Spacing at the top */
}

#closeiframe:hover {
  background-color: #0056b3; /* Darker background on hover */
}






