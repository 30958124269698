 @import url("https://fonts.googleapis.com/css2?family=Assistant:wght@200..800&family=Heebo:wght@100..900&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap"); 

* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}

body {
    direction: rtl;
  background: #ebecf1;
}

.UhmobileMenuTop {
  position: fixed;
  top: 0px;
  left: -100%;
  transition: 0.Uh4s;
  width: 100%;
  bottom: 0px;
  right: 0px;
  z-index: 99999999;
  background: rgba(53, 53, 53, 0.Uh9);
}
.Uheader .Uhright .Uhicons:nth-last-child(2) {
  margin-left: 14px;
}
.UhmobileMenuTop .Uhinner {
  position: relative;
  max-width: 364px;
  width: 90%;
  bottom: 0px;
  height: 100vh;
  background: #097a8f;
  padding: 22px;
  overflow-y: auto;
}

.UhmobileMenuTop .Uhinner .UheaderText {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 42px;
}

.UhmobileMenuTop .Uhinner .UheaderText .Uhlogo {
  cursor: pointer;
}

.UhmobileMenuTop .Uhinner .UheaderText .UhswitchBtn {
  width: 187px;
  height: 44px;
  border-radius: 40px;
  background: #2599af;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.UhmobileMenuTop .Uhinner .UheaderText .UhswitchBtn a {
  width: 99px;
  height: 44px;
  border-radius: 40px;
  opacity: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Heebo", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 46px;
  text-align: center;
  color: #ffffff;
  text-decoration: none;
}

.UhmobileMenuTop .Uhinner .UheaderText .UhswitchBtn a.Uhactive {
  background: #02343d;
}

.UhmobileMenuTop .Uhinner ul {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.UhmobileMenuTop .Uhinner ul li {
  margin: 0px 0px 7px 51px;
  list-style-type: none;
  width: 100%;
}

.UhmobileMenuTop .Uhinner ul li a {
  font-family: "Heebo", sans-serif;
  font-size: 24px;
  font-weight: 400;
  width: 100%;
  display: block;
  line-height: 46px;
  text-align: left;
  text-decoration: none;
  color: #fff;
}

.UhmobileMenuTop .Uhinner ul .Uhupgrade a {
  width: 179px;
  height: 48px;
  gap: 0px;
  opacity: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Assistant", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 18.Uh31px;
  text-align: center;
  background: #097185;
  border-radius: 35px;
  color: white;
}

.Uheader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 0% 0px 5%;
  background: #097185;
}

.Uheader .Uhleft {
  display: flex;
  align-items: center;
}

.Uheader .Uhleft .UhmobileMenu {
  display: none;
}

.Uheader .Uhleft .Uhlogo {
  margin-right: 39px;
}

.Uheader .Uhleft ul {
  display: flex;
  align-items: center;
}

.Uheader .Uhleft ul li {
  margin-right: 39px;
  list-style-type: none;
}

.Uheader .Uhleft ul li a {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 33px;
  text-align: left;
  text-decoration: none;
  color: #fff;
}

.Uheader .Uhleft ul li:nth-last-child(1) a {
  width: 103.Uh09px;
  height: 35px;
  gap: 0px;
  opacity: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Assistant", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 18.Uh31px;
  text-align: center;
  background: #fff;
  border-radius: 35px;
  color: #0a7f95;
}

.Uheader .Uhright {
  display: flex;
  align-items: center;
}

.Uheader .Uhright .UhlogoInst {
  width: 79px;
  height: 66px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Uheader .Uhright .Uhlang {
  width: 97px;
  height: 32px;
  gap: 0px;
  border-radius: 5px;
  margin-right: auto;
  border: 1px 0px 0px 0px;
  opacity: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #fff;
  padding: 0px 15px;
  cursor: pointer;
  position: relative;
  margin-right: 29px;
}

.Uheader .Uhright .Uhlang p {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 33px;
  color: #fff;
}

.Uheader .Uhright .Uhlang .Uhdropdown {
  position: absolute;
  bottom: -115%;
  border-radius: 7px;
  border: 1px 0px 0px 0px;
  opacity: 0px;
  right: 0px;
  background: #097185;
  border: 1px solid #fff;
  width: 100%;
  display: none;
}

.Uheader .Uhright .Uhlang .Uhshow {
  display: block;
  z-index: 9999;
}

.Uheader .Uhright .Uhlang .Uhdropdown a {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 33px;
  color: #fff;
  text-decoration: none;
  display: flex;
  align-items: center;
  width: 97px;
  height: 32px;
  justify-content: center;
}

.Uheader .Uhright .Uhicons:nth-last-child(2) {
  margin-right: 14px;
}

.UhmainContent {
  max-width: 1140px;
  width: 90%;
  margin: auto;
  margin-bottom: 0px;
}
.UhroleTabs {
  display: flex;
  justify-content: right;
  margin-bottom: 20px;
}

.tablinks {
  background-color: #ffffff;
  border: none;
 margin-right: 20px;
 margin-left: 0;
  color: #333;
  cursor: pointer;
  /* font-size: 16px; */
font-weight: 500;
line-height: 24px;
  padding: 7px 10px;
}

.tablinks:hover {
  background-color: #dddddd00;
  color: #000;
}
.tablinks.focus-visible {
  border-bottom: 2px solid #1a0707; /* Full red border for keyboard focus */
}
.tablinks.active {
  /* background-color: #4CAF50; */
  border-bottom: #000 2px solid;
  /* color: white; */
}
.tablinks.focus-visible {
  border-bottom: 2px solid #1a0707; /* Full red border for keyboard focus */
}
.freezeBackground {
  background-color: rgba(102, 102, 104, 0.356);
  color: black; /* Ensure text is readable against the blue background */
}
.UhmainContent .UhtopHeader {
  width: 100%;
  padding: 25px 18px;
  box-shadow: 0px 0px 30px 0px #0000001f;
  background: #fff;
  border-radius: 0px 0px 10px 10px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.UhmainContent .UhtopHeader .UhbackPage .UhbackPage {
  position: absolute;
  left: -70px;
  bottom: 15px;
  width: 51px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #1d9da2;
  background: #ffffff;
  cursor: pointer;
  border-radius: 40px;
}

.UhmainContent .UhtopHeader .Uhleft h2 {
  font-family: "Heebo", sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  color: #0f4152;
}

.UhmainContent .UhtopHeader .Uhleft p {
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #0f4152;
  margin-bottom: 0px !important;
}

.UhmainContent .UhtopHeader .Uhright {
  display: flex;
  align-items: center;
}

.UhmainContent .UhtopHeader .Uhright .Uhtext {
  margin-right: 13px;
}

.UhmainContent .UhtopHeader .Uhright .Uhtext h2 {
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  color: #0f4152;
}

.UhmainContent .UhtopHeader .Uhright .Uhtext p {
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  color: #0f4152;
}

.UhmainContent .UhtopHeader .Uhright button {
  width: 182px;
  height: 44px;
  gap: 0px;
  opacity: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #097285;
  border-radius: 35px;

  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 23.Uh5px;
  text-align: center;
  color: #fff;
  border: none;
  outline: none;
  box-shadow: none;
}

.UhmainContent .UhtopContent {
  width: 100%;
  box-shadow: 0px 0px 30px 0px #0000001f;
  background: #fff;
  border-radius: 10px 10px 0px 0px;
  padding-bottom: 50px;
}

.UhmainContent .UhtopContent .Uhsearch,
.UhmainContent .UhprogressChapter .Uhsearch {
  padding: 34px 18px 21px 18px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.UhmainContent .UhtopContent .Uhsearch h2,
.UhmainContent .UhprogressChapter .Uhsearch h2 {
  font-family: "Heebo", sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  color: #0f4152;
}

/* Custom styles for the select dropdown */
.UhmainContent .UhtopContent .Uhsearch .Uhcustom-select {
  position: relative;
  max-width: 162px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.UheditBookModal .UhinnerContent .UhbodyModal form .Uhrow .Uhcustom-select {
  position: relative;
  width: calc(50% - 6px);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.UhmainContent .UhtopContent .Uhsearch .Uhcustom-select .Uhselect-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 13px;
}

.UheditBookModal .UhinnerContent .UhbodyModal form .Uhrow .Uhcustom-select .Uhselect-icon {
  position: absolute;
  right: 14px;
}

.UhmainContent .UhtopContent .Uhsearch .Uhcustom-select select,
.UheditBookModal .UhinnerContent .UhbodyModal form .Uhrow .Uhcustom-select select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-right: 21px;
  height: 42px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #b6b6b6;
  padding: 0px 16px;
  outline: none;
  box-shadow: none;
  border-radius: 35px;
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 33px;
  color: #0f4152;
}

.UheditBookModal .UhinnerContent .UhbodyModal form .Uhrow .Uhcustom-select select {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #626262;
}

.UheditBookModal .UhinnerContent .UhbodyModal form .Uhrow .Uhcustom-select select {
  border-radius: 5px !important;
  height: 50px !important;
  background: #ffffff;
  border: 1px 0px 0px 0px;
  border: 1px solid #cdcfd6;
  border-radius: 5px;
  padding: 0px 23px;
  outline: none;
  box-shadow: none;
}

.UhmainContent .UhtopContent .Uhsearch .Uhselect-icon {
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 100%;
}

.UhmainContent .UhtopContent .Uhsearch .UhsearchInput,
.UhmainContent .UhprogressChapter .Uhsearch .UhsearchInput {
  max-width: 452px;
  width: 70%;
  height: 42px;
  border: 1px solid #b6b6b6;
  border-radius: 35px;
  overflow: hidden;
  display: flex;
  align-items: center;
}
.UhmainContent .UhprogressChapter .Uhsearch .UhsearchInput {
  max-width: 174px;
  width: 70%;
}
.UhmainContent .UhtopContent .Uhsearch .UhsearchInput input,
.UhmainContent .UhprogressChapter .Uhsearch .UhsearchInput input {
  width: calc(100% - 35px);
  height: 100%;
  border-radius: 35px;
  outline: none;
  box-shadow: none;
  border: none;
  padding: 0px 14px;
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20.Uh56px;
  color: #0f4152;
}

.UhmainContent .UhtopContent .Uhsearch .UhsearchInput input::placeholder,
.UhmainContent .UhtopContent .Uhsearch .UhsearchInput input option[disabled],
.UhmainContent .UhprogressChapter .Uhsearch .UhsearchInput input::placeholder {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20.Uh56px;
  color: #0f4152;
}

.UhmainContent .UhtopContent .Uhsearch .UhsearchInput img {
  cursor: pointer;
}

.UhmainContent .UhtopContent .Uhsearch button {
  width: 119px;
  height: 44px;
  background: #59a0ad;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 23.Uh5px;
  text-align: center;
  color: #fff;
  border-radius: 35px;
  border: none;
  outline: none;
  box-shadow: none;
}

.UhmainContent .UhtopContent .Uhsearch button.Uhactive {
  background: #097285;
}

.UhmainContent .Uhline {
  width: calc(100% - 36px);
  margin: auto;
  display: block;
  border: 1px dotted #cdcfd6;
  margin-bottom: 20px;
}

.UhmainContent .Uhbooks {
  width: 100%;
}

.UhmainContent .Uhbooks h1 {
  font-family: "Heebo", sans-serif;
  font-size: 28px;
  font-weight: 700;
  line-height: 41.Uh13px;
  text-align: left;
  color: #0f4152;
  margin-left: 18px;
  margin-bottom: 15px;
}

.UhmainContent .Uhbooks .UheaderBooksList {
  margin-left: 18px;
}

.UhmainContent .Uhbooks .UheaderBooksList button {
  background: none;
  border: none;
  outline: none;
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-right: 20px;
  color: #0f4152;
  padding-bottom: 3px;
  cursor: pointer;
}

.UhmainContent .Uhbooks .UheaderBooksList button.Uhactive::after {
  content: "";
  display: block;
  width: 58px;
  margin-bottom: -3px;
  margin-top: 3px;
  height: 4px;
  border-radius: 35px;
  background: #0f4152;
}

.UhmainContent .Uhbooks .UheaderBooksList img:nth-last-child(2) {
  margin-left: 39px;
  margin-right: 20px;
}

.UhmainContent .Uhbooks .UhcontentTab {
  width: 100%;
  margin-bottom: 80px;
}

.UhmainContent .Uhbooks .UhcontentTab .Uhtabcontent {
  display: none;
}

.UhmainContent .Uhbooks .UhcontentTab .Uhtabcontent .Uhrow:nth-child(1) {
  border-top: 1px solid #cdcfd6;
}

.UhmainContent .Uhbooks .UhcontentTab .Uhtabcontent .Uhrow {
  width: 100%;
  padding: 13px 18px;
  border-bottom: 1px solid #cdcfd6;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.UhmainContent .Uhbooks .UhcontentTab .Uhtabcontent .Uhrow .Uhleft {
  max-width: 234px;
  width: 25%;
  display: flex;
  align-items: center;
}

.UhmainContent .Uhbooks .UhcontentTab .Uhtabcontent .Uhrow .Uhleft img {
  margin-right: 20px;
  width: 72px;
  height: 97px;
  object-fit: cover;
}

.UhmainContent .Uhbooks .UhcontentTab .Uhtabcontent .Uhrow .Uhleft .Uhspan h2 {
  font-family: "Heebo", sans-serif;
  font-size: 22px;
  font-weight: 700;
  line-height: 32.Uh31px;
  color: #0f4152;
  border-bottom: 1px dotted #0a7f94;
  margin-bottom: 5px;
}

.UhmainContent .Uhbooks .UhcontentTab .Uhtabcontent .Uhrow .Uhleft .Uhspan p {
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.Uh2px;
  text-align: left;
  color: #3d3f45;
}

.UhmainContent .Uhbooks .UhcontentTab .Uhtabcontent .Uhrow .Uhcenter {
  width: calc(100% - 530px);
  display: flex;
  align-items: center;
}

.UhmainContent .Uhbooks .UhcontentTab .Uhtabcontent .Uhrow .Uhcenter p {
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 23.Uh5px;
  color: #2d2d2d;
  margin-right: 12px;
}

.UhmainContent .Uhbooks .UhcontentTab .Uhtabcontent .Uhrow .Uhright {
  max-width: 235px;
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.UhmainContent .Uhbooks .UhcontentTab .Uhtabcontent .Uhrow .Uhright .Uhread {
  width: 119px;
  height: 44px;
  border: 1px solid #097285;
  border-radius: 35px;
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  background: transparent;
  color: #000;
  font-weight: 700;
  line-height: 23.Uh5px;
  text-align: center;
  color: #0b7a8e;
  margin-right: 9px;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;
}

.UhmainContent .Uhbooks .UhcontentTab .Uhtabcontent .Uhrow .Uhright .Uhread img {
  margin-right: 16px;
}

.UhmainContent .Uhbooks .UhcontentTab .Uhtabcontent .Uhrow .Uhright .UhcontinueReading {
  width: 182px;
  height: 44px;
  background: #097285;
  color: #fff;
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 23.Uh5px;
  text-align: center;
  border-radius: 35px;
  outline: none;
  margin-right: 9px;
  box-shadow: none;
  border: none;
  cursor: pointer;
}

.UhmainContent .Uhbooks .UhcontentTab .Uhtabcontent .Uhrow .Uhright .Uhcircle,
.UhmainContent .UhprogressChapter .UhcontentTab .Uhtabcontent .Uhrow .Uhcircle {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  border: 1px solid #0b7a8e;
  color: #097285;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Heebo", sans-serif;
  font-size: 18px;
  font-weight: 500;
  position: relative;
  cursor: pointer;
}
.UhmainContent .UhprogressChapter .UhcontentTab .Uhtabcontent .Uhrow .Uhcircle {
  font-size: 14px;
}

.UhmainContent .Uhbooks .UhcontentTab .Uhtabcontent .Uhrow .Uhright .Uhcircle .UhdropDownTable,
.UhmainContent
  .UhprogressChapter
  .UhcontentTab
  .Uhtabcontent
  .Uhrow
  .Uhcircle
  .UhdropDownTable {
  position: absolute;
  right: -7px;
  z-index: 9999;
  box-shadow: 0px 2px 52px -9px #203fb233;
  background: #fff;
  top: 140%;
  border-radius: 7px;
  display: none;
  padding-top: 7px;
  padding-bottom: 7px;
}

.UhmainContent
  .Uhbooks
  .UhcontentTab
  .Uhtabcontent
  .Uhrow
  .Uhright
  .Uhcircle
  .UhdropDownTable
  .Uhinner,
.UhmainContent
  .UhprogressChapter
  .UhcontentTab
  .Uhtabcontent
  .Uhrow
  .Uhcircle
  .UhdropDownTable
  .Uhinner {
  position: absolute;
  height: auto;
  width: 244px;
  right: -7px;
  top: 10%;
  box-shadow: 0px 2px 52px -9px #203fb233;
  background: #fff;
  border-radius: 7px;
}

.UhmainContent
  .Uhbooks
  .UhcontentTab
  .Uhtabcontent
  .Uhrow
  .Uhright
  .Uhcircle
  .UhdropDownTable
  .Uhinner
  .Uhsquare,
.UhmainContent
  .UhprogressChapter
  .UhcontentTab
  .Uhtabcontent
  .Uhrow
  .Uhcircle
  .UhdropDownTable
  .Uhinner
  .Uhsquare {
  position: absolute;
  right: 20px;
  top: -6%;
  z-index: 1;
  width: 17px;
  height: 17px;
  transform: rotate(45deg);
  background: #fff;
}

.UhmainContent
  .Uhbooks
  .UhcontentTab
  .Uhtabcontent
  .Uhrow
  .Uhright
  .Uhcircle
  .UhdropDownTable
  .Uhinner
  button,
.UhmainContent
  .UhprogressChapter
  .UhcontentTab
  .Uhtabcontent
  .Uhrow
  .Uhcircle
  .UhdropDownTable
  .Uhinner
  button {
  width: 100%;
  height: 51px;
  background: transparent;
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  cursor: pointer;
  font-weight: 400;
  position: relative;
  border: none;
  z-index: 999;
  outline: none;
  box-shadow: none;
  line-height: 33px;
  padding: 0px 20px;
  text-align: left;
  color: #0f4152;
}

.UhmainContent
  .Uhbooks
  .UhcontentTab
  .Uhtabcontent
  .Uhrow
  .Uhright
  .Uhcircle
  .UhdropDownTable
  .Uhinner
  button:hover,
.UhmainContent
  .UhprogressChapter
  .UhcontentTab
  .Uhtabcontent
  .Uhrow
  .Uhcircle
  .UhdropDownTable
  .Uhinner
  button:hover {
  background: #eaebf1;
  font-size: 14px;
  font-weight: 700;
  color: #0f4152;
}
.top .right .accessiblity {
  position: absolute;
  bottom: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0px;
  right: 0px;
  margin-left: auto;
  margin-right: auto;
}
.top .right .accessiblity p {
  display: flex;
  align-items: center;
  justify-content: center;
}

.top .right .accessiblity p img {
  margin-right: 17px;
}
.top .right .accessiblity p a {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 33px;
  text-align: left;
  color: #0f4152;
}

/* all modal css here =========== */
.UheditBookModal,
.UhdeleteBookModal,
.UheditChapterModal,
.UhpaymentModal,
.UhsettingModal {
  position: fixed;
  top: 0px;
  right: 0px;
  width: 100%;
  left: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.Uh8);
  z-index: 9999999;
  overflow-y: auto;
  display: none;
}

.UhpaymentModal .UhinnerContent {
  max-width: 782px;
  width: 90%;
  height: auto;
  border-radius: 10px;
  opacity: 0px;
  background: #ffffff;
  border-radius: 7px;
  margin: 79px auto;
  position: relative;
  padding: 62px 33px;
  padding-bottom: 80px;
}

.UhpaymentModal .UhinnerContent .UhcrossPurchase {
  position: absolute;
  cursor: pointer;
  left: 33px;
  top: 33px;
}

.UhpaymentModal .UhinnerContent h1 {
  color: #023842;
  font-family: "Heebo", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  margin-bottom: 9px;
}

.UhpaymentModal .UhinnerContent p {
  font-family: "Heebo", sans-serif;
  max-width: 605px;
  width: 100%;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  margin: auto;
  text-align: center;
  color: #023842;
  margin-bottom: 42px;
}
.UhpaymentModal .UhinnerContent .UhuserCards {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #b9b9b9;
  height: 106px;
  border-radius: 7px;
  padding: 0px 20px;
  margin-bottom: 42px;
  display: flex;
}
.UhpaymentModal .UhinnerContent .UhuserCards .Uhcard {
  width: 25%;
  position: relative;
}
.UhpaymentModal .UhinnerContent .UhuserCards .Uhcard p {
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #2d2d2d;
  margin-bottom: 6px;
}
.UhpaymentModal .UhinnerContent .UhuserCards .Uhcard h6 {
  font-family: "Heebo", sans-serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #44c6cb;
}
.UhpaymentModal .UhinnerContent .UhuserCards .Uhcard img {
  position: absolute;
  right: 0px;
  top: 5px;
}
.UhpaymentModal .UhinnerContent form {
  display: flex;
  width: 100%;
  margin-bottom: 25px;
}
.UhpaymentModal .UhinnerContent form .Uhleft {
  width: 167px;
  margin-right: 10px;
}
.UhpaymentModal .UhinnerContent form .Uhleft p {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 33px;
  color: #626262;
  margin-bottom: 0px;
  text-align: left;
}
.Uhnumber-input-container {
  position: relative;
  width: 167px;
}

.Uhnumber-input-container input[type="number"] {
  width: 100%;
  height: 50px;
  text-align: left;
  border: 1px solid #cdcfd6;
  outline: none;
  box-shadow: none;
  background: #fff;
  border-radius: 7px;
  font-size: 16px;
  padding-left: 10px; 
  padding-right: 40px; 
  padding-right: 13px; 
  -moz-appearance: textfield; 
}

.Uhnumber-input-container input[type="number"]::-webkit-outer-spin-button,
.Uhnumber-input-container input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; 
}

.Uhnumber-input-container .Uhcustom-buttons {
  position: absolute;
  right: 5px;
  top: 10px;
  display: flex;
  flex-direction: column;
  height: 50px; 
}

.Uhnumber-input-container button {
  width: 20px;
  height: 15px;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  background: transparent;
  line-height: 0;
}
.UhpaymentModal .UhinnerContent form .Uhright {
  width: 167px;
}
.UhpaymentModal .UhinnerContent form .Uhright p {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 33px;
  color: #626262;
  margin-bottom: 0px;
  text-align: left;
}

.UhpaymentModal .UhinnerContent form .Uhright .Uhcustom-select {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 13px;
}

.UhpaymentModal .UhinnerContent form .Uhright .Uhcustom-select .Uhselect-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 14px;
  height: 50px;
}

.UhpaymentModal .UhinnerContent form .Uhright .Uhcustom-select .Uhselect-icon img {
  position: absolute;
  right: 14px;
  top: 17px;
}

.UhpaymentModal .UhinnerContent form .Uhright .Uhcustom-select select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #626262;
  width: 100%;
  padding: 0px 14px;
  background: #ffffff;
  border-radius: 5px;
  height: 50px;
  border: 1px solid #cdcfd6;
  outline: none;
  box-shadow: none;
}

.UhpaymentModal .UhinnerContent form .Uhright .Uhcustom-select .Uhselect-icon {
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 100%;
}

.UhpaymentModal .UhinnerContent .Uhtotal {
  width: 100%;
  height: 51px;
  background: #e9e9e9;
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  outline: none;
  box-shadow: none;
  border-radius: 48px;
  padding: 0px 22px;
  margin-bottom: 25px;
}
.UhpaymentModal .UhinnerContent .UhpurchaseBtn {
  width: 168px;
  height: 50px;
  border-radius: 48px;
  background: #0a7a8f;
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 500;
  display: block;
  line-height: 23.Uh5px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  border: none;
  float: right;
  outline: none;
  box-shadow: none;
}
.UhpaymentModal .UhinnerContent .Uhtotal .Uhrightt h3,
.UhpaymentModal .UhinnerContent .Uhtotal .Uhleftt h3 {
  font-family: "Heebo", sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 26.Uh44px;
  color: #0a7a8f;
  width: auto;
}
.UhpaymentModal .UhinnerContent .Uhtotal .Uhleftt {
  display: flex;
  align-items: center;
}
.UhpaymentModal .UhinnerContent .Uhtotal .Uhleftt h3 {
  width: 142px;
}

.UhpaymentModal .UhinnerContent .Uhtotal .Uhleftt h6 {
  max-width: auto !important;
  position: relative;
  width: auto !important;
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 33px;
  color: #626262;
}
.UheditBookModal .UhinnerContent {
  max-width: 682px;
  width: 90%;
  margin: 79px auto;
  background: #ffffff;
  border-radius: 10px;
  overflow: hidden;
}

.UheditBookModal .UhinnerContent .UheaderModal {
  width: 100%;
  height: 87px;
  background: #2ca3b9;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.UheditBookModal .UhinnerContent .UheaderModal .Uhcancel,
.UhdeleteBookModal .UhinnerContent .UheaderModal .Uhcancel,
.UhsettingModal .UhinnerContent .UheaderModal .Uhcancel {
  position: absolute;
  left: 41px;
  cursor: pointer;
}

.UheditBookModal .UhinnerContent .UheaderModal h1,
.UhdeleteBookModal .UhinnerContent .UheaderModal h1,
.UhsettingModal .UhinnerContent .UheaderModal h1 {
  font-family: "Heebo", sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #fff;
}

.UheditBookModal .UhinnerContent .UhbodyModal form {
  width: 100%;
  padding: 51px 71px;
}

.UheditBookModal .UhinnerContent .UhbodyModal form form {
  width: 100%;
}

.UheditBookModal .UhinnerContent .UhbodyModal form .Uhrow {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}

.UheditBookModal .UhinnerContent .UhbodyModal form .Uhrow input {
  width: calc(50% - 6px);
  height: 50px;
  background: #ffffff;
  border: 1px 0px 0px 0px;
  border: 1px solid #cdcfd6;
  border-radius: 5px;
  padding: 0px 23px;
  outline: none;
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #626262;
  box-shadow: none;
}

.UheditBookModal .UhinnerContent .UhbodyModal form textarea {
  width: 100%;
  resize: none;
  border-radius: 5px;
  height: 129px;
  border: 1px 0px 0px 0px;
  padding: 14px 16px;
  background: #ffffff;
  border: 1px solid #cdcfd6;
  outline: none;
  box-shadow: none;
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #626262;
  margin-bottom: 12px;
}

.UheditBookModal .UhinnerContent .UhbodyModal form textarea::placeholder {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #626262;
}

.Uhregister .Uhinner form input::placeholder,
.Uhregister .Uhinner form input option[disabled] {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #626262;
}

.UheditBookModal .UhinnerContent .UhbodyModal form .UhdeleteEditBook {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}

.UheditBookModal .UhinnerContent .UhbodyModal form .UhdeleteEditBook img {
  width: 33px;
  height: 45px;
  margin-right: 19px;
}

.UheditBookModal .UhinnerContent .UhbodyModal form .UhdeleteEditBook a {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: #c60000;
}

.UheditBookModal .UhinnerContent .UhbodyModal form .Uhcontinue {
  width: 168px;
  height: 50px;
  margin-left: auto;
  float: right;
  margin-bottom: 28px;
  cursor: pointer;
  border-radius: 35px;
  background: #097083;
  border: none;
  outline: none;
  box-shadow: none;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.Uh96px;
  text-align: center;
  color: #fff;
}

.UhdeleteBookModal .UhinnerContent {
  max-width: 499px;
  width: 90%;
  border-radius: 10px;
  overflow: hidden;
  background: #ffffff;
  margin: 79px auto;
}

.UhdeleteBookModal .UhinnerContent .UheaderModal {
  width: 100%;
  height: 87px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 10px 10px 0px 0px;
  background: #b92c78;
}

.UhdeleteBookModal .UhinnerContent .UhbodyModal {
  width: 100%;
  padding: 34px;
}

.UhdeleteBookModal .UhinnerContent .UhbodyModal p {
  font-family: "Heebo", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #626262;
}

.UhdeleteBookModal .UhinnerContent .UhbodyModal .Uhbtns {
  display: flex;
  align-items: center;
  justify-content: center;
}

.UhdeleteBookModal .UhinnerContent .UhbodyModal .Uhbtns .UhdeleteBtn {
  width: 168px;
  height: 50px;
  gap: 0px;
  opacity: 0px;
  border-radius: 35px;
  background: #b92c78;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.Uh96px;
  text-align: center;
  color: #fff;
  border: none;
  outline: none;
  box-shadow: none;
  margin-right: 6px;
  cursor: pointer;
}

.UhdeleteBookModal .UhinnerContent .UhbodyModal .Uhbtns .UhcancelBtn {
  width: 168px;
  height: 50px;
  background: transparent;
  border-radius: 35px;
  border: 1px solid #000000;
  cursor: pointer;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.Uh96px;
  text-align: center;
  color: #343434;
}

.UheditChapterModal .UhinnerContent {
  max-width: 411px;
  width: 90%;
  margin: 79px auto;
  overflow: hidden;
  background: #fff;
  border-radius: 10px;
}

.UheditChapterModal .UhinnerContent .UheaderModal {
  width: 100%;
  background: #2ca3b9;
  height: 87px;
  display: flex;
  padding: 0px 27px;
  align-items: center;
  justify-content: space-between;
}

.UheditChapterModal .UhinnerContent .UheaderModal img {
  cursor: pointer;
}

.UheditChapterModal .UhinnerContent .UheaderModal h1 {
  font-family: "Heebo", sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 24px;
  color: #fff;
}

.UheditChapterModal .UhinnerContent .UhbodyModal {
  padding: 27px;
}

.UheditChapterModal .UhinnerContent .UhbodyModal .Uhrow {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 29px;
}

.UheditChapterModal .UhinnerContent .UhbodyModal .Uhrow h3 {
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: #097083;
}

.UheditChapterModal .UhinnerContent .UhbodyModal .Uhrow img {
  cursor: pointer;
}

.UheditChapterModal .UhinnerContent .UhbodyModal .UhaddedChapter {
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #023842;
}

.UheditChapterModal .UhinnerContent .UhbodyModal .UhsureToCancel {
  margin-top: 160px;
  width: 100%;
}

.UheditChapterModal .UhinnerContent .UhbodyModal .UhsureToCancel p {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  color: #b92c78;
  margin-bottom: 5px;
}

.UheditChapterModal .UhinnerContent .UhbodyModal .UhsureToCancel .UhbtnsCancel {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 42px;
}

.UheditChapterModal .UhinnerContent .UhbodyModal .UhsureToCancel .UhbtnsCancel .UhyesBtn {
  width: 69px;
  height: 35px;
  border-radius: 35px;
  background: #b92c78;
  border: none;
  outline: none;
  box-shadow: none;
  margin-right: 8px;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.Uh96px;
  text-align: center;
  color: #fff;
  cursor: pointer;
}

.UheditChapterModal
  .UhinnerContent
  .UhbodyModal
  .UhsureToCancel
  .UhbtnsCancel
  .UhcancelBtn {
  width: 105px;
  height: 35px;
  border-radius: 35px;
  border: 1px 0px 0px 0px;
  border: 1px solid #000000;
  cursor: pointer;
  font-family: "Rubik", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.Uh59px;
  text-align: center;
  background: transparent;
  color: #383838;
}

.UheditChapterModal .UhinnerContent .UhbodyModal .Uhfinish {
  width: 168px;
  height: 50px;
  background: #097083;
  border: none;
  outline: none;
  box-shadow: none;
  border-radius: 35px;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.Uh96px;
  text-align: center;
  color: #fff;
  float: right;
  margin-bottom: 23px;
}

/* Modal Style */
.UhaddBookModal,
.UhaddLibraryModalTop,
.UheditLibraryModalTop,
.UhdeleteLibraryModalTop {
  position: fixed;
  top: 0px;
  right: 0px;
  width: 100%;
  left: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.Uh8);
  z-index: 9999999;
  overflow-y: auto;
  display: none;
}

/* Modal Content */
.UhaddBookModal .Uhmodal-content,
.UhaddLibraryModalTop .Uhmodal-content,
.UheditLibraryModalTop .Uhmodal-content,
.UhdeleteLibraryModalTop .Uhmodal-content {
  max-width: 682px;
  background-color: #fff;
  margin: 79px auto;
  width: 90%;
  border-radius: 7px;
  overflow: hidden;
  position: relative;
}

.UhaddLibraryModalTop .Uhmodal-content,
.UheditLibraryModalTop .Uhmodal-content,
.UhdeleteLibraryModalTop .Uhmodal-content {
  max-width: 499px;
}

/* Modal Header */
.UhaddBookModal .Uhmodal-content .Uhmodal-header,
.UhaddLibraryModalTop .Uhmodal-content .Uhmodal-header,
.UheditLibraryModalTop .Uhmodal-content .Uhmodal-header,
.UhdeleteLibraryModalTop .Uhmodal-content .Uhmodal-header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2ca3b9;
  width: 100%;
  height: 87px;
}

.UhdeleteLibraryModalTop .Uhmodal-content .Uhmodal-header {
  background: #b92c78 !important;
}

.UhaddBookModal .Uhmodal-content .Uhmodal-header .Uhclose,
.UhaddLibraryModalTop .Uhmodal-content .Uhmodal-header .Uhclose,
.UheditLibraryModalTop .Uhmodal-content .Uhmodal-header .Uhclose,
.UhdeleteLibraryModalTop .Uhmodal-content .Uhmodal-header .Uhclose {
  position: absolute;
  left: 41px;
  cursor: pointer;
}

.UhaddBookModal .Uhmodal-content .Uhmodal-header h2,
.UhaddLibraryModalTop .Uhmodal-content .Uhmodal-header h2,
.UheditLibraryModalTop .Uhmodal-content .Uhmodal-header h2,
.UhdeleteLibraryModalTop .Uhmodal-content .Uhmodal-header h2 {
  font-family: "Heebo", sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #fff;
}

.UhaddBookModal .Uhmodal-content .Uhmodal-body,
.UhaddLibraryModalTop .Uhmodal-content .Uhmodal-body,
.UheditLibraryModalTop .Uhmodal-content .Uhmodal-body,
.UhdeleteLibraryModalTop .Uhmodal-content .Uhmodal-body {
  padding: 35px;
  position: relative;
}

.UhaddBookModal .Uhmodal-content .Uhmodal-body img {
  margin-bottom: -40px;
}

.UhaddBookModal .Uhmodal-content .Uhmodal-body p,
.UhaddLibraryModalTop .Uhmodal-content .Uhmodal-body p,
.UheditLibraryModalTop .Uhmodal-content .Uhmodal-body p,
.UhdeleteLibraryModalTop .Uhmodal-content .Uhmodal-body p {
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  color: #023842;
}

.UhaddLibraryModalTop .Uhmodal-content .Uhmodal-body p,
.UheditLibraryModalTop .Uhmodal-content .Uhmodal-body p {
  text-align: center;
  margin-bottom: 25px;
}

.UhdeleteLibraryModalTop .Uhmodal-content .Uhmodal-body p {
  text-align: center;
  margin-bottom: 25px;
}

.UhdeleteLibraryModalTop .Uhmodal-content .Uhmodal-body p span {
  font-family: "Heebo", sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #626262;
}

.UhdeleteLibraryModalTop .Uhmodal-content .Uhmodal-body h6 {
  font-family: "Heebo", sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #e00561;
  margin-bottom: 35px;
}

.UhdeleteLibraryModalTop .Uhmodal-content .Uhmodal-body .UhbtnsDeleteLib {
  display: flex;
  align-items: center;
  justify-content: center;
}

.UhdeleteLibraryModalTop .Uhmodal-content .Uhmodal-body .UhbtnsDeleteLib .Uhcancel {
  max-width: 168px;
  width: 49%;
  height: 50px;
  border-radius: 40px;
  cursor: pointer;
  background: transparent;
  border: 1px solid #000000;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.Uh96px;
  text-align: center;
  color: #343434;
  margin-right: 3px;
}

.UhdeleteLibraryModalTop .Uhmodal-content .Uhmodal-body .UhbtnsDeleteLib .Uhdelete {
  max-width: 168px;
  width: 49%;
  height: 50px;
  cursor: pointer;
  border-radius: 40px;
  background: #b92c78;
  border: none;
  outline: none;
  box-shadow: none;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.Uh96px;
  text-align: center;
  color: #ffffff;
}

.UhaddLibraryModalTop .Uhmodal-content .Uhmodal-body form,
.UheditLibraryModalTop .Uhmodal-content .Uhmodal-body form {
  max-width: 383px;
  width: 100%;
  margin: auto;
}

.UhaddLibraryModalTop .Uhmodal-content .Uhmodal-body form input,
.UheditLibraryModalTop .Uhmodal-content .Uhmodal-body form input {
  width: 100%;
  height: 50px;
  margin-bottom: 13px;
  background: #ffffff;
  border: 1px solid #cdcfd6;
  border-radius: 7px;
  padding: 0px 25px;
  outline: none;
  box-shadow: none;
}

.UhaddLibraryModalTop .Uhmodal-content .Uhmodal-body form .Uhcustom-select,
.UheditLibraryModalTop .Uhmodal-content .Uhmodal-body form .Uhcustom-select {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 13px;
}

.UhaddLibraryModalTop .Uhmodal-content .Uhmodal-body form .Uhcustom-select .Uhselect-icon,
.UheditLibraryModalTop
  .Uhmodal-content
  .Uhmodal-body
  form
  .Uhcustom-select
  .Uhselect-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 14px;
  height: 50px;
}

.UhaddLibraryModalTop
  .Uhmodal-content
  .Uhmodal-body
  form
  .Uhcustom-select
  .Uhselect-icon
  img,
.UheditLibraryModalTop
  .Uhmodal-content
  .Uhmodal-body
  form
  .Uhcustom-select
  .Uhselect-icon
  img {
  position: absolute;
  right: 14px;
  top: 17px;
}

.UhaddLibraryModalTop .Uhmodal-content .Uhmodal-body form .Uhcustom-select select,
.UheditLibraryModalTop .Uhmodal-content .Uhmodal-body form .Uhcustom-select select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #626262;
  width: 100%;
  padding: 0px 14px;
  background: #ffffff;
  border-radius: 5px;
  height: 50px;
  border: 1px solid #cdcfd6;
  outline: none;
  box-shadow: none;
}

.UhaddLibraryModalTop .Uhmodal-content .Uhmodal-body form .Uhcustom-select .Uhselect-icon,
.UheditLibraryModalTop
  .Uhmodal-content
  .Uhmodal-body
  form
  .Uhcustom-select
  .Uhselect-icon {
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 100%;
}

.UhaddLibraryModalTop .Uhmodal-content .Uhmodal-body form button,
.UheditLibraryModalTop .Uhmodal-content .Uhmodal-body form button {
  width: 168px;
  height: 50px;
  border-radius: 40px;
  background: #097083;
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.Uh96px;
  text-align: center;
  float: right;
  outline: none;
  box-shadow: none;
  border: none;
  margin-bottom: 40px;
  color: #ffffff;
}

/* Modal footer */
.UhaddBookModal .Uhmodal-content .Uhmodal-footer {
  text-align: center;
}

.UhaddBookModal .Uhmodal-content .Uhmodal-body .Uhstep1Btn {
  position: absolute;
  right: 35px;
  bottom: 35px;
  width: 168px;
  height: 50px;
  border-radius: 35px;
  background: #097083;
  border: none;
  outline: none;
  box-shadow: none;
  cursor: pointer;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.Uh96px;
  text-align: center;
  color: #fff;
}

.UhaddBookModal .Uhmodal-content .Uhmodal-body .UheaderTitle {
  font-family: "Heebo", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #023842;
  margin-bottom: 11px;
}

.UhaddBookModal .Uhmodal-content .Uhmodal-body .UheaderPara {
  text-align: center;
  margin-bottom: 19px;
}

.UhaddBookModal .Uhmodal-content .Uhmodal-body form {
  width: 100%;
  padding: 0px 40px;
}

.UhaddBookModal .Uhmodal-content .Uhmodal-body form form {
  width: 100%;
}

.UhaddBookModal .Uhmodal-content .Uhmodal-body form .Uhrow {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}

.UhaddBookModal .Uhmodal-content .Uhmodal-body form .Uhrow input {
  width: calc(50% - 6px);
  height: 50px;
  background: #ffffff;
  border: 1px 0px 0px 0px;
  border: 1px solid #cdcfd6;
  border-radius: 5px;
  padding: 0px 16px;
  outline: none;
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #626262;
  box-shadow: none;
}

.UhaddBookModal .Uhmodal-content .Uhmodal-body form textarea {
  width: 100%;
  resize: none;
  border-radius: 5px;
  height: 129px;
  border: 1px 0px 0px 0px;
  padding: 14px 16px;
  background: #ffffff;
  border: 1px solid #cdcfd6;
  outline: none;
  box-shadow: none;
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #626262;
  margin-bottom: 19px;
}

.UhaddBookModal .Uhmodal-content .Uhmodal-body form textarea::placeholder {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #626262;
}

.UhaddBookModal .Uhmodal-content .Uhmodal-body form .Uhrow .Uhcustom-select {
  position: relative;
  width: calc(50% - 6px);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.UhaddBookModal .Uhmodal-content .Uhmodal-body form .Uhcustom-select .Uhselect-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 14px;
  height: 50px;
}

.UhaddBookModal .Uhmodal-content .Uhmodal-body form .Uhcustom-select .Uhselect-icon img {
  position: absolute;
  right: 14px;
  top: 17px;
}

.UhaddBookModal .Uhmodal-content .Uhmodal-body form .Uhrow .Uhcustom-select select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #626262;
  width: 100%;
  padding: 0px 14px;
  background: #ffffff;
  border-radius: 5px;
  height: 50px;
  border: 1px solid #cdcfd6;
  outline: none;
  box-shadow: none;
}

.UhaddBookModal .Uhmodal-content .Uhmodal-body form .Uhrow .Uhcustom-select .Uhselect-icon {
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 100%;
}

.UhaddBookModal .Uhmodal-content .Uhmodal-body .UhaddPara {
  text-align: center;
  font-weight: 500;
  color: #023842;
  margin-bottom: 153px;
}

.UhfileUploadCancel {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 13px;
}

.UhfileUploadCancel .UhredCross {
  cursor: pointer;
}

/* Custom Upload Button Style */
.Uhcustom-upload {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  margin-bottom: 9px;
}

.Uhcustom-upload .Uhfile-name {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: #626262;
  margin-right: 18px;
}

.Uhcustom-upload input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
  height: 100%;
  width: 100%;
}

.Uhcustom-upload button {
  width: 139px;
  height: 38px;
  border-radius: 35px;
  border: 1px solid #2ca3b9;
  cursor: pointer;
  color: #2ca3b9;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.Uh96px;
  text-align: center;
}

.Uhcustom-upload button img {
  margin-right: 9px;
  margin-bottom: 0px !important;
}

/* File Name Display */
.Uhfile-name {
  display: inline-block;
  margin-right: 10px;
}

.UhaddBookModal .Uhmodal-content .Uhmodal-body form .Uhmodal-footer .Uhback,
.UhaddBookModal .Uhmodal-content .Uhmodal-body .Uhback {
  width: 168px;
  height: 50px;
  border-radius: 35px;
  border: 1px 0px 0px 0px;
  opacity: 0px;
  border: 1px solid #097083;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.Uh96px;
  text-align: center;
  color: #097083;
  cursor: pointer;
  background: transparent;
  margin-right: 9px;
}

.UhaddBookModal .Uhmodal-content .Uhmodal-body .UhsuccessUpload {
  display: block;
  margin: auto;
}

.UhaddBookModal .Uhmodal-content .Uhmodal-body form .Uhmodal-footer .UhcontinueBtn,
.UhaddBookModal .Uhmodal-content .Uhmodal-body .UhcontinueBtn {
  width: 168px;
  height: 50px;
  border-radius: 35px;
  background: #097083;
  border: none;
  cursor: pointer;
  outline: none;
  box-shadow: none;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.Uh96px;
  text-align: center;
  color: #fff;
}

/* Close Button */
.Uhclose {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.Uhclose:hover,
.Uhclose:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

/* my account css here ===== */
.UhmainContent .UhcontentArea {
  width: 100%;
  box-shadow: 0px 0px 30px 0px #0000001f;
  background: #fff;
  border-radius: 0px;
  min-height: 92vh;
}

.UhmainContent .UhcontentArea .UheaderTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 46px;
}

.UhmainContent .UhcontentArea .UheaderTop h2 {
  font-family: "Heebo", sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 24px;
  color: #0f4152;
}

.UhmainContent .UhcontentArea .UheaderTop .UhtimeStamp {
  padding: 0px 26px;
  height: 45px;
  border-radius: 59px;
  background: #f1f1f1;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.UhmainContent .UhcontentArea .UheaderTop .UhtimeStamp h3 {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 20.Uh56px;
  text-align: left;
  color: #2d2d2d;
  margin-right: 9px;
}

.UhmainContent .UhcontentArea .UheaderTop .UhtimeStamp h3 span {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20.Uh56px;
  text-align: left;
  color: #2d2d2d;
}

.UhmainContent .UhcontentArea .Uhprogress {
  width: 100%;
}

.UhmainContent .UhcontentArea .Uhprogress .UhmyAccountList {
  width: 100%;
  border-bottom: 1px solid #acacac;
}

.UhmainContent .UhcontentArea .Uhprogress .UhmyAccountList button {
  margin-left: 22px;
  padding-bottom: 4px;
  background: transparent;
  outline: none;
  box-shadow: none;
  border: none;
  width: 108px;
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #5f5f5f;
  cursor: pointer;
}

.UhmainContent .UhcontentArea .Uhprogress .UhmyAccountList button.Uhactive {
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: #0a7a8f;
}

.UhmainContent .UhcontentArea .Uhprogress .UhmyAccountList button.Uhactive::after {
  content: "";
  display: block;
  width: 108px;
  height: 4px;
  margin-bottom: -4px;
  margin-top: 3px;
  border-radius: 35px;
  background: #0a7a8f;
}

.UhmainContent .UhcontentArea .Uhprogress .UhmyAccountList button:nth-last-child(1) {
  margin-left: 5px !important;
}

.UhmainContent .UhcontentArea .Uhprogress .UhtabContentTop {
  width: 100%;
  padding: 45px;
}

.UhmainContent .UhcontentArea .Uhprogress .UhtabContentTop .Uhtabcontent {
  display: none;
}

.UhmainContent .UhcontentArea .Uhprogress .UhtabContentTop .Uhtabcontent .Uhrow {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.UhmainContent .UhcontentArea .Uhprogress .UhtabContentTop .Uhtabcontent .Uhrow .Uhcard {
  max-width: 349px;
  width: 32%;
  height: 383px;
  overflow: hidden;
  background: #f1f1f1;
  border-radius: 35px;
  padding: 24px 24px 0px 24px;
  position: relative;
}

.UhmainContent .UhcontentArea .Uhprogress .UhtabContentTop .Uhtabcontent .Uhrow .Uhcard p {
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  text-align: center;
  color: #0f4152;
  margin-bottom: 6px;
}

.UhmainContent .UhcontentArea .Uhprogress .UhtabContentTop .Uhtabcontent .Uhrow .Uhcard h2 {
  font-family: "Heebo", sans-serif;
  font-size: 26px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #0a7a8f;
}

.UhmainContent
  .UhcontentArea
  .Uhprogress
  .UhtabContentTop
  .Uhtabcontent
  .Uhrow
  .Uhcard
  .UhbottomImg {
  position: absolute;
  bottom: -58px;
  left: 0px;
  right: 0px;
  margin: auto;
}

.UhmainContent
  .UhcontentArea
  .Uhprogress
  .UhtabContentTop
  .Uhtabcontent
  .Uhrow
  .Uhcard
  .UhrowTick {
  display: flex;
  align-items: center;
  justify-content: center;
}

.UhmainContent
  .UhcontentArea
  .Uhprogress
  .UhtabContentTop
  .Uhtabcontent
  .Uhrow
  .Uhcard
  .UhrowTick
  img {
  margin: 0px 2px;
}

.UhmainContent .UhcontentArea .Uhprogress .UhtabContentTop .Uhtabcontent form {
  max-width: 344px;
  width: 100%;
  flex-wrap: wrap;
}

.UhmainContent .UhcontentArea .Uhprogress .UhtabContentTop .Uhtabcontent form h2 {
  font-family: "Heebo", sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  color: #0f4152;
  margin-bottom: 18px;
}

.UhmainContent .UhcontentArea .Uhprogress .UhtabContentTop .Uhtabcontent form input {
  width: 100%;
  height: 50px;
  outline: none;
  box-shadow: none;
  border-radius: 7px;
  background: #ffffff;
  border: 1px solid #cdcfd6;
  margin-bottom: 7px;
  padding: 0px 23px;
}

.UhmainContent
  .UhcontentArea
  .Uhprogress
  .UhtabContentTop
  .Uhtabcontent
  form
  input::placeholder {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #626262;
}

.UhmainContent .UhcontentArea .Uhprogress .UhtabContentTop .Uhtabcontent form .UhformBtn {
  text-align: right;
}

.UhmainContent
  .UhcontentArea
  .Uhprogress
  .UhtabContentTop
  .Uhtabcontent
  form
  .UhformBtn
  button {
  width: 168px;
  height: 50px;
  border-radius: 35px;
  background: #0a7a8f;
  border: none;
  outline: none;
  box-shadow: none;
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 23.Uh5px;
  text-align: center;
  color: #ffffff;
}

.UhmainContent .UhindividualHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.UhmainContent .UhindividualHeader .Uhleft h2 {
  font-family: "Heebo", sans-serif;
  font-size: 28px;
  font-weight: 700;
  line-height: 24px;
  color: #0f4152;
  margin-right: 35px;
}

.UhmainContent .UhindividualHeader .Uhleft h2 span {
  font-family: "Heebo", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  color: #0f4152;
}

.UhmainContent .UhindividualHeader .Uhright {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.UhmainContent .UhindividualHeader .UhtotalUser {
  height: 59px;
  border-radius: 40px;
  background: #f1f1f1;
  padding: 0px 31px;
  border-radius: 59px;
  opacity: 0px;
}

.UhmainContent .UhindividualHeader .UhtotalUser h4 {
  font-family: "Heebo", sans-serif;
  font-size: 22px;
  font-weight: 700;
  line-height: 24px;
  color: #0f4152;
  margin: 22px 22px;
}

.UhmainContent .UhindividualHeader .UhtotalUser p {
  font-family: "Heebo", sans-serif;
  font-size: 18px;
  font-weight: 300;
  line-height: 24px;
  color: #0f4152;
}

.UhmainContent .UhindividualHeader .UhtotalUser p span {
  font-family: "Heebo", sans-serif;
  font-size: 26px;
  font-weight: 700;
  line-height: 24px;
  color: #0a7a8f;
}

.UhmainContent .UhindividualHeader .Uhright .UhprogressLine p {
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 23.Uh5px;
  text-align: center;
  color: #2d2d2d;
  width: 178px;
  margin-bottom: 3px;
}

.UhmainContent .UhindividualHeader .Uhright .UhprogressLine .UhgrayLine {
  width: 178px;
  height: 4px;
  border-radius: 35px;
  margin-right: 41px;
  background: #d8d8d8;
  position: relative;
  overflow: hidden;
}

.UhmainContent .UhindividualHeader .Uhright .UhprogressLine .UhgrayLine .UhinsideLine {
  background: #0a7a8f;
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
  border-radius: 35px;
}

.UhmainContent .UhindividualHeader .Uhright .Uhbtn {
  display: flex;
  align-items: center;
}

.UhmainContent .UhindividualHeader .Uhright .Uhbtn h2 {
  font-family: "Heebo", sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 26.Uh44px;
  color: #0f4152;
  margin-right: 9px;
}

.UhmainContent .UhindividualHeader .Uhright .Uhbtn button {
  width: 157px;
  height: 44px;
  border-radius: 35px;
  background: #097285;
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 23.Uh5px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}

.UhmainContent .UhprogressChapter {
  width: 100%;
  box-shadow: 0px 0px 30px 0px #0000001f;
  background: #fff;
  border-radius: 10px 10px 0px 0px;
  padding-bottom: 50px;
  min-height: 85vh;

}

.UhmainContent .UhprogressChapter .UhchapterList {
  border-bottom: 1px solid #acacac;
}

.UhmainContent .UhprogressChapter .UhchapterList button {
  margin-left: 18px;
  border: none;
  outline: none;
  box-shadow: none;
  background: transparent;
  padding-bottom: 3px;
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  width: 120px;
  font-weight: 400;
  line-height: 24px;
  position: relative;
  color: #5f5f5f;
  cursor: pointer;
}

.UhmainContent .UhprogressChapter .UhchapterList {
  width: 100%;
}

.UhmainContent .UhprogressChapter .UhchapterList button.Uhactive::after {
  content: "";
  display: block;
  width: 120px;
  height: 4px;
  margin-bottom: -4px;
  margin-left: 0px;
  margin-top: 3px;
  border-radius: 35px;
  background: #0a7a8f;
}

.UhmainContent .UhprogressChapter .UhchapterList button.Uhactive {
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #0a7a8f;
}

.UhmainContent .UhprogressChapter .UhcontentTab {
  width: 100%;
  margin-bottom: 50px;
}

.UhmainContent .UhprogressChapter .UhcontentTab .Uhtabcontent .Uhrow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 18px;
  border-bottom: 1px solid #cdcfd6;
}
.UhmainContent .UhaddLibTop .UhcontentTab .Uhtabcontent .Uhrow {
  padding: 5px 18px;
  height: 53px;
}
.UhmainContent .UhprogressChapter .UhcontentTab .Uhtabcontent .Uhrow .Uhcircle {
  width: 31px;
  height: 31px;
}
.UhcompletedBookProgress
  .UhmainContent
  .UhprogressChapter
  .UhcontentTab
  .Uhtabcontent
  .Uhrow
  h2 {
  font-family: "Heebo", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  width: 120px;
  color: #b8b8b8;
}

.UhmainContent .UhprogressChapter .UhcontentTab .Uhtabcontent .Uhrow .UhprogressBar {
  display: flex;
  align-items: center;
  /* width: 284px; */
  margin-right: 35px;
    
}

.UhmainContent
  .UhprogressChapter
  .UhcontentTab
  .Uhtabcontent
  .Uhrow
  .UhprogressBar
  p:nth-child(1) {
  width: 130px;
}

.UhmainContent
  .UhprogressChapter
  .UhcontentTab
  .Uhtabcontent
  .Uhrow
  .UhprogressBar
  .UhprogressTopLine {
  position: relative;
  width: 133px;
  height: 5px;
  background: #d8d8d8;
  border-radius: 35px;
  margin-right: 48px;
  overflow: hidden;
  
}

.UhmainContent
  .UhprogressChapter
  .UhcontentTab
  .Uhtabcontent
  .Uhrow
  .UhprogressBar
  .UhprogressTopLine
  .UhprogressInnerLine {
  background: #0a7a8f;
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 35px;
  bottom: 0px;
  
}

.UhmainContent
  .UhprogressChapter
  .UhcontentTab
  .Uhtabcontent
  .Uhrow
  .UhprogressBar
  .Uhcompleted {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  width: 160px;
  font-weight: 400;
  line-height: 24px;
  color: #626262;
  
}

.UhmainContent .UhprogressChapter .UhcontentTab .Uhtabcontent .Uhrow .Uhdescription {
  width: 450px;
  
}

.UhmainContent .UhprogressChapter .UhcontentTab .Uhtabcontent .Uhrow .Uhdescription p {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #c1c1c1;
  
}

.UhmainContent .UhprogressChapter .UhcontentTab .Uhtabcontent .Uhrow .UhbtnsList{
  /* width: 118px; */
  display: flex;
  
  align-items: center;
  justify-content: flex-end;
}
.UhmainContent .UhprogressChapter .UhcontentTab .Uhtabcontent .Uhrow .UhbtnsList.focus-visible{
  border: 1px solid #131212;
}
.UhbtnsList.focus-visible {
  border-bottom: 2px solid#1a0707; /* Full red border for keyboard focus */
}
.UhmainContent
  .UhprogressChapter
  .UhcontentTab
  .Uhtabcontent
  .Uhrow
  .UhbtnsList
  .UhkeepReading {
  width: 118px;
  
  height: 31px;
  border-radius: 35px;
  border: none;
  outline: none;
  box-shadow: none;
  background: #0a7a8f;
  color: #fff;
  font-family: "Heebo", sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 19.Uh09px;
  text-align: center;
}

.UhmainContent .UhprogressChapter .UhcontentTab .Uhtabcontent .Uhrow .UhbtnsList .UhreadLock {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 86px;
  height: 31px;
  border-radius: 35px;
  border: 1px solid #097285;
  background: transparent;
  margin-left: 5px;
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20.Uh56px;
  text-align: center;
  color: #0b7a8e;
}

.UhmainContent
  .UhprogressChapter
  .UhcontentTab
  .Uhtabcontent
  .Uhrow
  .UhbtnsList
  .UhreadLock
  img {
  width: 17px;
  height: 17px;
  margin-right: 11px;
}

.UhmainContent .UhprogressChapter .UhcontentTab .Uhtabcontent .Uhrow .UhbtnsList .Uhcolored {
  background: #0a7a8f;
  color: #fff;
}

.UhmainContent .UhprogressChapter .UhcontentTab .Uhtabcontent .Uhrow .UhbtnsList .Uhview,
.UhmainContent
  .UhprogressChapter
  .UhcontentTab
  .Uhtabcontent
  .Uhrow
  .UhbtnsList
  .UhreadAgain {
  margin: 3px;
  width: 82px;
  height: 31px;
  cursor: pointer;
  border-radius: 35px;
  border: 1px solid #b6b6b6;
  background: transparent;
  outline: none;
  box-shadow: none;
  font-family: "Heebo", sans-serif;
  font-size: 13px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  line-height: 19.Uh09px;
  text-align: center;
  color: #909090;
}
.UhmainContent .UhprogressChapter .UhcontentTab .Uhtabcontent .Uhrow .UhbtnsList 
.Uhview.focus-visible, .UhmainContent .UhprogressChapter .UhcontentTab .Uhtabcontent .Uhrow 
.UhbtnsList.focus-visible {
  outline: 2px solid#1a0707; /* Adjust color and thickness as needed */
  /* outline-offset: 2px; */
}

.UhmainContent
  .UhprogressChapter
  .UhcontentTab
  .Uhtabcontent
  .Uhrow
  .UhbtnsList
  .Uhview.Uhblue {
  border: 1px solid #0b7a8e;
  color: #0b7a8e;
}
.UhmainContent
  .UhprogressChapter
  .UhcontentTab
  .Uhtabcontent
  .Uhrow
  .UhbtnsList
  .Uhview.Uhblack {
  border: 1px solid #000;
  color: #000;
}

/* add this class to the completed book and all the color should appear  */
.UhmainContent .UhprogressChapter .UhcontentTab .Uhtabcontent .UhcompletedBookProgress {
  background: #f2f5fe !important;
}

.UhmainContent .UhprogressChapter .UhcontentTab .Uhtabcontent .Uhrow .Uhleft {
  display: flex;
  align-items: center;
}

.UhmainContent .UhprogressChapter .UhcontentTab .Uhtabcontent .Uhrow h2 {
  font-family: "Heebo", sans-serif;
  font-size: 18px;
  font-weight: 400;
  width: 100px;
  text-align: right;
  line-height: 24px;
  color: #2d2d2d;
  margin-right: 30px;
}
.UhmainContent .UhprogressChapter .UhcontentTab .Uhtabcontent .Uhrow .UhreadAgainText {
  color: #b8b8b8;
}
.UhmainContent .UhaddLibTop .UhcontentTab .Uhtabcontent .Uhrow h2 {
  width: 150px;
}

.UhmainContent
  .UhprogressChapter
  .UhcontentTab
  .Uhtabcontent
  .UhcompletedBookProgress
  .UhbtnsList
  .Uhview {
   cursor: pointer;
  background: #0a7a8f !important;
  color: #fff !important;
  border: none;
  outline: none;
  box-shadow: none;
}

.UhmainContent
  .UhprogressChapter
  .UhcontentTab
  .Uhtabcontent
  .UhcompletedBookProgress
  h2 {
  color: #0a7a8f;
  font-weight: 700;
}

.UhmainContent
  .UhprogressChapter
  .UhcontentTab
  .Uhtabcontent
  .UhcompletedBookProgress
  .UhprogressBar
  .Uhcompleted {
  font-weight: 700;
  color: #0a7a8f;
}

.UhmainContent
  .UhprogressChapter
  .UhcontentTab
  .Uhtabcontent
  .UhcompletedBookProgress
  .Uhdescription
  p {
  color: #0a7a8f;
  font-weight: 700;
}

/* reading book css here === */
.UhmainContent .UhreadingBookTop {
  padding: 31px 61px;
  position: relative;
}
.UhmainContent .UhreadingBookTop .Uhplayer .Uhgroup{
  display: none;
}

.UhbackPage {
  position: absolute;
  left: -70px;
  top: 51px;
  width: 51px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #1d9da2;
  background: #ffffff;
  cursor: pointer;
  border-radius: 40px;
}

.UhmainContent .UhreadingBookTop p {
  font-family: "Heebo", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #0f4152;
  margin-bottom: 23px;
}

.UhmainContent .UhreadingBookTop .Uhplayer {
  width: 100%;
  position: relative;
  padding: 15px 20px;
  border: 1px solid #9ebebf;
  height: 329px;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 201px;
}

.UhmainContent .UhreadingBookTop .Uhplayer h1 {
  font-family: "Heebo", sans-serif;
  font-size: 35px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #0f4152;
}

.UhmainContent .UhreadingBookTop .Uhplayer .UhfooterPlayer {
  position: absolute;
  bottom: 15px;
  width: 100%;
  left: 0px;
  right: 0px;
  border-radius: 7px;
  padding: 0px 20px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.UhmainContent .UhreadingBookTop .Uhplayer .UhfooterPlayer .Uhleft {
  display: flex;
  align-items: center;
  width: 140px;
}

.UhmainContent .UhreadingBookTop .Uhplayer .UhfooterPlayer .Uhleft img {
  cursor: pointer;
}

.UhmainContent .UhreadingBookTop .Uhplayer .UhfooterPlayer .Uhleft img:nth-last-child(1) {
  margin-left: 13px;
}

.UhmainContent .UhreadingBookTop .Uhplayer .UhfooterPlayer .Uhcenter {
  display: flex;
  justify-content: center;
}

.UhmainContent .UhreadingBookTop .Uhplayer .UhfooterPlayer .Uhcenter .Uhprevious,
.UhmainContent .UhreadingBookTop .Uhplayer .UhfooterPlayer .Uhcenter .Uhrepeat,
.UhmainContent .UhreadingBookTop .Uhplayer .UhfooterPlayer .Uhcenter .Uhstart,
.UhmainContent .UhreadingBookTop .Uhplayer .UhfooterPlayer .Uhcenter .Uhnext {
  width: 74px;
  height: 63px;
  border-radius: 6px;
  border: 1px 0px 0px 0px;
  opacity: 0px;
  border: 1px solid #e4e4e4;
  margin: 0px 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.UhmainContent .UhreadingBookTop .Uhplayer .UhfooterPlayer .Uhcenter .Uhprevious:hover,
.UhmainContent .UhreadingBookTop .Uhplayer .UhfooterPlayer .Uhcenter .Uhrepeat:hover,
.UhmainContent .UhreadingBookTop .Uhplayer .UhfooterPlayer .Uhcenter .Uhstart:hover,
.UhmainContent .UhreadingBookTop .Uhplayer .UhfooterPlayer .Uhcenter .Uhnext:hover {
  background: #f1f1f1;
}

.UhmainContent .UhreadingBookTop .Uhplayer .UhfooterPlayer .Uhcenter .Uhprevious:hover p,
.UhmainContent .UhreadingBookTop .Uhplayer .UhfooterPlayer .Uhcenter .Uhrepeat:hover p,
.UhmainContent .UhreadingBookTop .Uhplayer .UhfooterPlayer .Uhcenter .Uhstart:hover p,
.UhmainContent .UhreadingBookTop .Uhplayer .UhfooterPlayer .Uhcenter .Uhnext:hover p {
  color: #0f4152;
}

.UhmainContent .UhreadingBookTop .Uhplayer .UhfooterPlayer .Uhcenter .Uhprevious .Uhinner,
.UhmainContent .UhreadingBookTop .Uhplayer .UhfooterPlayer .Uhcenter .Uhrepeat .Uhinner,
.UhmainContent .UhreadingBookTop .Uhplayer .UhfooterPlayer .Uhcenter .Uhstart .Uhinner,
.UhmainContent .UhreadingBookTop .Uhplayer .UhfooterPlayer .Uhcenter .Uhnext .Uhinner {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.UhmainContent .UhreadingBookTop .Uhplayer .UhfooterPlayer .Uhcenter .Uhprevious .Uhinner img,
.UhmainContent .UhreadingBookTop .Uhplayer .UhfooterPlayer .Uhcenter .Uhrepeat img,
.UhmainContent .UhreadingBookTop .Uhplayer .UhfooterPlayer .Uhcenter .Uhstart img,
.UhmainContent .UhreadingBookTop .Uhplayer .UhfooterPlayer .Uhcenter .Uhnext img {
  margin-bottom: 4px;
  margin-top: 14px;
  width: 24px;
  height: 24px;
}

.UhmainContent .UhreadingBookTop .Uhplayer .UhfooterPlayer .Uhcenter .Uhprevious .Uhinner p,
.UhmainContent .UhreadingBookTop .Uhplayer .UhfooterPlayer .Uhcenter .Uhrepeat .Uhinner p,
.UhmainContent .UhreadingBookTop .Uhplayer .UhfooterPlayer .Uhcenter .Uhstart .Uhinner p,
.UhmainContent .UhreadingBookTop .Uhplayer .UhfooterPlayer .Uhcenter .Uhnext .Uhinner p {
  margin-bottom: 0px;
  font-family: "Heebo", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  text-align: center;
  color: #ababab;
}

.UhmainContent .UhreadingBookTop .Uhplayer .UhfooterPlayer .Uhright {
  width: 140px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.UhmainContent .UhreadingBookTop .Uhplayer .UhfooterPlayer .Uhright .UhcustomSelect {
  position: relative;
  width: 81px;
  height: 34px;
  border-radius: 35px;
  border: 1px solid #1d9da2;
  background: #fff;
}
.UhmainContent
  .UhreadingBookTop
  .Uhplayer
  .UhfooterPlayer
  .Uhright
  .UhcustomSelect
  .UhdropDownBtn {
  width: 100%;
  height: 100%;
  border-radius: 24px;
  background: transparent;
  border: none;
  outline: none;
  padding: 0px 10px;
  box-shadow: none;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 33px;
  color: #0a7a8f;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url("../../img/downSelectIcon.svg") no-repeat right 8px center;
  background-size: 12px;
  padding-right: 24px;
}

.UhmainContent
  .UhreadingBookTop
  .Uhplayer
  .UhfooterPlayer
  .Uhright
  .UhcustomSelect
  .UhdropContent {
  display: none;
  position: absolute;
  background-color: white;
  border: 1px solid #1d9da2;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.Uh2);
  z-index: 1;
  width: 81px;
  bottom: -8px;
  height: auto;
  overflow: hidden;
  border-radius: 25px;
}

.UhmainContent
  .UhreadingBookTop
  .Uhplayer
  .UhfooterPlayer
  .Uhright
  .UhcustomSelect
  .UhdropContent {
  display: none;
  position: absolute;
  background-color: white;
  border: 1px solid #1d9da2;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.Uh2);
  z-index: 1;
  width: 81px;
 
  bottom: -210px;
  height: auto;

  border-radius: 14px;
}
.UhmainContent
  .UhreadingBookTop
  .Uhplayer
  .UhfooterPlayer
  .Uhright
  .UhcustomSelect
  .UhdropContent
  a {
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
  display: block;
  padding: 0px 10px;
  line-height: 33px;
  text-align: left;
  color: #0a7a8f;
}

.UhmainContent
  .UhreadingBookTop
  .Uhplayer
  .UhfooterPlayer
  .Uhright
  .UhcustomSelect
  .UhdropContent
  a:hover {
  background-color: #0d8e9b;
  color: #fff;
}

.UhmainContent
  .UhreadingBookTop
  .Uhplayer
  .UhfooterPlayer
  .Uhright
  .UhcustomSelect
  .UhdropContent
  a.UhactiveBtn {
  background-color: #0d8e9b;
  color: #fff;
}

.UhmainContent
  .UhreadingBookTop
  .Uhplayer
  .UhfooterPlayer
  .Uhright
  .UhcustomSelect
  .UhdropContent.Uhshow {
  display: block;
  color: #fff;
}
.UhmainContent .UhreadingBookTop .Uhplayer .UhfooterPlayer .Uhright .UhcustomSelect img {
  position: absolute;
  right: 10px;
  top: 8px;
}

option {
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 33px;
  color: #0a7a8f;
}

.UhmainContent .UhreadingBookTop .UhprogressPlayer {
  width: 271px;
  margin: auto;
  margin-bottom: 123px;
}

.UhmainContent .UhreadingBookTop .UhprogressPlayer p {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #717171;
  margin-bottom: 11px;
}

.UhmainContent .UhreadingBookTop .UhprogressPlayer .Uhprogress {
  width: 100%;
  height: 7px;
  background: #d8d8d8;
  border-radius: 35px;
  overflow: hidden;
  position: relative;
}

.UhmainContent .UhreadingBookTop .UhprogressPlayer .Uhprogress .UhinnerLine {
  position: absolute;
  left: 0px;
  height: 100%;
  top: 0px;
  bottom: 0px;
  border-radius: 35px;
  background: #67b6c5;
}

.UhmainContent .UhreadingBookTop .UhpracticeEnd {
  display: flex;
  align-items: center;
  justify-content: center;
}

.UhmainContent .UhreadingBookTop .UhpracticeEnd h4 {
  font-family: "Heebo", sans-serif;
  font-size: 22px;
  font-weight: 900;
  line-height: 33px;
  text-align: center;
  color: #e90000;
}

/* celebrate here  */
.UhmainContent .UhreadingBookTop .Uhcelebrate {
  width: 100%;
  border: 1px solid #9ebebf;
  border-radius: 7px;
  height: 679px;
  overflow: hidden;
  margin-bottom: 100px;
}

.UhmainContent .UhreadingBookTop .Uhcelebrate h2 {
  font-family: "Heebo", sans-serif;
  font-size: 35px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #0f4152;
  margin-top: 57px;
  margin-bottom: 33px;
}

.UhmainContent .UhreadingBookTop .Uhcelebrate img {
  width: 100%;
  height: auto;
}

.UhsettingModal .UhinnerContent {
  max-width: 682px;
  width: 90%;
  margin: 79px auto;
  background: #ffffff;
  border-radius: 10px;
  overflow: hidden;
}

.UhsettingModal .UhinnerContent .UheaderModal {
  width: 100%;
  height: 87px;
  background: #2ca3b9;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.UhsettingModal .UhinnerContent .UhsettingModal-body {
  padding: 27px 34px;
  display: flex;
  justify-content: space-between;
}

.UhsettingModal .UhinnerContent .UhsettingModal-body .Uhleft {
  max-width: 265px;
  width: 48%;
}

.UhsettingModal .UhinnerContent .UhsettingModal-body .Uhleft .UheadText {
  width: 100%;
  border-bottom: 1px solid #000000;
  padding-bottom: 4px;
  margin-bottom: 9px;
}

.UhsettingModal .UhinnerContent .UhsettingModal-body .Uhleft .UheadText h2 {
  font-family: "Heebo", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  color: #023842;
}

.UhsettingModal .UhinnerContent .UhsettingModal-body .Uhleft form {
  width: 100%;
}

.UhsettingModal .UhinnerContent .UhsettingModal-body .Uhright {
  max-width: 265px;
  width: 48%;
}

.UhsettingModal
  .UhinnerContent
  .UhsettingModal-body
  .Uhleft
  form
  [type="radio"]:checked,
.UhsettingModal
  .UhinnerContent
  .UhsettingModal-body
  .Uhleft
  form
  [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.UhsettingModal
  .UhinnerContent
  .UhsettingModal-body
  .Uhleft
  form
  [type="radio"]:checked
  + label,
.UhsettingModal
  .UhinnerContent
  .UhsettingModal-body
  .Uhleft
  form
  [type="radio"]:not(:checked)
  + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  display: inline-block;
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #023842;
}

.UhsettingModal .UhinnerContent .UhsettingModal-body .Uhleft form p {
  margin-bottom: 5px;
}

.UhsettingModal .UhinnerContent .UhsettingModal-body .Uhleft form .Uhinner,
.UhsettingModal .UhinnerContent .UhsettingModal-body .Uhleft form .Uhinner {
  display: flex;
  align-self: center;
  font-family: "Heebo", sans-serif;
  font-size: 12px;
  font-weight: 300;
  line-height: 24px;
  margin-left: 28px;
  color: #023842;
  margin-top: -10px !important;
}

.UhsettingModal
  .UhinnerContent
  .UhsettingModal-body
  .Uhleft
  form
  [type="radio"]:checked
  + label
  span,
.UhsettingModal
  .UhinnerContent
  .UhsettingModal-body
  .Uhleft
  form
  [type="radio"]:not(:checked)
  + label
  span {
  display: flex;
  align-self: center;
  font-family: "Heebo", sans-serif;
  font-size: 12px;
  font-weight: 300;
  line-height: 24px;
  text-align: left;
  color: #023842;
  margin-top: -10px !important;
}

.UhsettingModal
  .UhinnerContent
  .UhsettingModal-body
  .Uhleft
  form
  [type="radio"]:checked
  + label:before,
.UhsettingModal
  .UhinnerContent
  .UhsettingModal-body
  .Uhleft
  form
  [type="radio"]:not(:checked)
  + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #000000;
  border-radius: 100%;
  background: #fff;
}

.UhsettingModal
  .UhinnerContent
  .UhsettingModal-body
  .Uhleft
  form
  [type="radio"]:checked
  + label:after,
.UhsettingModal
  .UhinnerContent
  .UhsettingModal-body
  .Uhleft
  form
  [type="radio"]:not(:checked)
  + label:after {
  content: "";
  width: 12px;
  height: 12px;
  background: #2ca3b9;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.Uh2s ease;
  transition: all 0.Uh2s ease;
}

.UhsettingModal
  .UhinnerContent
  .UhsettingModal-body
  .Uhleft
  form
  [type="radio"]:not(:checked)
  + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.UhsettingModal
  .UhinnerContent
  .UhsettingModal-body
  .Uhleft
  form
  [type="radio"]:checked
  + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.Uhcustom-dropdown  {
  position: relative;
  display: inline-block;
}

.Uhselected-value {
  width: 27px;
  height: 15px;
  border: 1px solid #000000;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Heebo", sans-serif;
  font-size: 12px;
  font-weight: 300;
  margin: 0px 5px;
  line-height: 24px;
  color: #023842;
  margin-top: 4px !important;
}


.Uhdropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  border-top: none;
  right: 0px;
  overflow: hidden;
  border-radius: 7px;
  width: 100%;
  width: 110px;
  z-index: 999;
  height: auto;
  box-shadow: 0px 4px 4px 0px #00000040;
}
.Uhcustom-dropdown .UheaderTimer{
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  display: none;
  margin-bottom: 11px;
}
.Uhcustom-dropdown .UheaderTimer h3 {
  font-family: "Heebo", sans-serif;
  font-size: 20px;
  display: inline;
  font-weight: 700;
  line-height: 24px;
  color: #023842;
}
.Uhcustom-dropdown .Uhdropdown-content p{
  display: none;
}
.Uhcustom-dropdown .UheaderTimer img{
  cursor: pointer;
}
.Uhdropdown-content .UhparaG{
  font-family: "Heebo", sans-serif;
font-size: 16px;
font-weight: 300;
line-height: 24px;
color: #023842;
margin-bottom: 20px;
display: none;
} 
.Uhdropdown-content .Uhoption {
  padding: 10px;
  height: 34px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  color: #023842;
}

.Uhdropdown-content .Uhoption:hover {
  background: #eaebf1;
  color: #023842;
  font-weight: 700;
}

.UhsettingModal .UhinnerContent .UhsettingModal-body .Uhleft form .Uhmarker {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  width: 300px !important;
}
 
.UhsettingModal .UhinnerContent .UhsettingModal-body .Uhleft form .Uhmarker .Uhmark {
  width: 148px;
  height: 29px;
  border-radius: 35px;
  background: transparent;
  border: 1px solid #acacac;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Heebo", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  color: #023842;
}

.UhsettingModal .UhinnerContent .UhsettingModal-body .Uhleft form .Uhmarker .Uhmark .Uhyellow,
.UhsettingModal .UhinnerContent .UhsettingModal-body .Uhleft form .Uhmarker .Uhmark .Uhgray {
  background: yellow;
  margin-right: 5px;
  padding: 0px 3px;
}

.UhsettingModal .UhinnerContent .UhsettingModal-body .Uhleft form .Uhmarker .Uhmark .Uhgray {
  background: #d5d5d5;
}

.UhsettingModal
  .UhinnerContent
  .UhsettingModal-body
  .Uhleft
  form
  .Uhmarker
  .Uhmark
  .Uhunderline {
  border-bottom: 1px solid #023842;
  margin-right: 5px;
  padding: 0px 3px;
}

.UhsettingModal .UhinnerContent .UhsettingModal-body .Uhleft .UheadText {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.UhsettingModal .UhinnerContent .UhsettingModal-body .Uhleft .UheadText p {
  font-family: "Heebo", sans-serif;
  font-size: 12px;
  font-weight: 300;
  line-height: 24px;
  color: #023842;
}

.UhsettingModal .UhinnerContent .UhbtnSave {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.UhsettingModal .UhinnerContent .UhbtnSave .Uhsave {
  width: 168px;
  height: 50px;
  border-radius: 35px;
  background: #097083;
  border: none;
  outline: none;
  box-shadow: none;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  cursor: pointer;
  font-weight: 500;
  line-height: 18.Uh96px;
  text-align: center;
  color: #ffffff;
  margin-right: 35px;
  margin-bottom: 35px;
}

/* mobile design here ======== */
.UhbookMobileColor .UhtopContent .Uhbooks .UhforMobileSearch {
  width: 100%;
  display: none;
  margin-bottom: 24px;
}

.UhbookMobileColor .UhtopContent .Uhbooks .UhforMobileSearch .UhdropDownSearch {
  position: relative;
  margin-bottom: 24px;
  z-index: 9999;
  box-shadow: 0px 0px 30px 0px #0000001f;
  background: #ffffff;
  border-radius: 7px;
}

.UhbookMobileColor .UhtopContent .Uhbooks .UhforMobileSearch .UhdropDownSearch .UhfindBook {
  height: 60px;

  padding: 0px 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.UhbookMobileColor
  .UhtopContent
  .Uhbooks
  .UhforMobileSearch
  .UhdropDownSearch
  .UhfindBook
  h3 {
  font-family: "Heebo", sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  color: #0f4152;
}

.UhbookMobileColor .UhtopContent .Uhbooks .UhforMobileSearch .UhsearchBooksMobile {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.UhbookMobileColor .UhtopContent .Uhbooks .UhforMobileSearch .UhsearchBooksMobile {
  width: 100%;
}

.UhbookMobileColor .UhtopContent .Uhbooks .UhforMobileSearch .UhsearchBooksMobile h3 {
  font-family: "Heebo", sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 24px;
  color: #0f4152;
}

.UhbookMobileColor
  .UhtopContent
  .Uhbooks
  .UhforMobileSearch
  .UhsearchBooksMobile
  .Uhcustom-select {
  position: relative;
  max-width: 246px;
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.UhbookMobileColor
  .UhtopContent
  .Uhbooks
  .UhforMobileSearch
  .UhsearchBooksMobile
  .Uhselect-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 13px;
}

.UhbookMobileColor
  .UhtopContent
  .Uhbooks
  .UhforMobileSearch
  .UhsearchBooksMobile
  .Uhcustom-select
  .Uhselect-icon {
  position: absolute;
  right: 14px;
}

.UhbookMobileColor
  .UhtopContent
  .Uhbooks
  .UhforMobileSearch
  .UhsearchBooksMobile
  .Uhcustom-select
  select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-right: 21px;
  height: 42px;
  width: 100%;
  background: transparent;
  border: 1px solid #097285;
  padding: 0px 16px;
  outline: none;
  box-shadow: none;
  border-radius: 35px;
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 33px;
  color: #5c5c5c;
}

.UhbookMobileColor
  .UhtopContent
  .Uhbooks
  .UhforMobileSearch
  .UhdropDownSearch
  .UhseachQuery {
  transition: 0.Uh4s;
  padding: 0px 18px 18px 18px;
  height: auto;
  display: none;
}

.UhbookMobileColor
  .UhtopContent
  .Uhbooks
  .UhforMobileSearch
  .UhdropDownSearch
  .UhseachQuery
  .Uhinner {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.UhbookMobileColor
  .UhtopContent
  .Uhbooks
  .UhforMobileSearch
  .UhdropDownSearch
  .UhseachQuery
  .Uhinner
  .Uhcustom-select {
  position: relative;
  width: 48%;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.UhbookMobileColor
  .UhtopContent
  .Uhbooks
  .UhforMobileSearch
  .UhdropDownSearch
  .UhseachQuery
  .Uhinner
  .Uhcustom-select
  .Uhselect-icon {
  position: absolute;
  top: 13px;
  right: 10px;
  width: 20px;
  height: 100%;
}

.UhbookMobileColor
  .UhtopContent
  .Uhbooks
  .UhforMobileSearch
  .UhdropDownSearch
  .UhseachQuery
  .Uhinner
  .Uhcustom-select
  select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-right: 21px;
  height: 42px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #b6b6b6;
  padding: 0px 16px;
  outline: none;
  box-shadow: none;
  border-radius: 35px;
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 33px;
  color: #0f4152;
}

.UhbookMobileColor
  .UhtopContent
  .Uhbooks
  .UhforMobileSearch
  .UhdropDownSearch
  .UhseachQuery
  .Uhinner
  .UhsearchInput {
  width: 100%;
  margin: 10px 0px;
  height: 42px;
  border: 1px solid #b6b6b6;
  border-radius: 35px;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.UhbookMobileColor
  .UhtopContent
  .Uhbooks
  .UhforMobileSearch
  .UhdropDownSearch
  .UhseachQuery
  .Uhinner
  .UhsearchInput
  input {
  width: calc(100% - 35px);
  height: 100%;
  border-radius: 35px;
  outline: none;
  box-shadow: none;
  border: none;
  padding: 0px 14px;
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20.Uh56px;
  color: #0f4152;
}

.UhbookMobileColor
  .UhtopContent
  .Uhbooks
  .UhforMobileSearch
  .UhdropDownSearch
  .UhseachQuery
  .Uhinner
  .UhsearchInput
  input::placeholder {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20.Uh56px;
  color: #0f4152;
}

.UhbookMobileColor
  .UhtopContent
  .Uhbooks
  .UhforMobileSearch
  .UhdropDownSearch
  .UhseachQuery
  .Uhinner
  img {
  cursor: pointer;
}

.UhbookMobileColor
  .UhtopContent
  .Uhbooks
  .UhforMobileSearch
  .UhdropDownSearch
  .UhseachQuery
  .Uhinner
  button {
  width: 119px;
  height: 44px;
  background: #59a0ad;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 23.Uh5px;
  text-align: center;
  color: #fff;
  border-radius: 35px;
  border: none;
  outline: none;
  box-shadow: none;
}

/* .UhbookMobileColor .UhtopContent .Uhbooks .UhforMobileSearch .UhdropDownSearch .UhseachQuery .Uhinner .Uhcustom-select .Uhselect-icon */
.UhbookMobileColor
  .UhtopContent
  .Uhbooks
  .UhforMobileSearch
  .UhdropDownSearch
  .UhseachQuery
  .Uhinner
  button.Uhactive {
  background: #097285;
}

#chapterMobile {
  font-family: "Heebo", sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 23px;
  color: #0f4152;
  margin-bottom: 24px;
  margin-top: 20px;
  display: none;
}

.Uhfooter {
  width: 100%;
  position: static;
  bottom: 0px;

  height: 66px;
  display: flex;
  align-items: center;
  background: #097185;
}

.Uhfooter .Uhinner {
  max-width: 1140px;
  width: 90%;
  margin: auto;
  display: flex;
  align-items: center;
}

.Uhfooter .Uhinner a {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 33px;
  width: 89px;
  text-align: right;
  color: #ffffff;
  text-decoration: none;
}
.UhmainContent .UhdetailAccountTop {
  padding: 58px 46px;
}
.UhmainContent .UhcontentArea .UhdetailAccount {
  display: flex;
  align-items: center;
  padding: 0px !important;
  justify-content: space-between;
  margin-bottom: 18px;
}

.UhmainContent .UhcontentArea .UhdetailAccount .UhactiveUsers {
  width: auto;
  padding: 0px 39px;
  height: 45px;
  background: #f1f1f1;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.UhmainContent .UhcontentArea .UhdetailAccount .UhactiveUsers p {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20.Uh56px;
  color: #2d2d2d;
  display: flex;
  align-items: center;
}
.UhmainContent .UhcontentArea .UhdetailAccount .UhactiveUsers p:nth-child(1) {
  margin-right: 37px;
}
.UhmainContent .UhcontentArea .UhdetailAccount .UhactiveUsers p img {
  margin-left: 15px;
}
.UhmainContent .UhcontentArea .UhuserRegistrationLink {
  width: 100%;
  margin: auto;
  border: 1px solid #cdcfd6;
  height: 83px;
  border-radius: 7px;
  display: flex;
  align-items: center;
  padding: 0px 24px;
  justify-content: space-between;
  margin-bottom: 28px;
}
.UhmainContent .UhcontentArea .UhuserRegistrationLink .Uhleft h3 {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  color: #0f4152;
}
.UhmainContent .UhcontentArea .UhuserRegistrationLink .Uhleft .UhcopyTexting {
  display: flex;
  align-items: center;
}
.UhmainContent .UhcontentArea .UhuserRegistrationLink .Uhleft .UhcopyTexting p,
.UhmainContent .UhcontentArea .UhuserRegistrationLink .Uhleft .Uhidden,
#copyNotification {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 24px;
  color: #44c6cb;
}
#copyNotification {
  color: rgb(235, 35, 8);
}
.UhmainContent .UhcontentArea .UhuserRegistrationLink .Uhleft .Uhidden {
  color: rgb(128, 9, 0);
  display: none;
}
.UhmainContent .UhcontentArea .UhuserRegistrationLink .Uhleft .UhcopyTexting img {
  cursor: pointer;
  margin-left: 16px;
}

.UhmainContent .UhcontentArea .UhuserRegistrationLink button {
  width: 265px;
  height: 43px;
  border-radius: 40px;
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20.Uh56px;
  text-align: center;
  color: #0a7a8f;
  border: 1px solid #979797;
  background: transparent;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;
  cursor: pointer;
}
.UhmainContent .UhcontentArea .UhuserRegistrationLink button img {
  margin-left: 22px;
}

.UhmainContent .UhcontentArea form {
  width: 100%;
  display: flex;
}
.UhmainContent .UhcontentArea form .Uhleft {
  max-width: 344px;
  width: 50%;
  margin-right: 55px;
}
.UhmainContent .UhcontentArea form .Uhleft h4,
.UhmainContent .UhcontentArea form .Uhright h4 {
  font-family: "Heebo", sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  color: #0f4152;
  margin-bottom: 18px;
}
.UhmainContent .UhcontentArea form .Uhleft input,
.UhmainContent .UhcontentArea form .Uhright input {
  border: 1px solid #cdcfd6;
  width: 100%;
  padding: 0px 23px;
  height: 50px;
  outline: none;
  box-shadow: none;
  background: transparent;
  border-radius: 7px;
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #626262;
  margin-bottom: 7px;
}
.UhmainContent .UhcontentArea form .Uhright {
  max-width: 344px;
  width: 50%;
}
.UhmainContent .UhcontentArea form .Uhleft .Uhcontainer {
  display: flex;
  align-items: center;
  margin-bottom: 7px;
}
.UhmainContent .UhcontentArea form .Uhleft .Uhcontainer .Uhcustom-file-upload {
  width: 50px;
  height: 50px;
  display: inline-block;
  border: 1px solid #cdcfd6;
  border-radius: 7px;
  cursor: pointer;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 36px; 
  background-color: transparent;
  position: relative;
}

.UhmainContent .UhcontentArea form .Uhleft .Uhcontainer .Uhcustom-file-upload:hover {
  background-color: #e0e0e0;
}
.UhmainContent .UhcontentArea form .Uhleft .Uhcontainer .Uhfile-name {
  margin-left: 18px;
  font-size: 16px;
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #626262;
}
.UhmainContent .UhcontentArea .UhsaveBtn {
  margin-top: 15px;
  width: 168px;
  height: 50px;
  border-radius: 40px;
  background: #0a7a8f;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 23.Uh5px;
  color: #ffffff;
  border: none;
  outline: none;
  box-shadow: none;
  cursor: pointer;
}
@media (max-width: 1300px) {
  .backPage {
    left: 0px;
    top: 10px;
    z-index: 999999999;
  }
  .UhmainContent .topHeader .backPage {
    top: 2px;
    left: 0px;
    height: 25px;
  }
}

@media (max-width: 1250px) {
  .UhmainContent
    .UhprogressChapter
    .UhcontentTab
    .Uhtabcontent
    .Uhrow
    .UhprogressBar
    .UhprogressTopLine {
    margin-left: 20px;
  }
}

@media (max-width: 1200px) {
  .header .left .logo {
    margin-left: 9px;
  }
  .header .left ul li {
    margin-right: 19px;
  }
  .UhmainContent
    .contentArea
    .progress
    .UhtabcontentTop
    .Uhtabcontent
    .Uhrow
    .card
    .bottomImg {
    width: 250px;
    display: block;
    margin: auto;
  }

  .UhmainContent .readingBookTop .celebrate {
    height: auto;
  }

  .UhmainContent .UhprogressChapter .UhcontentTab .Uhtabcontent .Uhrow .description {
    width: 400px !important;
  }

  .UhmainContent .topContent .search .searchInput {
    max-width: 250px;
  }
}

@media (max-width: 1100px) {
  /* .UhmainContent .UhprogressChapter .UhcontentTab .Uhtabcontent .Uhrow .UhprogressBar {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  } */
  .UhmainContent .UhprogressChapter .UhcontentTab .Uhtabcontent .Uhrow .UhprogressBar {
    /* flex-wrap: wrap; */
    /* flex-direction: column-reverse; */
    /* width: auto; */
  }
  .UhmainContent
    .UhprogressChapter
    .UhcontentTab
    .Uhtabcontent
    .Uhrow
    .UhprogressBar
    .completed {
    /* width: 100%; */
    margin-right: -14px;
    margin-left: 0px;
    text-align: center;
  }
}

@media (max-width: 992px) {
  .header {
    padding: 10px 5%;
  }
  .UhmainContent .topHeader .backPage,
  .backPage {
    display: none;
  }
  .UhmainContent .topContent .search .searchInput {
    max-width: 200px;
  }

  .header .right {
    display: none;
  }

  .header .left {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .header .left .mobileMenu {
    display: block;
    cursor: pointer;
  }

  .header .left .logo {
    margin-right: 0px;
  }

  .header .left .overLay {
    display: none;
  }

  .UhmainContent .contentArea .progress .UhtabcontentTop .Uhtabcontent .Uhrow .card h2 {
    font-size: 24px;
  }

  .UhmainContent .contentArea .progress .UhtabcontentTop .Uhtabcontent .Uhrow {
    flex-wrap: wrap;
  }

  .UhmainContent .contentArea .progress .UhtabcontentTop .Uhtabcontent .Uhrow .card {
    width: 49%;
    margin-bottom: 12px;
  }

  .UhmainContent .contentArea .progress .myAccountList {
    border-bottom: none;
    display: flex;
    justify-content: center;
  }

  .UhmainContentSuccess {
    width: 100% !important;
  }

  .UhmainContent .readingBookTop .celebrate {
    background: #fff;
  }

  .UhmainContent .readingBookTop .celebrate h2 {
    line-height: 40px;
  }
}

@media (max-width: 900px) {
  .UhmainContent .individualHeader .totalUser {
    display: none;
  }
  .UhmainContent .books .headerBooksList {
    display: none;
  }

  .UhmainContent .books .UhcontentTab .Uhtabcontent .Uhrow {
    background: #fff;
    border-radius: 7px;
    border: none;
    margin-bottom: 6px;
    box-shadow: 0px 0px 30px 0px #0000001f;
  }

  .UhmainContent .books h1 {
    display: none;
  }

  .bookMobileColor .topContent {
    box-shadow: none !important;
    background: transparent !important;
  }

  .bookMobileColor .topContent .books .forMobileSearch {
    display: block;
  }

  .UhmainContent .line {
    display: none;
  }

  .UhmainContent .books .UhcontentTab .Uhtabcontent .Uhrow .left,
  .UhmainContent .books .UhcontentTab .Uhtabcontent .Uhrow .center {
    max-width: 48%;
    width: 48%;
  }

  .UhmainContent .books .UhcontentTab .Uhtabcontent .Uhrow .right {
    max-width: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 24px;
  }

  .UhmainContent .books .UhcontentTab .Uhtabcontent .Uhrow {
    flex-wrap: wrap;
  }

  .UhmainContent .topContent .search {
    display: none;
  }

  .readingTop {
    max-width: 100% !important;
    width: 100% !important;
  }

  .UhmainContent .readingBookTop {
    background: transparent !important;
    padding: 15px 14px;
  }

  .UhmainContent .readingBookTop .hideParaMobile {
    display: none;
  }

  .UhmainContent .readingBookTop .player {
    background: #fff;
    height: 662px;
  }

  .UhmainContent .readingBookTop .player h1 {
    line-height: 55px;
  }

  .UhmainContent
    .readingBookTop
    .player
    .footerPlayer
    .center
    .previous
    .inner
    img,
  .UhmainContent .readingBookTop .player .footerPlayer .center .repeat img,
  .UhmainContent .readingBookTop .player .footerPlayer .center .start img,
  .UhmainContent .readingBookTop .player .footerPlayer .center .next img {
    margin-top: 7px;
  }

  .UhmainContent .readingBookTop .player {
    margin-bottom: 58px;
  }

  .UhmainContent .readingBookTop .progressPlayer {
    margin-bottom: 38px;
  }

  .UhmainContent .individualHeader .right {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    justify-content: flex-end;
  }

  .UhmainContent .individualHeader .right .progressLine {
    padding-right: 0px;
    margin-right: 0px;
    margin-left: 0px;
  }

  .UhmainContent .individualHeader .right .btn {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .UhmainContent .individualHeader .right .btn button {
    margin-bottom: 5px;
  }

  .UhmainContent .individualHeader .right .progressLine .grayLine {
    margin-right: 0px;
    width: 137px;
  }

  .UhmainContent .UhprogressChapter .UhcontentTab .Uhtabcontent .Uhrow .description {
    width: 250px !important;
  }
}
@media (max-width: 896px) {
  .UhmainContent .UhprogressChapter .UhcontentTab .Uhtabcontent .Uhrow h2 {
    margin-right: 3px;
  }
  .settingModal .innerContent .settingModal-body {
    padding: 20px;
  }

  .dropdown-content .option {
    display: none;
  }

  .custom-dropdown .headerTimer {
    display: block;
  }

  .custom-dropdown .headerTimer img {
   /* float: left; */
    display: inline;
  }

  .UhmainContent .readingBookTop .player {
    width: 90%;
    margin-left: 10%;
  }

  .UhmainContent .readingBookTop .player .group {
    display: block;
    width: 5%;
    position: absolute;
    top: 10px;
    left: -70px;
  }

  .UhmainContent .readingBookTop .player {
    height: 94vh;
    margin-bottom: 0px;
  }

  .UhmainContent .readingBookTop .player h1 {
    margin-bottom: 100px;
  }

  .headerReader {
    display: none;
  }

  .footerReader {
    display: none;
  }

  .UhmainContent .readingBookTop .progressPlayer {
    display: none;
  }

  .UhmainContent .readingBookTop .player .footerPlayer .center {
    position: absolute;
    top: -80px !important;
    left: 0px;
    right: 0px;
    margin: auto;
  }

  .settingModal .innerContent .settingModal-body .left form p {
    display: block;
    margin-bottom: 20px;
  }

  .custom-dropdown .inner2 {
    position: fixed;
    z-index: 9999999;
    background: rgba(0, 0, 0, 0);
    top: 0px;
    right: 0px;
    bottom: 0px;
    overflow-y: auto;
    padding-bottom: 50px;
    left: 0px;
    display: none;
  }

  .custom-dropdown .inner2 .dropdown-content {
    position: static;
    width: 90%;
    margin: auto;
    padding: 35px;
    margin-top: 5%;
  }
}
@media (max-width: 800px) {
  .UhmainContent .contentArea .UhserRegistrationLink .left .copyTexting p {
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .UhmainContent .contentArea .UhserRegistrationLink button {
    margin-top: 10px;
  }
  .UhmainContent .detailAccountTop .headerTop {
    flex-wrap: wrap !important;
  }
  .UhmainContent .contentArea .detailAccount .activeUsers {
    flex-wrap: wrap;
    height: auto !important;
    padding: 5px 20px;
  }
  .UhmainContent .contentArea .detailAccount h2 {
    margin-right: 20px;
    width: 100% !important;

    margin-bottom: 10px;
  }
  .UhmainContent .contentArea .UhserRegistrationLink {
    flex-wrap: wrap;
    height: auto;
    padding: 10px;
    overflow: hidden;
  }
  .UhmainContent .detailAccountTop {
    padding: 20px;
  }
}
@media (max-width: 779px) {
  .tablinks {
    font-size: 14px;
  }

  .UhmainContent .UhprogressChapter .UhcontentTab .Uhtabcontent .Uhrow .UhprogressBar {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .UhmainContent .UhprogressChapter .UhcontentTab .Uhtabcontent .Uhrow .UhbtnsList .Uhview {
 margin: 2px;   
  }
  .UhmainContent .UhprogressChapter .UhcontentTab .Uhtabcontent .Uhrow .UhbtnsList{
    display: flex;
    flex-direction: column;
    align-items: center;
    
  }
  .UhmainContent .UhprogressChapter .UhcontentTab .Uhtabcontent .Uhrow h2 {
    margin-left: 10px;
    width: 50%;
  }
  .UhmainContent .UhprogressChapter .UhcontentTab .Uhtabcontent .Uhrow .UhprogressBar {
    width: 50%;
  }
  .UhmainContent
    .UhprogressChapter
    .UhcontentTab
    .Uhtabcontent
    .Uhrow
    .UhprogressBar
    p:nth-child(1) {
    width: 160px;
  }

  .UhmainContent
    .UhprogressChapter
    .UhcontentTab
    .Uhtabcontent
    .Uhrow
    .UhprogressBar
    .UhprogressTopLine {
    margin-right: 5px;
  }

  .UhmainContent .contentArea .headerTop .timeStamp {
    height: auto;
    text-align: center;
    padding: 4px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .UhmainContent .contentArea .headerTop .timeStamp img {
    display: none;
  }

  .UhmainContent .contentArea .headerTop {
    padding: 23px 15px;
  }

  .UhmainContent .contentArea .headerTop h2 {
    font-size: 18px;
    line-height: 24px;
  }

  .UhmainContent .contentArea .headerTop .timeStamp h3 {
    text-align: center;
  }

  .UhmainContent .contentArea .headerTop .timeStamp h3 .span1 {
    display: none;
  }

  .UhmainContent .contentArea .headerTop .timeStamp h3 .span2 {
    display: block;
    text-align: center;
  }

  .UhmainContent .contentArea .progress .UhtabcontentTop .Uhtabcontent .Uhrow .card {
    width: 100%;
    margin: auto;
    margin-bottom: 12px;
  }

  .UhmainContent .contentArea .progress .UhtabcontentTop {
    padding: 23px 9px;
  }

  .UhmainContent .readingBookTop .celebrate img {
    height: 405px;
    object-fit: cover;
  }

  .UhmainContent .individualHeader {
    padding: 9px;
  }

  .UhmainContent .individualHeader .right .btn h2 {
    display: none;
  }

  .UhmainContent .individualHeader .left h2 {
    font-size: 18px;
    line-height: 24px;
    margin-right: 5px;
  }

  .UhmainContent .UhprogressChapter .chapterList button {
    margin-left: 0px;
    margin-right: 10px;
  }

  .UhmainContent .topHeader .right {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }

  .UhmainContent .topHeader .right button {
    margin-left: auto;
  }

  .UhmainContent .topHeader .right .text h2 {
    display: none;
  }

  .UhmainContent .topHeader .right .text {
    margin-right: 0px;
  }

  .UhmainContent .topHeader .right .text p {
    width: 160px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    margin-top: 8px;
  }

  .UhmainContent .topHeader {
    padding: 12px 9px;
  }
}

@media (max-width: 700px) {
 

  .custom-dropdown .dropdown-content label {
    font-family: "Heebo", sans-serif;
    font-size: 16px !important;
    font-weight: 300 !important;
    line-height: 24px;
    color: #023842;
  }
  .dropdown-content .paraG {
    display: block;
    margin-bottom: 20px!important;
  }
  
  .paymentModal .innerContent .UhserCards {
    flex-wrap: wrap;
    height: auto;
    padding: 10px 20px;
  }
  .paymentModal .innerContent .UhserCards .card {
    width: 50%;
  }
  .paymentModal .innerContent .UhserCards .card:nth-child(1),
  .paymentModal .innerContent .UhserCards .card:nth-child(2) {
    margin-bottom: 15px;
  }
  .paymentModal .innerContent .UhserCards .card:nth-child(2) img {
    display: none;
  }
  #chapterMobile {
    display: block;
  }

  .UhmainContent .UhprogressChapter .search,
  .UhmainContent .UhprogressChapter .chapterList {
    display: none;
  }

  .UhmainContent .books .headerBooksList {
    display: none;
  }

  .UhmainContent .books .UhcontentTab .Uhtabcontent .Uhrow .left,
  .UhmainContent .books .UhcontentTab .Uhtabcontent .Uhrow .center {
    max-width: 100%;
    width: 100%;
  }

  .UhmainContent .books .UhcontentTab .Uhtabcontent .Uhrow .center img {
    position: absolute;
    top: 14px;
    left: 18px;
  }

  .UhmainContent .books .UhcontentTab .Uhtabcontent .Uhrow .center {
    margin-top: 10px;
  }

  .settingModal .innerContent .settingModal-body {
    flex-wrap: wrap;
  }





  .UhmainContent .UhprogressChapter .UhcontentTab .Uhtabcontent .Uhrow .description {
    display: none;
  }

  .UhmainContent .UhprogressChapter .UhcontentTab .Uhtabcontent .Uhrow .btnsList {
    width: auto;
  }

  .UhmainContent .UhprogressChapter .UhcontentTab .Uhtabcontent .Uhrow .UhprogressBar {
    width: auto;
    /* margin-left: 10px; */
  }
  /* .UhmainContent
    .UhprogressChapter
    .UhcontentTab
    .Uhtabcontent
    .Uhrow
    .UhprogressBar
    .completed {
    margin-right: -34px;
    
  } */
  .UhmainContent
    .UhprogressChapter
    .UhcontentTab
    .Uhtabcontent
    .Uhrow
    .UhprogressBar
    .UhprogressTopLine {
    width: 100px;
  }

  .UhmainContent .UhprogressChapter .UhcontentTab .Uhtabcontent .Uhrow .UhprogressBar {
    margin-right: 5px;
  }

  .UhmainContent .UhprogressChapter .UhcontentTab .Uhtabcontent .Uhrow {
    padding: 12px 9px;
  }
}

@media (max-width: 679px) {
  .UhmainContent .UhprogressChapter .UhcontentTab .Uhtabcontent .Uhrow .btnsList .keepReading{
    width: 80px;
  }
  
  .addBookModal .modal-content .modal-body img {
    margin-bottom: 20px;
  }
  .addBookModal .modal-content .modal-body .step1Btn {
    margin: auto;
    left: 0px;
    right: 0px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  .UhmainContent .contentArea form {
    flex-wrap: wrap;
    max-width: 100%;
  }
  .UhmainContent .contentArea form .left,
  .UhmainContent .contentArea form .right {
    max-width: 100%;
    width: 100%;
    margin-left: 0px;
  }
  .addLibraryModalTop .modal-content .modal-header,
  .editLibraryModalTop .modal-content .modal-header,
  .deleteLibraryModalTop .modal-content .modal-header {
    justify-content: flex-start;
    padding-right: 25px;
  }
  .addLibraryModalTop .modal-content .modal-header h2,
  .editLibraryModalTop .modal-content .modal-header h2,
  .deleteLibraryModalTop .modal-content .modal-header h2 {
    font-size: 20px;
  }
  .UhmainContent .UhprogressChapter .UhcontentTab .Uhtabcontent .Uhrow h2 {
    /* width: 80px; */
    margin-left: 10px;
  }
  .editChapterModal .innerContent,
  .editBookModal .innerContent {
    margin: 36px auto;
  }

  .editBookModal .innerContent .headerModal {
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-right: 24px;
  }

  .UhmainContent .UhprogressChapter .UhcontentTab .Uhtabcontent .Uhrow .UhprogressBar {
    width: 160px;
    flex-direction: column;
    text-align: right;
  }

  .addBookModal .modal-content,
  .paymentModal .innerContent,
  .settingModal .innerContent,
  .deleteBookModal .innerContent {
    margin: 27px auto;
    overflow-y: auto;
  }

  .editBookModal .innerContent .headerModal {
    text-align: left;
  }

  .deleteBookModal .innerContent .headerModal {
    display: flex;
    justify-content: flex-start;
    padding-right: 26px;
  }

  .settingModal .innerContent .headerModal .cancel,
  .editBookModal .innerContent .headerModal .cancel,
  .deleteBookModal .innerContent .headerModal .cancel {
    left: 26px;
    margin-left: auto;
    position: absolute;
  }

  .addBookModal .modal-content .modal-header .close {
    right: 26px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .addBookModal .modal-content .modal-header,
  .settingModal .innerContent .headerModal {
    justify-content: flex-start;
    padding-right: 26px;
    position: relative;
  }

  .addBookModal .modal-content .modal-header h2,
  .settingModal .innerContent .headerModal h1,
  .editBookModal .innerContent .headerModal h1,
  .deleteBookModal .innerContent .headerModal h1 {
    font-size: 20px;
  }

  .addBookModal .modal-content .modal-body {
    padding: 17px 18px;
  }

  .addBookModal .modal-content .modal-body .firstImg {
    margin-bottom: 68px !important;
    position: static;
    display: block;
    margin: auto;
    width: 80%;
    height: auto;
  }

  .addBookModal .modal-content .modal-body form .Uhrow {
    flex-wrap: wrap;
  }

  .addBookModal .modal-content .modal-body form .Uhrow input {
    width: 100%;
    margin-bottom: 12px;
  }

  .addBookModal .modal-content .modal-body form .Uhrow .custom-select {
    width: 100%;
  }

  .addBookModal .modal-content .modal-body form .Uhrow .forMobileMargin {
    margin-bottom: 12px;
  }

  .addBookModal .modal-content .modal-footer {
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
  }

  .addBookModal .modal-content .modal-footer .back1 {
    margin-right: 0px;
  }

  .addBookModal .modal-content .modal-footer button {
    margin-bottom: 10px;
  }

  .addBookModal .modal-content .modal-body form {
    padding: 0px 18px;
  }

  .addBookModal .modal-content .modal-body .successUpload {
    width: 80%;
  }

  .UhmainContent .readingBookTop .player .footerPlayer .center {
    position: absolute;
    top: -80px !important;
    left: 0px;
    right: 0px;
    margin: auto;
  }
}

@media (max-width: 664px) {
    .settingModal .innerContent .settingModal-body .left {
    width: 100%;
  }
  .UhmainContent .topHeader .left p {
    font-size: 14px;
  }
  .UhmainContent .topHeader .right .text p {
    font-size: 12px;
  }
  .paymentModal .innerContent {
    padding: 62px 20px;
  }
  .paymentModal .innerContent .total .leftt {
    flex-wrap: wrap;
    width: 50%;
  }
  .paymentModal .innerContent .total .rightt h3,
  .paymentModal .innerContent .total .leftt h3 {
    font-size: 15px;
  }
  .paymentModal .innerContent .total .leftt p {
    width: 100%;
  }
  .paymentModal .innerContent .total {
    height: auto;
    padding: 10px;
    border-radius: 7px;
  }
  .paymentModal .innerContent .total .leftt {
    flex-wrap: wrap;
  }
  .paymentModal .innerContent .total .leftt .firstH3 {
    width: 90px !important;
  }
  .paymentModal .innerContent .total .leftt img {
    display: none;
  }
  .paymentModal .innerContent .UhserCards {
    padding: 10px;
  }
  .settingModal .innerContent .settingModal-body .leftForMobile {
    margin-top: 58px;
  }

  .editBookModal .innerContent .bodyModal form {
    padding: 28px;
  }
}

@media (max-width: 622px) {
  .paymentModal .innerContent form {
    flex-wrap: wrap;
  }
  .paymentModal .innerContent form .left,
  .number-input-container,
  .paymentModal .innerContent form .right {
    width: 100%;
    margin-left: 0px;
  }
  .paymentModal .innerContent .twoOffer .monthly,
  .paymentModal .innerContent .twoOffer .yearly {
    margin-right: 0px;
    width: 206px;
    margin-bottom: 25px;
  }

  .editBookModal .innerContent .bodyModal form .Uhrow {
    flex-wrap: wrap;
  }

  .editBookModal .innerContent .bodyModal form .Uhrow:nth-child(1) .custom-select,
  .editBookModal .innerContent .bodyModal form .Uhrow:nth-child(2) .custom-select,
  .editBookModal
    .innerContent
    .bodyModal
    form
    .Uhrow:nth-child(3)
    .custom-select {
    margin-bottom: 0px;
  }

  .editBookModal .innerContent .bodyModal form .Uhrow input,
  .editBookModal .innerContent .bodyModal form .Uhrow .custom-select {
    width: 100%;
    margin-bottom: 12px;
  }

  .deleteBookModal .innerContent .bodyModal .btns {
    flex-wrap: wrap;
  }

  .deleteBookModal .innerContent .bodyModal .btns button {
    margin-bottom: 6px;
  }
}
@media (max-width: 440px) {
  .UhmainContent .readingBookTop .progressPlayer{
    display: block;
    margin-top: 58px;
  }
  .footerReader{
    display: block;
  }
  .headerReader {
    display: block;
  }
  .UhmainContent .readingBookTop .player {
    width: 100%;
    margin-right: 0%;
  }
  .UhmainContent .readingBookTop .player .group {
    display: none;
  }
}
/* Responsive Styles */
@media only screen and (max-width: 768px) {
  .UhmainContent .UhindividualHeader .UhtotalUser {
    height: 59px;
    border-radius: 40px;
    background: #f0f0f0;
    padding: 0px 31px;
    border-radius: 59px;
    opacity: 0px;
  }
  
  .UhmainContent .UhindividualHeader .UhtotalUser h4 {
    font-family: "Heebo", sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: #0f4152;
    margin: 0 22px 0 22px;
  }
  .UhmainContent .UhindividualHeader .UhtotalUser p {
    font-family: "Heebo", sans-serif;
    font-size: 12px;
    font-weight: 300;
    line-height: 24px;
    color: #0f4152;
  }
  .UhmainContent .UhindividualHeader .UhtotalUser p span {
    font-family: "Heebo", sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    color: #0a7a8f;
  }
  
  .UhmainContent .UhtopHeader {
    padding: 20px 15px;
    flex-direction: column;
    align-items: flex-start;
  }

  .UhmainContent .UhtopHeader .UhbackPage {
    position: static;
    display: none;
    margin-top: 10px;
    width: 40px;
    height: 30px;
  }

  .UhmainContent .UhtopHeader .Uhleft h2 {
    font-size: 16px;
    line-height: 22px;
  }

  .UhmainContent .UhtopHeader .Uhleft p {
    font-size: 14px;
    line-height: 20px;
  }

  .UhmainContent .UhtopHeader .Uhright {
    width: 100%;
    justify-content: space-between;
    margin-top: 15px;
    padding: 10px;
    height: auto;
    display: none;
  }

  .UhmainContent .UhtopHeader .Uhright .Uhtext {
    margin-left: 0;
  }

  .UhmainContent .UhtopHeader .Uhright button {
    width: 100%;
    height: 40px;
    font-size: 14px;
  }
}

@media only screen and (max-width: 480px) {
  .UhmainContent .UhtopHeader {
    padding: 15px 10px;
  }

  .UhmainContent .UhtopHeader .Uhleft h2 {
    font-size: 18px;
  }

  .UhmainContent .UhtopHeader .Uhleft p {
    font-size: 12px;
  }

  .UhmainContent .UhtopHeader .Uhright button {
    height: 35px;
    font-size: 12px;
  }
}
