@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@100..900&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");
* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
   
}
body {
   
  background: #ebecf1;
}

.Rhtop {
  width: 100%;
  height: 100vh;
  display: flex;
  background: #ebecf1;
}

.Rhtop .Rhleft {
  width: calc(100% - 510px);
  height: 100vh;
  background-image: url("../../img/login.png");
  background-position: 100% 100%;
  background-size: 100% 100%;
  object-fit: cover;
  background-repeat: no-repeat;
}
.Rhtop .Rhright {
  width: 510px;
  position: relative;
  height: 100vh;
  padding: 43px 49px;
}
.Rhtop .Rhright .Rhlang,
.Rhregister .Rhinner .Rhlang {
  width: 97px;
  height: 32px;
  gap: 0px;
  border-radius: 7px;
  margin-right: auto;
  border: 1px 0px 0px 0px;

  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #0f4152;
  padding: 0px 15px;
  cursor: pointer;
  position: relative;
  margin-bottom: 49px;
}
.Rhtop .Rhright .Rhlang.focus-visible,
.Rhregister .Rhinner .Rhlangfocus-visible {
  outline: 2px solid #1d1b1b; /* White focus ring */
  outline-offset: 3px; /* Space between button and outline */
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.5); /* Optional shadow effect */
}
.Rhregister .Rhinner .Rhlang,
.Rhregister .Rhinner .RhlogoImg {
  margin-bottom: 12px !important;
}
.Rhtop .Rhright .Rhlang p,
.Rhregister .Rhinner .Rhlang p {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 33px;
  color: #0f4152;
}

.Rhtop .Rhright .Rhlang .Rhdropdown,
.Rhregister .Rhinner .Rhlang .Rhdropdown {
  position: absolute;
  bottom: -115%;
  border-radius: 7px;
  border: 1px 0px 0px 0px;
  background-color: white;
  right: 0px;
  border: 1px solid #0f4152;
  width: 100%;
  display: none;
}
.Rhtop .Rhright .Rhlang .show,
.Rhregister .Rhinner .Rhlang .show {
  display: block;
}
.Rhtop .Rhright .Rhlang .Rhdropdown a,
.Rhregister .Rhinner .Rhlang .Rhdropdown a {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 33px;
  color: #0f4152;
  text-decoration: none;
  display: flex;
  align-items: center;
  width: 97px;
  height: 32px;
  justify-content: center;
}

.Rhtop .Rhright .RhlogoImg,
.Rhregister .Rhinner .RhlogoImg {
  margin: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 55px;
}
.Rhtop .Rhright .RhlogoImg .Rhlogo,
.Rhregister .Rhinner .Rhlang {
  text-decoration: none;
}
.Rhtop .Rhright h1 {
  font-family: "Heebo", sans-serif;
  font-size: 28px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #0f4152;
  margin-bottom: 5px;
}
.Rhtop .Rhright h5 {
  font-family: "Heebo", sans-serif;
  font-size: 28px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #0f4152;
  margin-bottom: 27px;
}

.Rhtop .Rhright form {
  width: 100%;
}
.Rhtop .Rhright form .Rhinput {
  width: 100%;
  height: 50px;
  border: 1px 0px 0px 0px;

  border: 1px solid #cdcfd6;
  background: #ffffff;
  margin-bottom: 13px;
  border-radius: 7px;
  padding: 0px 23px;
  outline: none;
  box-shadow: none;
}

.Rhtop .Rhright form .Rhrow,
.Rhregister .Rhinner form .Rhrow {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  margin-bottom: 10px;
}
.Rhtop .Rhright form .Rhrow .Rhcustom-checkbox,
.Rhregister .Rhinner form .Rhrow .Rhcustom-checkbox {
  position: relative;
  display: inline-block;
  cursor: pointer;
  font-size: 16px;
}

.Rhtop .Rhright form .Rhrow .Rhcustom-checkbox input,
.Rhregister .Rhinner form .Rhrow .Rhcustom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.Rhtop .Rhright form .Rhrow .Rhcustom-checkbox p,
.Rhregister .Rhinner form .Rhrow .Rhcustom-checkbox p {
  margin-right: 35px;
  font-family: "Heebo", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #626262;
}
.Rhregister .Rhinner form .Rhrow .Rhcustom-checkbox p a {
  font-family: "Heebo", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #626262;
}
.Rhtop .Rhright form .Rhrow .Rhcustom-checkbox .Rhcheckmark,
.Rhregister .Rhinner form .Rhrow .Rhcustom-checkbox .Rhcheckmark {
  position: absolute;
  top: 0;
  right: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: 1px solid #15151524;
  border-radius: 5px;
}

.Rhtop .Rhright form .Rhrow .Rhcustom-checkbox:hover input ~ .Rhcheckmark,
.Rhregister .Rhinner form .Rhrow .Rhcustom-checkbox:hover input ~ .Rhcheckmark {
  background-color: #0f4152;
}

.Rhtop .Rhright form .Rhcustom-checkbox input:checked ~ .Rhcheckmark,
.Rhregister .Rhinner form .Rhrow .Rhcustom-checkbox input:checked ~ .Rhcheckmark {
  background-color: #0f4152;
}

.Rhtop .Rhright form .Rhrow .Rhcustom-checkbox .Rhcheckmark:after,
.Rhregister .Rhinner form .Rhrow .Rhcustom-checkbox .Rhcheckmark:after {
  content: "";
  position: absolute;
  display: none;
}

.Rhtop .Rhright form .Rhrow .Rhcustom-checkbox input:checked ~ .Rhcheckmark:after,
.Rhregister .Rhinner form .Rhrow .Rhcustom-checkbox input:checked ~ .Rhcheckmark:after {
  display: block;
}

.Rhtop .Rhright form .Rhrow .Rhcustom-checkbox .Rhcheckmark:after,
.Rhregister .Rhinner form .Rhrow .Rhcustom-checkbox .Rhcheckmark:after {
  left: 5px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
.Rhcheckmark:focus-visible {
  outline: 2px solid #0a7a8f;
  outline-offset: 2px;
  border-radius: 4px; /* Adjust if needed */
}

.Rhtop .Rhright form .Rhrow button,
.Rhregister .Rhinner form .Rhrow button {
  width: 168px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0px;
  background: #097083;

  border-radius: 35px;
  border: none;
  outline: none;
  box-shadow: none;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.Rh96px;
  text-align: center;
  color: #fff;
  cursor: pointer;
}
.Rhtop .Rhright form .Rhrow button.focus-visible,
.Rhregister .Rhinner form .Rhrow button.focus-visible {
  outline: 2px solid #1d1b1b; /* White focus ring */
  outline-offset: 3px; /* Space between button and outline */
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.5); /* Optional shadow effect */
}

.Rhtop .Rhright form .RhfPassword {
  font-family: "Heebo", sans-serif;
  font-size: 13px;
  width: 168px;
  text-align: center;
  font-weight: 400;
  line-height: 19.Rh09px;
  float: left;
  color: #626262;
  margin-bottom: 27px;
}
.Rhtop .Rhright form .Rhline,
.Rhregister .Rhinner form .Rhline {
  display: block;
  width: 100%;
  height: 1px;
  margin-top: 27px;
  border: 1px 0px 0px 0px;
  margin-bottom: 22px;
  border: 1px solid #979797;
}

.Rhtop .Rhright form .RhdontHaveAccount {
  display: flex;
  width: 100%;
  align-items: center;

  justify-content: center;
  text-align: right;
}
.Rhtop .Rhright form .RhdontHaveAccount p {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #626262;
  text-align: center;
}
.Rhtop .Rhright form .RhdontHaveAccount p a {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #0f4152;
}

.Rhtop .Rhright .Rhaccessiblity{
  position: absolute;
  bottom: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0px;
  right: 0px;
  margin-left: auto;
  margin-right: auto;
}
.Rhtop .Rhright .Rhaccessiblity p {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Rhtop .Rhright .Rhaccessiblity p img {
  margin-right: 17px;
}
.Rhtop .Rhright .Rhaccessiblity p a {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 33px;
  text-align: left;
  color: #0f4152;
}


/* registertaion css here ====== */
.Rhregister {
  display: flex;
  min-height: 100vh;
  padding-top: 40px;
  padding-bottom: 40px;
  align-items: center;
  justify-content: center;
}
.Rhregister .Rhinner {
  max-width: 644px;
  width: 90%;
  height: auto;
  box-shadow: 0px 2px 52px 0px #d3d3d3;
  border-radius: 7px;
  background: #fff;
  padding: 22px;
}
.Rhregister .Rhinner .Rhpara {
  font-family: "Heebo", sans-serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #0F4152;
  margin-bottom: 16px;
}
.Rhregister .Rhinner .RhpUserTop{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
}
.Rhregister .Rhinner .RhpUserTop .RhprivateUser {
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 33px;
  text-align: center;
  color: #ff0000;

}
.Rhregister .Rhinner h1 {
  font-family: "Heebo", sans-serif;
  font-size: 22px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #0f4152;
  margin-bottom: 21px;
}
.Rhregister .Rhinner form {
  max-width: 369px;
  width: 100%;
  margin: auto;
}

/* Custom styles for the select dropdown */
.Rhregister .Rhinner form .Rhcustom-select {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}

.Rhregister .Rhinner form .Rhcustom-select .Rhselect-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 13px;
}

.Rhregister .Rhinner form .Rhcustom-select select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-right: 20px;
  width: 100%;
  height: 50px;
  background: #ffffff;
  border: 1px solid #0f4152;
  padding: 0px 27px;
  outline: none;
  box-shadow: none;
  border-radius: 7px;

  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
 
}
.Rhregister .Rhinner form .Rhselect-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 100%;
  pointer-events: none;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.Rhregister .Rhinner form .Rhinput {
  width: 100%;
  height: 50px;
  padding: 0px 27px;
  background: #ffffff;
  border: 1px solid #cdcfd6;
  border-radius: 7px;
  outline: none;
  box-shadow: none;
  margin-bottom: 12px;
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #626262;
}
.Rhregister .Rhinner form .RhdontHaveAccount {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #626262;
}
.Rhregister .Rhinner form .RhdontHaveAccount a {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #626262;
}
.Rhregister .Rhinner form input::placeholder,
.Rhregister .Rhinner form input option[disabled] {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #626262;
}
.Rhregister .Rhinner form .RhdontHaveAccount {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #626262;
}
.Rhregister .Rhinner form .RhdontHaveAccount a {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  padding: 5px;
  color: #626262;
}
@media (max-width: 1100px) {
  .Rhtop .Rhleft {
    width: calc(100% - 410px);
  }
  .Rhtop .Rhright {
    width: 410px;
  }
  .Rhtop .Rhright .Rhaccessiblity{
    position: static;
    margin-top: 100px;
    padding-bottom: 40px;
  }
}

@media (max-width: 800px) {


  .Rhtop .Rhright form .Rhrow,
  .Rhregister .Rhinner form .Rhrow {

    align-items: flex-end;
  
  
    flex-direction: column-reverse;
     }
  .Rhtop .Rhleft {
    display: none;
  }
  .Rhtop .Rhright {
    width: 100%;
    padding: 30px 5%;
  }
  .Rhtop .Rhright form .Rhrow .Rhcustom-checkbox p {
    margin-left: 29px;
  }
  .Rhtop .Rhright form .Rhrow button,
  .Rhtop .Rhright form .RhfPassword {
    width: 158px;
  }
}
