@import url("https://fonts.googleapis.com/css2?family=Assistant:wght@200..800&family=Heebo:wght@100..900&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");
.error-border {
border: 1px solid rgba(255, 0, 0, 0.637)  !important ;
}

* {

  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}

body {

  background: #ebecf1;
}
.nav-link {
  font-weight: bold !important;
}
.mobileMenuTop2 {
  position: fixed;
  top: 0px;
  right: -100%;
  transition: 0.4s;
  width: 100%;
  bottom: 0px;
  z-index: 99999999;
  background: rgba(53, 53, 53, 0.9);
}
.spinner {
  display: block;
  margin: 0 auto;
  width: 50px;
  height: 50px; 
}

.custom-upload2,
.modal-footer2 {
  position: relative;
  z-index: 1;
  text-align: end;
}

form.loading {
  position: relative;
}

form.loading::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.7);
  z-index: 2;
}

.mobileMenuTop2 .inner2     {
  position: relative;
  max-width: 364px;
  width: 90%;
  bottom: 0px;
  height: 100vh;
  background: #097a8f;
  padding: 22px;
  overflow-y: auto;
}

.mobileMenuTop2 .inner2     .headerText2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 42px;
}

.mobileMenuTop2 .inner2  .headerText2 .logo2 {
  cursor: pointer;
}
.mobileMenuTop2 .inner2     .headerText2 .switchBtn2 a.active {
  background: #02343d;
}
.mobileMenuTop2 .inner2     .headerText2 .switchBtn2 {
  width: 187px;
  height: 44px;
  border-radius: 40px;
  background: #2599af;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mobileMenuTop2 .inner2     .headerText2 .switchBtn2 a {
  width: 99px;
  height: 44px;
  border-radius: 40px;
 
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Heebo", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 46px;
  text-align: center;
  color: #ffffff;
  text-decoration: none;
}

.mobileMenuTop2 .inner2  .headerText2 .switchBtn2 a.active {
  background: #02343d;
}

.mobileMenuTop2 .inner2     ul {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.mobileMenuTop2 .inner2     ul li {
  margin: 0px 51px 7px 0px;
  list-style-type: none;
  width: 100%;
}

.mobileMenuTop2 .inner2     ul li a {
  font-family: "Heebo", sans-serif;
  font-size: 24px;
  font-weight: 400;
  width: 100%;
  display: block;
  line-height: 46px;
  text-align: right;
  text-decoration: none;
  color: #fff;
}

.mobileMenuTop2 .inner2     ul .upgrade2 a {
  width: 179px;
  height: 48px;
  gap: 0px;
 
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Assistant", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 18.31px;
  text-align: center;
  background: #097185;
  border-radius: 35px;
  color: white;
}

.header2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 5%;
  background: #097185;
}
.header2 .Admin {
  padding: 0px 5% 0px 0% !important;
}
.header2 .left2 {
  display: flex;
  align-items: center;
}

.header2 .left2 .mobileMenu2 {
  display: none;
}

.header2 .left2 .logo2 {
  margin-left: 39px;
}

.header2 .left2 ul {
  display: flex;
  align-items: center;
}

.header2 .left2 ul li {
  margin-right: 39px;
  list-style-type: none;
}

.header2 .left2 ul li a {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  line-height: 33px;
  text-align: left;
  text-decoration: none;
  color: #fff;
}

.header2 .left2 ul li:nth-last-child(1) a {
  width: 103.09px;
  height: 35px;
  gap: 0px;
 
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Assistant", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 18.31px;
  text-align: center;
  background: #fff;
  border-radius: 35px;
  color: #0a7f95;
}

.header2 .right2 {
  display: flex;
  align-items: center;
}

.header2 .right2 .logoInst {
  width: 79px;
  height: 66px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header2 .right2 .logoInst img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  display: block;
}

.header2 .right2 .lang {
  width: 97px;
  height: 32px;
  gap: 0px;
  border-radius: 5px;
  margin-right: auto;
  border: 1px 0px 0px 0px;
 
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #fff;
  padding: 0px 15px;
  cursor: pointer;
  position: relative;
  margin-left: 29px;
}

.header2 .right2 .icons:nth-last-child(2) {
  margin-right: 14px;
}

.header2 .right2 .lang p {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 33px;
  color: #fff;
}

.header2 .right2 .lang .dropdown {
  position: absolute;
  bottom: -115%;
  border-radius: 7px;
  border: 1px 0px 0px 0px;
 
  right: 0px;
  background: #097185;
  border: 1px solid #fff;
  width: 100%;
  display: none;
}

.header2 .right2 .lang .show {
  display: block;
  z-index: 9999;
}

.header2 .right2 .lang .dropdown a {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 33px;
  color: #fff;
  text-decoration: none;
  display: flex;
  align-items: center;
  width: 97px;
  height: 32px;
  justify-content: center;
}

.header2 .right2 .icons:nth-last-child(2) {
  margin-right: 14px;
  margin-left: 14px;
}

.mainContent2 {
  max-width: 1140px;
  width: 90%;
  margin: auto;
}
.paymentModal2 .innerContent2 .twoOffer2 {
  display: flex;
  justify-content: center;
  justify-content: space-evenly;
}

.paymentModal2 .innerContent2 .twoOffer2 .monthly2 {
  width: 236px;
  height: 333px;
  border: 1px 0px 0px 0px;
  border: 1px solid #97979780;
  box-shadow: 0px 2px 52px -9px #d3d3d3;
  border-radius: 12px;
  padding: 39px 19px 24px 19px;
  margin-right: 22px;
}

.paymentModal2 .innerContent2 .twoOffer2 .monthly2 h3,
.paymentModal2 .innerContent2 .twoOffer2 .yearly2 h3 {
  font-family: "Heebo", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #000000;
  margin-bottom: 24px;
}

.paymentModal2 .innerContent2 .twoOffer2 .monthly2 h5,
.paymentModal2 .innerContent2 .twoOffer2 .yearly2 h5 {
  font-family: "Heebo", sans-serif;
  font-size: 28px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #0a7a8f;
  margin-bottom: 11px;
}

.paymentModal2 .innerContent2 .twoOffer2 .monthly2 p,
.paymentModal2 .innerContent2 .twoOffer2 .yearly2 p {
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #0f4152;
}

.paymentModal2 .innerContent2 .twoOffer2 .monthly2 .purchaseBtn2,
.paymentModal2 .innerContent2 .twoOffer2 .yearly2 .purchaseBtn2 {
  text-align: center;
}

.paymentModal2 .innerContent2 .twoOffer2 .monthly2 .purchaseBtn2 button,
.paymentModal2 .innerContent2 .twoOffer2 .yearly2 .purchaseBtn2 button {
  width: 168px;
  height: 50px;
  cursor: pointer;
  background: #0a7a8f;
  border-radius: 35px;
  outline: none;
  box-shadow: none;
  border: none;
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 23.5px;
  text-align: center;
  color: #fff;
}


.paymentModal2 .innerContent2 .twoOffer2 .monthly2 .purchaseBtn2 button.focus-visible, 
.paymentModal2 .innerContent2 .twoOffer2 .yearly2 .purchaseBtn2 button.focus-visible{
  outline: 2px solid #1a0707; /* White outline for contrast */
  outline-offset: 2px; /* Creates spacing around the button */
  box-shadow: 0 0 8px rgba(255, 255, 255, 0.6); /* Optional for a glow effect */

} 
.paymentModal2 .innerContent2 .twoOffer2 .yearly2 {
  width: 236px;
  height: 333px;
  border-radius: 12px;
  border: 1px 0px 0px 0px;
  padding: 39px 15px 24px 15px;
  border: 1px solid #0a7a8f;
  box-shadow: 0px 2px 52px -9px #d3d3d3;
  position: relative;
}

.paymentModal2 .innerContent2 .twoOffer2 .yearly2 .bestValue2 {
  position: absolute;
  width: 123px;
  height: 28px;
  background: #0f4152;
  border-radius: 5px;
  top: -4%;
  left: 0px;
  right: 0px;
  margin: auto;
}

.paymentModal2 .innerContent2 .twoOffer2 .yearly2 .bestValue2 p {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  margin-top: 4px;
  line-height: 20.56px;
  color: #ffffff;
}
.paymentModal2 .innerContent2 form .right2 {
  width: 167px;
}
.paymentModal2 .innerContent2 form .right2 p {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 33px;
  color: #626262;
  margin-bottom: 0px;
  text-align: right;
}

.paymentModal2 .innerContent2 form .right2 .custom-select2 {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 13px;
}

.paymentModal2 .innerContent2 form .right2 .custom-select2 .select-icon2 {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 14px;
  height: 50px;
}

.paymentModal2 .innerContent2 form .right2 .custom-select2 .select-icon2 img {
  position: absolute;
  right: 130px;
  top: 17px;
}

.paymentModal2 .innerContent2 form .right2 .custom-select2 select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #626262;
  width: 100%;
  padding: 0px 14px;
  background: #ffffff;
  border-radius: 5px;
  height: 50px;
  border: 1px solid #cdcfd6;
  outline: none;
  box-shadow: none;
}
.paymentModal2 .innerContent2 form .right2 .custom-select2.focus-visible{
  border: 2px solid #1a0707;
}
.paymentModal2 .innerContent2 form .left2 .custom-select2.focus-visible{
  border: 2px solid #1a0707;
}
.paymentModal2 .innerContent2 form  .left2 .custom-select2 select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #626262;
  width: 100%;
  padding: 0px 14px;
  background: #ffffff;
  border-radius: 5px;
  height: 50px;
  border: 1px solid #cdcfd6;
  outline: none;
  box-shadow: none;
}
.paymentModal2 .innerContent2 form .right2 .custom-select2 .select-icon2 {
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 100%;
}

.paymentModal2 .innerContent2 .total2 {
  width: 100%;
  height: 51px;
  background: #e9e9e9;
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  outline: none;
  box-shadow: none;
  border-radius: 48px;
  padding: 0px 22px;
  margin-bottom: 25px;
}
.paymentModal2 .innerContent2 .purchaseBtn2 {
  width: 168px;
  height: 50px;
  border-radius: 48px;
  background: #0a7a8f;
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 500;
  display: block;
  line-height: 23.5px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  border: none;

  outline: none;
  box-shadow: none;
}
.paymentModal2 .innerContent2 .purchaseBtn2.focus-visible {
  outline: 2px solid #1a0707; /* White outline for contrast */
    outline-offset: 2px; /* Creates spacing around the button */
    box-shadow: 0 0 8px rgba(255, 255, 255, 0.6); /* Optional for a glow effect */

}
.paymentModal2 .innerContent2 .total2 .rightt2 h3,
.paymentModal2 .innerContent2 .total2 .leftt2 h3 {
  font-family: "Heebo", sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 26.44px;
  color: #0a7a8f;
  width: auto;
}
.paymentModal2 .innerContent2 .total2 .leftt2 {
  display: flex;
  align-items: center;
}
.paymentModal2 .innerContent2 .total2 .leftt2 h3 {
  width: 142px;
}

.paymentModal2 .innerContent2 .total2 .leftt2 h6 {
  max-width: auto !important;
  position: relative;
  width: auto !important;
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 33px;
  color: #626262;
}

.top .right .accessiblity {
  position: absolute;
  bottom: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0px;
  right: 0px;
  margin-left: auto;
  margin-right: auto;
}
.top .right .accessiblity p {
  display: flex;
  align-items: center;
  justify-content: center;
}

.top .right .accessiblity p img {
  margin-right: 17px;
}
.top .right .accessiblity p a {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 33px;
  text-align: left;
  color: #0f4152;
}

.mainContent2 .topHeader2 {
  width: 100%;
  padding: 25px 18px;
  box-shadow: 0px 0px 30px 0px #0000001f;
  background: #fff;
  border-radius: 0px 0px 10px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  position: relative;
}

.mainContent2 .topHeader2 .backPage2  {
  position: absolute;
  left: -70px;
  bottom: 15px;
  width: 51px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #1d9da2;
  background: #ffffff;
  cursor: pointer;
  border-radius: 40px;
}

.mainContent2 .topHeader2 .left2 h2 {
  font-family: "Heebo", sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  color: #0f4152;
}

.mainContent2 .topHeader2 .left2 p {
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #0f4152;
  margin-bottom: 0px !important;
}

.mainContent2 .topHeader2 .right2 {
  display: flex;
  align-items: center;
}

.mainContent2 .topHeader2 .right2 .text2   {
  margin-left: 13px;
}

.mainContent2 .topHeader2 .right2 .text2   h2 {
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  color: #0f4152;
}

.mainContent2 .topHeader2 .right2 .text2   p {
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  color: #0f4152;
}

.mainContent2 .topHeader2 .right2 button {
  width: 182px;
  height: 44px;
  gap: 0px;
 
  display: flex;
  align-items: center;
  justify-content: center;
  background: #097285;
  border-radius: 35px;

  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 23.5px;
  text-align: center;
  color: #fff;
  border: none;
  outline: none;
  box-shadow: none;
}
.mainContent2 .topHeader2 .right2 button.focus-visible{
  border: 2px solid #1a0707
}
.mainContent2 .topHeader2 .right2 .addLibrary2 button.focus-visible{
  border: 2px solid #1a0707 !important;
}

.mainContent2 .progressChapter .contentTab2   .tabcontent2    .row2 .left2 {
  display: flex;
  align-items: center;
}

.mainContent2 .progressChapter .contentTab2   .tabcontent2    .row2 .btnsList .view {
  width: 82px;
  height: 31px;
  border-radius: 35px;
  border: 1px solid #b6b6b6;
  background: transparent;
  outline: none;
  box-shadow: none;
  font-family: "Heebo", sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.09px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #909090;
}

.mainContent2 .progressChapter .contentTab2   .tabcontent2    .completedBookProgress .btnsList .view {
  background: #0a7a8f !important;
  color: #fff !important;
  border: none;
  outline: none;
  box-shadow: none;
}
.mainContent2 .detailAccountTop2 {
  padding: 58px 46px;
}
.mainContent2 .topContent2   {
  width: 100%;

  box-shadow: 0px 0px 30px 0px #0000001f;
  background: #fff;
  border-radius: 10px 10px 0px 0px;
  padding-bottom: 50px;
}

.mainContent2 .topContent2   .search2,
.mainContent2 .progressChapter .search2     {
  padding: 34px 18px 21px 18px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.mainContent2 .topContent2   .search2     h2,
.mainContent2 .progressChapter .search2     h2 {
  font-family: "Heebo", sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  color: #0f4152;
}

.mainContent2 .topContent2   .search2     .custom-select2 {
  position: relative;
  max-width: 162px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mainContent2 .topContent2 .search2 .custom-select2.focus-visible {
  border: 2px solid #1a0707; 
  /* outline: 2px solid blue;
  outline-offset: 2px; */
}
.editBookModal2  .innerContent2 .bodyModal2  form .row2 .custom-select2 {
  position: relative;
  width: calc(50% - 6px);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.editBookModal2 .innerContent2 .bodyModal2 form .row2 .custom-select2.focus-visible {
  border: 0.5px solid #cdcfd6; 
}

.mainContent2 .topContent2   .search2     .custom-select2 .select-icon2 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 13px;
}

.editBookModal2  .innerContent2 .bodyModal2  form .row2 .custom-select2 .select-icon2 {
  position: absolute;
  left: 14px;
}

.mainContent2 .topContent2   .search2     .custom-select2 select,
.editBookModal2  .innerContent2 .bodyModal2  form .row2 .custom-select2 select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-right: 21px;
  height: 42px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #b6b6b6;
  padding: 0px 16px;
  outline: none;
  box-shadow: none;
  border-radius: 35px;
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 33px;
  color: #0f4152;
}
.mainContent2 .topContent2 .search2 .custom-select2 select.focus-visible{
  border: 1px solid #1a0707;
  }
  .mainContent2 .topContent2 .search2 button.focus-visible{
    border: 1px solid #1a0707;
    }

.editBookModal2  .innerContent2 .bodyModal2  form .row2 .custom-select2 select {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #626262;
}

.mainContent2 .progressChapter .contentTab2   .tabcontent2    .row2 .progressBar .progressTopLine {
  position: relative;
  width: 133px;
  height: 5px;
  background: #d8d8d8;
  border-radius: 35px;
  margin-left: 48px;
  overflow: hidden;
}

.mainContent2 .progressChapter .contentTab2   .tabcontent2    .row2 .progressBar .progressTopLine .progressInnerLine {
  background: #0a7a8f;
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 35px;
  bottom: 0px;
}

.editBookModal2  .innerContent2 .bodyModal2  form .row2 .custom-select2 select {
  border-radius: 5px !important;
  height: 50px !important;
  background: #ffffff;
  border: 1px 0px 0px 0px;
  border: 1px solid #cdcfd6;
  border-radius: 5px;
  padding: 0px 23px;
  outline: none;
  box-shadow: none;
}

.mainContent2 .topContent2   .search2     .select-icon2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 100%;
}

.mainContent2 .topContent2   .search2     .searchInput2 ,
.mainContent2 .progressChapter .search2     .searchInput2  {
  max-width: 452px;
  width: 70%;
  height: 42px;
  border: 1px solid #b6b6b6;
  border-radius: 35px;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.mainContent2 .progressChapter .search2     .searchInput2  {
  max-width: 174px;
  width: 70%;
}

.mainContent2 .topContent2   .search2     .searchInput2  input,
.mainContent2 .progressChapter .search2     .searchInput2  input {
  width: calc(100% - 35px);
  height: 100%;
  border-radius: 35px;
  outline: none;
  box-shadow: none;
  border: none;
  padding: 0px 14px;
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20.56px;
  color: #0f4152;
}

.mainContent2 .topContent2   .search2     .searchInput2.focus-visible,
.mainContent2 .progressChapter .search2     .searchInput2.focus-visible{
  border: 2px solid #1a0707;
}

.mainContent2 .topContent2   .search2     .searchInput2  input::placeholder,
.mainContent2 .topContent2   .search2     .searchInput2  input option[disabled],
.mainContent2 .progressChapter .search2     .searchInput2  input::placeholder {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20.56px;
  color: #0f4152;
}

.mainContent2 .topContent2   .search2     .searchInput2  img {
  cursor: pointer;
}

.mainContent2 .topContent2   .search2     button {
  width: 119px;
  height: 44px;
  background: #59a0ad;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 23.5px;
  text-align: center;
  color: #fff;
  border-radius: 35px;
  border: none;
  cursor: pointer;
  outline: none;
  box-shadow: none;
}

.mainContent2 .topContent2   .search2     button.active {
  background: #097285;
}

.mainContent2 .line2 {
  width: calc(100% - 36px);
  margin: auto;
  display: block;
  border: 1px dotted #cdcfd6;
  margin-bottom: 20px;
}

.mainContent2 .books2 {
  width: 100%;
}

.mainContent2 .books2 h1 {
  font-family: "Heebo", sans-serif;
  font-size: 28px;
  font-weight: 700;
  line-height: 41.13px;
  text-align: right;
  color: #0f4152;
  margin-right: 18px;
  margin-bottom: 15px;
}

.mainContent2 .books2 .headerBooksList2     {
  margin-left: 18px;
  text-align: right;
}

.mainContent2 .books2 .headerBooksList2     button {
  background: none;
  /* border: none;
  outline: none; */
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-right: 20px;
  color: #0f4152;
  padding-bottom: 3px;
  cursor: pointer;
}
.mainContent2 .books2 .headerBooksList2 button .focus-visible {
  border-bottom: 2px solid#1a0707; 
}

/* .mainContent2 .books2 .headerBooksList2     button.active::after {
  content: "";
  display: block;
  width: 58px;
  margin-bottom: -3px;
  margin-top: 3px;
  height: 4px;
  border-radius: 35px;
  background: #0f4152;
} */

.mainContent2 .books2 .headerBooksList2     input:nth-last-child(2) {
  margin-left: 39px;
  margin-right: 20px;
}

.mainContent2 .books2 .contentTab2   {
  width: 100%;
  margin-bottom: 80px;
}


.mainContent2 .books2 .contentTab2   .tabcontent2    .row2:nth-child(1) {
  border-top: 1px solid #cdcfd6;
}

.mainContent2 .books2 .contentTab2   .tabcontent2    .row2 {
  width: 100%;
  padding: 13px 18px;
  border-bottom: 1px solid #cdcfd6;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.mainContent2 .books2 .contentTab2   .tabcontent2    .row2 .left2 {
  max-width: 234px;
  width: 100%;
  display: flex;
  align-items: center;
}


.mainContent2 .books2 .contentTab2 .tabcontent2 .row2 .left2 img {
  margin-left: 20px;
  width: 100%;
  margin: 10px;
  max-width: 90px; 
  height: auto; 
  object-fit: cover; 
}

.mainContent2 .books2 .contentTab2   .tabcontent2    .row2 .left2 .span2     h2 {
  font-family: "Heebo", sans-serif;
  font-size: 22px;
  text-align: right;
  font-weight: 700;
  line-height: 32 .31px;
  color: #0f4152;
  border-bottom: 1px dotted #0A7F94;
  margin-bottom: 5px;
}

.mainContent2 .books2 .contentTab2   .tabcontent2    .row2 .left2 .span2     p {
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: right;
  color: #3d3f45;
}

.mainContent2 .books2 .contentTab2   .tabcontent2    .row2 .center2     {
  width: calc(100% - 530px);
  display: flex;
  cursor: pointer;
  text-align: right;
  justify-content: space-between;
  align-items: center;
}

.mainContent2 .books2 .contentTab2   .tabcontent2    .row2 .center2     p {
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 23.5px;
  text-align: right;
  color: #2d2d2d;
  margin-left: 12px;
}

.mainContent2 .books2 .contentTab2   .tabcontent2    .row2 .right2 {
  max-width: 235px;
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.mainContent2 .books2 .contentTab2   .tabcontent2    .row2 .right2 .read2 {
  width: 182px;
  height: 44px;
  border: 1px solid #097285;
  border-radius: 35px;
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  background: transparent;
  color: #000;
  font-weight: 700;
  line-height: 23.5px;
  text-align: center;
  color: #0b7a8e;
  margin-left: 9px;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;
}
.mainContent2 .books2 .contentTab2 .tabcontent2 .row2 .right2 .read2.focus-visible {
  border: 2px solid #1a0707; /* Example keyboard focus styling */
  outline: none; /* Removes default browser focus outline */

}

.mainContent2 .books2 .contentTab2   .tabcontent2    .row2 .right2 .read2 img {
  margin-right: 16px;
}

.mainContent2 .books2 .contentTab2   .tabcontent2    .row2 .right2 .continueReading2 {
  width: 182px;
  height: 44px;
  background: #097285;
  color: #fff;
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 23.5px;
  text-align: center;
  border-radius: 35px;
  outline: none;
  margin-left: 9px;
  box-shadow: none;
  border: none;
  cursor: pointer;
}
.mainContent2 .books2 .contentTab2 .tabcontent2 .row2 .right2 .continueReading2.focus-visible {
  border: 2px solid#1a0707; /* Example keyboard focus styling */
  outline: none; /* Removes default browser focus outline */
}

.mainContent2 .books2 .contentTab2   .tabcontent2    .row2 .right2 .circle2,
.mainContent2 .progressChapter .contentTab2   .tabcontent2    .row2 .circle2     {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  border: 1px solid #0b7a8e;
  color: #097285;
  display: flex;
  align-items: center;
  /* align-items: stretch; */
  justify-content: center;
  font-family: "Heebo", sans-serif;
  font-size: 18px;
  font-weight: 500;
  position: relative;
  cursor: pointer;
}


.mainContent2 .books2 .contentTab2   .tabcontent2    .row2 .right2 .circle2     .dropDownTable2,
.mainContent2 .progressChapter .contentTab2   .tabcontent2    .row2 .circle2     .dropDownTable2 {
  position: absolute;
  left: -7px;
  z-index: 9999;
  box-shadow: 0px 2px 52px -9px #203fb233;
  background: #fff;
  top: 140%;
  border-radius: 7px;
  display: none;
  padding-top: 7px;
  padding: auto;
  padding-bottom: 7px;

}
.circle2.focus-visible{
  border: 2px solid #1a0707; 
}
.number-input-container2     {
  position: relative;
  width: 100%;
}
.paymentModal2 .innerContent2 .purchaseBtn2 {
  width: 168px;
  height: 50px;
  border-radius: 48px;
  background: #0a7a8f;
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 500;
  display: block;
  line-height: 23.5px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  border: none;
margin: auto;
  outline: none;
  box-shadow: none;
}
.number-input-container2     input[type="number"] {
  width: 100%;
  height: 50px;
  text-align: left;
  border: 1px solid #cdcfd6;
  outline: none;
  box-shadow: none;
  background: #fff;
  border-radius: 7px;
  font-size: 16px;
  padding-left: 10px;
  padding-right: 40px; 
  padding-right: 13px; 
  -moz-appearance: textfield; 
}

.number-input-container2     input[type="number"]::-webkit-outer-spin-button,
.number-input-container2     input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; 
}
.custom-pagination {
  display: flex;
  justify-content: center;
  padding: 1em 0;
  list-style: none;
}

.custom-pagination .MuiPaginationItem-root {
  margin: 0 0.25em;
  padding: 0.5em 0.75em;
  text-decoration: none;
  color: #2596be;
  border: 1px solid #dee2e6;

  transition: background-color 0.3s, color 0.3s;
}

.custom-pagination .Mui-selected {
  color: #fff !important;
  background-color: #2596be !important;
  border-color: #2596be !important;
}

.custom-pagination .MuiPaginationItem-page:hover {
  background-color: #e9ecef;
  color: #2596be;
  border-color: #dae0e5;
}

.custom-pagination .MuiPaginationItem-page.Mui-disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6;
}

.number-input-container2  .custom-buttons2 {
  position: absolute;
  right: 250px;
  top: 10px;
  display: flex;
  flex-direction: column;
  height: 50px; 
}

.number-input-container2     button {
  width: 20px;
  height: 15px;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  background: transparent;
  line-height: 0;
}
.mainContent2 .books2 .contentTab2   .tabcontent2    .row2 .right2   .circle2     .dropDownTable2 .inner2  ,
.mainContent2 .progressChapter .contentTab2   .tabcontent2    .row2 .circle2     .dropDownTable2 .inner2     {
  position: relative;
  height: auto;
  width: 244px;
}

.mainContent2 .books2 .contentTab2   .tabcontent2    .row2 .right2   .circle2     .dropDownTable2 .inner2   .square2   ,
.mainContent2 .progressChapter .contentTab2   .tabcontent2    .row2 .circle2     .dropDownTable2 .inner2   .square2    {
  position: absolute;
  left: 20px;
  top: -10%;
  z-index: 1;
  width: 17px;
  height: 17px;
  transform: rotate(45deg);
  background: #fff;
}

.mainContent2 .books2 .contentTab2   .tabcontent2    .row2 .right2   .circle2     .dropDownTable2 .inner2     button,
.mainContent2 .progressChapter .contentTab2   .tabcontent2    .row2 .circle2     .dropDownTable2 .inner2     button {
  width: 100%;
  height: 51px;
  background: transparent;
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  cursor: pointer;
  font-weight: 400;
  position: relative;
  border: none;
  z-index: 999;
  outline: none;
  box-shadow: none;
  line-height: 33px;
  padding: 0px 20px;
  text-align: right;
  color: #0f4152;
}
.mainContent2
.books2
.contentTab2
.tabcontent2
.row2
.right2
.circle2
.dropDownTable2
.inner2
button.focus-visible,
.mainContent2 .progressChapter .contentTab2 .tabcontent2 .row2 .circle2 .dropDownTable2 .inner2 button.focus-visible{
    border: 2px solid #1a0707;
  }

.mainContent2 .books2 .contentTab2   .tabcontent2    .row2 .right2   .circle2     .dropDownTable2 .inner2   button:hover,
.mainContent2 .progressChapter .contentTab2   .tabcontent2    .row2 .circle2     .dropDownTable2 .inner2   button:hover {
  background: #eaebf1;
  font-size: 14px;
  font-weight: 700;
  color: #0f4152;
}


.editBookModal2,
.deleteBookModal2,
.editChapterModal2,
.paymentModal2,
.settingModal2 {
  position: fixed;
  top: 0px;
  right: 0px;
  width: 100%;
  left: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999999;
  overflow-y: auto;
  display: none;
}

.paymentModal2 .innerContent2 {
  max-width: 782px;
  width: 90%;
  height: auto;
  border-radius: 10px;
 
  background: #ffffff;
  border-radius: 7px;
  margin: 79px auto;
  position: relative;
  padding: 62px 33px;
  padding-bottom: 80px;
}

.paymentModal2 .innerContent2 .crossPurchase2 {
  position: absolute;
  cursor: pointer;
  left: 33px;
  top: 33px;
}

.paymentModal2 .innerContent2 h1 {
  color: #023842;
  font-family: "Heebo", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  margin-bottom: 9px;
}

.paymentModal2 .innerContent2 p {
  font-family: "Heebo", sans-serif;
  max-width: 605px;
  width: 100%;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  margin: auto;
  text-align: center;
  color: #023842;
  margin-bottom: 42px;
}
.paymentModal2 .innerContent2 .userCards2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #b9b9b9;
  height: 106px;
  border-radius: 7px;
  padding: 0px 20px;
  margin-bottom: 42px;
  display: flex;
}
.paymentModal2 .innerContent2 .userCards2 .card2 {
  width: 50%;
  position: relative;
}
.paymentModal2 .innerContent2 .userCards2 .card2 p {
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #2d2d2d;
  margin-bottom: 6px;
}
.paymentModal2 .innerContent2 .userCards2 .card2 h6 {
  font-family: "Heebo", sans-serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #44c6cb;
}
.paymentModal2 .innerContent2 .userCards2 .card2 img {
  position: absolute;
  left: -3px;
  top: 5px;
}
.paymentModal2 .innerContent2 form {
  display: flex;
  width: 100%;
  margin-bottom: 25px;
}
.paymentModal2 .innerContent2 form .left2 {
  width: 167px;
  margin-right: 10px;
  margin-left: 10px;
}
.paymentModal2 .innerContent2 form .left2 p {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 33px;
  color: #626262;
  margin-bottom: 0px;
  text-align: right;
}

.editBookModal2  .innerContent2 {
  max-width: 682px;
  width: 90%;
  margin: 79px auto;
  background: #ffffff;
  border-radius: 10px;
  overflow: hidden;
}

.editBookModal2  .innerContent2 .headerModal2  {
  width: 100%;
  height: 87px;
  background: #2ca3b9;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.editBookModal2  .innerContent2 .headerModal2  .cancel2,
.deleteBookModal2  .innerContent2 .headerModal2  .cancel2,
.settingModal2 .innerContent2 .headerModal2  .cancel2  {
  position: absolute;
  left: 41px;

  cursor: pointer;
}

.editBookModal2  .innerContent2 .headerModal2  h1,
.deleteBookModal2  .innerContent2 .headerModal2  h1,
.settingModal2 .innerContent2 .headerModal2  h1 {
  font-family: "Heebo", sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #fff;
}

.editBookModal2  .innerContent2 .bodyModal2  form {
  width: 100%;
  padding: 51px 71px;
}

.editBookModal2  .innerContent2 .bodyModal2  form form {
  width: 100%;
}

.editBookModal2  .innerContent2 .bodyModal2  form .row2 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}

.editBookModal2  .innerContent2 .bodyModal2  form .row2 input {
  width: calc(50% - 6px);
  height: 50px;
  background: #ffffff;
  border: 1px 0px 0px 0px;
  border: 1px solid #cdcfd6;
  border-radius: 5px;
  padding: 0px 23px;
  outline: none;
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #626262;
  box-shadow: none;
}
.editBookModal2  .innerContent2 .bodyModal2  form .row2 input.focus-visible{
  outline: 0.5px solid #cdcfd6;
}

.editBookModal2  .innerContent2 .bodyModal2  form textarea {
  width: 100%;
  resize: none;
  border-radius: 5px;
  height: 129px;
  border: 1px 0px 0px 0px;
  padding: 14px 16px;
  background: #ffffff;
  border: 1px solid #cdcfd6;
  outline: none;
  box-shadow: none;
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #626262;
  margin-bottom: 12px;
}

.editBookModal2  .innerContent2 .bodyModal2  form textarea::placeholder {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #626262;
}

.register .inner2     form input::placeholder,
.register .inner2     form input option[disabled] {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #626262;
}

.editBookModal2  .innerContent2 .bodyModal2  form .deleteEditBook2   {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}

.editBookModal2  .innerContent2 .bodyModal2  form .deleteEditBook2   img {
  width: 33px;
  height: 45px;
  margin-left: 19px;
}

.editBookModal2  .innerContent2 .bodyModal2  form .deleteEditBook2   a {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: #c60000;
}

.editBookModal2  .innerContent2 .bodyModal2  form .continue2 {
  width: 168px;
  height: 50px;
  margin-left: auto;
  float: left;
  margin-bottom: 28px;
  cursor: pointer;
  border-radius: 35px;
  background: #097083;
  border: none;
  outline: none;
  box-shadow: none;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.96px;
  text-align: center;
  color: #fff;
}
.editBookModal2  .innerContent2 .bodyModal2  form .continue2.focus-visible{
  border: 2px solid #1a0707; 
}

.deleteBookModal2  .innerContent2 {
  max-width: 499px;
  width: 90%;
  border-radius: 10px;
  overflow: hidden;
  background: #ffffff;
  margin: 79px auto;
}

.deleteBookModal2  .innerContent2 .headerModal2  {
  width: 100%;
  height: 87px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 10px 10px 0px 0px;
  background: #b92c78;
}

.deleteBookModal2  .innerContent2 .bodyModal2  {
  width: 100%;
  padding: 34px;
}

.deleteBookModal2  .innerContent2 .bodyModal2  p {
  font-family: "Heebo", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #626262;
}

.deleteBookModal2  .innerContent2 .bodyModal2  .btns2   {
  display: flex;
  align-items: center;
  justify-content: center;
}

.deleteBookModal2  .innerContent2 .bodyModal2  .btns2   .deleteBtn2 {
  width: 168px;
  height: 50px;
  gap: 0px;
 
  border-radius: 35px;
  background: #b92c78;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.96px;
  text-align: center;
  color: #fff;
  border: none;
  outline: none;
  box-shadow: none;
  margin-left: 6px;
  cursor: pointer;
}
.deleteBookModal2  .innerContent2 .bodyModal2  .btns2   .deleteBtn2.focus-visible{
  border: 2px solid #1a0707;
}


.deleteBookModal2  .innerContent2 .bodyModal2  .btns2   .cancelBtn2 {
  width: 168px;
  height: 50px;
  background: transparent;
  border-radius: 35px;
  border: 1px solid #000000;
  cursor: pointer;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.96px;
  text-align: center;
  color: #343434;
}
.deleteBookModal2  .innerContent2 .bodyModal2  .btns2   .cancelBtn2.focus-visible{
  border: 2px solid #1a0707;
}

.editChapterModal2   .innerContent2 {
  max-width: 411px;
  width: 90%;
  margin: 79px auto;
  overflow: hidden;
  background: #fff;
  border-radius: 10px;
}

.editChapterModal2   .innerContent2 .headerModal2  {
  width: 100%;
  background: #2ca3b9;
  height: 87px;
  display: flex;
  padding: 0px 27px;
  align-items: center;
  justify-content: space-between;
}

.editChapterModal2   .innerContent2 .headerModal2  img {
  cursor: pointer;
}

.editChapterModal2   .innerContent2 .headerModal2  h1 {
  font-family: "Heebo", sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 24px;
  color: #fff;
}

.editChapterModal2   .innerContent2 .bodyModal2  {
  padding: 27px;
}

.editChapterModal2   .innerContent2 .bodyModal2  .row2 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 29px;
}

.editChapterModal2   .innerContent2 .bodyModal2  .row2 h3 {
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: #097083;
}

.editChapterModal2   .innerContent2 .bodyModal2  .row2 img {
  cursor: pointer;
}

.editChapterModal2   .innerContent2 .bodyModal2  .addedChapter2 {
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #023842;
}

.editChapterModal2   .innerContent2 .bodyModal2  .sureTocancel2  {
  margin-top: 160px;
  width: 100%;
}

.editChapterModal2   .innerContent2 .bodyModal2  .sureTocancel2  p {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  color: #b92c78;
  margin-bottom: 5px;
}

.editChapterModal2   .innerContent2 .bodyModal2  .sureTocancel2  .btnscancel2  {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 42px; 
}

.editChapterModal2   .innerContent2 .bodyModal2  .sureTocancel2  .btnscancel2  .yesBtn2 {
  width: 69px;
  height: 35px;
  border-radius: 35px;
  background: #b92c78;
  border: none;
  outline: none;
  box-shadow: none;
  margin-right: 8px;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.96px;
  text-align: center;
  color: #fff;
  cursor: pointer;
}
.editChapterModal2   .innerContent2 .bodyModal2  .sureTocancel2  .btnscancel2  .yesBtn2.focus-visible{
  border: 2px solid #1a0707;
}
.editChapterModal2   .innerContent2 .bodyModal2  .sureTocancel2  .btnscancel2  .cancelBtn2 {
  width: 105px;
  height: 35px;
  border-radius: 35px;
  border: 1px 0px 0px 0px;
  border: 1px solid #000000;
  cursor: pointer;
  font-family: "Rubik", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.59px;
  text-align: center;
  background: transparent;
  color: #383838;
}

.editChapterModal2 .innerContent2 .bodyModal2 .sureToCancel2 .btnsCancel2 .yesBtn2 {
  width: 69px;
  height: 35px;
  border-radius: 35px;
  background: #b92c78;
  border: none;
  outline: none;
  box-shadow: none;
  margin-right: 8px;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.96px;
  text-align: center;
  color: #fff;
  cursor: pointer;
}

.editChapterModal2
  .innerContent2
  .bodyModal2
  .sureToCancel2
  .btnsCancel2
  .cancelBtn2 {
  width: 105px;
  height: 35px;
  border-radius: 35px;
  border: 1px 0px 0px 0px;
  border: 1px solid #000000;
  cursor: pointer;
  font-family: "Rubik", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.59px;
  text-align: center;
  background: transparent;
  color: #383838;
}
.editChapterModal2
  .innerContent2
  .bodyModal2
  .sureToCancel2
  .btnsCancel2
  .cancelBtn2.focus-visible{
    border: 2px solid #1a0707;
  }

.editChapterModal2   .innerContent2 .bodyModal2  .finish2 {
  width: 168px;
  height: 50px;
  cursor: pointer;
  background: #097083;
  border: none;
  outline: none;
  box-shadow: none;
  border-radius: 35px;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.96px;
  text-align: center;
  color: #fff;
  float: left;
  margin-bottom: 23px;
}
.editChapterModal2   .innerContent2 .bodyModal2  .finish2.focus-visible{
  border: 2px solid #1a0707;
}
.editChapterModal2 .innerContent2 .bodyModal2 .sureToCancel2 {
  margin-top: 60px;
  width: 100%;
  margin-bottom: 10px;
}

.editChapterModal2 .innerContent2 .bodyModal2 .sureToCancel2 p {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  color: #b92c78;
  margin-bottom: 5px;
}
.addBookModal2,
.addLibraryModalTop,
.editLibraryModalTop,
.deleteLibraryModalTop {
  position: fixed;
  top: 0px;
  right: 0px;
  width: 100%;
  left: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.8);

  z-index: 9999999;
  overflow-y: auto;
  display: none;
}

.addBookModal2 .modal-content2,
.addLibraryModalTop .modal-content2,
.editLibraryModalTop .modal-content2,
.deleteLibraryModalTop .modal-content2 {
  max-width: 682px;
  background-color: #fff;
  margin: 79px auto;
  width: 90%;
  border-radius: 7px;
  overflow: hidden;
  position: relative;
}

.addLibraryModalTop .modal-content2,
.editLibraryModalTop .modal-content2,
.deleteLibraryModalTop .modal-content2 {
  max-width: 499px;
}

.addBookModal2 .modal-content2 .modal-header2,
.addLibraryModalTop .modal-content2 .modal-header2,
.editLibraryModalTop .modal-content2 .modal-header2,
.deleteLibraryModalTop .modal-content2 .modal-header2 {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2ca3b9;
  width: 100%;
  height: 87px;
}

.deleteLibraryModalTop .modal-content2 .modal-header2 {
  background: #b92c78 !important;
}

.addBookModal2 .modal-content2 .modal-header2 .close2,
.addLibraryModalTop .modal-content2 .modal-header2 .close2,
.editLibraryModalTop .modal-content2 .modal-header2 .close2,
.deleteLibraryModalTop .modal-content2 .modal-header2 .close2 {
  position: absolute;
  left: 41px;
  cursor: pointer;
}

.addBookModal2 .modal-content2 .modal-header2 h2,
.addLibraryModalTop .modal-content2 .modal-header2 h2,
.editLibraryModalTop .modal-content2 .modal-header2 h2,
.deleteLibraryModalTop .modal-content2 .modal-header2 h2 {
  font-family: "Heebo", sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #fff;
}

.addBookModal2 .modal-content2 .modal-body2,
.addLibraryModalTop .modal-content2 .modal-body2,
.editLibraryModalTop .modal-content2 .modal-body2,
.deleteLibraryModalTop .modal-content2 .modal-body2 {
  padding: 35px;
  text-align: right;
  position: relative;
}

.addBookModal2 .modal-content2 .modal-body2 img {
  margin-bottom: -40px;
}

.addBookModal2 .modal-content2 .modal-body2 .redCross2 {
  margin-right: 26px;
}

.addBookModal2 .modal-content2 .modal-body2 p,
.addLibraryModalTop .modal-content2 .modal-body2 p,
.editLibraryModalTop .modal-content2 .modal-body2 p,
.deleteLibraryModalTop .modal-content2 .modal-body2 p {
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  color: #023842;
}

.addLibraryModalTop .modal-content2 .modal-body2 p,
.editLibraryModalTop .modal-content2 .modal-body2 p {
  text-align: center;
  margin-bottom: 25px;
}

.deleteLibraryModalTop .modal-content2 .modal-body2 p {
  text-align: center;
  margin-bottom: 25px;
}

.deleteLibraryModalTop .modal-content2 .modal-body2 p span {
  font-family: "Heebo", sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #626262;
}

.deleteLibraryModalTop .modal-content2 .modal-body2 h6 {
  font-family: "Heebo", sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #e00561;
  margin-bottom: 35px;
}

.deleteLibraryModalTop .modal-content2 .modal-body2 .btnsDeleteLib {
  display: flex;
  align-items: center;
  justify-content: center;
}

.deleteLibraryModalTop .modal-content2 .modal-body2 .btnsDeleteLib .cancel2  {
  max-width: 168px;
  width: 49%;
  height: 50px;
  border-radius: 40px;
  background: transparent;
  border: 1px solid #000000;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.96px;
  text-align: center;
  color: #343434;
  margin-left: 3px;
}


.deleteLibraryModalTop .modal-content2 .modal-body2 .btnsDeleteLib .cancel2.focus-visible {
  border: 2px solid #1a0707;
}
.deleteLibraryModalTop .modal-content2 .modal-body2 .btnsDeleteLib .delete {
  max-width: 168px;
  width: 49%;
  height: 50px;
  border-radius: 40px;
  background: #b92c78;
  border: none;
  outline: none;
  margin-right: 3px;
  box-shadow: none;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.96px;
  text-align: center;
  color: #ffffff;
}
.deleteLibraryModalTop .modal-content2 .modal-body2 .btnsDeleteLib .delete.focus-visible{
  border: 2px solid #1a0707; 
}

.addLibraryModalTop .modal-content2 .modal-body2 form,
.editLibraryModalTop .modal-content2 .modal-body2 form {
  max-width: 383px;
  width: 100%;
  margin: auto;
}

.addLibraryModalTop .modal-content2 .modal-body2 form input,
.editLibraryModalTop .modal-content2 .modal-body2 form input {
  width: 100%;
  height: 50px;
  margin-bottom: 13px;
  background: #ffffff;
  border: 1px solid #cdcfd6;
  border-radius: 7px;
  padding: 0px 25px;
  outline: none;
  box-shadow: none;
}
.addLibraryModalTop .modal-content2 .modal-body2 form input.focus-visible,
.editLibraryModalTop .modal-content2 .modal-body2 form input.focus-visible {
  outline: 0.5px solid #cdcfd6;

}

.addLibraryModalTop .modal-content2 .modal-body2 form .custom-select2,
.editLibraryModalTop .modal-content2 .modal-body2 form .custom-select2 {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 13px;
}
.addLibraryModalTop .modal-content2 .modal-body2 form .custom-select2.focus-visible,
.editLibraryModalTop .modal-content2 .modal-body2 form .custom-select2.focus-visible {
  outline: 0.5px solid #cdcfd6;

}

.addLibraryModalTop .modal-content2 .modal-body2 form .custom-select2 .select-icon2,
.editLibraryModalTop .modal-content2 .modal-body2 form .custom-select2 .select-icon2 {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 14px;
  height: 50px;
}

.addLibraryModalTop .modal-content2 .modal-body2 form .custom-select2 .select-icon2 img,
.editLibraryModalTop .modal-content2 .modal-body2 form .custom-select2 .select-icon2 img {
  position: absolute;
  left: 14px;
  top: 17px;
}

.addLibraryModalTop .modal-content2 .modal-body2 form .custom-select2 select,
.editLibraryModalTop .modal-content2 .modal-body2 form .custom-select2 select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #626262;
  width: 100%;
  padding: 0px 14px;
  background: #ffffff;
  border-radius: 5px;
  height: 50px;
  border: 1px solid #cdcfd6;
  outline: none;
  box-shadow: none;
}

.addLibraryModalTop .modal-content2 .modal-body2 form .custom-select2 .select-icon2,
.editLibraryModalTop .modal-content2 .modal-body2 form .custom-select2 .select-icon2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 100%;
}

.addLibraryModalTop .modal-content2 .modal-body2 form button,
.editLibraryModalTop .modal-content2 .modal-body2 form button {
  width: 168px;
  height: 50px;
  border-radius: 40px;
  background: #097083;
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.96px;
  text-align: center;
  float: left;
  outline: none;
  box-shadow: none;
  border: none;
  margin-bottom: 40px;
  color: #ffffff;
}
.addLibraryModalTop .modal-content2 .modal-body2 form button.focus-visible,
.editLibraryModalTop .modal-content2 .modal-body2 form button.focus-visible {
  border: 2px solid #1a0707;
}

.addBookModal2 .modal-content2 .modal-footer {
  text-align: center;
}

.addBookModal2 .modal-content2 .modal-body2 .step1Btn2 {
  position: absolute;
  left: 35px;
  bottom: 35px;
  width: 168px;
  height: 50px;
  border-radius: 35px;
  background: #097083;
  border: none;
  outline: none;
  box-shadow: none;
  cursor: pointer;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.96px;
  text-align: center;
  color: #fff;
}
.addBookModal2 .modal-content2 .modal-body2 .step1Btn2.focus-visible{
  outline: 2px solid#1a0707; /* White outline for contrast */
  outline-offset: 2px; /* Creates spacing around the button */
  box-shadow: 0 0 8px rgba(255, 255, 255, 0.6); /* Optional for a glow effect */
}


.addBookModal2 .modal-content2 .modal-body2 .headerTitle2 {
  font-family: "Heebo", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #023842;
  margin-bottom: 11px;
}

.addBookModal2 .modal-content2 .modal-body2 .headerPara2 {
  text-align: center;
  margin-bottom: 19px;
}

.addBookModal2 .modal-content2 .modal-body2 form {
  width: 100%;
  padding: 0px 40px;
}

.addBookModal2 .modal-content2 .modal-body2 form form {
  width: 100%;
}

.addBookModal2 .modal-content2 .modal-body2 form .row2 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}

.addBookModal2 .modal-content2 .modal-body2 form .row2 input {
  width: calc(50% - 6px);
  height: 50px;
  background: #ffffff;
  border: 1px 0px 0px 0px;
  border: 1px solid #cdcfd6;
  border-radius: 5px;
  padding: 0px 16px;
  outline: none;
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #626262;
  box-shadow: none;
}
.addBookModal2 .modal-content2 .modal-body2 form .row2 input.focus-visible {
  outline: 0.5px solid #cdcfd6;
}
.addBookModal2 .modal-content2 .modal-body2 form textarea {
  width: 100%;
  resize: none;
  border-radius: 5px;
  height: 129px;
  border: 1px 0px 0px 0px;
  padding: 14px 16px;
  background: #ffffff;
  border: 1px solid #cdcfd6;
  outline: none;
  box-shadow: none;
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #626262;
  margin-bottom: 19px;
}
.addBookModal2 .modal-content2 .modal-body2 form textarea.focus-visible {
  outline: 0.5px solid #cdcfd6;

}
.addBookModal2 .modal-content2 .modal-body2 form textarea::placeholder {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #626262;
}

.addBookModal2 .modal-content2 .modal-body2 form .row2 .custom-select2 {
  position: relative;
  width: calc(50% - 6px);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.addBookModal2 .modal-content2 .modal-body2 form .custom-select2 .select-icon2 {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 14px;
  height: 50px;
}

.addBookModal2 .modal-content2 .modal-body2 form .custom-select2 .select-icon2 img {
  position: absolute;
  left: 14px;
  top: 18px;
}

.addBookModal2 .modal-content2 .modal-body2 form .row2 .custom-select2 select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #626262;
  width: 100%;
  padding: 0px 14px;
  background: #ffffff;
  border-radius: 5px;
  height: 50px;
  border: 1px solid #cdcfd6;
  outline: none;
  box-shadow: none;
}
.addBookModal2 .modal-content2 .modal-body2 form .row2 .custom-select2 select.focus-visible  {
  outline: 1.5px solid #cdcfd6;
}
.addBookModal2 .modal-content2 .modal-body2 form .row2 .custom-select2 .select-icon2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 100%;
}

.addBookModal2 .modal-content2 .modal-body2 .addPara2 {
  text-align: center;
  font-weight: 500;
  color: #023842;
  cursor: pointer;
  display: flex;
  margin-bottom: 153px;
  border: none;
  outline: none;
  align-items: center;
  flex-direction: column-reverse;
}
.addBookModal2 .modal-content2 .modal-body2 .addPara2.focus-visible {
  outline: 2px solid #1a0707;
  outline-offset: 2px;
}

.fileUploadCancel2 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 13px;
}

.fileUploadCancel2 .redCross2 {
  cursor: pointer;
}

.custom-upload2 {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  margin-bottom: 9px;
}
.custom-upload2.focus-visible {
  outline: 2px solid #0a0a0a;
  outline-offset: 2px;
}
.custom-upload2 .fileName {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: #626262;
  margin-left: 18px;
}

.custom-upload2 input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
  height: 100%;
  width: 100%;
}
.custom-upload2 label.focus-visible{
  border: 2px solid #0a0a0a;
}
.custom-upload2 button.focus-visible{
  border: 2px solid #0a0a0a;
}
.custom-upload2  input.focus-visible{
  border: 2px solid #0a0a0a;
}

.custom-upload2 label {
  width: 139px;
  height: 38px;
  border-radius: 35px;
  border: 1px solid #2ca3b9;
  cursor: pointer;
  color: #2ca3b9;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.96px;
  text-align: center;
}
.custom-upload2 button {
  width: 139px;
  height: 38px;
  border-radius: 35px;
  border: 1px solid #2ca3b9;
  cursor: pointer;
  color: #2ca3b9;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.96px;
  text-align: center;
}

.custom-upload2 label img {
  margin-right: 9px;
  margin-bottom: 0px !important;
}

.custom-upload2 button img {
  margin-right: 9px;
  margin-bottom: 0px !important;
}

.fileName {
  display: inline-block;
  margin-right: 10px;
}

.addBookModal2 .modal-content2 .modal-body2 form .modal-footer .back,
.addBookModal2 .modal-content2 .modal-body2 .back2 {
  width: 168px;
  height: 50px;
  border-radius: 35px;
  border: 1px 0px 0px 0px;
  margin: 5px;
  border: 1px solid #097083;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.96px;
  text-align: center;
  color: #097083;
  cursor: pointer;
  background: transparent;
  margin-right: 9px;
}
.addBookModal2 .modal-content2 .modal-body2 form .modal-footer .back.focus-visible,
.addBookModal2 .modal-content2 .modal-body2 .back2.focus-visible {
  outline: 2px solid#1a0707;
}

.addBookModal2 .modal-content2 .modal-body2 .successUpload2 {
  display: block;
  margin: auto;
}

.addBookModal2 .modal-content2 .modal-body2 form .modal-footer .continueBtn2,
.addBookModal2 .modal-content2 .modal-body2 .continueBtn2 {
  width: 168px;
  height: 50px;
  border-radius: 35px;
  background: #097083;
  border: none;
  cursor: pointer;
  outline: none;
  box-shadow: none;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.96px;
  text-align: center;
  color: #fff;
}
.addBookModal2 .modal-content2 .modal-body2 form .modal-footer .continueBtn2.focus-visible,
.addBookModal2 .modal-content2 .modal-body2 .continueBtn2.focus-visible {
  border: 2px solid #1a0707; /* White focus ring */
  outline-offset: 12px;  
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.5); 
} 
.close2 {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close2:hover,
.close2:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.mainContent2 .contentArea2  {
  width: 100%;
  box-shadow: 0px 0px 30px 0px #0000001f;
  background: #fff;
  border-radius: 0px;
  min-height: 92vh;
}

.mainContent2 .contentArea2  .headerTop2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 46px;
}

.mainContent2 .contentArea2  .headerTop2 h2 {
  font-family: "Heebo", sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 24px;
  color: #0f4152;
}

.mainContent2 .contentArea2  .headerTop2 .timeStamp2     {
  padding: 0px 26px;
  height: 45px;
  border-radius: 59px;
  background: #f1f1f1;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mainContent2 .contentArea2  .headerTop2 .timeStamp2    h3 {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 20.56px;
  text-align: left;
  color: #2d2d2d;
  margin-left: 9px;
}

.mainContent2 .contentArea2  .headerTop2   .timeStamp2     h3 span {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20.56px;
  text-align: left;
  color: #2d2d2d;
}

.mainContent2 .contentArea2  .progress2     {
  width: 100%;
}

.mainContent2 .contentArea2  .progress2     .myAccountList2   {
  width: 100%;
  text-align: right;
  border-bottom: 1px solid #acacac;
}

.mainContent2 .contentArea2  .progress2     .myAccountList2   button {
  margin-right: 22px;
  padding-bottom: 4px;
  background: transparent;
  outline: none;
  box-shadow: none;
  border: none;
  /* width: 108px; */
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #5f5f5f;
  cursor: pointer;
}
.mainContent2 .contentArea2  .progress2     .myAccountList2   button.focus-visible {
  border-bottom: 2px solid #0a7a8f; /* Keep active underline */
  outline: none;} 

.mainContent2 .contentArea2  .progress2     .myAccountList2   button.active {
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: #0a7a8f;
}

/* .mainContent2 .contentArea2  .progress2     .myAccountList2   button.active::after {
  content: "";
  display: block;
  width: 108px;
  height: 4px;
  margin-bottom: -4px;
  margin-top: 3px;
  border-radius: 35px;
  background: #0a7a8f;
} */

.mainContent2 .contentArea2 .progress2 .myAccountList2 button.active:not(.focus-visible)::after {
  content: "";
  display: block;
  width: 108px;
  height: 4px;
  margin-bottom: -4px;
  margin-top: 3px;
  border-radius: 35px;
  background: #0a7a8f;

}


.mainContent2 .contentArea2 .progress2 .myAccountList2 button.focus-visible {
  border-bottom: 2px solid #0a7a8f; /* Keep active underline */
  outline: none; 
}


/* .mainContent .contentArea .progress .myAccountList button.active::after {
  content: "";
  display: block;
  width: 108px;
  height: 4px;
  margin-bottom: -4px;
  margin-top: 3px;
  border-radius: 35px;
  background: #0a7a8f;
} */
.mainContent2 .contentArea2 .progress2 .myAccountList2 button.active:not(.focus-visible)::after {
  content: "";
  display: block;
  width: 108px;
  height: 4px;
  margin-bottom: -4px;
  margin-top: 3px;
  border-radius: 35px;
  background: #0a7a8f;
}

.mainContent2 .contentArea2  .progress2     .myAccountList2   button:nth-last-child(1) {
  margin-left: 5px !important;
}

.mainContent2 .contentArea2  .progress2     .tabContentTop2   {
  width: 100%;
  padding: 45px;
}

.mainContent2 .contentArea2  .progress2     .tabContentTop2   .tabcontent2    {
  display: none;
}

.mainContent2 .contentArea2  .progress2     .tabContentTop2   .tabcontent2    .row2 {
  display: flex;
  justify-content: space-between;
 
  width: 100%;
}

.mainContent2 .contentArea2  .progress2     .tabContentTop2   .tabcontent2    .row2 .card2 {
  max-width: 349px;
  width: 32%;
  height: 383px;
  overflow: hidden;
  background: #f1f1f1;
  border-radius: 35px;
  padding: 24px 24px 0px 24px;
  position: relative;
}

.mainContent2 .contentArea2  .progress2     .tabContentTop2   .tabcontent2    .row2 .card2 p {
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  text-align: center;
  color: #0f4152;
  margin-bottom: 6px;
}

.mainContent2 .contentArea2  .progress2     .tabContentTop2   .tabcontent2    .row2 .card2 h2 {
  font-family: "Heebo", sans-serif;
  font-size: 26px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #0a7a8f;
}

.mainContent2 .contentArea2  .progress2     .tabContentTop2   .tabcontent2    .row2 .card2 .bottomImg2 {
  position: absolute;
  bottom: -58px;
  left: 0px;
  right: 0px;
  margin: auto;
}

.mainContent2 .contentArea2  .progress2     .tabContentTop2   .tabcontent2    .row2 .card2 .rowTick2 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mainContent2 .contentArea2  .progress2     .tabContentTop2   .tabcontent2    .row2 .card2 .rowTick2 img {
  margin: 0px 2px;
}

.mainContent2 .contentArea2  .progress2     .tabContentTop2   .tabcontent2    form {
  max-width: 344px;
  width: 100%;
  flex-wrap: wrap;
}

.mainContent2 .contentArea2  .progress2     .tabContentTop2   .tabcontent2    form h2 {
  font-family: "Heebo", sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  color: #0f4152;
  margin-bottom: 18px;
}

.mainContent2 .contentArea2  .progress2     .tabContentTop2   .tabcontent2    form input {
  width: 100%;
  height: 50px;
  outline: none;
  box-shadow: none;
  border-radius: 7px;
  background: #ffffff;
  border: 1px solid #cdcfd6;
  margin-bottom: 7px;
  padding: 0px 23px;
}

.mainContent2 .contentArea2  .progress2     .tabContentTop2   .tabcontent2    form input::placeholder {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #626262;
}

.mainContent2 .contentArea2  .progress2     .tabContentTop2   .tabcontent2    form .formBtn2 {
  text-align: left;
}

.mainContent2 .contentArea2  .progress2     .tabContentTop2   .tabcontent2    form .formBtn2 button {
  width: 168px;
  height: 50px;
  border-radius: 35px;
  background: #0a7a8f;
  border: none;
  cursor: pointer;
  outline: none;
  box-shadow: none;
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 23.5px;
  text-align: center;
  color: #ffffff;
}



.mainContent2
  .contentArea2
  .progress2
  .tabContentTop2
  .tabcontent2
  form
  .formBtn2
  button.focus-visible{
    outline: 2px solid #1a0707; /* White outline for contrast */
    outline-offset: 2px; /* Creates spacing around the button */
    box-shadow: 0 0 8px rgba(255, 255, 255, 0.6); /* Optional for a glow effect */

  }
.mainContent2 .individualHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mainContent2 .individualHeader .left2 h2 {
  font-family: "Heebo", sans-serif;
  font-size: 28px;
  font-weight: 700;
  line-height: 24px;
  color: #0f4152;
  margin-left: 35px;
}

.mainContent2 .individualHeader .left2 h2 span {
  font-family: "Heebo", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  color: #0f4152;
}

.mainContent2 .individualHeader .right2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mainContent2 .individualHeader .totalUser {
  height: 59px;
  border-radius: 40px;
  background: #f1f1f1;
  padding: 0px 31px;
  border-radius: 59px;
 
}

.mainContent2 .individualHeader .totalUser h4 {
  font-family: "Heebo", sans-serif;
  font-size: 22px;
  font-weight: 700;
  line-height: 24px;
  color: #0f4152;
  margin-left: 22px;
}

.mainContent2 .individualHeader .totalUser p {
  font-family: "Heebo", sans-serif;
  font-size: 18px;
  font-weight: 300;
  line-height: 24px;
  color: #0f4152;
}

.mainContent2 .individualHeader .totalUser p span {
  font-family: "Heebo", sans-serif;
  font-size: 26px;
  font-weight: 700;
  line-height: 24px;
  color: #0a7a8f;
}

.mainContent2 .individualHeader .right2 .progressLine {
  margin-left: 41px;
}

.mainContent2 .addLibTop .contentTab2   .tabcontent2    .row2 {
  padding: 5px 18px;
  height: 53px;
}

.mainContent2 .progressChapter .contentTab2   .tabcontent2    .row2 .circle2     {
  width: 31px;
  height: 31px;
  font-size: 14px;
}
.mainContent2 .individualHeader .right2 .progressLine{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
}
.mainContent2 .individualHeader .right2 .progressLine p {
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 23.5px;
  text-align: center;
  color: #2d2d2d;
  margin-bottom: 3px;
}

.mainContent2 .individualHeader .right2 .progressLine .grayLine {
  width: 100px;
  height: 4px;
  border-radius: 35px;

  background: #d8d8d8;
  position: relative;
  overflow: hidden;
}

.mainContent2 .individualHeader .right2 .progressLine .grayLine .insideLine {
  background: #0a7a8f;
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
  border-radius: 35px;
}

.mainContent2 .individualHeader .right2 .btn {
  display: flex;
  align-items: center;
}

.mainContent2 .individualHeader .right2 .btn h2 {
  font-family: "Heebo", sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 26.44px;
  color: #0f4152;
  margin-left: 9px;
}

.mainContent2 .individualHeader .right2 .btn button {
  width: 157px;
  height: 44px;
  border-radius: 35px;
  background: #097285;
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 23.5px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}
.mainContent2 .individualHeader .right2 .btn button.focus-visible{
  border: 2px solid #1a0707;
} 

.mainContent2 .progressChapter {
  width: 100%;
  box-shadow: 0px 0px 30px 0px #0000001f;
  background: #fff;
  border-radius: 10px 10px 0px 0px;
  padding-bottom: 50px;
}

.mainContent2 .progressChapter .chapterList {
  border-bottom: 1px solid #acacac;
}

.mainContent2 .progressChapter .chapterList button {
  margin-left: 18px;
  border: none;
  outline: none;
  box-shadow: none;
  background: transparent;
  padding-bottom: 3px;
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  width: 120px;
  font-weight: 400;
  line-height: 24px;
  position: relative;
  color: #5f5f5f;
  cursor: pointer;
}
.mainContent2 .progressChapter .chapterList button.focus-visible{
  border-bottom: 2px solid #1a0707;
}
.mainContent2 .progressChapter .chapterList {
  width: 100%;
  text-align: right;
}

.mainContent2 .progressChapter .chapterList button.active:not(.focus-visible)::after {
  content: "";
  display: block;
  width: 120px;
  height: 4px;
  margin-bottom: -4px;
  margin-left: 0px;
  margin-top: 3px;
  border-radius: 35px;
  background: #0a7a8f;
}

.mainContent2 .progressChapter .chapterList button.active {
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #0a7a8f;
}

.mainContent2 .progressChapter .contentTab2   {
  width: 100%;
}

.mainContent2 .progressChapter .contentTab2   .tabcontent2    .row2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 18px;
  border-bottom: 1px solid #cdcfd6;
}

.mainContent2 .progressChapter .contentTab2   .tabcontent2    .row2 h2 {
  font-family: "Heebo", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  width: 150px;
  margin-right: 3px;
 text-align: right;
}

.mainContent2 .progressChapter .contentTab2   .tabcontent2    .row2 .readAgaintext2   {
  color: #b8b8b8;
}

.mainContent2 .progressChapter .contentTab2   .tabcontent2    .row2 .progressBar {
  display: flex;
  align-items: center;
  margin-left: 35px;
}

.mainContent2 .progressChapter .contentTab2   .tabcontent2    .row2 .progressBar p:nth-child(1) {
  width: 130px;
}

.mainContent2 .progressChapter .contentTab2   .tabcontent2    .row2 .progressBar .progressTopLine {
  position: relative;
  width: 133px;
  height: 5px;
  background: #d8d8d8;
  border-radius: 35px;
  margin-left: 48px;
  overflow: hidden;
}

.mainContent2 .progressChapter .contentTab2   .tabcontent2    .row2 .progressBar .progressTopLine .progressInnerLine {
  background: #0a7a8f;
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 35px;
  bottom: 0px;
}

.mainContent2 .progressChapter .contentTab2   .tabcontent2    .row2 .progressBar .completed {
  width: 120px;
  font-family: "Heebo", sans-serif;
  font-size: 14px;

  font-weight: 400;
  line-height: 24px;
  color: #626262;
}

.mainContent2 .progressChapter .contentTab2   .tabcontent2    .row2 .progressBar .completed {
  width: 100px;
}

.mainContent2 .progressChapter .contentTab2   .tabcontent2    .row2 .description {
  width: 450px;
  text-align: right;
}

.mainContent2 .progressChapter .contentTab2   .tabcontent2    .row2 .description p {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #c1c1c1;
  text-align: right;
}

.mainContent2 .progressChapter .contentTab2   .tabcontent2    .row2 .btnsList {
  width: 228px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.mainContent2 .progressChapter .contentTab2   .tabcontent2    .row2 .btnsList .keepReading {
  width: 118px;
  height: 31px;
  border-radius: 35px;
  border: none;
  outline: none;
  box-shadow: none;
  background: #0a7a8f;
  color: #fff;
  font-family: "Heebo", sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 19.09px;
  text-align: center;
}
.mainContent2 .progressChapter .contentTab2   .tabcontent2    .row2 .btnsList .keepReading.focus-visible{
  border: 2px solid #1a0707;
}


.mainContent2 .progressChapter .contentTab2   .tabcontent2    .row2 .btnsList .readLock {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 118px;
  height: 31px;
  border-radius: 35px;
  border: 1px solid #097285;
  background: transparent;
  /* margin-left: 5px; */
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20.56px;
  text-align: center;
  color: #0b7a8e;
}
.mainContent2 .progressChapter .contentTab2   .tabcontent2    .row2 .btnsList .readLock.focus-visible{
  border: 2px solid #1a0707;
}

.mainContent2 .progressChapter .contentTab2   .tabcontent2    .row2 .btnsList .readLock img {
  width: 17px;
  height: 17px;
  margin-left: 11px;
}

.mainContent2 .progressChapter .contentTab2   .tabcontent2    .row2 .btnsList .colored {
  background: #0a7a8f;
  color: #fff;
}

.mainContent2 .progressChapter .contentTab2   .tabcontent2    .row2 .btnsList .readAgain {
  width: 118px;
  height: 31px;
  border-radius: 35px;
  border: 1px solid #b6b6b6;
  background: transparent;
  outline: none;
  box-shadow: none;
  font-family: "Heebo", sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.09px;
  text-align: center;
  color: #909090;
}
.mainContent2 .progressChapter .contentTab2   .tabcontent2    .row2 .btnsList .readAgain.focus-visible{
  border: 2px solid #1a0707;
}

.mainContent2 .progressChapter .contentTab2   .tabcontent2    .completedBookProgress {
  background: #f2f5fe !important;
}

.mainContent2 .progressChapter .contentTab2   .tabcontent2    .completedBookProgress h2 {
  color: #0a7a8f;
  font-weight: 700;
}

.mainContent2 .progressChapter .contentTab2   .tabcontent2    .completedBookProgress .progressBar .completed {
  font-weight: 700;
  color: #0a7a8f;
  max-width: 200px;

}

.mainContent2 .progressChapter .contentTab2   .tabcontent2    .completedBookProgress .description p {
  text-align: right;
  width: auto;
  color: #0a7a8f;
  font-weight: 700;
}

.mainContent2 .readingBookTop {
  padding: 31px 61px;

  position: relative;
}

.mainContent2 .readingBookTop .player .group {
  display: none;
}

.backPage2  {
  position: absolute;
  left: -70px;
  top: 51px;
  width: 51px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #1d9da2;
  background: #ffffff;
  cursor: pointer;
  border-radius: 40px;
}

.mainContent2 .readingBookTop p {
  font-family: "Heebo", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #0f4152;
  margin-bottom: 23px;
}

.mainContent2 .readingBookTop .player {
  width: 100%;
  padding: 15px 20px;
  border: 1px solid #9ebebf;
  height: 329px;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 201px;
}

.mainContent2 .readingBookTop .player h1 {
  font-family: "Heebo", sans-serif;
  font-size: 35px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #0f4152;
}

.mainContent2 .readingBookTop .player .footerPlayer {
  position: absolute;
  bottom: 15px;
  width: 100%;
  left: 0px;
  right: 0px;
  border-radius: 7px;
  padding: 0px 20px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.mainContent2 .readingBookTop .player .footerPlayer .left2 {
  display: flex;
  align-items: center;
  width: 140px;
}

.mainContent2 .readingBookTop .player .footerPlayer .left2 img {
  cursor: pointer;
}
.mainContent2 .readingBookTop .player .footerPlayer .left2 img:focus-visible {
  border: 2px solid #1a0707; /* Add the focus-visible styling here */
}

.mainContent2 .readingBookTop .player .footerPlayer .left2 img:nth-last-child(1) {
  margin-left: 13px;
}

.mainContent2 .readingBookTop .player .footerPlayer .center2     {
  display: flex;
  justify-content: center;
}

.mainContent2 .readingBookTop .player .footerPlayer .center2     .previous,
.mainContent2 .readingBookTop .player .footerPlayer .center2     .repeat,
.mainContent2 .readingBookTop .player .footerPlayer .center2     .start,
.mainContent2 .readingBookTop .player .footerPlayer .center2     .next {
  width: 74px;
  height: 63px;
  border-radius: 6px;
  border: 1px 0px 0px 0px;
  opacity: 0px;
  border: 1px solid #e4e4e4;
  margin: 0px 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.mainContent2 .readingBookTop .player .footerPlayer .center2     .previous:hover,
.mainContent2 .readingBookTop .player .footerPlayer .center2     .repeat:hover,
.mainContent2 .readingBookTop .player .footerPlayer .center2     .start:hover,
.mainContent2 .readingBookTop .player .footerPlayer .center2     .next:hover {
  background: #f1f1f1;
}

.mainContent2 .readingBookTop .player .footerPlayer .center2     .previous:hover p,
.mainContent2 .readingBookTop .player .footerPlayer .center2     .repeat:hover p,
.mainContent2 .readingBookTop .player .footerPlayer .center2     .start:hover p,
.mainContent2 .readingBookTop .player .footerPlayer .center2     .next:hover p {
  color: #0f4152;
}

.mainContent2 .readingBookTop .player .footerPlayer .center2     .previous .inner,
.mainContent2 .readingBookTop .player .footerPlayer .center2     .repeat .inner,
.mainContent2 .readingBookTop .player .footerPlayer .center2     .start .inner,
.mainContent2 .readingBookTop .player .footerPlayer .center2     .next .inner2     {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.mainContent2 .readingBookTop .player .footerPlayer .center2     .previous .inner2     img,
.mainContent2 .readingBookTop .player .footerPlayer .center2     .repeat img,
.mainContent2 .readingBookTop .player .footerPlayer .center2     .start img,
.mainContent2 .readingBookTop .player .footerPlayer .center2     .next img {
  margin-bottom: 4px;
  margin-top: 14px;
  width: 24px;
  height: 24px;
}

.mainContent2 .readingBookTop .player .footerPlayer .center2     .previous .inner2     p,
.mainContent2 .readingBookTop .player .footerPlayer .center2     .repeat .inner2     p,
.mainContent2 .readingBookTop .player .footerPlayer .center2     .start .inner2     p,
.mainContent2 .readingBookTop .player .footerPlayer .center2     .next .inner2     p {
  margin-bottom: 0px;
  font-family: "Heebo", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  text-align: center;
  color: #ababab;
}

.mainContent2 .readingBookTop .player .footerPlayer .right2 {
  width: 140px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.mainContent2 .readingBookTop .player .footerPlayer .right2 .customSelect {
  position: relative;
  width: 81px;
  height: 34px;
  border-radius: 35px;
  border: 1px solid #1d9da2;
  background: #fff;
 
}

.mainContent2 .readingBookTop .player .footerPlayer .right2   .customSelect .dropDownBtn {
  width: 100%;
  height: 100%;
  border-radius: 24px;
  background: transparent;
  border: none;
  outline: none;
  padding: 0px 10px;
  box-shadow: none;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 33px;
  color: #0a7a8f;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url("../../img/downSelectIcon.svg") no-repeat right2 8px center;
  background-size: 12px;
  padding-right: 24px;
}


.mainContent2 .readingBookTop .player .footerPlayer .right2   .customSelect .dropContent {
  display: none;
  position: absolute;
  background-color: white;
  border: 1px solid #1d9da2;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  width: 81px;
  bottom: -8px;
  height: auto;
  overflow: hidden;
  border-radius: 25px;
}
.mainContent2 .readingBookTop .player .footerPlayer .right2   .customSelect.focus-visible{
  border: 2px solid #1a0707;
}
.mainContent2 .readingBookTop .player .footerPlayer .right2   .customSelect .dropContent {
  display: none;
  position: absolute;
  background-color: white;
  border: 1px solid #1d9da2;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  width: 81px;

  bottom: -210px;
  height: auto;

  border-radius: 14px;
}

.mainContent2 .readingBookTop .player .footerPlayer .right2   .customSelect .dropContent a {
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
  display: block;
  padding: 0px 10px;
  line-height: 33px;
  text-align: left;
  color: #0A7A8F;

}

.mainContent2 .readingBookTop .player .footerPlayer .right2   .customSelect .dropContent a:hover {
  background-color: #0d8e9b;
  color: #fff;
}



.mainContent2 .readingBookTop .player .footerPlayer .right2   .customSelect .dropContent a.activeBtn {
  background-color: #0d8e9b;
  color: #fff;

}

.mainContent2 .readingBookTop .player .footerPlayer .right2   .customSelect .dropContent.show {
  display: block;
  color: #fff;
}

.mainContent2 .readingBookTop .player .footerPlayer .right2 .customSelect img {
  position: absolute;
  right: 10px;
  top: 8px;
}


option {
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 33px;
  color: #0a7a8f;
}

.mainContent2 .readingBookTop .progressPlayer {
  width: 271px;
  margin: auto;
  margin-bottom: 123px;
}

.mainContent2 .readingBookTop .progressPlayer p {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #717171;
  margin-bottom: 11px;
}

.mainContent2 .readingBookTop .progressPlayer .progress2     {
  width: 100%;
  height: 7px;
  background: #d8d8d8;
  border-radius: 35px;
  overflow: hidden;
  position: relative;
}

.mainContent2 .readingBookTop .progressPlayer .progress2     .innerLine {
  position: absolute;
  left: 0px;
  height: 100%;
  top: 0px;
  bottom: 0px;
  border-radius: 35px;
  background: #67b6c5;
}

.mainContent2 .readingBookTop .practiceEnd {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mainContent2 .readingBookTop .practiceEnd h4 {
  font-family: "Heebo", sans-serif;
  font-size: 22px;
  font-weight: 900;
  line-height: 33px;
  text-align: center;
  color: #e90000;
}

.mainContent2 .readingBookTop .celebrate {
  width: 100%;
  border: 1px solid #9ebebf;
  border-radius: 7px;
  height: 679px;
  overflow: hidden;
  margin-bottom: 100px;
}

.mainContent2 .readingBookTop .celebrate h2 {
  font-family: "Heebo", sans-serif;
  font-size: 35px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #0f4152;
  margin-top: 57px;
  margin-bottom: 33px;
}

.mainContent2 .readingBookTop .celebrate img {
  width: 100%;
  height: auto;
}

.settingModal2 .innerContent2 {
  max-width: 682px;
  width: 90%;
  margin: 79px auto;
  background: #ffffff;
  border-radius: 10px;
  overflow: hidden;
}

.settingModal2 .innerContent2 .headerModal2  {
  width: 100%;
  height: 87px;
  background: #2ca3b9;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
}

.settingModal2 .innerContent2 .settingModal2-body2 {
  padding: 27px 34px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.settingModal2 .innerContent2 .settingModal2-body2 .left2 {
  max-width: 285px;
  width: 49%;
  direction: rtl;
}

.settingModal2 .innerContent2 .settingModal2-body2 .left2 .headtext2   {
  width: 100%;
  border-bottom: 1px solid #000000;
  padding-bottom: 4px;
  margin-bottom: 9px;
}

.settingModal2 .innerContent2 .settingModal2-body2 .left2 .headtext2   h2 {
  font-family: "Heebo", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  color: #023842;
}

.settingModal2 .innerContent2 .settingModal2-body2 .left2 form {
  width: 100%;
}

.settingModal2 .innerContent2 .settingModal2-body2 .right2 {
  max-width: 265px;
  width: 48%;
}

.settingModal2 .innerContent2 .settingModal2-body2 .left2  form [type="radio"]:checked,
.settingModal2 .innerContent2 .settingModal2-body2 .left2  form [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.settingModal2 .innerContent2 .settingModal2-body2 .left2  form [type="radio"]:checked+label,
.settingModal2 .innerContent2 .settingModal2-body2 .left2  form [type="radio"]:not(:checked)+label {
  position: relative;
  padding-right: 28px;
  cursor: pointer;
  display: inline-block;
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: right;
  color: #023842;
}
.settingModal2 .innerContent2 .settingModal2-body2 .left2  form [type="radio"].focus-visible{
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #000000;
  border-radius: 100%;
  background: #1bbb1b;
}

.settingModal2 .innerContent2 .settingModal2-body2 .left2 form p {
  margin-bottom: 5px;
}

.settingModal2 .innerContent2 .settingModal2-body2 .left2 form .inner,
.settingModal2 .innerContent2 .settingModal2-body2 .left2 form .inner2     {
  display: flex;
  align-self: center;
  font-family: "Heebo", sans-serif;
  font-size: 12px;
  font-weight: 300;
  line-height: 24px;
  margin-right: 28px;
  color: #023842;
  margin-top: -10px !important;
}

.settingModal2 .innerContent2 .settingModal2-body2 .left2  form [type="radio"]:checked+label span,
.settingModal2 .innerContent2 .settingModal2-body2 .left2  form [type="radio"]:not(:checked)+label span {
  display: flex;
  align-self: center;
  font-family: "Heebo", sans-serif;
  font-size: 12px;
  font-weight: 300;
  line-height: 24px;
  text-align: right;
  color: #023842;
  margin-top: -10px !important;
}

.settingModal2 .innerContent2 .settingModal2-body2 .left2  form [type="radio"]:checked+label:before,
.settingModal2 .innerContent2 .settingModal2-body2 .left2  form [type="radio"]:not(:checked)+label:before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #000000;
  border-radius: 100%;
  background: #fff;
}

.settingModal2 .innerContent2 .settingModal2-body2 .left2  form [type="radio"]:checked+label:after,
.settingModal2 .innerContent2 .settingModal2-body2 .left2  form [type="radio"]:not(:checked)+label:after {
  content: "";
  width: 12px;
  height: 12px;
  background: #2ca3b9;
  position: absolute;
  top: 4px;
  right: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.settingModal2 .innerContent2 .settingModal2-body2 .left2  form [type="radio"]:not(:checked)+label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.settingModal2 .innerContent2 .settingModal2-body2 .left2  form [type="radio"]:checked+label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.custom-dropdown {
  position: relative;
  display: inline-block;
}

.selected-value {
  width: 27px;
  height: 15px;
  border: 1px solid #000000;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Heebo", sans-serif;
  font-size: 12px;
  font-weight: 300;
  margin: 0px 5px;
  line-height: 24px;
  color: #023842;
  margin-top: 4px !important;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  border-top: none;
  right: 0px;
  overflow: hidden;
  border-radius: 7px;
  width: 100%;
  width: 110px;
  z-index: 999;
  height: auto;
  box-shadow: 0px 4px 4px 0px #00000040;
}


.custom-dropdown .headerTimer {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  display: none;
  margin-bottom: 11px;
}

.custom-dropdown .headerTimer h3 {
  font-family: "Heebo", sans-serif;
  font-size: 20px;
  display: inline;
  font-weight: 700;
  line-height: 24px;
  color: #023842;
}

.custom-dropdown .dropdown-content p {
  display: none;
}

.custom-dropdown .headerTimer img {
  cursor: pointer;
}

.dropdown-content .paraG {
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  color: #023842;
  margin-bottom: 20px;
  display: none;
}

.dropdown-content .option {
  padding: 10px;
  height: 34px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  color: #023842;
}

.dropdown-content .option:hover {
  background: #eaebf1;
  color: #023842;
  font-weight: 700;
}

.settingModal2 .innerContent2 .settingModal2-body2 .left2 form .marker {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  /* width: 300px !important; */
}

.settingModal2 .innerContent2 .settingModal2-body2 .left2 form .marker .mark {
  width: 148px;
  height: 29px;
  border-radius: 35px;
  background: transparent;
  border: 1px solid #acacac;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Heebo", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  color: #023842;
}

.settingModal2 .innerContent2 .settingModal2-body2 .left2 form .marker .mark .yellow,
.settingModal2 .innerContent2 .settingModal2-body2 .left2 form .marker .mark .gray {
  background: yellow;
  margin-left: 5px;
  padding: 0px 3px;
}

.settingModal2 .innerContent2 .settingModal2-body2 .left2 form .marker .mark .gray {
  background: #d5d5d5;
}

.settingModal2 .innerContent2 .settingModal2-body2 .left2  form .marker .mark .underline {
  border-bottom: 1px solid #023842;
  margin-left: 5px;
  padding: 0px 3px;
}
.settingModal2 .innerContent2 .settingModal2-body2 .left2 form .marker .mark .noMarker {
  margin-left: 5px;
}
.settingModal2 .innerContent2 .settingModal2-body2 .left2 .headtext2   {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.settingModal2 .innerContent2 .settingModal2-body2 .left2 .headtext2   p {
  font-family: "Heebo", sans-serif;
  font-size: 12px;
  font-weight: 300;
  line-height: 24px;
  color: #023842;
}

.settingModal2 .innerContent2 .btnSave2 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.settingModal2 .innerContent2 .btnSave2 .save {
  width: 168px;
  height: 50px;
  border-radius: 35px;
  background: #097083;
  border: none;
  outline: none;
  box-shadow: none;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  cursor: pointer;
  font-weight: 500;
  line-height: 18.96px;
  text-align: center;
  color: #ffffff;
  margin-left: 35px;
  margin-bottom: 35px;
}
.settingModal2 .innerContent2 .btnSave2 .save.focus-visible{
  border:  2px solid #000000;
}

.bookMobileColor2 .topContent2 .books2 .forMobileSearch2 {
  width: 100%;
  display: none;
  margin-bottom: 24px;
}

.bookMobileColor2 .topContent2 .books2 .forMobileSearch2 .dropDownSearch2 {
  /* position: relative; */
  margin-bottom: 24px;
  z-index: 9999;
  box-shadow: 0px 0px 30px 0px #0000001f;
  background: #ffffff;
  border-radius: 7px;
}

.bookMobileColor2 .topContent2 .books2 .forMobileSearch2 .dropDownSearch2 .findBook2 {
  height: 60px;

  padding: 0px 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.bookMobileColor2 .topContent2   .books2 .forMobileSearch2 .dropDownSearch2 .findBook2 h3 {
  font-family: "Heebo", sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  color: #0f4152;
}

.bookMobileColor2 .topContent2   .books2 .forMobileSearch2 .searchBooksMobile2      {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bookMobileColor2 .topContent2   .books2 .forMobileSearch2 .searchBooksMobile2      {
  width: 100%;
}

.bookMobileColor2 .topContent2   .books2 .forMobileSearch2 .searchBooksMobile2      h3 {
  font-family: "Heebo", sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 24px;
  color: #0f4152;
}

.bookMobileColor2 .topContent2   .books2 .forMobileSearch2 .searchBooksMobile2      .custom-select2 {
  position: relative;
  max-width: 246px;
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.bookMobileColor2 .topContent2   .books2 .forMobileSearch2 .searchBooksMobile2      .custom-select2.focus-visible{
  border: 2px solid #1a0707;
}

.bookMobileColor2 .topContent2   .books2 .forMobileSearch2 .searchBooksMobile2      .select-icon2 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 13px;
}

.bookMobileColor2 .topContent2   .books2 .forMobileSearch2 .searchBooksMobile2      .custom-select2 .select-icon2 {
  position: absolute;
  left: 14px;
}

.bookMobileColor2 .topContent2   .books2 .forMobileSearch2 .searchBooksMobile2      .custom-select2 select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-right: 21px;
  height: 42px;
  width: 100%;
  background: transparent;
  border: 1px solid #097285;
  padding: 0px 16px;
  outline: none;
  box-shadow: none;
  border-radius: 35px;
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 33px;
  color: #5c5c5c;
}
.bookMobileColor2 .topContent2   .books2 .forMobileSearch2 .searchBooksMobile2  .custom-select2  select.focus-visible{
  border: 2px solid #1a0707;

}


.bookMobileColor2 .topContent2   .books2 .forMobileSearch2 .dropDownSearch2 .seachQuery2{
  transition: 0.4s;
  padding: 0px 18px 18px 18px;
  height: auto;
  display: none;
}

.bookMobileColor2 .topContent2   .books2 .forMobileSearch2 .dropDownSearch2 .seachQuery2 .inner2     {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.bookMobileColor2 .topContent2   .books2 .forMobileSearch2 .dropDownSearch2 .seachQuery2 .inner2  .custom-select2 {
  position: relative;
  width: 48%;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bookMobileColor2 .topContent2   .books2 .forMobileSearch2 .dropDownSearch2 .seachQuery2 .inner2  .custom-select2 .select-icon2 {
  position: absolute;
  top: 13px;
  left: 10px;
  width: 20px;
  height: 100%;
}

.bookMobileColor2 .topContent2   .books2 .forMobileSearch2 .dropDownSearch2 .seachQuery2 .inner2  .custom-select2 select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-right: 21px;
  height: 42px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #b6b6b6;
  padding: 0px 16px;
  outline: none;
  box-shadow: none;
  border-radius: 35px;
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 33px;
  color: #0f4152;
}
.bookMobileColor2 .topContent2   .books2 .forMobileSearch2 .dropDownSearch2 .seachQuery2 .inner2  .custom-select2 select.focus-visible{
  border: 2px solid #1a0707;

}
.bookMobileColor2 .topContent2   .books2 .forMobileSearch2 .dropDownSearch2 .seachQuery2 .inner2  .searchInput2  {
  width: 100%;
  margin: 10px 0px;
  cursor: pointer;
  height: 42px;
  border: 1px solid #b6b6b6;
  border-radius: 35px;
  overflow: hidden;
  display: flex;
  align-items: center;
}
.bookMobileColor2 .topContent2   .books2 .forMobileSearch2 .dropDownSearch2 .seachQuery2 .inner2  .searchInput2.focus-visible{
  border: 2px solid #1a0707;

}

.bookMobileColor2 .topContent2   .books2 .forMobileSearch2 .dropDownSearch2 .seachQuery2 .inner2  .searchInput2  input {
  width: calc(100% - 35px);
  height: 100%;
  border-radius: 35px;
  outline: none;
  box-shadow: none;
  border: none;
  padding: 0px 14px;
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20.56px;
  color: #0f4152;
}

.bookMobileColor2 .topContent2   .books2 .forMobileSearch2 .dropDownSearch2 .seachQuery2 .inner2  .searchInput2  input::placeholder {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20.56px;
  color: #0f4152;
}

.bookMobileColor2 .topContent2   .books2 .forMobileSearch2 .dropDownSearch2 .seachQuery2 .inner2  img {
  cursor: pointer;
}

.bookMobileColor2 .topContent2   .books2 .forMobileSearch2 .dropDownSearch2 .seachQuery2 .inner2  button {
  width: 119px;
  height: 44px;
  background: #59a0ad;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 23.5px;
  text-align: center;
  color: #fff;
  border-radius: 35px;
  border: none;
  outline: none;
  box-shadow: none;
}
.bookMobileColor2 .topContent2   .books2 .forMobileSearch2 .dropDownSearch2 .seachQuery2 .inner2  button.focus-visible {
  border: 2px solid #1a0707;

}


.bookMobileColor2 .topContent2   .books2 .forMobileSearch2 .dropDownSearch2 .seachQuery2 .inner2   button.active {
  background: #097285;
}

#chapterMobile {
  font-family: "Heebo", sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 23px;
  color: #0f4152;
  margin-bottom: 24px;
  margin-top: 20px;
  display: none;
}

.footer {
  width: 100%;
  position: static;
  height: 66px;
  display: flex;
  align-items: center;
  background: #097185;
}

.footer .inner2     {
  max-width: 1140px;
  width: 90%;
  margin: auto;
  display: flex;
  align-items: center;
}

.footer .inner2     a {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 33px;
  width: 89px;
  text-align: left;
  color: #ffffff;
  text-decoration: none;
}

.mainContent2 .detailAccount2  Top {
  padding: 58px 46px;
}

.mainContent2 .contentArea2  .detailAccount2   {
  display: flex;
  align-items: center;
  padding: 0px !important;

  justify-content: space-between;
  margin-bottom: 18px;
}

.mainContent2 .contentArea2  .detailAccount2   .activeUsers2 {
  width: auto;
  padding: 0px 39px;
  height: 45px;
  background: #f1f1f1;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mainContent2 .contentArea2  .detailAccount2   .activeUsers2 p {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20.56px;
  color: #2d2d2d;
  display: flex;
  align-items: center;
}

.mainContent2 .contentArea2  .detailAccount2   .activeUsers2 p:nth-child(1) {
  margin-left: 37px;
}

.mainContent2 .contentArea2  .detailAccount2   .activeUsers2 p img {
  margin-right: 15px;
}
.mainContent2 .contentArea2 .userRegistrationLink2 .EmailButton{
  display: flex;
flex-wrap: wrap;
align-content: flex-start;
flex-direction: row-reverse;

}
.mainContent2 .contentArea2  .userRegistrationLink2 {
  width: 100%;
  margin: auto;
  border: 1px solid #cdcfd6;
  height: 123px;
  border-radius: 7px;
  display: flex;
  align-items: center;
  padding: 24px 24px;
  justify-content: space-between;
  margin-bottom: 28px;
}
.mainContent2 .contentArea2  .userRegistrationLink2 .left2{
  display: flex;
}
.mainContent2 .contentArea2  .userRegistrationLink2 .left2 h3 {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  color: #0f4152;
}

.mainContent2 .contentArea2  .userRegistrationLink2  .left2 .copyTexting2   {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.mainContent2 .contentArea2  .userRegistrationLink2  .left2 .copyTexting2   p,
.mainContent2 .contentArea2  .userRegistrationLink2  .left2 .hidden2,
#copyNotification {
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 24px;
  color: #44c6cb;
}

#copyNotification {
  color: green;
}

.mainContent2 .contentArea2  .userRegistrationLink2  .left2 .hidden2 {
  color: green;
  display: none;
}

.mainContent2 .contentArea2  .userRegistrationLink2  .left2 .copyTexting2   img {
  cursor: pointer;
  margin-right: 16px;
}

.mainContent2 .contentArea2  .userRegistrationLink2  button {
  width: 265px;
  height: 43px;
  padding: 10px;
  margin: 5px;
  border-radius: 40px;
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20.56px;
  text-align: center;
  color: #0a7a8f;
  border: 1px solid #979797;
  background: transparent;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;
  cursor: pointer;
}
.mainContent2 .contentArea2  .userRegistrationLink2  button.focus-visible{
  border: 2px solid #1a0707;
}


.mainContent2 .contentArea2  .userRegistrationLink2  button img {
  margin-right: 22px;
}

.mainContent2 .contentArea2  form {
  width: 100%;
  display: flex;
}

.mainContent2 .contentArea2  form .left2 {
  max-width: 344px;
  /* width: 50%; */
  margin-left: 55px;
}

.mainContent2 .contentArea2  form .left2 h4,
.mainContent2 .contentArea2  form .right2 h4 {
  font-family: "Heebo", sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  color: #0f4152;
  margin-bottom: 18px;
}

.mainContent2 .contentArea2  form .left2 input,
.mainContent2 .contentArea2  form .right2 input {
  border: 1px solid #cdcfd6;
  width: 100%;
  padding: 0px 23px;
  height: 50px;
  outline: none;
  box-shadow: none;
  background: transparent;
  border-radius: 7px;
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #626262;
  margin-bottom: 7px;
}

.mainContent2 .contentArea2  form .right2 {
  max-width: 344px;
  width: 50%;
}

.mainContent2 .contentArea2  form .left2 .container2     {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 7px;
}

.mainContent2 .contentArea2  form .left2 .container2     .custom-file-upload2 {
  width: 50px;
  height: 50px;
  display: inline-block;
  border: 1px solid #cdcfd6;
  border-radius: 7px;
  cursor: pointer;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 36px;

  background-color: transparent;
  position: relative;

}
.custom-file-upload2:focus-visible {
  outline: 1px solid #1a1b1b; /* Customize focus outline color *
  outline-offset: 4px; /* Add spacing between the element and the outline */
}

.mainContent2 .contentArea2  form .left2 .container2     .custom-file-upload2:hover {
  background-color: #e0e0e0;
}

.mainContent2 .contentArea2  form .left2 .container2     .fileName {
  margin-left: 18px;
  font-size: 16px;
  font-family: "Heebo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #626262;
}

 #bt2{
  margin-top: 550px;
}
.mainContent2 .contentArea2  .saveBtn2 {
  margin-top: 15px;
  width: 168px;
  margin-top: 502px;
  height: 50px;
  margin: 12px;
  border-radius: 40px;
  background: #0a7a8f;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Heebo", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 23.5px;
  color: #ffffff;
  border: none;
  outline: none;
  box-shadow: none;
  cursor: pointer;
}
.mainContent2 .contentArea2  .saveBtn2.focus-visible{
  border: 1px solid #17181a;
}
@media (max-width: 1300px) {
  .backPage2  {
    left: 0px;
    top: 10px;
    z-index: 999999999;
  }

  .mainContent2 .topHeader2 .backPage2  {
    top: 2px;
    left: 0px;
    height: 25px;
  }
}

@media (max-width: 1250px) {
  .mainContent2 .progressChapter .contentTab2   .tabcontent2    .row2 .progressBar .progressTopLine {
    margin-left: 20px;
  }
}

@media (max-width: 1200px) {
  .header2 .left2 .logo2 {
    margin-left: 9px;
  }

  .header2 .left2 ul li {
    margin-right: 19px;
  }

  .mainContent2 .contentArea2  .progress2     .tabContentTop2   .tabcontent2    .row2 .card2 .bottomImg {
    width: 250px;
    display: block;
    margin: auto;
  }

  .mainContent2 .readingBookTop .celebrate {
    height: auto;
  }

  .mainContent2 .progressChapter .contentTab2   .tabcontent2    .row2 .description {
    width: 400px !important;
    text-align: right;
  }

  .mainContent2 .topContent2   .search2     .searchInput2  {
    max-width: 250px;
  }
}

@media (max-width: 1100px) {



  .mainContent2 .progressChapter .contentTab2   .tabcontent2    .row2 .progressBar {
  }

  .mainContent2 .progressChapter .contentTab2   .tabcontent2    .row2 .progressBar .completed {
   
    margin-right: -14px;
    margin-left: 0px;
    text-align: center;
  }
}

@media (max-width: 992px) {
  .header2 {
    padding: 10px 5%;
  }
  .header2 .Admin {
    padding: 10px 5%;
  }
  .mainContent2 .topHeader2 .backPage2 ,
  .backPage2  {
    display: none;
  }

  .mainContent2 .topContent2   .search2     .searchInput2  {
    max-width: 200px;
  }

  .header2 .right2 {
    display: none;
  }

  .header2 .left2 {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .header2 .left2 .mobileMenu2 {
    display: block;
    cursor: pointer;
  }

  .header2 .left2 .logo2 {
    margin-right: 0px;
  }

  .header2 .left2 .overLay2 {
    display: none;
  }

  .mainContent2 .contentArea2  .progress2     .tabContentTop2   .tabcontent2    .row2 .card2 h2 {
    font-size: 24px;
  }

  .mainContent2 .contentArea2  .progress2     .tabContentTop2   .tabcontent2    .row2 {
    flex-wrap: wrap;
  }

  .mainContent2 .contentArea2  .progress2     .tabContentTop2   .tabcontent2    .row2 .card2 {
    width: 49%;
    margin-bottom: 12px;
  }

  .mainContent2 .contentArea2  .progress2     .myAccountList2   {
    border-bottom: none;
    display: flex;
    justify-content: center;
  }

  .mainContent2Success {
    width: 100% !important;
  }

  .mainContent2 .readingBookTop .celebrate {
    background: #fff;
  }

  .mainContent2 .readingBookTop .celebrate h2 {
    line-height: 40px;
  }
}

@media (max-width: 900px) {
  .mainContent2 .individualHeader .totalUser {
    display: none;
  }

  .mainContent2 .books2 .headerBooksList2     {
    display: none;
  }

  .mainContent2 .books2 .contentTab2   .tabcontent2    .row2 {
    background: #fff;
    border-radius: 7px;
    border: none;
    margin-bottom: 6px;
    box-shadow: 0px 0px 30px 0px #0000001f;
  }

  .mainContent2 .books2 h1 {
    display: none;
  }
  .mainContent2 .detailAccountTop2 {
    padding: 20px;
  }
  .bookMobileColor2 .topContent2   {
    box-shadow: none !important;
    background: transparent !important;
  }

  .bookMobileColor2 .topContent2   .books2 .forMobileSearch2{
    display: block;
  }

  .mainContent2 .line2 {
    display: none;
  }

  .mainContent2 .books2 .contentTab2   .tabcontent2    .row2 .left,
  .mainContent2 .books2 .contentTab2   .tabcontent2    .row2 .center2     {
    max-width: 48%;
    width: 48%;
  }

  .mainContent2 .books2 .contentTab2   .tabcontent2    .row2 .right2 {
    max-width: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 24px;
  }

  .mainContent2 .books2 .contentTab2   .tabcontent2    .row2 {
    flex-wrap: wrap;
  }

  .mainContent2 .topContent2   .search2     {
    display: none;
  }

  .readingTop {
    max-width: 100% !important;
    width: 100% !important;
  }

  .mainContent2 .readingBookTop {
    background: transparent !important;
    padding: 15px 14px;
  }

  .mainContent2 .readingBookTop .hideParaMobile {
    display: none;
  }

  .mainContent2 .readingBookTop .player {
    background: #fff;
    height: 662px;
  }

  .mainContent2 .readingBookTop .player h1 {
    line-height: 55px;
  }

  .mainContent2 .readingBookTop .player .footerPlayer .center2     .previous .inner2  img,
  .mainContent2 .readingBookTop .player .footerPlayer .center2     .repeat img,
  .mainContent2 .readingBookTop .player .footerPlayer .center2     .start img,
  .mainContent2 .readingBookTop .player .footerPlayer .center2     .next img {
    margin-top: 7px;
  }

  .mainContent2 .readingBookTop .player {
    margin-bottom: 58px;
  }

  .mainContent2 .readingBookTop .progressPlayer {
    margin-bottom: 38px;
  }

  .mainContent2 .individualHeader .right2 {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    justify-content: flex-end;
  }

  .mainContent2 .individualHeader .right2 .progressLine {
    padding-right: 0px;
    margin-right: 0px;
    margin-left: 0px;
  }

  .mainContent2 .individualHeader .right2 .btn {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .mainContent2 .individualHeader .right2 .btn button {
    margin-bottom: 5px;
  }

  .mainContent2 .individualHeader .right2 .progressLine .grayLine {
    margin-right: 0px;
    width: 100px;
  }

  .mainContent2 .progressChapter .contentTab2   .tabcontent2    .row2 .description {
    width: 250px !important;
  }
}

@media (max-width: 800px) {
  .mainContent2 .contentArea2  .userRegistrationLink2  .left2 .copyTexting2   p {
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .mainContent2 .detailAccountTop2 .headerTop2 {
    flex-wrap: wrap !important;
  }
  .mainContent2 .contentArea2  .userRegistrationLink2  button {
    margin-top: 10px;
  }

  .mainContent2 .detailAccount2  Top .headerTop2   {
    flex-wrap: wrap !important;
  }

  .mainContent2 .contentArea2  .detailAccount2   .activeUsers2 {
    flex-wrap: wrap;
    height: auto !important;
    padding: 5px 20px;
  }

  .mainContent2 .contentArea2  .detailAccount2   h2 {
    margin-right: 20px;
    width: 100% !important;

    margin-bottom: 10px;
  }

  .mainContent2 .contentArea2  .userRegistrationLink2  {
    flex-wrap: wrap;
    height: auto;
    padding: 10px;
    overflow: hidden;
  }

  .mainContent2 .detailAccount2  Top {
    padding: 20px;
  }
}

@media (max-width: 779px) {
  .mainContent2 .progressChapter .contentTab2   .tabcontent2    .row2 .progressBar {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }

  .mainContent2 .progressChapter .contentTab2   .tabcontent2    .row2 h2 {
    /* margin-left: 10px;
    width: 50%; */
   
  }

  .mainContent2 .progressChapter .contentTab2   .tabcontent2    .row2 .progressBar {
    width: 50%;
  }

  .mainContent2 .progressChapter .contentTab2   .tabcontent2    .row2 .progressBar p:nth-child(1) {
    width: auto;
  }

  .mainContent2 .progressChapter .contentTab2   .tabcontent2    .row2 .progressBar .progressTopLine {
    margin-right: 5px;
  }

  .mainContent2 .contentArea2  .headerTop2   .timeStamp2     {
    height: auto;
    text-align: center;
    padding: 4px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mainContent2 .contentArea2  .headerTop2   .timeStamp2     img {
    display: none;
  }

  .mainContent2 .contentArea2  .headerTop2   {
    padding: 23px 15px;
  }

  .mainContent2 .contentArea2  .headerTop2   h2 {
    font-size: 18px;
    line-height: 24px;
  }

  .mainContent2 .contentArea2  .headerTop2   .timeStamp2     h3 {
    text-align: center;
  }

  .mainContent2 .contentArea2  .headerTop2   .timeStamp2     h3 .span1 {
    display: none;
  }

  .mainContent2 .contentArea2  .headerTop2   .timeStamp2     h3 .span2 {
    display: block;
    text-align: center;
  }

  .mainContent2 .contentArea2  .progress2     .tabContentTop2   .tabcontent2    .row2 .card2 {
    width: 100%;
    margin: auto;
    margin-bottom: 12px;
  }

  .mainContent2 .contentArea2  .progress2     .tabContentTop2   {
    padding: 23px 9px;
  }

  .mainContent2 .readingBookTop .celebrate img {
    height: 405px;
    object-fit: cover;
  }

  .mainContent2 .individualHeader {
    padding: 9px;
  }

  .mainContent2 .individualHeader .right2 .btn h2 {
    display: none;
  }

  .mainContent2 .individualHeader .left2 h2 {
    font-size: 18px;
    line-height: 24px;
    margin-right: 5px;
  }

  .mainContent2 .progressChapter .chapterList button {
    margin-left: 0px;
    margin-right: 10px;
  }

  .mainContent2 .topHeader2 .right2 {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }

  .mainContent2 .topHeader2 .right2 button {
    margin-left: auto;
  }

  .mainContent2 .topHeader2 .right2 .text2   h2 {
    display: none;
  }

  .mainContent2 .topHeader2 .right2 .text2   {
    margin-right: 0px;
  }

  .mainContent2 .topHeader2 .right2 .text2   p {
    width: 160px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    margin-top: 8px;
  }

  .mainContent2 .topHeader2 {
    padding: 12px 9px;
  }
}

@media (max-width: 700px) {
  .custom-dropdown .headerTimer {
    display: block;
  }

  .custom-dropdown .headerTimer img {
    float: left;
    display: inline;
  }

  .headerReader {
    display: none;
  }

  .mainContent2 .readingBookTop .player {
    width: 90%;
    margin-left: 10%;
  }

  .mainContent2 .readingBookTop .player .group {
    display: block;
    width: 5%;
    position: absolute;
    top: 10px;
    left: -50px;
  }

  .custom-dropdown .option {
    display: none;
  }

  .custom-dropdown .dropdown-content p {
    display: block;
  }

  .custom-dropdown .dropdown-content label {
    font-family: "Heebo", sans-serif;
    font-size: 16px !important;
    font-weight: 300 !important;
    line-height: 24px;
    color: #023842;
  }

  .dropdown-content .paraG {
    display: block;
    margin-bottom: 20px !important;
  }

  .custom-dropdown .inner2     {
    position: fixed;
    z-index: 9999999;
    background: rgba(0, 0, 0, 0.7);
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    display: none;
  }

  .custom-dropdown .inner2     .dropdown-content {
    position: static;
    width: 90%;
    margin: auto;
    padding: 35px;
    margin-top: 5%;
  }

  .paymentModal2 .innerContent2 .userCards {
    flex-wrap: wrap;
    height: auto;
    padding: 10px 20px;
  }
  .paymentModal2 .innerContent2 .userCards2 .card2 h6 {
    font-size: 15px;
  }
  .paymentModal2 .innerContent2 .userCards2 .card2 p{
    font-size: 12px;
  }
  .paymentModal2 .innerContent2 .userCards2 .card2 {
    width: 10;
    margin-bottom: 15px;
  }

  .paymentModal2 .innerContent2 .userCards .card2 {
    width: 50%;
  }
  .number-input-container .custom-buttons2{
    right: 260px;
  }
  .paymentModal2 .innerContent2 form .right2 .custom-select2 .select-icon2 img {
    right: 260px;
  }
  .paymentModal2 .innerContent2 .userCards .card:nth-child(1),
  .paymentModal2 .innerContent2 .userCards .card:nth-child(2) {
    margin-bottom: 15px;
  }

  .paymentModal2 .innerContent2 .userCards .card:nth-child(2) img {
    display: none;
  }

  #chapterMobile {
    display: block;
  }

  .mainContent2 .progressChapter .search2,
  .mainContent2 .progressChapter .chapterList {
    /* display: none; */
  }

  .mainContent2 .books2 .headerBooksList2     {
    /* display: none; */
  }

  .mainContent2 .books2 .contentTab2   .tabcontent2    .row2 .left,
  .mainContent2 .books2 .contentTab2   .tabcontent2    .row2 .center2     {
    max-width: 100%;
    width: 100%;
  }

  .mainContent2 .books2 .contentTab2   .tabcontent2    .row2 .center2     img {
    position: absolute;
    top: 14px;
    left: 18px;
  }

  .mainContent2 .books2 .contentTab2   .tabcontent2    .row2 .center2     {
    margin-top: 10px;
  }

  .settingModal2 .innerContent2 .settingModal2-body2 {
    flex-wrap: wrap;
  }

  .settingModal2 .innerContent2 .settingModal2-body2 .left2 {
    width: 100%;
  }

  .mainContent2 .readingBookTop .player {
    height: 600px;
  }

  .mainContent2 .progressChapter .contentTab2   .tabcontent2    .row2 .description {
    display: none;
  }

  .mainContent2 .progressChapter .contentTab2   .tabcontent2    .row2 .btnsList {
    width: auto;
  }

  .mainContent2 .progressChapter .contentTab2   .tabcontent2    .row2 .progressBar {
    width: auto;
    margin-left: 10px;
  }

  .paymentModal2 .innerContent2 .userCards2 {
    display: flex;
    flex-wrap: wrap;
    height: auto;
    padding: 10px;
    

  }
  .mainContent2 .progressChapter .contentTab2   .tabcontent2    .row2 .progressBar .progressTopLine {
    width: 100px;
  }

  .mainContent2 .progressChapter .contentTab2   .tabcontent2    .row2 .progressBar {
    margin-right: 5px;
  }

  .mainContent2 .progressChapter .contentTab2   .tabcontent2    .row2 {
    padding: 12px 9px;
  }
}

@media (max-width: 679px) {
  .mainContent2 .progressChapter .contentTab2   .tabcontent2    .row2 .btnsList .keepReading {
    width: 118px;
  }
  #bt2{
    margin-top: 20px;
  }
  .mainContent2 .contentArea2  .userRegistrationLink2 .left2{
    display: flex;
    flex-direction: column-reverse;
  }
  .addBookModal2 .modal-content2 .modal-body2 img {
    margin-bottom: 20px;
  }

  .addBookModal2 .modal-content2 .modal-body2 .step1Btn2 {
    margin: auto;
    left: 0px;
    right: 0px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .mainContent2 .contentArea2  form {
    flex-wrap: wrap;
    max-width: 100%;
  }

  .mainContent2 .contentArea2  form .left,
  .mainContent2 .contentArea2  form .right2 {
    max-width: 100%;
    width: 100%;
    margin-left: 0px;
  }

  .addLibraryModalTop .modal-content2 .modal-header2,
  .editLibraryModalTop .modal-content2 .modal-header2,
  .deleteLibraryModalTop .modal-content2 .modal-header2 {
    justify-content: flex-start;
    padding-right: 25px;
  }

  .addLibraryModalTop .modal-content2 .modal-header2 h2,
  .editLibraryModalTop .modal-content2 .modal-header2 h2,
  .deleteLibraryModalTop .modal-content2 .modal-header2 h2 {
    font-size: 20px;
  }

  .mainContent2 .progressChapter .contentTab2   .tabcontent2    .row2 h2 {
    margin-left: 10px;
  }

  .editChapterModal2   .innerContent2,
  .editBookModal2  .innerContent2 {
    margin: 36px auto;
  }

  .editBookModal2  .innerContent2 .headerModal2  {
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-right: 24px;
  }

  .mainContent2 .progressChapter .contentTab2   .tabcontent2    .row2 .progressBar {
    width: auto;
  }
 
  .addBookModal2 .modal-content2,
  .paymentModal2 .innerContent2,
  .settingModal2 .innerContent2,
  .deleteBookModal2  .innerContent2 {
    margin: 27px auto;
    overflow-y: auto;
  }

  .editBookModal2  .innerContent2 .headerModal2  {
    text-align: left;
  }

  .deleteBookModal2  .innerContent2 .headerModal2  {
    display: flex;
    justify-content: flex-start;
    padding-right: 26px;
  }

  .settingModal2 .innerContent2 .headerModal2  .cancel2,
  .editBookModal2  .innerContent2 .headerModal2  .cancel2,
  .deleteBookModal2  .innerContent2 .headerModal2  .cancel2  {
    left: 26px;
    /* margin-left: auto; */
    /* position: absolute; */
  }

  .addBookModal2 .modal-content2 .modal-header2 .close2 {
    right: 26px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .addBookModal2 .modal-content2 .modal-header2,
  .settingModal2 .innerContent2 .headerModal2  {
    justify-content: space-between;
    padding: 26px;
  
    position: relative;
  }

  .addBookModal2 .modal-content2 .modal-header2 h2,
  .settingModal2 .innerContent2 .headerModal2  h1,
  .editBookModal2  .innerContent2 .headerModal2  h1,
  .deleteBookModal2  .innerContent2 .headerModal2  h1 {
    font-size: 20px;  
    margin-left: 123px;
  }

  .addBookModal2 .modal-content2 .modal-body2 {
    padding: 17px 18px;
  }

  .addBookModal2 .modal-content2 .modal-body2 .firstImg {
    margin-bottom: 68px !important;
    position: static;
    display: block;
    margin: auto;
    width: 80%;
    height: auto;
  }

  .addBookModal2 .modal-content2 .modal-body2 form .row2 {
    flex-wrap: wrap;
  }

  .addBookModal2 .modal-content2 .modal-body2 form .row2 input {
    width: 100%;
    margin-bottom: 12px;
  }

  .addBookModal2 .modal-content2 .modal-body2 form .row2 .custom-select2 {
    width: 100%;margin-bottom: 12px;
  }

  .addBookModal2 .modal-content2 .modal-body2 form .row2 .forMobileMargin {
    margin-bottom: 12px;
  }

  .addBookModal2 .modal-content2 .modal-footer {
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
  }

  .addBookModal2 .modal-content2 .modal-footer .back1 {
    margin-right: 0px;
  }

  .addBookModal2 .modal-content2 .modal-footer button {
    margin-bottom: 10px;
  }

  .addBookModal2 .modal-content2 .modal-body2 form {
    padding: 0px 18px;
  }

  .addBookModal2 .modal-content2 .modal-body2 .successUpload2 {
    width: 80%;
  }

  .mainContent2 .readingBookTop .player .footerPlayer .center2     {
    position: absolute;
    top: -80px !important;
    left: 0px;
    right: 0px;
    margin: auto;
  }
}

@media (max-width: 664px) {
  .mainContent2 .topHeader2 .left2 p {
    font-size: 14px;
  }

  .mainContent2 .topHeader2 .right2 .text2   p {
    font-size: 12px;
  }

  .paymentModal2 .innerContent2 {
    padding: 62px 20px;
  }

  .paymentModal2 .innerContent2 .total2 .leftt2 {
    flex-wrap: wrap;
    width: 50%;
  }

  .paymentModal2 .innerContent2 .total2 .rightt2 h3,
  .paymentModal2 .innerContent2 .total2 .leftt2 h3 {
    font-size: 15px;
  }

  .paymentModal2 .innerContent2 .total2 .leftt2 p {
    width: 100%;
  }

  .paymentModal2 .innerContent2 .total2 {
    height: auto;
    padding: 10px;
    border-radius: 7px;
  }

  .paymentModal2 .innerContent2 .total2 .leftt2 {
    flex-wrap: wrap;
  }

  .paymentModal2 .innerContent2 .total2 .leftt2 .firstH32 {
    width: 90px !important;
  }

  .paymentModal2 .innerContent2 .total2 .leftt2 img {
    display: none;
  }

  .paymentModal2 .innerContent2 .userCards {
    padding: 10px;
  }

  .settingModal2 .innerContent2 .settingModal2-body2 .leftForMobile {
    margin-top: 58px;
  }

  .editBookModal2  .innerContent2 .bodyModal2  form {
    padding: 28px;
  }
}

@media (max-width: 622px) {
  .paymentModal2 .innerContent2 form {
    flex-wrap: wrap;
  }

  .paymentModal2 .innerContent2 form .left2,
  .number-input-container,
  .paymentModal2 .innerContent2 form .right2 {
    width: 100%;
    margin-left: 0px;
  }.paymentModal2 .innerContent2 .twoOffer2 {
    flex-direction: column;
    align-items: center;
  }

  .paymentModal2 .innerContent2 .twoOffer2 .monthly2,
  .paymentModal2 .innerContent2 .twoOffer2 .yearly2 {
    margin-right: 0px;
    width: 206px;
    margin-bottom: 25px;
  }

  .editBookModal2  .innerContent2 .bodyModal2  form .row2 {
    flex-wrap: wrap;
  }

  .editBookModal2  .innerContent2 .bodyModal2  form .row2:nth-child(1) .custom-select2,
  .editBookModal2  .innerContent2 .bodyModal2  form .row2:nth-child(2) .custom-select2,
  .editBookModal2  .innerContent2 .bodyModal2  form .row2:nth-child(3) .custom-select2 {
    margin-bottom: 12px;
  }

  .editBookModal2  .innerContent2 .bodyModal2  form .row2 input,
  .editBookModal2  .innerContent2 .bodyModal2  form .row2 .custom-select2 {
    width: 100%;
    margin-bottom: 12px;
  }

  .deleteBookModal2  .innerContent2 .bodyModal2  .btns2   {
    flex-wrap: wrap;
  }

  .deleteBookModal2  .innerContent2 .bodyModal2  .btns2   button {
    margin-bottom: 6px;
  }
}

@media (max-width: 440px) {
  .headerReader {
    display: block;
  }

  .mainContent2 .readingBookTop .player {
    width: 100%;
    margin-right: 0%;
  }

  .mainContent2 .readingBookTop .player .group {
    display: none;
  }
}


/* Modal backdrop styling (if not using a specific class for the backdrop) */
.modal.fade {
  display: none; /* Hidden by default */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 1050; /* Ensure it's above other elements */
}

/* Modal dialog styling */
.modal-dialog {
  margin: 5% auto; /* Centered vertically and horizontally */
  max-width: 600px; /* Set a max width */
  position: relative;
}

/* Specific styling for subs_modal */
.subs_modal {
  width: 100%;
}

/* Modal content styling */
.modal-content {
  position: relative;
  background-color: #fff;
  border-radius: 0.3rem;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2); /* Box shadow for depth */
  overflow: hidden;
  /* padding: 20px; */
  margin-top: 20%; /* Align with inline style */
}

/* RTL support for the container */
.container-fluid.rtl {
  direction: rtl;
}

/* Full-width for w-100 */
.w-100 {
  width: 100%;
}

/* Iframe styling */
iframe {
  width: 100%;
  height: 400px; /* Adjust as needed */
  border: none;
  display: block;
}

/* Button styling */
#closeiframe {
  display: block; /* Make sure button behaves like a block element */
  width: auto;
  padding: 10px 20px;
  color: #fff; /* Text color */
  background-color: var(--bg-color1, #007bff); /* Background color */
  border: none; /* No border */
  border-radius: 3px; /* Rounded corners */
  cursor: pointer;
  margin-top: 20px; /* Spacing at the top */
}

#closeiframe:hover {
  background-color: #0056b3; /* Darker background on hover */
}
