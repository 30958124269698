@import url("https://fonts.googleapis.com/css2?family=Assistant:wght@200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  padding: 0%;
  margin: 0%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-family: "Heebo", sans-serif;
}

html {
  width: 100%;
  font-size: 100%;
  overflow-x: hidden;
  scroll-behavior: auto !important;
}

@media screen and (max-width: 992px) {
  html {
    font-size: 100%;
  }
}

@media screen and (max-width: 668px) {
  html {
    font-size: 92%;
  }
}

@media screen and (max-width: 575px) {
  html {
    font-size: 88%;
  }
}

@media screen and (max-width: 475px) {
  html {
    font-size: 84%;
  }
}

@media screen and (max-width: 380px) {
  html {
    font-size: 80%;
  }
}

@media screen and (max-width: 280px) {
  html {
    font-size: 76%;
  }
}

body {
  background-color: #f0ecf4 !important;
}

.assistant {
  font-family: "Assistant", sans-serif;
}

.heebo {
  font-family: "Heebo", sans-serif;
}

.rubik {
  font-family: "Rubik", sans-serif;
}

.f8 {
  font-size: 0.5rem;
}

.f10 {
  font-size: 0.625rem;
}

.f12 {
  font-size: 0.75rem;
}

.f14 {
  font-size: 0.875rem;
}

.f15 {
  font-size: 0.938rem;
}

.f16 {
  font-size: 1rem;
}

.f18 {
  font-size: 1.125rem;
}

.f20 {
  font-size: 1.25rem;
}

.f22 {
  font-size: 1.375rem;
}

.f24 {
  font-size: 1.5rem;
}

.f28 {
  font-size: 1.75rem;
}

.f34 {
  font-size: 2.125rem;
}

.f30 {
  font-size: 1.875rem;
}

.f35 {
  font-size: 2.125rem;
  line-height: 57px;
}

.f50 {
  font-size: 3.125rem;
  line-height: 65px;
}

.f64 {
  font-size: 4rem;
  line-height: 71px;
}

.fw400 {
  font-weight: 400;
}

.fw500 {
  font-weight: 500;
}

.fw600 {
  font-weight: 600;
}

.fw700 {
  font-weight: 700;
}

.fw800 {
  font-weight: 800;
}

.fw900 {
  font-weight: 900;
}

.color1 {
  color: #0f4152 !important;
}

.bg_color1 {
  background: #0f4152 !important;
}

.color2 {
  color: #606060 !important;
}

.bg_color2 {
  background: #606060 !important;
}

.color3 {
  color: #f0ecf4 !important;
}

.bg_color3 {
  background: #f0ecf4 !important;
}

.color4 {
  color: #bababa !important;
}

.bg_color4 {
  background: #bababa !important;
}

.color5 {
  color: #2d2d2d !important;
}

.bg_color5 {
  background: #2d2d2d !important;
}

.color6 {
  color: #3d3f45 !important;
}

.bg_color6 {
  background: #3d3f45 !important;
}

.color7 {
  color: #f2f5fe !important;
}

.bg_color7 {
  background: #f2f5fe !important;
}

.color8 {
  color: #cdcfd6 !important;
}

.bg_color8 {
  background: #cdcfd6 !important;
}

.color9 {
  color: #eceef4 !important;
}

.bg_color9 {
  background: #eceef4 !important;
}

.color10 {
  color: #44c6cb !important;
}

.bg_color10 {
  background: #44c6cb !important;
}

.color11 {
  color: #626262 !important;
}

.bg_color11 {
  background: #626262 !important;
}

.rtl {
  direction: rtl;
}

.ltr {
  direction: ltr;
}

button:not([disabled]) {
  -webkit-transition: 1s;
  transition: 1s;
}

button:not([disabled]):focus {
  outline: none;
}

button:not([disabled]):hover {
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
}

.page_container {
  max-width: 1200px;
  width: 100%;
  padding: 0rem 1rem;
  margin-left: auto;
  margin-right: auto;
}

details.custom-select {
  position: relative;
  width: 120px;
  /* FAKE SELECT */
}

details.custom-select summary {
  padding: 0.3rem 0.6rem;
  cursor: pointer;
  border-radius: 5px;
  border: 2px solid #0f4152;
  list-style: none;
  color: #0f4152;
}

details.custom-select summary::-webkit-details-marker {
  display: none;
}

details.custom-select summary:after {
  content: url("../../icons/downArrow.svg");
  display: inline-block;
  float: right;
}

details.custom-select summary.rtl:after {
  content: url("../../icons/downArrow.svg");
  display: inline-block;
  float: left;
}

details.custom-select summary:focus {
  outline: none;
}

details.custom-select ul {
  list-style: none;
  width: 100%;
  background: #0f4152;
  position: absolute;
  top: calc(100% + 0.5rem);
  left: 0;
  padding: 1rem;
  margin: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 5px;
  max-height: 200px;
  overflow-y: auto;
}

details.custom-select li {
  margin: 0;
  padding: 0.5rem 0;
  border-bottom: 1px solid #0f4152;
}

details.custom-select li:first-child {
  padding-top: 0;
}

details.custom-select li:last-child {
  padding-bottom: 0;
  border-bottom: none;
}

details.custom-select summary.radios {
  counter-reset: radios;
}

details.custom-select input[type="radio"] {
  counter-increment: radios;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  display: none;
}

details.custom-select input[type="radio"]:checked {
  display: inline;
}

details.custom-select input[type="radio"]:after {
  content: attr(title);
  display: inline;
  font-size: 1rem;
  color: #0f4152;
  margin-right: 0.5rem;
}

details.custom-select summary.rtl input[type="radio"]:after {
  margin-right: 0rem;
}

details.custom-select ul.list {
  counter-reset: labels;
}

details.custom-select label {
  width: 100%;
  display: block;
  cursor: pointer;
}

details.custom-select[open] summary:before {
  content: "";
  display: block;
  width: 100vw;
  height: 100vh;
  background: transparent;
  position: fixed;
  top: 0;
  left: 0;
}

details.custom-select[open] {
  z-index: 1;
}

details.custom-select.light {
  position: relative;
  width: 120px;
  /* FAKE SELECT */
}

details.custom-select.light summary {
  padding: 0.3rem 0.6rem;
  cursor: pointer;
  border-radius: 5px;
  border: 2px solid #f0ecf4;
  list-style: none;
  color: #f0ecf4;
}

details.custom-select.light summary::-webkit-details-marker {
  display: none;
}

details.custom-select.light summary:after {
  content: url("../../icons/downArrow-light.svg");
  display: inline-block;
  float: right;
}

details.custom-select.light summary.rtl:after {
  content: url("../../icons/downArrow-light.svg");
  display: inline-block;
  float: left;
}

details.custom-select.light summary:focus {
  outline: none;
}

details.custom-select.light ul {
  list-style: none;
  width: 100%;
  background: #0f4152;
  position: absolute;
  top: calc(100% + 0.5rem);
  left: 0;
  padding: 1rem;
  margin: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 5px;
  max-height: 200px;
  overflow-y: auto;
}

details.custom-select.light li {
  margin: 0;
  padding: 0.5rem 0;
  border-bottom: 1px solid #0f4152;
}

details.custom-select.light li:first-child {
  padding-top: 0;
}

details.custom-select.light li:last-child {
  padding-bottom: 0;
  border-bottom: none;
}

details.custom-select.light summary.radios {
  counter-reset: radios;
}

details.custom-select.light input[type="radio"] {
  counter-increment: radios;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  display: none;
}

details.custom-select.light input[type="radio"]:checked {
  display: inline;
}

details.custom-select.light input[type="radio"]:after {
  content: attr(title);
  display: inline;
  font-size: 1rem;
  color: #f0ecf4;
  margin-right: 0.5rem;
}

details.custom-select.light summary.rtl input[type="radio"]:after {
  margin-right: 0rem;
}

details.custom-select.light ul.list {
  counter-reset: labels;
}

details.custom-select.light label {
  width: 100%;
  display: block;
  cursor: pointer;
}

details.custom-select.light[open] summary:before {
  content: "";
  display: block;
  width: 100vw;
  height: 100vh;
  background: transparent;
  position: fixed;
  top: 0;
  left: 0;
}

details.custom-select.light[open] {
  z-index: 1;
}

.header_container {
  background: #0f4152;
  height: 66px;
  position: relative;
}

.header_container .profile,
.header_container .access {
  position: absolute;
}

.header_container .profile {
  left: 15px;
}

.header_container .access {
  right: 15px;
}

.header_container .inner_header_container {
  position: relative;
}

.header_container .inner_header_container .logo {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.sidebar_container {
  background: #0f4152;
  height: 66px;
  position: absolute;
  width: 100%;
  left: 0%;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  top:-100%
}

.body_container {
  margin-top: 6rem;
  background: #ffffff;
  border: 1px solid #979797;
  -webkit-box-shadow: 0px 2px 52px #d3d3d3;
          box-shadow: 0px 2px 52px #d3d3d3;
  border-radius: 10px;
  position: relative;
}

.body_container .back_btn {
  position: absolute;
  top: -1.5rem;
  left: -5.8rem;
  height: 34px;
  width: 50px;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.body_container .swiper .slide_box {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 260px;
  border: 1px solid #9b9b9b;
  background: -webkit-gradient(linear, left bottom, left top, color-stop(11%, #ddebee), color-stop(31%, white));
  background: linear-gradient(0deg, #ddebee 11%, white 31%);
  border-radius: 10px;
  -webkit-box-shadow: 1px 1px 10px #027e9479;
          box-shadow: 1px 1px 10px #027e9479;
}
.preference_modal
{
  max-width: 65% !important;
    margin-left: 20% !important;
    margin-top: 5% !important;
}
.markupbtn
{
  background-color: lightgrey;
}
.lighterthengrey
{
  background-color: lightgray;
}
.yellow{
  background-color: yellow;
}
.lightgrey
{
  background-color: lightgrey;

}
.grey{
  background-color: grey;
}

.yellowbtn{
  background-color: yellow;
    border-radius: 50%;
    padding: 10px;
}
.greybtn{
  background-color: grey;
    border-radius: 50%;
    padding: 10px;
}
.underline{
  text-decoration: underline;
}
.body_container .swiper .slide_box .swiper-button-prev {
  width: 20px;
}
#carteSoudCtrl
{
  visibility: hidden;
}
#audio
{
  visibility: hidden;
}
.body_container .swiper .slide_box .swiper-button-next,
.body_container .swiper .slide_box .swiper-button-prev {
  height: 32px;
  width: 32px;
  -o-object-fit: contain;
     object-fit: contain;
}

.body_container .swiper .slide_box .swiper-button-next,
.body_container .swiper .slide_box .swiper-rtl .swiper-button-prev {
  right: -25px !important;
}

.body_container .swiper .slide_box .swiper-button-prev,
.body_container .swiper .slide_box .swiper-rtl .swiper-button-next {
  left: -28px !important;
}

.body_container .swiper .slide_box .center_part {
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.body_container .zoom {
  background: #ffffff;
  border: 1px solid #1d9da2;
  border-radius: 100px;
  padding: 0.6rem 1.2rem 0.5rem 1.2rem;
}

@media screen and (max-width: 575px) {
  .body_container {
    margin-top: 5rem;
  }
}
